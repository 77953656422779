import { z } from 'zod';

import { DdiHomeMetricOption } from '~anyx/feature/report';
import { DdiHomeReportInput, SaleChannelType } from '~anyx/shared/graphql';
import { TimezoneUtils, dayjs } from '~anyx/shared/utils';

type Constraints = DdiHomeReportInput & {
  metric: DdiHomeMetricOption;
};

export const DdiHomePageFiltersParser = z.object({
  endDate: z.string().catch(dayjs().tz(TimezoneUtils.getCurrentTimezone()).endOf('day').format()),
  startDate: z
    .string()
    .catch(dayjs().tz(TimezoneUtils.getCurrentTimezone()).startOf('day').format()),
  saleChannelTypes: z.array(z.nativeEnum(SaleChannelType)).catch([]),
  masterDataStoreIds: z.array(z.string()).catch([]),
  metric: z.nativeEnum(DdiHomeMetricOption).catch(DdiHomeMetricOption.GROSS_SALES),
}) satisfies z.ZodType<Constraints, z.ZodTypeDef, unknown>;

export type DdiHomeFilterInputFilterType = z.infer<typeof DdiHomePageFiltersParser>;
