import { useMemo } from 'react';

import { DefaultRangeValue, useRangeSelectDefaultOptions } from '@any-ui-react/dates';

import { DdiHomeMetricOption, useReportSyncCalendar } from '~anyx/feature/report';
import { ReportDimensions } from '~anyx/shared/graphql';
import { DateUtils, dayjs, TimezoneUtils, useFilters } from '~anyx/shared/utils';

import { DdiHomePageFiltersParser } from './ddiHomePage.filters';
import { DdiHomeFilterInputFilterType } from './ddiHomePage.filters';

export const useDdiHomeFilter = ({
  accountId,
  storeId,
}: {
  accountId?: string;
  storeId?: string;
}) => {
  const timezone = TimezoneUtils.getCurrentTimezone();
  const rangeList = useRangeSelectDefaultOptions({
    only: [DefaultRangeValue.LAST_7_DAYS],
  });
  const startDate = rangeList[0]?.value[0] || dayjs().tz(timezone).startOf('day').format();
  const endDate = rangeList[0]?.value[1] || dayjs().tz(timezone).endOf('day').format();
  const { range } = useReportSyncCalendar();

  const filtersDefault = useMemo(
    () =>
      DdiHomePageFiltersParser.parse({
        startDate: range.startDate ?? startDate,
        endDate: range.endDate ?? endDate,
        masterDataStoreIds: storeId ? [storeId] : [],
        marketplaces: [],
        dimensions: ReportDimensions.DAY,
        metric: DdiHomeMetricOption.GROSS_SALES,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, accountId, storeId]
  );

  const filters = useFilters<DdiHomeFilterInputFilterType>(filtersDefault, {
    withPagination: false,
    withLocalStorage: false,
    scope: 'home',
    parser: DdiHomePageFiltersParser.parse,
  });

  const dateVariables = {
    endDate: filters.current?.endDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.endDate))
      : '',
    startDate: filters.current?.startDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.startDate))
      : '',
  };

  const filterInputs = {
    ...filters.current,
    ...dateVariables,
  };

  return {
    filters,
    filterInputs,
  };
};
