import { useTranslation } from 'react-i18next';

import { RangeDatePickerInput } from '@any-ui-react/dates';

import { MarketplaceChannelSelect } from '~anyx/feature/marketplace';
import { useReportSyncCalendar } from '~anyx/feature/report';
import { useRangeDatePickerInputProps } from '~anyx/shared/ui';
import { FilterProps } from '~anyx/shared/utils';

import { DdiHomeFilterInputFilterType } from './ddiHomePage.filters';

export const DdiHomeFilters = ({
  current,
  updateFilters,
  changeFilter,
}: FilterProps<DdiHomeFilterInputFilterType>) => {
  const rangeDatePickerInputProps = useRangeDatePickerInputProps();
  const { t } = useTranslation();
  const { setRange } = useReportSyncCalendar();

  return (
    <div data-test-id="report-filters" className="grid grid-cols-2 gap-2 p-2">
      <div className="col-span-2 sm:col-span-1">
        <RangeDatePickerInput
          {...rangeDatePickerInputProps}
          value={[current.startDate, current.endDate]}
          onChange={([start, end]) => {
            const dateFilters = {
              endDate: end || '',
              startDate: start || '',
            };
            updateFilters({ ...current, ...dateFilters });
            setRange(dateFilters);
          }}
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <MarketplaceChannelSelect
          isMulti
          isSearchable
          isClearable
          className="select-has-min-w-menu [&_.any-select\_\_value-container]:flex-nowrap [&_.option-label]:truncate [&_label]:truncate"
          storeIds={current.masterDataStoreIds}
          onChange={(options) => {
            changeFilter(
              'saleChannelTypes',
              options.map((option) => option.value)
            );
          }}
          placeholder={t('shared.entity.channel', { ns: 'shared', count: 2 })}
          value={current.saleChannelTypes.map((channel) => ({
            value: channel,
            label: t('gql.enum.channel', { ns: 'gql', channel }),
          }))}
        />
      </div>
    </div>
  );
};
