import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, InputProps } from '@any-ui-react/core';
import { SingleDatePickerInput } from '@any-ui-react/dates';
import { Select } from '@any-ui-react/select';
import { set } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';

import { ReportSupportedCurrency, SaleChannelType } from '~anyx/shared/graphql';
import { TimezoneUtils } from '~anyx/shared/utils';

import { mergeDateInTimeZone } from '../../../utils';

export interface EventCalendarData {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  target?: {
    revenue?: string | null;
    currency: ReportSupportedCurrency;
  } | null;
  saleChannelCountry?: string | null;
  masterDataStoreId?: string | null;
  saleChannelType: SaleChannelType;
}

interface CalendarTimeSelectorProps extends InputProps {
  control?: Control<EventCalendarData>;
  name: 'startDate' | 'endDate';
  label?: string;
  placeholder?: string;
}

export const CalendarTimeSelector = ({
  control,
  name,
  disabled,
  required,
  placeholder,
  label,
  error,
  ...rest
}: CalendarTimeSelectorProps) => {
  const { t } = useTranslation();
  const timezone = TimezoneUtils.getCurrentTimezone();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...field } }) => {
        const valueInDate = toZonedTime(value, timezone);

        return (
          <Input.Wrapper {...rest.wrapperProps} error={error} className={rest.className}>
            <div className="flex flex-col gap-1">
              <div className="sm:flex sm:gap-3">
                <div className="w-full">
                  <SingleDatePickerInput
                    {...field}
                    value={value}
                    onChange={(date) => {
                      if (!date) return;

                      onChange(
                        mergeDateInTimeZone({
                          currentDate: value,
                          newDate: date,
                          timezone,
                          mergeValues: {
                            year: true,
                            month: true,
                            date: true,
                          },
                        })
                      );
                    }}
                    inputProps={{
                      required,
                      disabled,
                      error: !!error,
                      placeholder: placeholder,
                      label: label,
                      wrapperProps: {
                        className: 'gap-px',
                      },
                    }}
                  />
                </div>
                <div className="pt-4 sm:pt-0">
                  <label className="mb-px flex leading-10">
                    {t('report.page.eventCalendar.dialog.field.time.name', {
                      ns: 'report',
                    })}
                  </label>
                  <div className="grid grid-cols-[minmax(4.5rem,_1fr)_auto_minmax(4.5rem,_1fr)] gap-x-1">
                    <Select
                      value={valueInDate.getHours()}
                      options={Array.from({ length: 24 }).map((_, i) => {
                        return {
                          label: `${i}`.padStart(2, '0'),
                          value: i,
                        };
                      })}
                      maxMenuHeight={160}
                      menuPortalTarget={document.body}
                      onChange={(option) => {
                        if (!option) return;
                        const newDate = fromZonedTime(
                          set(new Date(), {
                            hours: option.value,
                          }),
                          timezone
                        ).toISOString();

                        onChange(
                          mergeDateInTimeZone({
                            currentDate: value,
                            newDate,
                            timezone,
                            mergeValues: {
                              hours: true,
                            },
                          })
                        );
                      }}
                      placeholder={t('report.page.eventCalendar.dialog.field.time.placeholder', {
                        ns: 'report',
                      })}
                      disabled={disabled}
                      isClearable={false}
                      error={!!error}
                    />
                    <span className="mt-1 font-bold">:</span>
                    <Select
                      value={valueInDate.getMinutes()}
                      options={Array.from({ length: 60 }).map((_, i) => {
                        return {
                          label: `${i}`.padStart(2, '0'),
                          value: i,
                        };
                      })}
                      maxMenuHeight={160}
                      menuPortalTarget={document.body}
                      onChange={(option) => {
                        if (!option) return;

                        const newDate = fromZonedTime(
                          set(new Date(), {
                            minutes: option.value,
                          }),
                          timezone
                        ).toISOString();

                        onChange(
                          mergeDateInTimeZone({
                            currentDate: value,
                            newDate,
                            timezone,
                            mergeValues: {
                              minutes: true,
                            },
                          })
                        );
                      }}
                      placeholder={t('report.page.eventCalendar.dialog.field.time.placeholder', {
                        ns: 'report',
                      })}
                      disabled={disabled}
                      isClearable={false}
                      error={!!error}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Input.Wrapper>
        );
      }}
    />
  );
};
