import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createStore } from 'zustand/vanilla';

import { CurrenciesEnum } from '../../enums';
import { Environment } from '../../models';
import { AnyXLanguage } from '../../utils';

export interface EnvironmentStore {
  environment: Environment;
  currentLanguage: AnyXLanguage;
  appName: string;
  languages: AnyXLanguage[];
  currentLocale: string;
  currentTimezone: string;
  currentCurrency: CurrenciesEnum;
  enableDebug?: boolean;
  pseudoLanguage?: string;
  setEnvironment: (environment: Environment) => void;
  setEnableDebug: (enableDebug?: boolean) => void;
  setAppName: (product: 'ANYCHAT' | 'ANYLOGI' | 'ANYX') => void;
  setPseudoLanguage: (pseudoLanguage?: string) => void;
  setLanguages: (languages: AnyXLanguage[]) => void;
  setCurrentLanguage: (currentLanguage: AnyXLanguage) => void;
  setCurrentLocale: (localeLanguage: string) => void;
  setTimezone: (currentTimezone: string) => void;
  setCurrency: (currentCurrency: CurrenciesEnum) => void;
}
export const environmentStore = createStore<EnvironmentStore>()(
  devtools(
    immer((set) => ({
      environment: Environment.LOCAL,
      enableDebug: true,
      appName: 'AnyX',
      currentLanguage: 'en-US' as AnyXLanguage,
      languages: ['en-US'] as AnyXLanguage[],
      currentLocale: 'en-US',
      currentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      currentCurrency: CurrenciesEnum.JPY,
      setEnvironment: (environment) => set({ environment }, false, '[environment] environment'),
      setEnableDebug: (enableDebug) => set({ enableDebug }, false, '[environment] enableDebug'),
      setPseudoLanguage: (pseudoLanguage) =>
        set({ pseudoLanguage }, false, '[environment] setPseudoLanguage'),
      setLanguages: (languages) => set({ languages }, false, '[environment] languages'),
      setCurrentLanguage: (currentLanguage) =>
        set({ currentLanguage }, false, '[environment] currentLanguage'),
      setCurrentLocale: (currentLocale) =>
        set({ currentLocale }, false, '[environment] localeLanguage'),
      setTimezone: (currentTimezone) =>
        set({ currentTimezone }, false, '[environment] currentTimezone'),
      setAppName: (product: 'ANYCHAT' | 'ANYLOGI' | 'ANYX') => {
        const getAppName = () => {
          switch (product) {
            case 'ANYCHAT':
              return 'AnyChat';
            case 'ANYLOGI':
              return 'AnyLogi';
            default:
              return 'AnyX';
          }
        };

        set({ appName: getAppName() }, false, '[environment] appName');
      },
      setCurrency: (currentCurrency) =>
        set({ currentCurrency }, false, '[environment] currentCurrency'),
    })),
    {
      name: 'Environment',
    }
  )
);

export const useEnvironmentStore = <T>(selector: (state: EnvironmentStore) => T): T =>
  useStore(environmentStore, selector);
