import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { environment } from '~anyx/app-core/env';

import { initI18n } from '~anyx/shared/i18n';
import { Spinner } from '~anyx/shared/ui';
import {
  Environment,
  LanguageUtils,
  pwaStore,
  environmentStore,
  DocUtils,
} from '~anyx/shared/utils';
import { dayjs } from '~anyx/shared/utils';

import { getRouter } from './app/app.routing';
import { initWebVitals, initSentry } from './monitoring';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const version = process.env['NX_PUBLIC_VERSION'] || '';
// eslint-disable-next-line no-console
console.log(
  `${environment.appName} Version : ${version} ; Configuration : ${Environment[environment.env]}`
);
environmentStore.setState((state) => ({
  ...state,
  environment: environment.env,
  currentTimezone: dayjs.tz.guess(),
  currentLanguage: LanguageUtils.fromI18nLanguage(environment.crowdin.default),
  pseudoLanguage: environment.crowdin.pseudoLanguage,
  languages: environment.crowdin.supported.map(LanguageUtils.fromI18nLanguage),
}));

DocUtils.DOCUMENT_URL = environment.help.gitbook;

initWebVitals();
initSentry();
initI18n({
  fallbackLng: environment.crowdin.default,
  interpolation: {
    escapeValue: false,
  },
  ns: [
    'account',
    'amazon',
    'anchanto',
    'anychat',
    'anylogi',
    'core',
    'crm',
    'crmIntegration',
    'dhl',
    'facebook',
    'fedex',
    'google',
    'gql',
    'japanPost',
    'jubelio',
    'lazada',
    'logiless',
    'logistic',
    'manual',
    'marketplace',
    'qoo10',
    'rakuten',
    'report',
    'shared',
    'shipping',
    'shopee',
    'shopify',
    'tikTok',
    'tokopedia',
    'ups',
    'workflow',
    'yahoo',
    'zozotown',
  ],
  load: 'currentOnly',
  debug: environment.enableDebug,
  lng: environment.crowdin.default,
  supportedLngs: environment.crowdin.allowTranslation
    ? [...environment.crowdin.supported, environment.crowdin.pseudoLanguage]
    : environment.crowdin.supported,
});

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <RouterProvider router={getRouter()} fallbackElement={<Spinner />} />
  </StrictMode>
);

if ([Environment.STAGING, Environment.PRODUCTION].includes(environment.env)) {
  serviceWorkerRegistration.register({
    path: 'service-worker.js',
    onSuccess: () => pwaStore.setState((state) => ({ ...state, isReady: true })),
    onUpdate: () => pwaStore.setState((state) => ({ ...state, hasUpdate: true })),
  });
}
