import { useTranslation } from 'react-i18next';

import { RangeDatePickerInputProps } from '@any-ui-react/dates';

import { useScreenBreakpoint } from '~anyx/shared/utils';

export const useRangeDatePickerInputProps = ({
  inputProps,
  datePickerProps,
  rangeInputProps,
  footerProps,
  ...props
}: RangeDatePickerInputProps = {}) => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenBreakpoint('lg');

  return {
    size: 'full',
    withRangeInputs: false,
    inputProps: {
      ...inputProps,
    },
    datePickerProps: {
      numberOfColumns: isLargeScreen ? 2 : 1,
      ...datePickerProps,
    },
    rangeSelectorProps: {
      inputProps: {
        label: t('shared.date.title', { ns: 'shared' }),
        placeholder: t('shared.date.rangePlaceholder', { ns: 'shared' }),
      },
      renderOptions: (options) => {
        return options.map((option) => ({
          ...option,
          label: t('shared.date.range', { ns: 'shared', range: option.label }),
        }));
      },
      ...rangeInputProps,
    },
    footerProps: {
      applyButtonProps: {
        children: t('shared.button.apply', { ns: 'shared' }),
      },
      cancelButtonProps: {
        children: t('shared.button.cancel', { ns: 'shared' }),
      },
      ...footerProps,
    },
    ...props,
  } as RangeDatePickerInputProps;
};
