import { useTranslation } from 'react-i18next';

import { RangeDatePickerInput } from '@any-ui-react/dates';
import { Select } from '@any-ui-react/select';
import { useSessionStorage } from '@mantine/hooks';
import classNames from 'classnames';

import { usePermissionCtx } from '~anyx/common/permission';
import { useSelfCtx } from '~anyx/common/self';
import { RestrictedWorkspaceMode, useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';
import { AccountSelect } from '~anyx/feature/account';
import { StoreSelect } from '~anyx/feature/account';
import { MarketplaceChannelSelect } from '~anyx/feature/marketplace';
import {
  MAX_SELECTED_ACCOUNTS,
  MIN_SELECTED_ACCOUNTS,
  useReportSyncCalendar,
} from '~anyx/feature/report';
import { AdsMetricsType, MdUserRole, ReportOrderStatus } from '~anyx/shared/graphql';
import { useRangeDatePickerInputProps } from '~anyx/shared/ui';
import { FilterProps, ObjectUtils, REPORT_DEFAULT_ACCOUNTS_STORAGE_KEY } from '~anyx/shared/utils';

import { HomeFilterInputFilterType } from './homePage.filters';

export const HomeFilters = ({
  current,
  updateFilters,
  changeFilter,
}: FilterProps<HomeFilterInputFilterType>) => {
  const { t } = useTranslation();
  const { self } = useSelfCtx();
  const [savedAccount, setSavedAccount] = useSessionStorage<Record<string, string[]>>({
    key: REPORT_DEFAULT_ACCOUNTS_STORAGE_KEY,
  });
  const rangeDatePickerInputProps = useRangeDatePickerInputProps();
  const { setRange } = useReportSyncCalendar();
  const { mode } = useWorkspaceCtx();
  const { allowed: isAdminOrStaff } = usePermissionCtx({
    roles: [MdUserRole.ADMIN, MdUserRole.STAFF],
  });

  const filterMode = {
    merchantViewSingle: mode === WorkspaceMode.ON && !isAdminOrStaff, // (3)
    merchantViewAll: mode === WorkspaceMode.ACCOUNT_ONLY && !isAdminOrStaff, //  store (4)
    adminOrStaffViewSingle: mode === WorkspaceMode.ON && isAdminOrStaff, // + gross (4)
    adminOrStaffViewAll: mode === WorkspaceMode.OFF && isAdminOrStaff, // + gross + (account + store) (6)
  };

  const filterClassNames = classNames('col-span-2 sm:col-span-1 ', {
    'md:basis-96 lg:basis-1/5': filterMode.merchantViewAll,
    'md:basis-52 xl:basis-56': filterMode.adminOrStaffViewAll,
    'md:basis-32': filterMode.adminOrStaffViewSingle || filterMode.merchantViewSingle,
  });

  const {
    startDate,
    endDate,
    masterDataAccountIds,
    masterDataStoreIds,
    marketplaces,
    orderStatus,
    adsMetricsType,
  } = current;

  return (
    <div data-test-id="report-filters" className="grid grid-cols-2 gap-2 p-2">
      <div className={filterClassNames}>
        <RangeDatePickerInput
          {...rangeDatePickerInputProps}
          value={[startDate, endDate]}
          onChange={([start, end]) => {
            const dateFilters = {
              endDate: end || undefined,
              startDate: start || undefined,
            };
            updateFilters({ ...current, ...dateFilters });
            setRange(dateFilters);
          }}
        />
      </div>
      <div className={filterClassNames}>
        <MarketplaceChannelSelect
          isMulti
          isSearchable
          isClearable
          className="select-has-min-w-menu [&_.any-select\_\_value-container]:flex-nowrap [&_.option-label]:truncate [&_label]:truncate"
          storeIds={masterDataStoreIds}
          onChange={(options) => {
            changeFilter(
              'marketplaces',
              options.map((option) => option.value)
            );
          }}
          placeholder={t('shared.entity.channel', { ns: 'shared', count: 2 })}
          value={marketplaces.map((channel) => ({
            value: channel,
            label: t('gql.enum.channel', { ns: 'gql', channel }),
          }))}
        />
      </div>
      <div className={filterClassNames}>
        <Select
          onChange={(option) => {
            if (option?.value) {
              changeFilter('orderStatus', option.value);
            }
          }}
          options={ObjectUtils.arrayFromEnum<ReportOrderStatus>(ReportOrderStatus).map(
            (status) => ({
              label: t('gql.enum.reportOrderStatus', { ns: 'gql', status }),
              value: status,
            })
          )}
          value={orderStatus}
        />
      </div>
      {isAdminOrStaff && (
        <div className={filterClassNames}>
          <Select
            onChange={(option) => {
              if (option?.value) {
                changeFilter('adsMetricsType', option.value);
              }
            }}
            options={ObjectUtils.arrayFromEnum<AdsMetricsType>(AdsMetricsType).map((type) => ({
              label: t('gql.enum.adsMetricsType', { ns: 'gql', type }),
              value: type,
            }))}
            value={adsMetricsType}
          />
        </div>
      )}
      <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.OFF]}>
        <div className={filterClassNames}>
          <AccountSelect
            isClearable={false}
            isMulti
            isOptionDisabled={(option) => {
              const selectedCount = masterDataAccountIds?.length || 0;
              const isSelected = masterDataAccountIds?.includes(option.value);

              return (
                (selectedCount === MIN_SELECTED_ACCOUNTS && isSelected) ||
                (selectedCount === MAX_SELECTED_ACCOUNTS && !isSelected)
              );
            }}
            className="select-has-disabled-options"
            onChange={(options) => {
              const selectedAccountIds = options.map((option) => option.value);
              updateFilters({
                ...current,
                masterDataAccountIds: selectedAccountIds,
                masterDataStoreIds: undefined,
                marketplaces: undefined,
              });

              if (options) {
                setSavedAccount({
                  ...savedAccount,
                  [self.id]: selectedAccountIds,
                });
              }
            }}
            backspaceRemovesValue={masterDataAccountIds?.length !== 1}
            value={masterDataAccountIds}
            placeholder={t('shared.entity.account', {
              ns: 'shared',
              count: 1,
            })}
          />
        </div>
      </RestrictedWorkspaceMode>
      <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.OFF, WorkspaceMode.ACCOUNT_ONLY]}>
        <div className={filterClassNames}>
          <StoreSelect
            isMulti={true}
            onChange={(options) => {
              const selectedStoreIds = options.map((option) => option.value);
              updateFilters({
                ...current,
                masterDataStoreIds: selectedStoreIds,
                marketplaces: undefined,
              });
            }}
            value={masterDataStoreIds || null}
            filters={{ accountIds: masterDataAccountIds }}
            placeholder={t('shared.entity.store', {
              ns: 'shared',
              count: 1,
            })}
          />
        </div>
      </RestrictedWorkspaceMode>
    </div>
  );
};
