export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A type representing a formatted java.math.BigDecimal */
  BigDecimal: { input: string; output: string; }
  Date: { input: any; output: any; }
  /** An instantaneous point on the time-line represented by a standard date time string */
  Instant: { input: any; output: any; }
  /** A type representing a formatted java.time.LocalDate */
  LocalDate: { input: any; output: any; }
  /** A type representing a formatted java.time.LocalDatetime */
  LocalDatetime: { input: any; output: any; }
  /** A type representing a formatted java.lang.Long */
  Long: { input: string; output: string; }
  /** A type representing a formatted kotlin.collections.Map */
  Map: { input: Record<string, any>; output: Record<string, any>; }
  /** A date-time with an offset from UTC/Greenwich in the ISO-8601 calendar system using the format 1970-01-01T00:00:00Z */
  OffsetDateTime: { input: string; output: string; }
  Time: { input: any; output: any; }
  /** A type representing a formatted java.net.URL */
  URL: { input: string; output: string; }
  UnixTimestamp: { input: number; output: number; }
  /** plain http url */
  Url: { input: string; output: string; }
  /** A type representing a formatted java.util.YearMonth */
  YearMonth: { input: any; output: any; }
  /** A type representing a formatted java.time.ZonedDateTime */
  ZonedDatetime: { input: string; output: string; }
};

export type AiAuthor = {
  readonly __typename?: 'AIAuthor';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type AiMessageFailedToSendEvent = {
  readonly __typename?: 'AIMessageFailedToSendEvent';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountAlreadyHaveConnection = {
  readonly __typename?: 'AccountAlreadyHaveConnection';
  readonly message: Scalars['String']['output'];
};

export type AccountCourierSettings = {
  readonly __typename?: 'AccountCourierSettings';
  readonly accountId: Scalars['ID']['output'];
  readonly companyName: Scalars['String']['output'];
  readonly country: Country;
  readonly ready: AccountCourierSettingsStatus;
  readonly updated?: Maybe<Scalars['Date']['output']>;
  readonly websiteUrl: Scalars['String']['output'];
};

export const AccountCourierSettingsStatus = {
  NOT_READY: 'NOT_READY',
  READY: 'READY'
} as const;

export type AccountCourierSettingsStatus = typeof AccountCourierSettingsStatus[keyof typeof AccountCourierSettingsStatus];
export const Action = {
  INITIAL_ORDER_SYNC: 'INITIAL_ORDER_SYNC',
  INITIAL_SYNC: 'INITIAL_SYNC',
  LISTING_SYNC: 'LISTING_SYNC',
  ORDER_SYNC: 'ORDER_SYNC'
} as const;

export type Action = typeof Action[keyof typeof Action];
export type ActionLabelV2 = {
  readonly __typename?: 'ActionLabelV2';
  readonly action: LineCardButtonAction;
  readonly label: Scalars['String']['output'];
};

export type ActionSettingBlock = {
  readonly __typename?: 'ActionSettingBlock';
  readonly actions: ReadonlyArray<WorkflowAction>;
  readonly nextBlockId?: Maybe<Scalars['String']['output']>;
};

export type ActionSettingBlockInput = {
  readonly actions: ReadonlyArray<WorkflowActionInput>;
  readonly nextBlockId?: InputMaybe<Scalars['String']['input']>;
};

export type AdCampaignDetailedReportDimensionValuesPayload = {
  readonly __typename?: 'AdCampaignDetailedReportDimensionValuesPayload';
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly day?: Maybe<Scalars['LocalDate']['output']>;
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly month?: Maybe<Scalars['YearMonth']['output']>;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly week?: Maybe<Scalars['LocalDate']['output']>;
};

export type AdCampaignDetailedReportInput = {
  readonly adPlatforms?: InputMaybe<ReadonlyArray<AdReportPlatform>>;
  readonly dimension: AdReportDimension;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type AdCampaignDetailedReportPayload = {
  readonly __typename?: 'AdCampaignDetailedReportPayload';
  readonly budget?: Maybe<Scalars['BigDecimal']['output']>;
  readonly budgetType?: Maybe<Scalars['String']['output']>;
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly datasourceId: Scalars['Long']['output'];
  readonly dimensionValues?: Maybe<ReadonlyArray<AdCampaignDetailedReportDimensionValuesPayload>>;
  readonly id: Scalars['Long']['output'];
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly platform: AdReportPlatform;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type AdCampaignDetailedReportPayloadList = {
  readonly __typename?: 'AdCampaignDetailedReportPayloadList';
  readonly items: ReadonlyArray<AdCampaignDetailedReportPayload>;
  readonly meta: ReportListMeta;
};

export type AdChartInput = {
  readonly adPlatforms?: InputMaybe<ReadonlyArray<AdReportPlatform>>;
  readonly campaignId?: InputMaybe<Scalars['Long']['input']>;
  readonly datasourceId?: InputMaybe<Scalars['Long']['input']>;
  readonly dimension: AdReportDimension;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly groupId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type AdChartPayload = {
  readonly __typename?: 'AdChartPayload';
  readonly clicks: ReadonlyArray<Scalars['Map']['output']>;
  readonly conversions: ReadonlyArray<Scalars['Map']['output']>;
  readonly cost: ReadonlyArray<Scalars['Map']['output']>;
  readonly cpa: ReadonlyArray<Scalars['Map']['output']>;
  readonly cpc: ReadonlyArray<Scalars['Map']['output']>;
  readonly cpm: ReadonlyArray<Scalars['Map']['output']>;
  readonly ctr: ReadonlyArray<Scalars['Map']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly cvr: ReadonlyArray<Scalars['Map']['output']>;
  readonly impressions: ReadonlyArray<Scalars['Map']['output']>;
  readonly roas: ReadonlyArray<Scalars['Map']['output']>;
  readonly sales: ReadonlyArray<Scalars['Map']['output']>;
};

export type AdCostCampaignMappingInfoPayload = {
  readonly __typename?: 'AdCostCampaignMappingInfoPayload';
  readonly adsCostType: AdsCostType;
  readonly campaignName: Scalars['String']['output'];
  readonly id: Scalars['Long']['output'];
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly products: ReadonlyArray<AdCostCampaignMappingProductInfoPayload>;
  readonly saleChannelType: SaleChannelType;
};

export type AdCostCampaignMappingListFilterInput = {
  readonly adCostCampaignMappingIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly adType?: InputMaybe<AdsCostType>;
  readonly campaignKeyword?: InputMaybe<Scalars['String']['input']>;
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly saleChannelType: SaleChannelType;
};

export type AdCostCampaignMappingListInfoPayload = {
  readonly __typename?: 'AdCostCampaignMappingListInfoPayload';
  readonly adCostCampaignMappingGroupId: Scalars['Long']['output'];
  readonly adsCostType: AdsCostType;
  readonly campaignName: Scalars['String']['output'];
  readonly numberOfProductVariants?: Maybe<Scalars['Int']['output']>;
};

export type AdCostCampaignMappingListPayload = {
  readonly __typename?: 'AdCostCampaignMappingListPayload';
  readonly items: ReadonlyArray<AdCostCampaignMappingListInfoPayload>;
  readonly meta: ReportListMeta;
};

export type AdCostCampaignMappingProductInfoPayload = {
  readonly __typename?: 'AdCostCampaignMappingProductInfoPayload';
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly productCode: Scalars['String']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly productName: Scalars['String']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly productVariantName: Scalars['String']['output'];
  readonly saleChannels?: Maybe<ReadonlyArray<ListingInfoPayload>>;
};

export type AdCostCampaignMappingSaveInput = {
  readonly adCostCampaignMappingIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productVariantIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
};

export type AdCostCampaignMappingSavePayload = {
  readonly __typename?: 'AdCostCampaignMappingSavePayload';
  readonly ok: Scalars['Boolean']['output'];
};

export type AdDetailedListMeta = {
  readonly __typename?: 'AdDetailedListMeta';
  readonly length: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly types: ReadonlyArray<Scalars['String']['output']>;
};

export type AdDetailedReportDimensionValuesPayload = {
  readonly __typename?: 'AdDetailedReportDimensionValuesPayload';
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly day?: Maybe<Scalars['LocalDate']['output']>;
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly month?: Maybe<Scalars['YearMonth']['output']>;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly week?: Maybe<Scalars['LocalDate']['output']>;
};

export type AdDetailedReportInput = {
  readonly adTypes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly campaignId: Scalars['Long']['input'];
  readonly datasourceId: Scalars['Long']['input'];
  readonly dimension: AdReportDimension;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly groupId: Scalars['Long']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type AdDetailedReportPayload = {
  readonly __typename?: 'AdDetailedReportPayload';
  readonly adGroupId: Scalars['Long']['output'];
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly datasourceId: Scalars['Long']['output'];
  readonly dimensionValues?: Maybe<ReadonlyArray<AdDetailedReportDimensionValuesPayload>>;
  readonly id: Scalars['Long']['output'];
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly thumbnail?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type AdDetailedReportPayloadList = {
  readonly __typename?: 'AdDetailedReportPayloadList';
  readonly items: ReadonlyArray<AdDetailedReportPayload>;
  readonly meta: AdDetailedListMeta;
};

export type AdGroupDetailedReportDimensionValuesPayload = {
  readonly __typename?: 'AdGroupDetailedReportDimensionValuesPayload';
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly day?: Maybe<Scalars['LocalDate']['output']>;
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly month?: Maybe<Scalars['YearMonth']['output']>;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly week?: Maybe<Scalars['LocalDate']['output']>;
};

export type AdGroupDetailedReportInput = {
  readonly campaignId: Scalars['Long']['input'];
  readonly datasourceId: Scalars['Long']['input'];
  readonly dimension: AdReportDimension;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type AdGroupDetailedReportPayload = {
  readonly __typename?: 'AdGroupDetailedReportPayload';
  readonly budget?: Maybe<Scalars['BigDecimal']['output']>;
  readonly budgetType?: Maybe<Scalars['String']['output']>;
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly datasourceId: Scalars['Long']['output'];
  readonly dimensionValues?: Maybe<ReadonlyArray<AdGroupDetailedReportDimensionValuesPayload>>;
  readonly id: Scalars['Long']['output'];
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type AdGroupDetailedReportPayloadList = {
  readonly __typename?: 'AdGroupDetailedReportPayloadList';
  readonly items: ReadonlyArray<AdGroupDetailedReportPayload>;
  readonly meta: ReportListMeta;
};

export type AdOverviewReportInput = {
  readonly adPlatforms?: InputMaybe<ReadonlyArray<AdReportPlatform>>;
  readonly dimension: AdReportDimension;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type AdOverviewReportPayload = {
  readonly __typename?: 'AdOverviewReportPayload';
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly day?: Maybe<Scalars['LocalDate']['output']>;
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly month?: Maybe<Scalars['YearMonth']['output']>;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly week?: Maybe<Scalars['LocalDate']['output']>;
};

export type AdOverviewReportPayloadList = {
  readonly __typename?: 'AdOverviewReportPayloadList';
  readonly items: ReadonlyArray<AdOverviewReportPayload>;
  readonly meta: ReportListMeta;
};

export const AdReportDimension = {
  DAY: 'DAY',
  MONTH: 'MONTH',
  NONE: 'NONE',
  WEEK: 'WEEK'
} as const;

export type AdReportDimension = typeof AdReportDimension[keyof typeof AdReportDimension];
export const AdReportPlatform = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  TIKTOK: 'TIKTOK',
  YAHOO_DISPLAY: 'YAHOO_DISPLAY',
  YAHOO_SEARCH: 'YAHOO_SEARCH'
} as const;

export type AdReportPlatform = typeof AdReportPlatform[keyof typeof AdReportPlatform];
export const AdReportPricesView = {
  GROSS: 'GROSS',
  NET: 'NET'
} as const;

export type AdReportPricesView = typeof AdReportPricesView[keyof typeof AdReportPricesView];
export const AdSetCampaignStatus = {
  FACEBOOKADS_ACTIVE: 'FACEBOOKADS_ACTIVE',
  FACEBOOKADS_ADS_OFF_OR_AD_SET_OFF: 'FACEBOOKADS_ADS_OFF_OR_AD_SET_OFF',
  FACEBOOKADS_ARCHIVED: 'FACEBOOKADS_ARCHIVED',
  FACEBOOKADS_COMPLETED: 'FACEBOOKADS_COMPLETED',
  FACEBOOKADS_IN_REVIEW: 'FACEBOOKADS_IN_REVIEW',
  FACEBOOKADS_LEARNING: 'FACEBOOKADS_LEARNING',
  FACEBOOKADS_LEARNING_LIMITED: 'FACEBOOKADS_LEARNING_LIMITED',
  FACEBOOKADS_NO_ADS: 'FACEBOOKADS_NO_ADS',
  FACEBOOKADS_PROCESSING: 'FACEBOOKADS_PROCESSING',
  FACEBOOKADS_REJECTED: 'FACEBOOKADS_REJECTED',
  FACEBOOKADS_SCHEDULED: 'FACEBOOKADS_SCHEDULED',
  GOOGLEADS_ELIGIBLE: 'GOOGLEADS_ELIGIBLE',
  GOOGLEADS_ELIGIBLE_LIMITED: 'GOOGLEADS_ELIGIBLE_LIMITED',
  GOOGLEADS_ENDED: 'GOOGLEADS_ENDED',
  GOOGLEADS_NOT_ELIGIBLE: 'GOOGLEADS_NOT_ELIGIBLE',
  GOOGLEADS_PAUSED: 'GOOGLEADS_PAUSED',
  GOOGLEADS_PENDING: 'GOOGLEADS_PENDING',
  GOOGLEADS_REMOVED: 'GOOGLEADS_REMOVED',
  TIKTOKADS_ACTIVE: 'TIKTOKADS_ACTIVE',
  TIKTOKADS_CLOSED: 'TIKTOKADS_CLOSED',
  TIKTOKADS_COMPLETED: 'TIKTOKADS_COMPLETED',
  TIKTOKADS_DELETED: 'TIKTOKADS_DELETED',
  TIKTOKADS_INACTIVE: 'TIKTOKADS_INACTIVE',
  TIKTOKADS_NOT_DELIVERING: 'TIKTOKADS_NOT_DELIVERING',
  YAHOODISPLAYADS_ENDED: 'YAHOODISPLAYADS_ENDED',
  YAHOODISPLAYADS_PENDING: 'YAHOODISPLAYADS_PENDING',
  YAHOODISPLAYADS_SERVING: 'YAHOODISPLAYADS_SERVING',
  YAHOODISPLAYADS_STOP: 'YAHOODISPLAYADS_STOP',
  YAHOODISPLAYADS_UNKNOWN: 'YAHOODISPLAYADS_UNKNOWN',
  YAHOOSEARCHADS_ENDED: 'YAHOOSEARCHADS_ENDED',
  YAHOOSEARCHADS_PENDING: 'YAHOOSEARCHADS_PENDING',
  YAHOOSEARCHADS_SERVING: 'YAHOOSEARCHADS_SERVING',
  YAHOOSEARCHADS_STOP: 'YAHOOSEARCHADS_STOP',
  YAHOOSEARCHADS_UNKNOWN: 'YAHOOSEARCHADS_UNKNOWN'
} as const;

export type AdSetCampaignStatus = typeof AdSetCampaignStatus[keyof typeof AdSetCampaignStatus];
export const AdSetGroupStatus = {
  FACEBOOKADS_ACTIVE: 'FACEBOOKADS_ACTIVE',
  FACEBOOKADS_ADS_OFF_OR_AD_SET_OFF: 'FACEBOOKADS_ADS_OFF_OR_AD_SET_OFF',
  FACEBOOKADS_ARCHIVED: 'FACEBOOKADS_ARCHIVED',
  FACEBOOKADS_COMPLETED: 'FACEBOOKADS_COMPLETED',
  FACEBOOKADS_IN_REVIEW: 'FACEBOOKADS_IN_REVIEW',
  FACEBOOKADS_LEARNING: 'FACEBOOKADS_LEARNING',
  FACEBOOKADS_LEARNING_LIMITED: 'FACEBOOKADS_LEARNING_LIMITED',
  FACEBOOKADS_NO_ADS: 'FACEBOOKADS_NO_ADS',
  FACEBOOKADS_PROCESSING: 'FACEBOOKADS_PROCESSING',
  FACEBOOKADS_REJECTED: 'FACEBOOKADS_REJECTED',
  FACEBOOKADS_SCHEDULED: 'FACEBOOKADS_SCHEDULED',
  GOOGLEADS_ELIGIBLE: 'GOOGLEADS_ELIGIBLE',
  GOOGLEADS_INCOMPLETE: 'GOOGLEADS_INCOMPLETE',
  GOOGLEADS_NOT_ELIGIBLE: 'GOOGLEADS_NOT_ELIGIBLE',
  GOOGLEADS_PAUSED: 'GOOGLEADS_PAUSED',
  GOOGLEADS_REMOVED: 'GOOGLEADS_REMOVED',
  TIKTOKADS_ACTIVE: 'TIKTOKADS_ACTIVE',
  TIKTOKADS_CLOSED: 'TIKTOKADS_CLOSED',
  TIKTOKADS_COMPLETED: 'TIKTOKADS_COMPLETED',
  TIKTOKADS_DELETED: 'TIKTOKADS_DELETED',
  TIKTOKADS_INACTIVE: 'TIKTOKADS_INACTIVE',
  TIKTOKADS_NOT_DELIVERING: 'TIKTOKADS_NOT_DELIVERING'
} as const;

export type AdSetGroupStatus = typeof AdSetGroupStatus[keyof typeof AdSetGroupStatus];
export type AdSetMappingCampaignListFilterInput = {
  readonly campaignKeyword?: InputMaybe<Scalars['String']['input']>;
  readonly datasourceId: Scalars['Long']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<AdSetCampaignStatus>;
  readonly statusRaw?: InputMaybe<Scalars['String']['input']>;
  readonly statuses?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type AdSetMappingCampaignPayload = {
  readonly __typename?: 'AdSetMappingCampaignPayload';
  readonly adSetMappingCampaignId: Scalars['Long']['output'];
  readonly campaignId: Scalars['Long']['output'];
  readonly campaignName: Scalars['String']['output'];
  readonly campaignStatus?: Maybe<AdSetCampaignStatus>;
  readonly marginPercentage: Scalars['BigDecimal']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
};

export type AdSetMappingCampaignsListPayload = {
  readonly __typename?: 'AdSetMappingCampaignsListPayload';
  readonly items: ReadonlyArray<AdSetMappingCampaignPayload>;
  readonly meta: ReportListMeta;
};

export type AdSetMappingGroupInfoPayload = {
  readonly __typename?: 'AdSetMappingGroupInfoPayload';
  readonly adGroupId: Scalars['Long']['output'];
  readonly adGroupName: Scalars['String']['output'];
  readonly campaignId: Scalars['Long']['output'];
  readonly campaignName: Scalars['String']['output'];
  readonly products: ReadonlyArray<ProductAdSetMappingInfoPayload>;
  readonly saleChannels: ReadonlyArray<Scalars['String']['output']>;
};

export type AdSetMappingGroupListFilterInput = {
  readonly adGroupKeyword?: InputMaybe<Scalars['String']['input']>;
  readonly adSetMappingCampaignId?: InputMaybe<Scalars['Long']['input']>;
  readonly adSetMappingGroupIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly campaignId?: InputMaybe<Scalars['Long']['input']>;
  readonly datasourceId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<AdSetGroupStatus>;
  readonly statusRaw?: InputMaybe<Scalars['String']['input']>;
  readonly statuses?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type AdSetMappingGroupListInfoPayload = {
  readonly __typename?: 'AdSetMappingGroupListInfoPayload';
  readonly adGroupId: Scalars['Long']['output'];
  readonly adGroupName: Scalars['String']['output'];
  readonly adGroupStatus?: Maybe<AdSetGroupStatus>;
  readonly adSetMappingGroupId: Scalars['Long']['output'];
  readonly numberOfProductVariants: Scalars['Int']['output'];
  readonly numberOfSaleChannels: Scalars['Int']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
};

export type AdSetMappingGroupListPayload = {
  readonly __typename?: 'AdSetMappingGroupListPayload';
  readonly items: ReadonlyArray<AdSetMappingGroupListInfoPayload>;
  readonly meta: ReportListMeta;
};

export type AdSetMappingGroupProductPayload = {
  readonly __typename?: 'AdSetMappingGroupProductPayload';
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly productCode: Scalars['String']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly productName: Scalars['String']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly productVariantName: Scalars['String']['output'];
  readonly saleChannels?: Maybe<ReadonlyArray<ListingInfo>>;
};

export type AdSetMappingGroupProductsListPayload = {
  readonly __typename?: 'AdSetMappingGroupProductsListPayload';
  readonly items: ReadonlyArray<AdSetMappingGroupProductPayload>;
  readonly meta: ReportListMeta;
};

export type AdSetMappingProductListFilterInput = {
  readonly channels?: InputMaybe<ReadonlyArray<SaleChannelType>>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type AdSetMappingSaveInput = {
  readonly adSetMappingGroupIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly campaignMappingId: Scalars['Long']['input'];
  readonly datasourceId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productVariantIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly saleChannels: ReadonlyArray<SaleChannelType>;
};

export type AdSetMappingSavePayload = {
  readonly __typename?: 'AdSetMappingSavePayload';
  readonly ok: Scalars['Boolean']['output'];
};

export type AdSetMappingSetMarginInput = {
  readonly settings: ReadonlyArray<MarginSettingInput>;
};

export type AdSetMappingSetMarginPayload = {
  readonly __typename?: 'AdSetMappingSetMarginPayload';
  readonly ok: Scalars['Boolean']['output'];
};

export type AdSummaryInput = {
  readonly adPlatforms?: InputMaybe<ReadonlyArray<AdReportPlatform>>;
  readonly campaignId?: InputMaybe<Scalars['Long']['input']>;
  readonly datasourceId?: InputMaybe<Scalars['Long']['input']>;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly groupId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type AdSummaryPayload = {
  readonly __typename?: 'AdSummaryPayload';
  readonly clicks: NumericValueWithDiffPayload;
  readonly conversions: NumericValueWithDiffPayload;
  readonly cost: NumericValueWithDiffPayload;
  readonly cpa: NumericValueWithDiffPayload;
  readonly currency: ReportSupportedCurrency;
  readonly impressions: NumericValueWithDiffPayload;
  readonly roas: NumericValueWithDiffPayload;
  readonly sales: NumericValueWithDiffPayload;
};

export type AddAutomationFlowRequest = {
  readonly eventType: AutomationFlowEventType;
  readonly name: Scalars['String']['input'];
  readonly replyContents: ReadonlyArray<AutomationFlowContentInput>;
  readonly sendWhen: LiveCommentAutoReplyTiming;
  readonly storeId: Scalars['String']['input'];
  readonly trigger: AutomationFlowTriggerInput;
};

export type AddCustomerTagRequest = {
  readonly customerTagNames: ReadonlyArray<Scalars['String']['input']>;
  readonly storeId: Scalars['String']['input'];
};

export type AddCustomerTagsActivity = CustomerActivity & {
  readonly __typename?: 'AddCustomerTagsActivity';
  readonly customerTags: ReadonlyArray<CustomerTag>;
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly user: BasicUser;
};

export type Address = {
  readonly __typename?: 'Address';
  readonly addressFirst: Scalars['String']['output'];
  readonly addressSecond: Scalars['String']['output'];
  readonly city: Scalars['String']['output'];
  readonly country: Country;
  readonly postalCode: Scalars['String']['output'];
  readonly provinceId: Scalars['String']['output'];
};

export type AdsCostByCampaignCsvPayload = {
  readonly __typename?: 'AdsCostByCampaignCSVPayload';
  readonly campaignName: Scalars['String']['output'];
  readonly clicks: Scalars['Long']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpcCurrency: ReportSupportedCurrency;
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly date: Scalars['LocalDate']['output'];
  readonly impressions: Scalars['Long']['output'];
  readonly numberOfOrders: Scalars['Long']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly spend: Scalars['BigDecimal']['output'];
  readonly spendCurrency: ReportSupportedCurrency;
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly totalSalesCurrency: ReportSupportedCurrency;
};

export type AdsCostByCampaignCsvPayloadList = {
  readonly __typename?: 'AdsCostByCampaignCSVPayloadList';
  readonly items: ReadonlyArray<AdsCostByCampaignCsvPayload>;
  readonly meta: ReportListMeta;
};

export type AdsCostCsvFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly saleChannelMarketplace?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly type?: InputMaybe<AdsCostType>;
};

export type AdsCostRangePerProductDailyCsvFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productVariantId?: InputMaybe<Scalars['Long']['input']>;
  readonly saleChannelMarketplace?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly type?: InputMaybe<AdsCostType>;
};

export type AdsCostRangePerProductDailyCsvPayload = {
  readonly __typename?: 'AdsCostRangePerProductDailyCSVPayload';
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly date: Scalars['LocalDate']['output'];
  readonly expense: Scalars['BigDecimal']['output'];
  readonly expenseCurrency: ReportSupportedCurrency;
  readonly externalId: Scalars['String']['output'];
  readonly gmv: Scalars['BigDecimal']['output'];
  readonly gmvCurrency: ReportSupportedCurrency;
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly productName: Scalars['String']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly roi: Scalars['BigDecimal']['output'];
  readonly roiCurrency: ReportSupportedCurrency;
  readonly saleChannelMarketplace: Scalars['String']['output'];
  readonly saleChannelType: SaleChannelType;
  readonly sourceUrl: Scalars['String']['output'];
  readonly spend: Scalars['BigDecimal']['output'];
  readonly spendCurrency: ReportSupportedCurrency;
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly totalSalesCurrency: ReportSupportedCurrency;
};

export type AdsCostRangePerProductDailyCsvPayloadList = {
  readonly __typename?: 'AdsCostRangePerProductDailyCSVPayloadList';
  readonly items: ReadonlyArray<AdsCostRangePerProductDailyCsvPayload>;
  readonly meta: ReportListMeta;
};

export type AdsCostRangeProductLevelDailyCsvFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];  
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productId?: InputMaybe<Scalars['Long']['input']>;
  readonly saleChannelMarketplace?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly type?: InputMaybe<AdsCostType>;
};

export type AdsCostRangeProductLevelDailyCsvPayload = {
  readonly __typename?: 'AdsCostRangeProductLevelDailyCSVPayload';
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly date: Scalars['LocalDate']['output'];
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly productName: Scalars['String']['output'];
  readonly roi?: Maybe<Scalars['BigDecimal']['output']>;
  readonly roiCurrency?: Maybe<ReportSupportedCurrency>;
  readonly saleChannelMarketplace: Scalars['String']['output'];
  readonly saleChannelType: SaleChannelType;
  readonly sourceUrl: Scalars['String']['output'];
  readonly spend: Scalars['BigDecimal']['output'];
  readonly spendCurrency: ReportSupportedCurrency;
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly totalSalesCurrency: ReportSupportedCurrency;
};

export type AdsCostRangeProductLevelDailyCsvPayloadList = {
  readonly __typename?: 'AdsCostRangeProductLevelDailyCSVPayloadList';
  readonly items: ReadonlyArray<AdsCostRangeProductLevelDailyCsvPayload>;
  readonly lastUpdated?: Maybe<Scalars['ZonedDatetime']['output']>;
  readonly meta: ReportListMeta;
};

export const AdsCostType = {
  AMAZON_SPONSOR_BRAND: 'AMAZON_SPONSOR_BRAND',
  AMAZON_SPONSOR_DISPLAY: 'AMAZON_SPONSOR_DISPLAY',
  AMAZON_SPONSOR_PRODUCT: 'AMAZON_SPONSOR_PRODUCT',
  LAZADA_SPONSOR_DISCOVERY: 'LAZADA_SPONSOR_DISCOVERY',
  RAKUTEN_COUPON_ADVANCED: 'RAKUTEN_COUPON_ADVANCED',
  RAKUTEN_RPP: 'RAKUTEN_RPP',
  SHOPEE_ADS: 'SHOPEE_ADS',
  TOKOPEDIA_ADS: 'TOKOPEDIA_ADS',
  UNKNOWN: 'UNKNOWN',
  YAHOO_ITEM_MATCH_AD: 'YAHOO_ITEM_MATCH_AD',
  ZOZOTOWN_ADS: 'ZOZOTOWN_ADS'
} as const;

export type AdsCostType = typeof AdsCostType[keyof typeof AdsCostType];
export type AdsCostZozotownCsvPayload = {
  readonly __typename?: 'AdsCostZozotownCSVPayload';
  readonly clicks: Scalars['Long']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpcCurrency: ReportSupportedCurrency;
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly date: Scalars['LocalDate']['output'];
  readonly displayLocation: Scalars['String']['output'];
  readonly impressions: Scalars['Long']['output'];
  readonly numberOfOrders: Scalars['Long']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly productVariantId: Scalars['Long']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly spend: Scalars['BigDecimal']['output'];
  readonly spendCurrency: ReportSupportedCurrency;
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly totalSalesCurrency: ReportSupportedCurrency;
};

export type AdsCostZozotownCsvPayloadList = {
  readonly __typename?: 'AdsCostZozotownCSVPayloadList';
  readonly items: ReadonlyArray<AdsCostZozotownCsvPayload>;
  readonly meta: ReportListMeta;
};

export type AdsMappingProductListFilterInput = {
  readonly channels?: InputMaybe<ReadonlyArray<SaleChannelType>>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type AdsMappingProductPayload = {
  readonly __typename?: 'AdsMappingProductPayload';
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly productCode: Scalars['String']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly productName: Scalars['String']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly productVariantName: Scalars['String']['output'];
  readonly saleChannels?: Maybe<ReadonlyArray<ListingInfo>>;
};

export type AdsMappingProductsListPayload = {
  readonly __typename?: 'AdsMappingProductsListPayload';
  readonly items: ReadonlyArray<AdsMappingProductPayload>;
  readonly meta: ReportListMeta;
};

export const AdsMetricsType = {
  GROSS: 'GROSS',
  NET: 'NET'
} as const;

export type AdsMetricsType = typeof AdsMetricsType[keyof typeof AdsMetricsType];
export type All = {
  readonly __typename?: 'All';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type AmazonSaleChannelData = {
  readonly __typename?: 'AmazonSaleChannelData';
  readonly marketplace: AmazonSellerCentralMarketplace;
};

export type AmazonSalesChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type AmazonSalesChartPayload = {
  readonly __typename?: 'AmazonSalesChartPayload';
  readonly averageNumberOfUnitsPerOrderItem: ReadonlyArray<Scalars['Map']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly itemAverageCost: ReadonlyArray<Scalars['Map']['output']>;
  readonly itemsOrdered: ReadonlyArray<Scalars['Map']['output']>;
  readonly sales: ReadonlyArray<Scalars['Map']['output']>;
  readonly unitAverageCost: ReadonlyArray<Scalars['Map']['output']>;
  readonly unitsOrdered: ReadonlyArray<Scalars['Map']['output']>;
};

export type AmazonSalesDetailedReportInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type AmazonSalesDetailedReportPayload = {
  readonly __typename?: 'AmazonSalesDetailedReportPayload';
  readonly averageNumberOfUnitsPerOrderItem: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly itemAverageCost: Scalars['BigDecimal']['output'];
  readonly itemsOrdered: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly unitAverageCost: Scalars['BigDecimal']['output'];
  readonly unitsOrdered: Scalars['Long']['output'];
};

export type AmazonSalesDetailedReportPayloadList = {
  readonly __typename?: 'AmazonSalesDetailedReportPayloadList';
  readonly items: ReadonlyArray<AmazonSalesDetailedReportPayload>;
  readonly meta: ReportListMeta;
  readonly total: AmazonSalesDetailedReportTotalPayload;
};

export type AmazonSalesDetailedReportTotalPayload = {
  readonly __typename?: 'AmazonSalesDetailedReportTotalPayload';
  readonly averageNumberOfUnitsPerOrderItem: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly itemAverageCost: Scalars['BigDecimal']['output'];
  readonly itemsOrdered: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly unitAverageCost: Scalars['BigDecimal']['output'];
  readonly unitsOrdered: Scalars['Long']['output'];
};

export type AmazonSalesSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type AmazonSalesSummaryPayload = {
  readonly __typename?: 'AmazonSalesSummaryPayload';
  readonly averageNumberOfUnitsPerOrderItem: NumericValueWithDiffPayload;
  readonly currency: ReportSupportedCurrency;
  readonly itemAverageCost: NumericValueWithDiffPayload;
  readonly itemsOrdered: NumericValueWithDiffPayload;
  readonly sales: NumericValueWithDiffPayload;
  readonly unitAverageCost: NumericValueWithDiffPayload;
  readonly unitsOrdered: NumericValueWithDiffPayload;
};

export type AmazonSellerCentralFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
  readonly type: AmazonSellerCentralFileUploadType;
};

export const AmazonSellerCentralFileUploadType = {
  AD_COST_SPONSOR_BRAND: 'AD_COST_SPONSOR_BRAND',
  AD_COST_SPONSOR_DISPLAY: 'AD_COST_SPONSOR_DISPLAY',
  AD_COST_SPONSOR_PRODUCT: 'AD_COST_SPONSOR_PRODUCT',
  SESSION: 'SESSION'
} as const;

export type AmazonSellerCentralFileUploadType = typeof AmazonSellerCentralFileUploadType[keyof typeof AmazonSellerCentralFileUploadType];
export type AmazonSellerCentralLoginUrlV2Input = {
  readonly marketplace: AmazonSellerCentralMarketplace;
  readonly redirectUri: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export const AmazonSellerCentralMarketplace = {
  AE: 'AE',
  CA: 'CA',
  DE: 'DE',
  ES: 'ES',
  FR: 'FR',
  IN: 'IN',
  IT: 'IT',
  JP: 'JP',
  NL: 'NL',
  SA: 'SA',
  SG: 'SG',
  UK: 'UK',
  US: 'US'
} as const;

export type AmazonSellerCentralMarketplace = typeof AmazonSellerCentralMarketplace[keyof typeof AmazonSellerCentralMarketplace];
export type AnimatedSticker = {
  readonly __typename?: 'AnimatedSticker';
  readonly url: Scalars['String']['output'];
};

export const AnyChatDeliveryStatus = {
  OTHERS: 'OTHERS',
  SHIPPED: 'SHIPPED',
  UNSHIPPED: 'UNSHIPPED'
} as const;

export type AnyChatDeliveryStatus = typeof AnyChatDeliveryStatus[keyof typeof AnyChatDeliveryStatus];
export type AnyChatGetStoreRequest = {
  readonly id: Scalars['String']['input'];
};

export type AnyChatMoney = {
  readonly __typename?: 'AnyChatMoney';
  readonly amount: Scalars['BigDecimal']['output'];
  readonly currencyCode: Scalars['String']['output'];
};

export type AnyChatOkResponse = {
  readonly __typename?: 'AnyChatOKResponse';
  readonly ok: Scalars['Boolean']['output'];
};

export const AnyChatPaymentStatus = {
  OTHERS: 'OTHERS',
  PAID: 'PAID',
  PENDING: 'PENDING'
} as const;

export type AnyChatPaymentStatus = typeof AnyChatPaymentStatus[keyof typeof AnyChatPaymentStatus];
export const AnyChatSaleChannelType = {
  LAZADA: 'LAZADA',
  SHOPEE: 'SHOPEE',
  SHOPIFY: 'SHOPIFY'
} as const;

export type AnyChatSaleChannelType = typeof AnyChatSaleChannelType[keyof typeof AnyChatSaleChannelType];
export type AnyChatStore = {
  readonly __typename?: 'AnyChatStore';
  readonly accountId: Scalars['String']['output'];
  readonly chatCount: OngoingCount;
  readonly chats: ReadonlyArray<StoreChat>;
  readonly crmType: CrmType;
  readonly id: Scalars['String']['output'];
  readonly isCrmEnabled: Scalars['Boolean']['output'];
  readonly isCrmMerchantEnabled: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
};

export type AnyChatStoreCrmConfigUpdateRequest = {
  readonly crmType: CrmType;
  readonly isCrmMerchantEnabled: Scalars['Boolean']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export type AnyContent = {
  readonly __typename?: 'AnyContent';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type AnyLogiOrderAddressInput = {
  readonly address1: Scalars['String']['input'];
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  readonly cityName?: InputMaybe<Scalars['String']['input']>;
  readonly company?: InputMaybe<Scalars['String']['input']>;
  readonly country: Country;
  readonly name: NameInput;
  readonly phoneNumber: PhoneNumberInput;
  readonly provinceCode?: InputMaybe<Scalars['String']['input']>;
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type AnyLogiOrderCustomerInput = {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly name: NameInput;
  readonly phoneNumber: PhoneNumberInput;
};

export type AnyLogiProduct = {
  readonly productCode?: InputMaybe<Scalars['String']['input']>;
  readonly productVariantId?: InputMaybe<Scalars['Long']['input']>;
  readonly quantity: Scalars['Int']['input'];
};

export type AnyLogiSkuForOrderDetailed = {
  readonly __typename?: 'AnyLogiSkuForOrderDetailed';
  readonly code: Scalars['String']['output'];
  readonly currency: AnylogiMoney;
  readonly gtin: Scalars['String']['output'];
  readonly image: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly price: Scalars['String']['output'];
  readonly quantity: Scalars['Int']['output'];
  readonly shippingNotRequired: Scalars['Boolean']['output'];
  readonly shippingStatus?: Maybe<AnylogiItemShippingStatus>;
  readonly unknown: Scalars['Boolean']['output'];
};

export type AnyLogiUpsertOrderInput = {
  readonly anyXOrderId: Scalars['Long']['input'];
  readonly billingAddress?: InputMaybe<AnyLogiOrderAddressInput>;
  readonly channelOrderId?: InputMaybe<Scalars['String']['input']>;
  readonly courierType: CourierType;
  readonly customer: AnyLogiOrderCustomerInput;
  readonly discount: AnylogiMoneyInput;
  readonly note?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethod: PaymentMethodInput;
  readonly paymentStatus: PaymentStatus;
  readonly preferredDeliveryDate?: InputMaybe<Scalars['Time']['input']>;
  readonly preferredDeliveryTime?: InputMaybe<DeliveryTimeframe>;
  readonly products: ReadonlyArray<AnyLogiProduct>;
  readonly shipping?: InputMaybe<ShippingInput>;
  readonly shippingAddress: AnyLogiOrderAddressInput;
  readonly shippingCost: AnylogiMoneyInput;
  readonly shippingInstructions?: InputMaybe<Scalars['String']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly temperature: Temperature;
  readonly translatedShippingAddress?: InputMaybe<AnyLogiOrderAddressInput>;
  readonly updateOnlyAdminFields?: InputMaybe<Scalars['Boolean']['input']>;
};

export const AnyXDeliveryStatus = {
  CANCELLED: 'CANCELLED',
  PAYING: 'PAYING',
  PENDING: 'PENDING',
  SHIPPED: 'SHIPPED'
} as const;

export type AnyXDeliveryStatus = typeof AnyXDeliveryStatus[keyof typeof AnyXDeliveryStatus];
export type AnyXWarehouse = {
  readonly __typename?: 'AnyXWarehouse';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type AnylogiCrossBorderShippingInput = {
  readonly boxSizes?: InputMaybe<ReadonlyArray<ShippingBoxSizeInput>>;
  readonly deliveryDutyPaid: Scalars['Boolean']['input'];
  readonly insurance?: InputMaybe<AnylogiMoneyInput>;
  readonly shipmentCategory?: InputMaybe<Category>;
  readonly shipperAddressID: Scalars['String']['input'];
  readonly signatureOption: Scalars['Boolean']['input'];
};

export const AnylogiCurrency = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYN: 'BYN',
  BYR: 'BYR',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHF: 'CHF',
  CLF: 'CLF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EEK: 'EEK',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GGP: 'GGP',
  GHC: 'GHC',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LTL: 'LTL',
  LVL: 'LVL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RUR: 'RUR',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SKK: 'SKK',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  SSP: 'SSP',
  STD: 'STD',
  SVC: 'SVC',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRL: 'TRL',
  TRY: 'TRY',
  TTD: 'TTD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XAG: 'XAG',
  XAU: 'XAU',
  XCD: 'XCD',
  XDR: 'XDR',
  XOF: 'XOF',
  XPF: 'XPF',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
  ZWD: 'ZWD'
} as const;

export type AnylogiCurrency = typeof AnylogiCurrency[keyof typeof AnylogiCurrency];
export const AnylogiCurrencyInput = {
  AED: 'AED',
  AUD: 'AUD',
  BHD: 'BHD',
  BRL: 'BRL',
  CAD: 'CAD',
  CNY: 'CNY',
  EGP: 'EGP',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  IDR: 'IDR',
  INR: 'INR',
  JOD: 'JOD',
  JPY: 'JPY',
  KHR: 'KHR',
  KRW: 'KRW',
  KWD: 'KWD',
  MMK: 'MMK',
  MXN: 'MXN',
  MYR: 'MYR',
  OMR: 'OMR',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  QAR: 'QAR',
  SAR: 'SAR',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  TWD: 'TWD',
  USD: 'USD',
  VND: 'VND'
} as const;

export type AnylogiCurrencyInput = typeof AnylogiCurrencyInput[keyof typeof AnylogiCurrencyInput];
export const AnylogiItemShippingStatus = {
  ALLOCATED: 'ALLOCATED',
  ALLOCATING: 'ALLOCATING',
  CANCELED: 'CANCELED'
} as const;

export type AnylogiItemShippingStatus = typeof AnylogiItemShippingStatus[keyof typeof AnylogiItemShippingStatus];
export type AnylogiMoney = {
  readonly __typename?: 'AnylogiMoney';
  readonly amount: Scalars['BigDecimal']['output'];
  readonly currency: AnylogiCurrency;
};

export type AnylogiMoneyInput = {
  readonly amount: Scalars['BigDecimal']['input'];
  readonly currency: AnylogiCurrencyInput;
};

export type AnylogiOrder = {
  readonly __typename?: 'AnylogiOrder';
  readonly account: IdName;
  readonly anyXOrderId: Scalars['Long']['output'];
  readonly anylogiId: Scalars['ID']['output'];
  readonly billingAddress?: Maybe<FullAddress>;
  readonly channelOrderId: Scalars['String']['output'];
  readonly channelProvider: ChannelProvider;
  readonly channelUrl?: Maybe<Scalars['String']['output']>;
  readonly codFee?: Maybe<Scalars['Float']['output']>;
  readonly courierType?: Maybe<CourierType>;
  readonly created: Scalars['ZonedDatetime']['output'];
  readonly crossBorderValidationErrors?: Maybe<ReadonlyArray<CrossBorderValidationError>>;
  readonly currency: Scalars['String']['output'];
  readonly customer?: Maybe<AnylogiReadOrderCustomer>;
  readonly deliveryStatus: DeliveryStatus;
  readonly discount?: Maybe<Scalars['Float']['output']>;
  readonly displayId: Scalars['String']['output'];
  readonly editable: Scalars['Boolean']['output'];
  readonly fulfillmentAmount?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly image: Scalars['String']['output'];
  readonly items: Scalars['Int']['output'];
  readonly labelCreated?: Maybe<Scalars['ZonedDatetime']['output']>;
  readonly laterPay?: Maybe<Scalars['Boolean']['output']>;
  readonly note?: Maybe<Scalars['String']['output']>;
  readonly paymentMethod: AnylogiPaymentMethod;
  readonly paymentStatus: AnylogiPaymentStatus;
  readonly pickupNumber?: Maybe<Scalars['String']['output']>;
  readonly preferredDeliveryDate?: Maybe<Scalars['Date']['output']>;
  readonly preferredDeliveryTime?: Maybe<DeliveryTimeframe>;
  readonly productCount: Scalars['Int']['output'];
  readonly products: ReadonlyArray<AnyLogiSkuForOrderDetailed>;
  readonly shipping: AnylogiShipping;
  readonly shippingAddress?: Maybe<FullAddress>;
  readonly shippingCost?: Maybe<Scalars['Float']['output']>;
  readonly shippingDate?: Maybe<Scalars['ZonedDatetime']['output']>;
  readonly shippingInstructions?: Maybe<Scalars['String']['output']>;
  readonly store: IdName;
  readonly subtotalAmount?: Maybe<Scalars['Float']['output']>;
  readonly tax?: Maybe<Scalars['Float']['output']>;
  readonly temperature: Temperature;
  readonly totalAmount?: Maybe<Scalars['Float']['output']>;
  readonly totalWeight?: Maybe<Scalars['Float']['output']>;
  readonly trackingNumber?: Maybe<Scalars['String']['output']>;
  readonly trackingStatus?: Maybe<AnylogiOrderTrackingStatus>;
  readonly trackingStatusOriginal?: Maybe<Scalars['String']['output']>;
  readonly trackingUrl?: Maybe<Scalars['String']['output']>;
  readonly translatedShippingAddress?: Maybe<FullAddress>;
  readonly validationStatus?: Maybe<ValidationStatus>;
  readonly warehouse: IdName;
  readonly warehouseName: Scalars['String']['output'];
};

export type AnylogiOrderLogistics = {
  readonly __typename?: 'AnylogiOrderLogistics';
  readonly anylogiId: Scalars['ID']['output'];
  readonly codFee: Scalars['BigDecimal']['output'];
  readonly courierService: Scalars['String']['output'];
  readonly deliveryNote: Scalars['String']['output'];
  readonly deliveryStatus: DeliveryStatus;
  readonly desiredDeliveryDate?: Maybe<Scalars['Date']['output']>;
  readonly desiredDeliveryTime?: Maybe<DeliveryTimeframe>;
  readonly paymentStatus: PaymentStatus;
  readonly temperature?: Maybe<Temperature>;
  readonly trackingNumber?: Maybe<Scalars['String']['output']>;
  readonly trackingUrl?: Maybe<Scalars['String']['output']>;
  readonly validationStatus: ValidationStatus;
  readonly warehouse?: Maybe<Warehouse>;
};

export const AnylogiOrderTrackingStatus = {
  DELIVERED: 'DELIVERED',
  LABEL_CREATED: 'LABEL_CREATED',
  ON_THE_WAY: 'ON_THE_WAY',
  PENDING: 'PENDING',
  VOIDED: 'VOIDED'
} as const;

export type AnylogiOrderTrackingStatus = typeof AnylogiOrderTrackingStatus[keyof typeof AnylogiOrderTrackingStatus];
export type AnylogiOrdersPaginated = Paginated & {
  readonly __typename?: 'AnylogiOrdersPaginated';
  readonly page: Scalars['Int']['output'];
  readonly records: ReadonlyArray<AnylogiOrder>;
  readonly total: Scalars['Int']['output'];
  readonly totalInvalid: Scalars['Int']['output'];
};

export const AnylogiPaymentMethod = {
  ALIPAY: 'ALIPAY',
  AMAZON_PAYMENTS: 'AMAZON_PAYMENTS',
  APPLE_PAY: 'APPLE_PAY',
  AUTO_LOAN: 'AUTO_LOAN',
  BANK: 'BANK',
  CASH: 'CASH',
  CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
  CREDIT_CARD: 'CREDIT_CARD',
  GMO_CREDIT: 'GMO_CREDIT',
  GMO_CVS: 'GMO_CVS',
  GMO_SMARTPHONE: 'GMO_SMARTPHONE',
  INSTALLMENT: 'INSTALLMENT',
  INVOICE: 'INVOICE',
  KOMOJU_BANK: 'KOMOJU_BANK',
  KOMOJU_CVS: 'KOMOJU_CVS',
  KOMOJU_SMARTPHONE: 'KOMOJU_SMARTPHONE',
  LEASE: 'LEASE',
  LOAN: 'LOAN',
  MOBILE_CREDITS: 'MOBILE_CREDITS',
  NO_PAYMENT: 'NO_PAYMENT',
  NP_ATOBARAI: 'NP_ATOBARAI',
  OTHER: 'OTHER',
  PAIDY_NEXT_MONTH_CVS_BANK: 'PAIDY_NEXT_MONTH_CVS_BANK',
  PAIDY_PAY_LATER: 'PAIDY_PAY_LATER',
  PAYPAL: 'PAYPAL',
  PAY_LATER_1: 'PAY_LATER_1',
  PAY_LATER_2: 'PAY_LATER_2',
  PRE_PAY: 'PRE_PAY',
  RAKUTEN_POINT: 'RAKUTEN_POINT',
  REGISTERED_MAIL: 'REGISTERED_MAIL',
  SEVEN_PRE_PAY: 'SEVEN_PRE_PAY',
  SHOPIFY_PAYMENTS: 'SHOPIFY_PAYMENTS',
  STORE: 'STORE',
  VIRTUAL_ACCOUNT: 'VIRTUAL_ACCOUNT'
} as const;

export type AnylogiPaymentMethod = typeof AnylogiPaymentMethod[keyof typeof AnylogiPaymentMethod];
export const AnylogiPaymentStatus = {
  CANCELLED: 'CANCELLED',
  NONE: 'NONE',
  PAID: 'PAID',
  PAYING: 'PAYING'
} as const;

export type AnylogiPaymentStatus = typeof AnylogiPaymentStatus[keyof typeof AnylogiPaymentStatus];
export type AnylogiPickupOrder = {
  readonly __typename?: 'AnylogiPickupOrder';
  readonly account: IdName;
  readonly anylogiId: Scalars['ID']['output'];
  readonly channelOrderId: Scalars['String']['output'];
  readonly created: Scalars['ZonedDatetime']['output'];
  readonly customer: AnylogiReadOrderCustomer;
  readonly displayId: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly labelCreated: Scalars['ZonedDatetime']['output'];
  readonly store: IdName;
};

export type AnylogiReadOrderCustomer = {
  readonly __typename?: 'AnylogiReadOrderCustomer';
  readonly email: Scalars['String']['output'];
  readonly name: Name;
  readonly numberOfPurchases: Scalars['Long']['output'];
  readonly phoneNumber: Scalars['String']['output'];
};

export type AnylogiShipping = {
  readonly __typename?: 'AnylogiShipping';
  readonly boxSizes?: Maybe<ReadonlyArray<ShippingBoxSize>>;
  readonly deliveryDutyPaid: Scalars['Boolean']['output'];
  readonly insurance?: Maybe<AnylogiMoney>;
  readonly orderID: Scalars['ID']['output'];
  readonly serviceCode?: Maybe<CrossBorderServiceCode>;
  readonly shipmentCategory?: Maybe<Category>;
  readonly shipmentPrice?: Maybe<AnylogiMoney>;
  readonly shipperAddressID: Scalars['String']['output'];
  readonly signatureOption: Scalars['Boolean']['output'];
  readonly specialServiceTypes?: Maybe<ReadonlyArray<SpecialServiceType>>;
  readonly status?: Maybe<ShippingStatus>;
  readonly trackingURL: Scalars['String']['output'];
};

export const AnylogiTimezone = {
  ASIA_BANGKOK: 'ASIA_BANGKOK',
  ASIA_DHAKA: 'ASIA_DHAKA',
  ASIA_KOLKATA: 'ASIA_KOLKATA',
  ASIA_SINGAPORE: 'ASIA_SINGAPORE',
  ASIA_TOKYO: 'ASIA_TOKYO',
  ASIA_YANGON: 'ASIA_YANGON'
} as const;

export type AnylogiTimezone = typeof AnylogiTimezone[keyof typeof AnylogiTimezone];
export type AnylogiUpdateCrossBorderShippingOrderInput = {
  readonly customer?: InputMaybe<AnyLogiOrderCustomerInput>;
  readonly id: Scalars['ID']['input'];
  readonly shipping?: InputMaybe<AnylogiCrossBorderShippingInput>;
  readonly translatedShippingAddress?: InputMaybe<AnyLogiOrderAddressInput>;
};

export type AnylogiUser = {
  readonly __typename?: 'AnylogiUser';
  readonly created: Scalars['Time']['output'];
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly role: AnylogiUserRoles;
  readonly status: AnylogiUserStatus;
};

export type AnylogiUserDetailed = {
  readonly __typename?: 'AnylogiUserDetailed';
  readonly created: Scalars['Time']['output'];
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly preferences?: Maybe<AnylogiUserPreferences>;
  readonly role: AnylogiUserRoles;
  readonly status: AnylogiUserStatus;
  readonly supplier?: Maybe<Supplier>;
};

export type AnylogiUserFilters = {
  readonly accountId?: InputMaybe<Scalars['ID']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly role?: InputMaybe<AnylogiUserRoles>;
  readonly status?: InputMaybe<AnylogiUserStatus>;
  readonly supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnylogiUserPreferences = {
  readonly __typename?: 'AnylogiUserPreferences';
  readonly currency: AnylogiCurrency;
  readonly language: Language;
  readonly timezone: AnylogiTimezone;
};

export const AnylogiUserRoles = {
  ADMIN: 'ADMIN',
  MERCHANT: 'MERCHANT',
  STAFF: 'STAFF',
  SUPPLIER: 'SUPPLIER'
} as const;

export type AnylogiUserRoles = typeof AnylogiUserRoles[keyof typeof AnylogiUserRoles];
export const AnylogiUserStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ONBOARDING: 'ONBOARDING',
  PENDING: 'PENDING',
  REVIEW: 'REVIEW'
} as const;

export type AnylogiUserStatus = typeof AnylogiUserStatus[keyof typeof AnylogiUserStatus];
export type AnylogiUsersPaginated = Paginated & {
  readonly __typename?: 'AnylogiUsersPaginated';
  readonly page: Scalars['Int']['output'];
  readonly records: ReadonlyArray<AnylogiUser>;
  readonly total: Scalars['Int']['output'];
};

export type AnylogiWarehouse = {
  readonly __typename?: 'AnylogiWarehouse';
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

export type ArchiveProductVariantsOutput = {
  readonly __typename?: 'ArchiveProductVariantsOutput';
  readonly ok: Scalars['Boolean']['output'];
};

export type AssetUploadUrl = {
  readonly __typename?: 'AssetUploadUrl';
  readonly originalUrl: UploadUrl;
  readonly previewUrl?: Maybe<UploadUrl>;
};

export type AuMarketFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: AuMarketFileUploadType;
};

export const AuMarketFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type AuMarketFileUploadType = typeof AuMarketFileUploadType[keyof typeof AuMarketFileUploadType];
export type AutoReplyContent = {
  readonly __typename?: 'AutoReplyContent';
  readonly autoReplyId: Scalars['String']['output'];
};

export type AutoReplyInfo = {
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly trigger: AutomationFlowTrigger;
};

export type AutomationFlow = {
  readonly __typename?: 'AutomationFlow';
  readonly enabled: Scalars['Boolean']['output'];
  readonly eventType: AutomationFlowEventType;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly replyContents: ReadonlyArray<AutomationFlowContent>;
  readonly sendWhen: LiveCommentAutoReplyTiming;
  readonly trigger: AutomationFlowTrigger;
};

export type AutomationFlowContent = AutomationTemplateContent | AutomationTextContent;

export type AutomationFlowContentInput = {
  readonly contentType: AutomationFlowContentType;
  readonly template?: InputMaybe<AutomationTemplateContentInput>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

export const AutomationFlowContentType = {
  TemplateContent: 'TemplateContent',
  TextContent: 'TextContent'
} as const;

export type AutomationFlowContentType = typeof AutomationFlowContentType[keyof typeof AutomationFlowContentType];
export const AutomationFlowEventType = {
  InstagramLiveComment: 'InstagramLiveComment'
} as const;

export type AutomationFlowEventType = typeof AutomationFlowEventType[keyof typeof AutomationFlowEventType];
export type AutomationFlowTrigger = AnyContent | ContainsKeyword;

export type AutomationFlowTriggerInput = {
  readonly containsKeyword?: InputMaybe<ContainsKeywordInput>;
};

export type AutomationTemplateButton = {
  readonly __typename?: 'AutomationTemplateButton';
  readonly buttonUrl: Scalars['Url']['output'];
  readonly title: Scalars['String']['output'];
};

export type AutomationTemplateButtonInput = {
  readonly buttonUrl: Scalars['Url']['input'];
  readonly title: Scalars['String']['input'];
};

export type AutomationTemplateContent = {
  readonly __typename?: 'AutomationTemplateContent';
  readonly buttons: ReadonlyArray<AutomationTemplateButton>;
  readonly imageUrl?: Maybe<Scalars['Url']['output']>;
  readonly title: Scalars['String']['output'];
};

export type AutomationTemplateContentInput = {
  readonly buttons: ReadonlyArray<AutomationTemplateButtonInput>;
  readonly imageUrl?: InputMaybe<Scalars['Url']['input']>;
  readonly title: Scalars['String']['input'];
};

export type AutomationTextContent = {
  readonly __typename?: 'AutomationTextContent';
  readonly text: Scalars['String']['output'];
};

export type BasicUser = {
  readonly __typename?: 'BasicUser';
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type BotReaction = {
  readonly __typename?: 'BotReaction';
  readonly sign: ReactionSign;
};

export type BoxSize = {
  readonly __typename?: 'BoxSize';
  readonly default: Scalars['Boolean']['output'];
  readonly depth: Scalars['Int']['output'];
  readonly height: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly weight?: Maybe<Scalars['Int']['output']>;
  readonly width: Scalars['Int']['output'];
};

export type BoxSizeInput = {
  readonly default: Scalars['Boolean']['input'];
  readonly depth: Scalars['Int']['input'];
  readonly height: Scalars['Int']['input'];
  readonly name: Scalars['String']['input'];
  readonly weight?: InputMaybe<Scalars['Int']['input']>;
  readonly width: Scalars['Int']['input'];
};

export type Brand = {
  readonly __typename?: 'Brand';
  readonly defaultCourierType?: Maybe<CourierType>;
  readonly logisticFeatureFlag?: Maybe<LogisticFeatureFlag>;
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly name: Scalars['String']['output'];
};

export type BrandSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: StoreSortByField;
};

/**
 * example value for each condition type
 *
 * CUSTOMER_AGE = ["90"]
 * CUSTOMER_AMOUNT_SPENT = ["20.35"]
 * CUSTOMER_MONTH_OF_BIRTH = ["12"]
 * CUSTOMER_GENDER = ["MALE"],["FEMALE"],["OTHER"]
 * CUSTOMER_ITEM_PURCHASED = ["ITEMID"]
 * CUSTOMER_LAST_ORDER_DATE = ["32"]
 * CUSTOMER_NUMBER_OF_ORDERS = ["32"]
 * CUSTOMER_PROVINCE = ["JP","JP-01"]
 */
export type BroadcastCondition = {
  readonly __typename?: 'BroadcastCondition';
  readonly id: Scalars['String']['output'];
  readonly operator: BroadcastConditionOperator;
  readonly type: BroadcastConditionType;
  readonly values: ReadonlyArray<Scalars['String']['output']>;
};

/**
 * example value for each condition type
 *
 * CUSTOMER_AGE = ["90"]
 * CUSTOMER_AMOUNT_SPENT = ["20.35"]
 * CUSTOMER_MONTH_OF_BIRTH = ["12"]
 * CUSTOMER_GENDER = ["MALE"],["FEMALE"],["OTHER"]
 * CUSTOMER_ITEM_PURCHASED = ["ITEMID"]
 * CUSTOMER_LAST_ORDER_DATE = ["32"]
 * CUSTOMER_NUMBER_OF_ORDERS = ["32"]
 * CUSTOMER_PROVINCE = ["JP","JP-01"]
 */
export type BroadcastConditionInput = {
  readonly id: Scalars['String']['input'];
  readonly operator: BroadcastConditionOperator;
  readonly type: BroadcastConditionType;
  readonly values: ReadonlyArray<Scalars['String']['input']>;
};

export const BroadcastConditionOperator = {
  BETWEEN: 'BETWEEN',
  BLANK: 'BLANK',
  CONTAINS_ALL: 'CONTAINS_ALL',
  CONTAINS_ANY: 'CONTAINS_ANY',
  EQUALS: 'EQUALS',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO: 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO: 'LESS_THAN_OR_EQUAL_TO',
  NOT_BLANK: 'NOT_BLANK',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO'
} as const;

export type BroadcastConditionOperator = typeof BroadcastConditionOperator[keyof typeof BroadcastConditionOperator];
export const BroadcastConditionType = {
  CUSTOMER_AGE: 'CUSTOMER_AGE',
  CUSTOMER_AMOUNT_SPENT: 'CUSTOMER_AMOUNT_SPENT',
  CUSTOMER_GENDER: 'CUSTOMER_GENDER',
  CUSTOMER_ITEM_PURCHASED: 'CUSTOMER_ITEM_PURCHASED',
  CUSTOMER_LAST_ORDER_DATE: 'CUSTOMER_LAST_ORDER_DATE',
  CUSTOMER_MONTH_OF_BIRTH: 'CUSTOMER_MONTH_OF_BIRTH',
  CUSTOMER_NUMBER_OF_ORDERS: 'CUSTOMER_NUMBER_OF_ORDERS',
  CUSTOMER_PROVINCE: 'CUSTOMER_PROVINCE'
} as const;

export type BroadcastConditionType = typeof BroadcastConditionType[keyof typeof BroadcastConditionType];
export type BroadcastConditionWrapper = {
  readonly __typename?: 'BroadcastConditionWrapper';
  readonly combinator: BroadcastConditionsCombinator;
  readonly conditions: ReadonlyArray<BroadcastCondition>;
};

export type BroadcastConditionWrapperInput = {
  readonly combinator: BroadcastConditionsCombinator;
  readonly conditions: ReadonlyArray<BroadcastConditionInput>;
};

export const BroadcastConditionsCombinator = {
  AND: 'AND',
  OR: 'OR'
} as const;

export type BroadcastConditionsCombinator = typeof BroadcastConditionsCombinator[keyof typeof BroadcastConditionsCombinator];
export type BroadcastInfluencerCondition = {
  readonly __typename?: 'BroadcastInfluencerCondition';
  readonly id: Scalars['String']['output'];
  readonly operator: BroadcastConditionOperator;
  readonly type: BroadcastInfluencerConditionType;
  readonly values: ReadonlyArray<Scalars['String']['output']>;
};

export type BroadcastInfluencerConditionInput = {
  readonly id: Scalars['String']['input'];
  readonly operator: BroadcastConditionOperator;
  readonly type: BroadcastInfluencerConditionType;
  readonly values: ReadonlyArray<Scalars['String']['input']>;
};

export const BroadcastInfluencerConditionType = {
  INFLUENCER_AGE: 'INFLUENCER_AGE',
  INFLUENCER_CAMPAIGN_JOINING_STATUS: 'INFLUENCER_CAMPAIGN_JOINING_STATUS',
  INFLUENCER_CAMPAIGN_POSTING_STATUS: 'INFLUENCER_CAMPAIGN_POSTING_STATUS',
  INFLUENCER_CATEGORY: 'INFLUENCER_CATEGORY',
  INFLUENCER_GENDER: 'INFLUENCER_GENDER',
  INFLUENCER_JOINED_CAMPAIGNS: 'INFLUENCER_JOINED_CAMPAIGNS',
  INFLUENCER_JOINING_CAMPAIGNS: 'INFLUENCER_JOINING_CAMPAIGNS',
  INFLUENCER_NUMBER_OF_FOLLOWERS: 'INFLUENCER_NUMBER_OF_FOLLOWERS',
  INFLUENCER_PARTICIPATED_CAMPAIGNS: 'INFLUENCER_PARTICIPATED_CAMPAIGNS',
  INFLUENCER_REGISTERED_SOCIAL_MEDIA: 'INFLUENCER_REGISTERED_SOCIAL_MEDIA',
  INFLUENCER_SIGNED_UP_DATE: 'INFLUENCER_SIGNED_UP_DATE',
  INFLUENCER_TAG: 'INFLUENCER_TAG'
} as const;

export type BroadcastInfluencerConditionType = typeof BroadcastInfluencerConditionType[keyof typeof BroadcastInfluencerConditionType];
export type BroadcastInfluencerConditionWrapper = {
  readonly __typename?: 'BroadcastInfluencerConditionWrapper';
  readonly combinator: BroadcastConditionsCombinator;
  readonly conditions: ReadonlyArray<BroadcastInfluencerCondition>;
};

export type BroadcastInfluencerConditionWrapperInput = {
  readonly combinator: BroadcastConditionsCombinator;
  readonly conditions: ReadonlyArray<BroadcastInfluencerConditionInput>;
};

export type BroadcastStatistics = {
  readonly __typename?: 'BroadcastStatistics';
  readonly averageOrderValue: Scalars['Float']['output'];
  readonly clickCount: Scalars['Int']['output'];
  readonly clickRate: Scalars['Float']['output'];
  readonly conversionCount: Scalars['Int']['output'];
  readonly conversionRate: Scalars['Float']['output'];
  readonly deliveredCount: Scalars['Int']['output'];
  readonly openCount: Scalars['Int']['output'];
  readonly openRate: Scalars['Float']['output'];
  readonly saleAmount: Scalars['Float']['output'];
};

export const BroadcastStatus = {
  DELIVERED: 'DELIVERED',
  DRAFT: 'DRAFT',
  ERROR: 'ERROR',
  SCHEDULED: 'SCHEDULED',
  SENDING: 'SENDING'
} as const;

export type BroadcastStatus = typeof BroadcastStatus[keyof typeof BroadcastStatus];
export type BulkOperationResponse = {
  readonly __typename?: 'BulkOperationResponse';
  readonly failed: Scalars['Int']['output'];
  readonly succeeded: Scalars['Int']['output'];
};

export type ButtonActionLabelInput = {
  readonly label: Scalars['String']['input'];
  readonly linkedPostback?: InputMaybe<LinkedPostbackActionInput>;
  readonly type: LineCardButtonActionType;
  readonly url?: InputMaybe<UrlActionInput>;
};

export type ButtonMessage = {
  readonly __typename?: 'ButtonMessage';
  readonly cards: ReadonlyArray<Card>;
};

export type ButtonMessageInputV2 = {
  readonly cards: ReadonlyArray<CardInputV2>;
};

export const CrmType = {
  COMMERCIAL: 'COMMERCIAL',
  INFLUENCER: 'INFLUENCER'
} as const;

export type CrmType = typeof CrmType[keyof typeof CrmType];
export type CampaignHistory = {
  readonly __typename?: 'CampaignHistory';
  readonly campaignName: Scalars['String']['output'];
  readonly campaignUrl: Scalars['String']['output'];
  readonly joinedDatetime: Scalars['OffsetDateTime']['output'];
  readonly thumbnail: Scalars['String']['output'];
};

export type CancelOrderInput = {
  readonly orderIds: ReadonlyArray<Scalars['Long']['input']>;
};

export type CancelOrderPayload = {
  readonly __typename?: 'CancelOrderPayload';
  readonly failureOrderIds: ReadonlyArray<FailureCancelOrder>;
  readonly successOrderIds: ReadonlyArray<Scalars['Long']['output']>;
};

export type Card = {
  readonly __typename?: 'Card';
  readonly actionLabelsV2?: Maybe<ReadonlyArray<ActionLabelV2>>;
  readonly description?: Maybe<TextComponent>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<TextComponent>;
};

export type CardInputV2 = {
  readonly actionLabels?: InputMaybe<ReadonlyArray<ButtonActionLabelInput>>;
  readonly description?: InputMaybe<TextComponentInput>;
  readonly imageUrl?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<TextComponentInput>;
};

export const Category = {
  GIFT: 'GIFT',
  MERCHANDISE: 'MERCHANDISE',
  NON_COMMERCIAL_DOCS: 'NON_COMMERCIAL_DOCS',
  OTHER: 'OTHER',
  PERSONAL: 'PERSONAL',
  REPAIR: 'REPAIR',
  RETURN: 'RETURN',
  SAMPLE: 'SAMPLE'
} as const;

export type Category = typeof Category[keyof typeof Category];
export const ChannelFormStatus = {
  DELETED: 'DELETED',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS'
} as const;

export type ChannelFormStatus = typeof ChannelFormStatus[keyof typeof ChannelFormStatus];
export const ChannelProvider = {
  MANUAL: 'MANUAL',
  QOO10: 'QOO10',
  RAKUTEN: 'RAKUTEN',
  SHOPEE: 'SHOPEE',
  SHOPIFY: 'SHOPIFY'
} as const;

export type ChannelProvider = typeof ChannelProvider[keyof typeof ChannelProvider];
export type Chat = {
  readonly __typename?: 'Chat';
  readonly assignee?: Maybe<ChatAssignee>;
  readonly avatar: Scalars['String']['output'];
  readonly checked: Scalars['Boolean']['output'];
  readonly customerTags: ReadonlyArray<CustomerTag>;
  readonly id: Scalars['String']['output'];
  readonly lastMessage: Scalars['String']['output'];
  readonly lastMessageAt: Scalars['UnixTimestamp']['output'];
  /** @deprecated please change to use assignee field instead */
  readonly pic?: Maybe<BasicUser>;
  readonly resolved: Scalars['Boolean']['output'];
  readonly type: ChatType;
  readonly userName: Scalars['String']['output'];
};

export type ChatAiAssignee = {
  readonly __typename?: 'ChatAIAssignee';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type ChatAssignRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly pic: Scalars['String']['input'];
};

export type ChatAssignRequestV2 = {
  readonly assigneeType: ChatAssigneeType;
  readonly chatId: Scalars['String']['input'];
  readonly pic?: InputMaybe<Scalars['Long']['input']>;
};

export type ChatAssignResponse = {
  readonly __typename?: 'ChatAssignResponse';
  readonly assignee: ChatAssignee;
};

export type ChatAssignee = ChatAiAssignee | ChatUserAssignee;

export const ChatAssigneeType = {
  AI: 'AI',
  PIC: 'PIC'
} as const;

export type ChatAssigneeType = typeof ChatAssigneeType[keyof typeof ChatAssigneeType];
export type ChatCustomerDetail = {
  readonly __typename?: 'ChatCustomerDetail';
  readonly chatAvatar: Scalars['String']['output'];
  readonly chatId: Scalars['String']['output'];
  readonly chatPlatformDetail: SpecificChatPlatformDetail;
  readonly chatUserName: Scalars['String']['output'];
  readonly customerId: Scalars['String']['output'];
  readonly customerTags: ReadonlyArray<CustomerTag>;
  readonly influencerInfo?: Maybe<InfluencerInfo>;
  readonly lastOrderAt?: Maybe<Scalars['UnixTimestamp']['output']>;
  readonly name: Scalars['String']['output'];
  readonly orderNum: Scalars['Int']['output'];
  readonly pic?: Maybe<BasicUser>;
  readonly shopifyCustomerFirstName?: Maybe<Scalars['String']['output']>;
  readonly shopifyCustomerId?: Maybe<Scalars['String']['output']>;
  readonly shopifyCustomerLastName?: Maybe<Scalars['String']['output']>;
  readonly shopifyCustomerUrl?: Maybe<Scalars['String']['output']>;
  readonly startedAt: Scalars['UnixTimestamp']['output'];
  readonly totalSpent: Scalars['String']['output'];
  readonly type: ChatType;
};

export type ChatInfo = {
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type ChatResolveRequest = {
  readonly chatId: Scalars['String']['input'];
};

export type ChatState = {
  readonly __typename?: 'ChatState';
  readonly shopifyCustomerId?: Maybe<Scalars['String']['output']>;
};

export type ChatStateUpdateRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly key: Scalars['String']['input'];
  readonly value: Scalars['String']['input'];
};

export const ChatType = {
  INSTAGRAM: 'INSTAGRAM',
  LAZADA: 'LAZADA',
  LINE: 'LINE',
  MESSENGER: 'MESSENGER',
  SHOPEE: 'SHOPEE',
  TIKTOK_SHOP: 'TIKTOK_SHOP',
  WHATSAPP: 'WHATSAPP'
} as const;

export type ChatType = typeof ChatType[keyof typeof ChatType];
export const ChatTypeFilter = {
  ACTIVATED: 'ACTIVATED',
  ALL: 'ALL',
  ASSIGNED_TO_ME: 'ASSIGNED_TO_ME',
  ONGOING_INQUIRY: 'ONGOING_INQUIRY',
  ONGOING_MESSAGE: 'ONGOING_MESSAGE'
} as const;

export type ChatTypeFilter = typeof ChatTypeFilter[keyof typeof ChatTypeFilter];
export type ChatUnassignRequest = {
  readonly chatId: Scalars['String']['input'];
};

export type ChatUnresolveRequest = {
  readonly chatId: Scalars['String']['input'];
};

export type ChatUserAssignee = {
  readonly __typename?: 'ChatUserAssignee';
  readonly user: BasicUser;
};

export type Chats = {
  readonly __typename?: 'Chats';
  readonly activatedCount: Scalars['Int']['output'];
  readonly assignedToMeCount: Scalars['Int']['output'];
  readonly chats: ReadonlyArray<Chat>;
  readonly hasNext: Scalars['Boolean']['output'];
  readonly ongoingInquiryCount: Scalars['Int']['output'];
  readonly ongoingMessageCount: Scalars['Int']['output'];
  readonly totalCount: Scalars['Int']['output'];
};

export type CommonChatEvent = {
  readonly __typename?: 'CommonChatEvent';
  readonly event: CommonChatEventData;
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type CommonChatEventData = AiMessageFailedToSendEvent | ManualAssign | ManualUnassign | SystemAutoAssignAi | SystemAutoUnassignAi;

export type CompleteSendingMessengerBroadcastSuccess = {
  readonly __typename?: 'CompleteSendingMessengerBroadcastSuccess';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type Condition = {
  readonly __typename?: 'Condition';
  readonly condition: BroadcastConditionWrapper;
};

export const ConditionOperator = {
  BEGINS_WITH: 'BEGINS_WITH',
  BETWEEN: 'BETWEEN',
  BLANK: 'BLANK',
  CONTAINS: 'CONTAINS',
  DOES_NOT_BEGIN_WITH: 'DOES_NOT_BEGIN_WITH',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
  DOES_NOT_END_WITH: 'DOES_NOT_END_WITH',
  ENDS_WITH: 'ENDS_WITH',
  EQUALS: 'EQUALS',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO: 'GREATER_THAN_OR_EQUAL_TO',
  IN: 'IN',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO: 'LESS_THAN_OR_EQUAL_TO',
  NOT_BETWEEN: 'NOT_BETWEEN',
  NOT_BLANK: 'NOT_BLANK',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  NOT_IN: 'NOT_IN',
  NOT_NULL: 'NOT_NULL',
  NULL: 'NULL'
} as const;

export type ConditionOperator = typeof ConditionOperator[keyof typeof ConditionOperator];
export const ConditionType = {
  CUSTOMER_AMOUNT_SPENT: 'CUSTOMER_AMOUNT_SPENT',
  CUSTOMER_ITEM_PURCHASED: 'CUSTOMER_ITEM_PURCHASED',
  CUSTOMER_NUMBER_OF_ORDERS: 'CUSTOMER_NUMBER_OF_ORDERS',
  CUSTOMER_TAGS: 'CUSTOMER_TAGS',
  LINE_SHOPIFY_CONNECTED: 'LINE_SHOPIFY_CONNECTED',
  MESSAGE_TEXT: 'MESSAGE_TEXT',
  ORDER_FULFILLMENT_STATUS: 'ORDER_FULFILLMENT_STATUS',
  ORDER_GRAND_TOTAL: 'ORDER_GRAND_TOTAL',
  ORDER_PAYMENT_STATUS: 'ORDER_PAYMENT_STATUS',
  ORDER_QUANTITY_ITEMS: 'ORDER_QUANTITY_ITEMS',
  ORDER_SUBTOTAL: 'ORDER_SUBTOTAL',
  RICH_MENU_ID: 'RICH_MENU_ID',
  RICH_MENU_ID_AND_TRIGGER: 'RICH_MENU_ID_AND_TRIGGER',
  RICH_MENU_TRIGGER: 'RICH_MENU_TRIGGER'
} as const;

export type ConditionType = typeof ConditionType[keyof typeof ConditionType];
export type ConditionalSplitBlock = {
  readonly __typename?: 'ConditionalSplitBlock';
  readonly combinator: ConditionsCombinator;
  readonly conditions: ReadonlyArray<WorkflowCondition>;
  readonly nextMeetBlockId?: Maybe<Scalars['String']['output']>;
  readonly nextOtherBlockId?: Maybe<Scalars['String']['output']>;
};

export type ConditionalSplitBlockInput = {
  readonly combinator: ConditionsCombinator;
  readonly conditions: ReadonlyArray<WorkflowConditionInput>;
  readonly nextMeetBlockId?: InputMaybe<Scalars['String']['input']>;
  readonly nextOtherBlockId?: InputMaybe<Scalars['String']['input']>;
};

export const ConditionsCombinator = {
  AND: 'AND',
  OR: 'OR'
} as const;

export type ConditionsCombinator = typeof ConditionsCombinator[keyof typeof ConditionsCombinator];
export type ConnectLazadaRequest = {
  readonly code: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type ConnectLazadaResponse = ConnectLazadaResponseSuccess | UnknownErrorConnectLazadaError;

export type ConnectLazadaResponseSuccess = {
  readonly __typename?: 'ConnectLazadaResponseSuccess';
  readonly connectionId: Scalars['String']['output'];
};

export type ConnectShopeeChatContinueSelectShopResponse = {
  readonly __typename?: 'ConnectShopeeChatContinueSelectShopResponse';
  readonly shops: ReadonlyArray<ShopeeContinueShopSelectionItem>;
  readonly tempConnectionId: Scalars['String']['output'];
};

export type ConnectShopeeChatForMainAccountRequest = {
  readonly code: Scalars['String']['input'];
  readonly mainAccountId: Scalars['Long']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type ConnectShopeeChatForSelectedShopRequest = {
  readonly shopId: Scalars['Long']['input'];
  readonly tempConnectionId: Scalars['String']['input'];
};

export type ConnectShopeeChatForShopAccountRequest = {
  readonly code: Scalars['String']['input'];
  readonly shopId: Scalars['Long']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type ConnectShopeeChatRequestInput = {
  readonly inputType: ConnectShopeeInputType;
  readonly mainAccountInput?: InputMaybe<ConnectShopeeChatForMainAccountRequest>;
  readonly selectedShopInput?: InputMaybe<ConnectShopeeChatForSelectedShopRequest>;
  readonly shopAccountInput?: InputMaybe<ConnectShopeeChatForShopAccountRequest>;
};

export type ConnectShopeeChatResponse = ConnectShopeeChatContinueSelectShopResponse | ConnectShopeeChatSuccessResponse;

export type ConnectShopeeChatSuccessResponse = {
  readonly __typename?: 'ConnectShopeeChatSuccessResponse';
  readonly connectionId: Scalars['String']['output'];
};

export const ConnectShopeeInputType = {
  MAIN_ACCOUNT: 'MAIN_ACCOUNT',
  SELECTED_SHOP: 'SELECTED_SHOP',
  SHOP_ACCOUNT: 'SHOP_ACCOUNT'
} as const;

export type ConnectShopeeInputType = typeof ConnectShopeeInputType[keyof typeof ConnectShopeeInputType];
export type ConnectTikTokShopRequest = {
  readonly authCode: Scalars['String']['input'];
  readonly region: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type ConnectTikTokShopResponse = {
  readonly __typename?: 'ConnectTikTokShopResponse';
  readonly id: Scalars['String']['output'];
};

export type ConnectToFacebookRequestV2Input = {
  readonly accessToken: Scalars['String']['input'];
  readonly pageId: Scalars['String']['input'];
  readonly platform: MetaMessagingPlatform;
  readonly storeId: Scalars['String']['input'];
};

export type ConnectToFacebookResponseSuccess = {
  readonly __typename?: 'ConnectToFacebookResponseSuccess';
  readonly connectionId: Scalars['String']['output'];
};

export type ConnectToFacebookResponseV2 = AccountAlreadyHaveConnection | ConnectToFacebookResponseSuccess | MessengerNotSupportChatPlugInWithAnonymousUserFacebookError | NoInstagramAccountConnectToFacebookError | PageHaveConnectedToOtherStore | UnknownErrorConnectToFacebookError;

export type ConnectToWhatsAppAccountAlreadyHaveConnection = {
  readonly __typename?: 'ConnectToWhatsAppAccountAlreadyHaveConnection';
  readonly message: Scalars['String']['output'];
};

export type ConnectToWhatsAppResponse = ConnectToWhatsAppAccountAlreadyHaveConnection | ConnectToWhatsAppResponseSuccess | UnknownErrorConnectToWhatsAppError;

export type ConnectToWhatsAppResponseSuccess = {
  readonly __typename?: 'ConnectToWhatsAppResponseSuccess';
  readonly connectionId: Scalars['String']['output'];
};

export type ConnectWhatsAppRequestInput = {
  readonly businessAccountId: Scalars['String']['input'];
  readonly code: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type Contact = {
  readonly attentionName?: InputMaybe<Scalars['String']['input']>;
  readonly businessName: Scalars['String']['input'];
  readonly contactName?: InputMaybe<ContactName>;
  readonly contactType?: InputMaybe<UpsContactType>;
  readonly departmentName?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly mailStopCode?: InputMaybe<Scalars['String']['input']>;
  readonly notificationPreference: Scalars['Boolean']['input'];
  readonly phone?: InputMaybe<UpsPhone>;
};

export type ContactName = {
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
};

export type ContainsKeyword = {
  readonly __typename?: 'ContainsKeyword';
  readonly value: Scalars['String']['output'];
};

export type ContainsKeywordInput = {
  readonly value: Scalars['String']['input'];
};

export const CostCategory = {
  ADVERTISING_FEE: 'ADVERTISING_FEE',
  COMMISSION_FEE: 'COMMISSION_FEE',
  DISCOUNT: 'DISCOUNT',
  LOGISTICS_FEE: 'LOGISTICS_FEE',
  OTHER_COST: 'OTHER_COST',
  UNIT_COST: 'UNIT_COST'
} as const;

export type CostCategory = typeof CostCategory[keyof typeof CostCategory];
export const Country = {
  AC: 'AC',
  AD: 'AD',
  AE: 'AE',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AN: 'AN',
  AO: 'AO',
  AQ: 'AQ',
  AR: 'AR',
  AS: 'AS',
  AT: 'AT',
  AU: 'AU',
  AW: 'AW',
  AX: 'AX',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BE: 'BE',
  BF: 'BF',
  BG: 'BG',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BL: 'BL',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BQ: 'BQ',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BV: 'BV',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CC: 'CC',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CH: 'CH',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CN: 'CN',
  CO: 'CO',
  CR: 'CR',
  CU: 'CU',
  CV: 'CV',
  CW: 'CW',
  CX: 'CX',
  CY: 'CY',
  CZ: 'CZ',
  DE: 'DE',
  DJ: 'DJ',
  DK: 'DK',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EE: 'EE',
  EG: 'EG',
  EH: 'EH',
  ER: 'ER',
  ES: 'ES',
  ET: 'ET',
  FI: 'FI',
  FJ: 'FJ',
  FK: 'FK',
  FM: 'FM',
  FO: 'FO',
  FR: 'FR',
  GA: 'GA',
  GB: 'GB',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GR: 'GR',
  GS: 'GS',
  GT: 'GT',
  GU: 'GU',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HM: 'HM',
  HN: 'HN',
  HR: 'HR',
  HT: 'HT',
  HU: 'HU',
  ID: 'ID',
  IE: 'IE',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IO: 'IO',
  IQ: 'IQ',
  IR: 'IR',
  IS: 'IS',
  IT: 'IT',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  JP: 'JP',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KP: 'KP',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LI: 'LI',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LT: 'LT',
  LU: 'LU',
  LV: 'LV',
  LY: 'LY',
  MA: 'MA',
  MC: 'MC',
  MD: 'MD',
  ME: 'ME',
  MF: 'MF',
  MG: 'MG',
  MH: 'MH',
  MK: 'MK',
  ML: 'ML',
  MM: 'MM',
  MN: 'MN',
  MO: 'MO',
  MP: 'MP',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MT: 'MT',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NF: 'NF',
  NG: 'NG',
  NI: 'NI',
  NL: 'NL',
  NO: 'NO',
  NP: 'NP',
  NR: 'NR',
  NU: 'NU',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PL: 'PL',
  PM: 'PM',
  PN: 'PN',
  PR: 'PR',
  PS: 'PS',
  PT: 'PT',
  PW: 'PW',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RS: 'RS',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SD: 'SD',
  SE: 'SE',
  SG: 'SG',
  SH: 'SH',
  SI: 'SI',
  SJ: 'SJ',
  SK: 'SK',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  SS: 'SS',
  ST: 'ST',
  SV: 'SV',
  SX: 'SX',
  SY: 'SY',
  SZ: 'SZ',
  TA: 'TA',
  TC: 'TC',
  TD: 'TD',
  TF: 'TF',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TK: 'TK',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  UM: 'UM',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VI: 'VI',
  VN: 'VN',
  VU: 'VU',
  WF: 'WF',
  WS: 'WS',
  XK: 'XK',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW'
} as const;

export type Country = typeof Country[keyof typeof Country];
export type Courier = {
  readonly __typename?: 'Courier';
  readonly deliveryTimeframe: ReadonlyArray<DeliveryTimeframe>;
  readonly type: CourierType;
};

export type CourierAccountDetails = {
  readonly __typename?: 'CourierAccountDetails';
  readonly account: IdName;
  readonly courierDetails?: Maybe<CourierDetails>;
  readonly created: Scalars['Date']['output'];
  readonly crossBorderCourierProvider: CrossBorderCourierProvider;
  readonly id: Scalars['ID']['output'];
  readonly merchantId: Scalars['String']['output'];
  readonly status: CourierStatus;
  readonly updated: Scalars['Date']['output'];
};

export type CourierAccountDetailsListResponse = Paginated & {
  readonly __typename?: 'CourierAccountDetailsListResponse';
  readonly courierAccountDetails: ReadonlyArray<CourierAccountDetails>;
  readonly page: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type CourierAccountSupported = {
  readonly __typename?: 'CourierAccountSupported';
  readonly crossBorderCourierProvider: CrossBorderCourierProvider;
  readonly name: Scalars['String']['output'];
};

export type CourierDetails = DhlAccountDetails | FedExAccountDetails | JapanPostAccountDetails | UpsAccountDetails;

export const CourierService = {
  ACCEPTANCE_RECORDED_MAIL: 'ACCEPTANCE_RECORDED_MAIL',
  CHARTER: 'CHARTER',
  CLICK_POST: 'CLICK_POST',
  DELIVERY_PROVIDER: 'DELIVERY_PROVIDER',
  DHL: 'DHL',
  ECMS: 'ECMS',
  ECOHAI: 'ECOHAI',
  FED_EX: 'FED_EX',
  FUKUTSU: 'FUKUTSU',
  GLOBAL_E_PARCEL: 'GLOBAL_E_PARCEL',
  IN_STORE_PICKUP: 'IN_STORE_PICKUP',
  IN_WAREHOUSE_PICKUP: 'IN_WAREHOUSE_PICKUP',
  JAD: 'JAD',
  JAPAN_POST: 'JAPAN_POST',
  JAPAN_POST_EMS: 'JAPAN_POST_EMS',
  JAPAN_POST_INTERNATIONAL_E_PACKET: 'JAPAN_POST_INTERNATIONAL_E_PACKET',
  JAPAN_POST_INTERNATIONAL_E_PACKET_LIGHT: 'JAPAN_POST_INTERNATIONAL_E_PACKET_LIGHT',
  JAPAN_POST_INTERNATIONAL_PARCELS: 'JAPAN_POST_INTERNATIONAL_PARCELS',
  JAPAN_POST_RAKUTEN_CVS: 'JAPAN_POST_RAKUTEN_CVS',
  JAPAN_POST_RAKUTEN_PO: 'JAPAN_POST_RAKUTEN_PO',
  JAPAN_POST_SMALL_PACKING: 'JAPAN_POST_SMALL_PACKING',
  KINBUTSU_REX: 'KINBUTSU_REX',
  KURUME: 'KURUME',
  LAST_ONE_MILE_BUSINESS_DELIVERY: 'LAST_ONE_MILE_BUSINESS_DELIVERY',
  LAST_ONE_MILE_PERSONAL_DELIVERY: 'LAST_ONE_MILE_PERSONAL_DELIVERY',
  LETTER_PACK_LIGHT: 'LETTER_PACK_LIGHT',
  LETTER_PACK_PLUS: 'LETTER_PACK_PLUS',
  MANUFACTURER_DIRECT: 'MANUFACTURER_DIRECT',
  NITTSU: 'NITTSU',
  NITTSU_EXPRESS_HIGH_SPEED: 'NITTSU_EXPRESS_HIGH_SPEED',
  NON_STANDARD_SIZE_MAIL: 'NON_STANDARD_SIZE_MAIL',
  PEGASUS: 'PEGASUS',
  RAKUTEN_EXPRESS: 'RAKUTEN_EXPRESS',
  RAKUTEN_EXPRESS_MAIL: 'RAKUTEN_EXPRESS_MAIL',
  SAGAWA: 'SAGAWA',
  SAGAWA_MAIL: 'SAGAWA_MAIL',
  SAGAWA_RAKUTEN_CVS: 'SAGAWA_RAKUTEN_CVS',
  SAGAWA_YU_MAIL: 'SAGAWA_YU_MAIL',
  SANYO_JIDOSHA: 'SANYO_JIDOSHA',
  SBS_SOKUHAI: 'SBS_SOKUHAI',
  SEINO_EXPRESS: 'SEINO_EXPRESS',
  SEINO_MINI: 'SEINO_MINI',
  STANDARD_SIZE_MAIL: 'STANDARD_SIZE_MAIL',
  TOLL_EXPRESS: 'TOLL_EXPRESS',
  TONAMI: 'TONAMI',
  UPS: 'UPS',
  YAMATO: 'YAMATO',
  YAMATO_BOX_CHARTER: 'YAMATO_BOX_CHARTER',
  YAMATO_COMPACT: 'YAMATO_COMPACT',
  YAMATO_MAIL: 'YAMATO_MAIL',
  YAMATO_NEKOPOSU: 'YAMATO_NEKOPOSU',
  YAMATO_POA: 'YAMATO_POA',
  YAMATO_RAKUTEN_CVS: 'YAMATO_RAKUTEN_CVS',
  YU_MAIL: 'YU_MAIL',
  YU_MAIL_150G: 'YU_MAIL_150G',
  YU_MAIL_250G: 'YU_MAIL_250G',
  YU_MAIL_500G: 'YU_MAIL_500G',
  YU_MAIL_1000G: 'YU_MAIL_1000G',
  YU_PACK: 'YU_PACK',
  YU_PACKET: 'YU_PACKET',
  YU_PACKET_1CM: 'YU_PACKET_1CM',
  YU_PACKET_2CM: 'YU_PACKET_2CM',
  YU_PACKET_3CM: 'YU_PACKET_3CM',
  YU_PACK_POA: 'YU_PACK_POA'
} as const;

export type CourierService = typeof CourierService[keyof typeof CourierService];
export type CourierSettings = {
  readonly __typename?: 'CourierSettings';
  readonly accountId: Scalars['ID']['output'];
  readonly boxSizes?: Maybe<ReadonlyArray<BoxSize>>;
  readonly category: Category;
  readonly letterHeadImage?: Maybe<Scalars['String']['output']>;
  readonly signatureImage?: Maybe<Scalars['String']['output']>;
};

export type CourierSettingsFilterInput = {
  readonly country?: InputMaybe<Country>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly ready?: InputMaybe<AccountCourierSettingsStatus>;
};

export type CourierSettingsInput = {
  readonly accountId: Scalars['ID']['input'];
  readonly boxSizes: ReadonlyArray<BoxSizeInput>;
  readonly category: Category;
  readonly letterHeadImage?: InputMaybe<Scalars['String']['input']>;
  readonly signatureImage?: InputMaybe<Scalars['String']['input']>;
};

export const CourierStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
} as const;

export type CourierStatus = typeof CourierStatus[keyof typeof CourierStatus];
export const CourierType = {
  ACCEPTANCE_RECORDED_MAIL: 'ACCEPTANCE_RECORDED_MAIL',
  CHARTER: 'CHARTER',
  CLICK_POST: 'CLICK_POST',
  DELIVERY_PROVIDER: 'DELIVERY_PROVIDER',
  DHL: 'DHL',
  ECMS: 'ECMS',
  ECOHAI: 'ECOHAI',
  FED_EX: 'FED_EX',
  FUKUTSU: 'FUKUTSU',
  GLOBAL_E_PARCEL: 'GLOBAL_E_PARCEL',
  IN_STORE_PICKUP: 'IN_STORE_PICKUP',
  IN_WAREHOUSE_PICKUP: 'IN_WAREHOUSE_PICKUP',
  JAD: 'JAD',
  JAPAN_POST: 'JAPAN_POST',
  JAPAN_POST_EMS: 'JAPAN_POST_EMS',
  JAPAN_POST_INTERNATIONAL_E_PACKET: 'JAPAN_POST_INTERNATIONAL_E_PACKET',
  JAPAN_POST_INTERNATIONAL_E_PACKET_LIGHT: 'JAPAN_POST_INTERNATIONAL_E_PACKET_LIGHT',
  JAPAN_POST_INTERNATIONAL_PARCELS: 'JAPAN_POST_INTERNATIONAL_PARCELS',
  JAPAN_POST_RAKUTEN_CVS: 'JAPAN_POST_RAKUTEN_CVS',
  JAPAN_POST_RAKUTEN_PO: 'JAPAN_POST_RAKUTEN_PO',
  JAPAN_POST_SMALL_PACKING: 'JAPAN_POST_SMALL_PACKING',
  KINBUTSU_REX: 'KINBUTSU_REX',
  KURUME: 'KURUME',
  LAST_ONE_MILE_BUSINESS_DELIVERY: 'LAST_ONE_MILE_BUSINESS_DELIVERY',
  LAST_ONE_MILE_PERSONAL_DELIVERY: 'LAST_ONE_MILE_PERSONAL_DELIVERY',
  LETTER_PACK_LIGHT: 'LETTER_PACK_LIGHT',
  LETTER_PACK_PLUS: 'LETTER_PACK_PLUS',
  MANUFACTURER_DIRECT: 'MANUFACTURER_DIRECT',
  NITTSU: 'NITTSU',
  NITTSU_EXPRESS_HIGH_SPEED: 'NITTSU_EXPRESS_HIGH_SPEED',
  NON_STANDARD_SIZE_MAIL: 'NON_STANDARD_SIZE_MAIL',
  PEGASUS: 'PEGASUS',
  RAKUTEN_EXPRESS: 'RAKUTEN_EXPRESS',
  RAKUTEN_EXPRESS_MAIL: 'RAKUTEN_EXPRESS_MAIL',
  SAGAWA: 'SAGAWA',
  SAGAWA_MAIL: 'SAGAWA_MAIL',
  SAGAWA_RAKUTEN_CVS: 'SAGAWA_RAKUTEN_CVS',
  SAGAWA_YU_MAIL: 'SAGAWA_YU_MAIL',
  SANYO_JIDOSHA: 'SANYO_JIDOSHA',
  SBS_SOKUHAI: 'SBS_SOKUHAI',
  SEINO_EXPRESS: 'SEINO_EXPRESS',
  SEINO_MINI: 'SEINO_MINI',
  STANDARD_SIZE_MAIL: 'STANDARD_SIZE_MAIL',
  TOLL_EXPRESS: 'TOLL_EXPRESS',
  TONAMI: 'TONAMI',
  UPS: 'UPS',
  YAMATO: 'YAMATO',
  YAMATO_BOX_CHARTER: 'YAMATO_BOX_CHARTER',
  YAMATO_COMPACT: 'YAMATO_COMPACT',
  YAMATO_MAIL: 'YAMATO_MAIL',
  YAMATO_NEKOPOSU: 'YAMATO_NEKOPOSU',
  YAMATO_POA: 'YAMATO_POA',
  YAMATO_RAKUTEN_CVS: 'YAMATO_RAKUTEN_CVS',
  YU_MAIL: 'YU_MAIL',
  YU_MAIL_150G: 'YU_MAIL_150G',
  YU_MAIL_250G: 'YU_MAIL_250G',
  YU_MAIL_500G: 'YU_MAIL_500G',
  YU_MAIL_1000G: 'YU_MAIL_1000G',
  YU_PACK: 'YU_PACK',
  YU_PACKET: 'YU_PACKET',
  YU_PACKET_1CM: 'YU_PACKET_1CM',
  YU_PACKET_2CM: 'YU_PACKET_2CM',
  YU_PACKET_3CM: 'YU_PACKET_3CM',
  YU_PACK_POA: 'YU_PACK_POA'
} as const;

export type CourierType = typeof CourierType[keyof typeof CourierType];
export type CreateDatasourceInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly setting: DatasourceSettingInput;
  readonly tokenId?: InputMaybe<Scalars['Long']['input']>;
  readonly type: DatasourceType;
};

export type CreateManualCostInput = {
  readonly amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly category: CostCategory;
  readonly currency?: InputMaybe<ReportSupportedCurrency>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly name: Scalars['String']['input'];
  readonly percentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly saleChannelId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly type: ManualCostType;
};

export type CreateManualOrderInput = {
  readonly billingAddress: OrderAddressInput;
  readonly channelOrderId?: InputMaybe<Scalars['String']['input']>;
  readonly currency: CurrencyCode;
  readonly customer: OrderCustomerInput;
  readonly customerNote: Scalars['String']['input'];
  readonly discount: Scalars['BigDecimal']['input'];
  /** only for logistics connected store */
  readonly logisticsInput?: InputMaybe<LogisticsInput>;
  readonly orderProductsV2: ReadonlyArray<OrderProductInputV2Input>;
  readonly paymentMethod: PaymentMethodInput;
  /** if saleChannelId is null, will use Manual SaleChannel */
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly shipping: Scalars['BigDecimal']['input'];
  readonly shippingAddress: OrderAddressInput;
  readonly storeId: Scalars['Long']['input'];
  readonly totalTax: Scalars['BigDecimal']['input'];
};

export type CreateMarketplaceCalendarEventInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId?: InputMaybe<Scalars['Long']['input']>;
  readonly saleChannelCountry?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly target?: InputMaybe<MarketplaceCalendarEventTargetInput>;
  readonly title: Scalars['String']['input'];
};

export type CreateProductV2Input = {
  readonly description: Scalars['String']['input'];
  /** only for logistics connected store */
  readonly logistics?: InputMaybe<ProductLogisticsInput>;
  readonly media: ReadonlyArray<Scalars['URL']['input']>;
  readonly name: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
  readonly variant: CreateProductVariantV2Input;
};

export type CreateProductVariantV2Input = {
  readonly countryOfOrigin?: InputMaybe<ShopifySupportedCountryCode>;
  readonly hsCode?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<GraphQlMoneyInput>;
  readonly productCode?: InputMaybe<Scalars['String']['input']>;
  readonly setItems: ReadonlyArray<ProductVariantSetItemInput>;
  readonly tags: ReadonlyArray<Scalars['String']['input']>;
  readonly unitCost?: InputMaybe<GraphQlMoneyInput>;
  readonly weightInGrams?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export type CrossBorderBasicAuth = {
  readonly __typename?: 'CrossBorderBasicAuth';
  readonly apiKey: Scalars['String']['output'];
  readonly apiSecret: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly mdAccount: MdAccount;
};

export const CrossBorderCourierProvider = {
  DHL: 'DHL',
  FED_EX: 'FED_EX',
  JAPAN_POST: 'JAPAN_POST',
  UPS: 'UPS'
} as const;

export type CrossBorderCourierProvider = typeof CrossBorderCourierProvider[keyof typeof CrossBorderCourierProvider];
export const CrossBorderCourierType = {
  DHL: 'DHL',
  FED_EX: 'FED_EX',
  JAPAN_POST_EMS: 'JAPAN_POST_EMS',
  JAPAN_POST_INTERNATIONAL_PARCELS: 'JAPAN_POST_INTERNATIONAL_PARCELS',
  JAPAN_POST_SMALL_PACKING: 'JAPAN_POST_SMALL_PACKING',
  UPS: 'UPS'
} as const;

export type CrossBorderCourierType = typeof CrossBorderCourierType[keyof typeof CrossBorderCourierType];
export type CrossBorderServiceCode = DhlServiceCodeWrapper | FedExServiceCodeWrapper | JapanPostServiceCodeWrapper | UpsServiceCodeWrapper;

export const CrossBorderValidationError = {
  BILLING_ADDRESS_CANNOT_BE_EMPTY: 'BILLING_ADDRESS_CANNOT_BE_EMPTY',
  INVALID_TRANSLATED_SHIPPING_ADDRESS_CITY: 'INVALID_TRANSLATED_SHIPPING_ADDRESS_CITY',
  INVALID_TRANSLATED_SHIPPING_ADDRESS_COUNTRY_CODE: 'INVALID_TRANSLATED_SHIPPING_ADDRESS_COUNTRY_CODE',
  INVALID_TRANSLATED_SHIPPING_ADDRESS_POSTAL_CODE: 'INVALID_TRANSLATED_SHIPPING_ADDRESS_POSTAL_CODE',
  INVALID_TRANSLATED_SHIPPING_ADDRESS_PROVINCE: 'INVALID_TRANSLATED_SHIPPING_ADDRESS_PROVINCE',
  PRODUCT_NAME_MUST_BE_ALPHABET: 'PRODUCT_NAME_MUST_BE_ALPHABET',
  TRANSLATED_SHIPPING_ADDRESS_CANNOT_BE_EMPTY: 'TRANSLATED_SHIPPING_ADDRESS_CANNOT_BE_EMPTY',
  TRANSLATED_SHIPPING_NAME_CANNOT_BE_EMPTY: 'TRANSLATED_SHIPPING_NAME_CANNOT_BE_EMPTY',
  TRANSLATED_SHIPPING_PHONE_CANNOT_BE_EMPTY: 'TRANSLATED_SHIPPING_PHONE_CANNOT_BE_EMPTY'
} as const;

export type CrossBorderValidationError = typeof CrossBorderValidationError[keyof typeof CrossBorderValidationError];
export type CsvExportJobResult = {
  readonly __typename?: 'CsvExportJobResult';
  readonly jobId: Scalars['String']['output'];
  readonly state: JobState;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export const CsvImportOperation = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  UPDATE_GTIN: 'UPDATE_GTIN'
} as const;

export type CsvImportOperation = typeof CsvImportOperation[keyof typeof CsvImportOperation];
export type CsvTemplate = {
  readonly __typename?: 'CsvTemplate';
  readonly columns: ReadonlyArray<Scalars['String']['output']>;
  readonly filename: Scalars['String']['output'];
};

export const CurrencyCode = {
  AED: 'AED',
  CAD: 'CAD',
  CNY: 'CNY',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  IDR: 'IDR',
  INR: 'INR',
  JPY: 'JPY',
  KHR: 'KHR',
  KRW: 'KRW',
  KWD: 'KWD',
  MMK: 'MMK',
  MYR: 'MYR',
  PHP: 'PHP',
  SAR: 'SAR',
  SGD: 'SGD',
  THB: 'THB',
  TWD: 'TWD',
  USD: 'USD',
  VND: 'VND'
} as const;

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];
export const CurrencyProduct = {
  ANYCHAT: 'ANYCHAT',
  ANYFACTORY: 'ANYFACTORY',
  ANYLOGI: 'ANYLOGI',
  ANYMANAGER: 'ANYMANAGER',
  ANYTAG: 'ANYTAG',
  ANYX: 'ANYX'
} as const;

export type CurrencyProduct = typeof CurrencyProduct[keyof typeof CurrencyProduct];
export type CurrentLineRichMenuInput = {
  readonly storeId: Scalars['String']['input'];
};

export type Customer = {
  readonly __typename?: 'Customer';
  readonly address?: Maybe<CustomerAddress>;
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly amountOfOrders: Scalars['Int']['output'];
  readonly amountSpent: Scalars['String']['output'];
  readonly birthDate?: Maybe<Scalars['LocalDate']['output']>;
  readonly chatInfo: ReadonlyArray<ChatInfo>;
  readonly createdAt: Scalars['UnixTimestamp']['output'];
  readonly customerTags: ReadonlyArray<CustomerTag>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly gender?: Maybe<Gender>;
  readonly height?: Maybe<Scalars['Float']['output']>;
  readonly id: Scalars['String']['output'];
  readonly lastOrderedAt?: Maybe<Scalars['UnixTimestamp']['output']>;
  /** @deprecated use email */
  readonly lineEmail?: Maybe<Scalars['String']['output']>;
  /** @deprecated use province from address */
  readonly location?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  /** @deprecated use amountOfOrders */
  readonly numberOfOrder: Scalars['Int']['output'];
  /** @deprecated use email */
  readonly personalEmail?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly salesChannels: ReadonlyArray<AnyChatSaleChannelType>;
  /** @deprecated use salesChannels */
  readonly shopifyConnected: Scalars['Boolean']['output'];
  /** @deprecated use name */
  readonly shopifyCustomerFirstName?: Maybe<Scalars['String']['output']>;
  /** @deprecated use name */
  readonly shopifyCustomerLastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated use email */
  readonly shopifyEmail?: Maybe<Scalars['String']['output']>;
  readonly size?: Maybe<Scalars['String']['output']>;
  readonly weight?: Maybe<Scalars['Float']['output']>;
};

export type CustomerActivity = {
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export const CustomerActivityType = {
  AddCustomerTagsActivity: 'AddCustomerTagsActivity',
  CustomerCreatedActivity: 'CustomerCreatedActivity',
  InquiryReceivedActivity: 'InquiryReceivedActivity',
  InquiryResolvedActivity: 'InquiryResolvedActivity',
  InquiryUnresolvedActivity: 'InquiryUnresolvedActivity',
  NotedActivity: 'NotedActivity',
  OptInAcceptedActivity: 'OptInAcceptedActivity',
  OptInRequestSentActivity: 'OptInRequestSentActivity',
  OptedOutActivity: 'OptedOutActivity',
  OrderedActivity: 'OrderedActivity',
  ProfileUpdatedActivity: 'ProfileUpdatedActivity',
  RemoveCustomerTagsActivity: 'RemoveCustomerTagsActivity'
} as const;

export type CustomerActivityType = typeof CustomerActivityType[keyof typeof CustomerActivityType];
export type CustomerAddress = {
  readonly __typename?: 'CustomerAddress';
  readonly address1?: Maybe<Scalars['String']['output']>;
  readonly address2?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country: Scalars['String']['output'];
  readonly province?: Maybe<Scalars['String']['output']>;
  readonly zip?: Maybe<Scalars['String']['output']>;
};

export const CustomerClassificationType = {
  BUSINESS: 'BUSINESS'
} as const;

export type CustomerClassificationType = typeof CustomerClassificationType[keyof typeof CustomerClassificationType];
export type CustomerCreatedActivity = CustomerActivity & {
  readonly __typename?: 'CustomerCreatedActivity';
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type CustomerIdInput = {
  readonly customerId: Scalars['String']['input'];
};

export type CustomerName = {
  readonly firstName: Scalars['String']['input'];
  readonly surname: Scalars['String']['input'];
};

export type CustomerNoteAddRequest = {
  readonly customerId: Scalars['String']['input'];
  readonly note: Scalars['String']['input'];
};

export type CustomerOrder = {
  readonly __typename?: 'CustomerOrder';
  readonly anyxOrderId?: Maybe<Scalars['String']['output']>;
  /** @deprecated use status */
  readonly deliveryStatus: AnyChatDeliveryStatus;
  readonly items: ReadonlyArray<OrderItem>;
  readonly orderId: Scalars['String']['output'];
  readonly orderUrl?: Maybe<Scalars['String']['output']>;
  readonly orderedAt: Scalars['UnixTimestamp']['output'];
  /** @deprecated use status */
  readonly paymentStatus: AnyChatPaymentStatus;
  readonly saleChannelType: AnyChatSaleChannelType;
  readonly status: DeliveryStatus;
};

export type CustomerProfile = {
  readonly __typename?: 'CustomerProfile';
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly birthday?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['UnixTimestamp']['output'];
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly gender?: Maybe<Gender>;
  readonly height?: Maybe<Scalars['Float']['output']>;
  readonly id: Scalars['String']['output'];
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly lineEmail?: Maybe<Scalars['String']['output']>;
  readonly personalEmail?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly provinceName?: Maybe<Scalars['String']['output']>;
  readonly shopifyEmail?: Maybe<Scalars['String']['output']>;
  readonly size?: Maybe<Scalars['String']['output']>;
  readonly weight?: Maybe<Scalars['Float']['output']>;
};

export type CustomerProfileUpdateRequest = {
  readonly birthday?: InputMaybe<Scalars['String']['input']>;
  readonly customerId: Scalars['String']['input'];
  readonly gender?: InputMaybe<Gender>;
  readonly height?: InputMaybe<Scalars['Float']['input']>;
  readonly personalEmail?: InputMaybe<Scalars['String']['input']>;
  readonly size?: InputMaybe<Scalars['String']['input']>;
  readonly weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CustomerTag = {
  readonly __typename?: 'CustomerTag';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly taggedCount: Scalars['Int']['output'];
};

export type CustomerTagDeleteFailure = {
  readonly __typename?: 'CustomerTagDeleteFailure';
  readonly customerTagInUses: ReadonlyArray<CustomerTag>;
};

export type CustomerTagDeleteResponse = CustomerTagDeleteFailure | CustomerTagDeleteSuccess;

export type CustomerTagDeleteSuccess = {
  readonly __typename?: 'CustomerTagDeleteSuccess';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerTagName = {
  readonly __typename?: 'CustomerTagName';
  readonly name: Scalars['String']['output'];
};

export type CustomerTagNames = {
  readonly __typename?: 'CustomerTagNames';
  readonly names: ReadonlyArray<Scalars['String']['output']>;
};

export type CustomerTagNamesInput = {
  readonly names: ReadonlyArray<Scalars['String']['input']>;
};

export const CustomerTagOrdering = {
  ByAlphabetical: 'ByAlphabetical',
  ByDateCreated: 'ByDateCreated',
  ByFrequentlyUsed: 'ByFrequentlyUsed'
} as const;

export type CustomerTagOrdering = typeof CustomerTagOrdering[keyof typeof CustomerTagOrdering];
export type CustomerTags = {
  readonly __typename?: 'CustomerTags';
  readonly customerTags: ReadonlyArray<CustomerTag>;
  readonly total: Scalars['Int']['output'];
};

export type Customers = {
  readonly __typename?: 'Customers';
  readonly customers: ReadonlyArray<Customer>;
  readonly total: Scalars['Int']['output'];
};

export const CustomersViewSortField = {
  AmountOfOrders: 'AmountOfOrders',
  AmountSpent: 'AmountSpent',
  LastOrderDate: 'LastOrderDate'
} as const;

export type CustomersViewSortField = typeof CustomersViewSortField[keyof typeof CustomersViewSortField];
export type CustomersViewSortInput = {
  readonly descending: Scalars['Boolean']['input'];
  readonly field: CustomersViewSortField;
};

export type DhlAccountDetails = {
  readonly __typename?: 'DHLAccountDetails';
  readonly dhlAccountNumber: Scalars['String']['output'];
  readonly dhlApiKey: Scalars['String']['output'];
  readonly dhlApiSecret: Scalars['String']['output'];
  readonly dhlCorporateNumber?: Maybe<Scalars['String']['output']>;
  readonly dhlCorporateNumberInJP: Scalars['Boolean']['output'];
  readonly dhlShipperAddressId: Scalars['ID']['output'];
  readonly dhlSignatureImageURL?: Maybe<Scalars['String']['output']>;
  readonly dhlSupportPackageTypes?: Maybe<ReadonlyArray<DhlPackageType>>;
};

export type DhlCreateCourierInput = {
  readonly accountId: Scalars['String']['input'];
  readonly accountNumber: Scalars['String']['input'];
  readonly apiKey: Scalars['String']['input'];
  readonly apiSecret: Scalars['String']['input'];
  readonly corporateNumber?: InputMaybe<Scalars['String']['input']>;
  readonly corporateNumberInJP: Scalars['Boolean']['input'];
  readonly shipperAddressId: Scalars['ID']['input'];
  readonly signatureImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly supportPackageTypes?: InputMaybe<ReadonlyArray<DhlPackageType>>;
};

export const DhlPackageType = {
  DHL_BOX_2: 'DHL_BOX_2',
  DHL_BOX_3: 'DHL_BOX_3',
  DHL_BOX_4: 'DHL_BOX_4',
  DHL_BOX_5: 'DHL_BOX_5',
  DHL_BOX_6: 'DHL_BOX_6',
  DHL_BOX_7: 'DHL_BOX_7',
  DHL_BOX_8: 'DHL_BOX_8',
  DHL_CARD_ENVELOPE: 'DHL_CARD_ENVELOPE',
  DHL_EXPRESS_ENVELOPE: 'DHL_EXPRESS_ENVELOPE',
  DHL_TUBE_LARGE: 'DHL_TUBE_LARGE',
  DHL_TUBE_SMALL: 'DHL_TUBE_SMALL'
} as const;

export type DhlPackageType = typeof DhlPackageType[keyof typeof DhlPackageType];
export type DhlPackageWrapper = {
  readonly __typename?: 'DHLPackageWrapper';
  readonly DHLPackageType?: Maybe<DhlPackageType>;
};

export const DhlPickupServiceCode = {
  B2C: 'B2C',
  BBX: 'BBX',
  BTC: 'BTC',
  CMX: 'CMX',
  DES: 'DES',
  DOK: 'DOK',
  DOL: 'DOL',
  DOM: 'DOM',
  DOT: 'DOT',
  DOX: 'DOX',
  ECX: 'ECX',
  EPA: 'EPA',
  EPP: 'EPP',
  ESI: 'ESI',
  ESU: 'ESU',
  FRT: 'FRT',
  GMB: 'GMB',
  JBX: 'JBX',
  NFO: 'NFO',
  SDX: 'SDX',
  SPL: 'SPL',
  TDE: 'TDE',
  TDK: 'TDK',
  TDL: 'TDL',
  TDM: 'TDM',
  TDT: 'TDT',
  TDY: 'TDY',
  WMX: 'WMX',
  WPX: 'WPX',
  XED: 'XED',
  XEP: 'XEP',
  XPD: 'XPD'
} as const;

export type DhlPickupServiceCode = typeof DhlPickupServiceCode[keyof typeof DhlPickupServiceCode];
export type DhlPickupServiceCodeWrapper = {
  readonly __typename?: 'DHLPickupServiceCodeWrapper';
  readonly dhlPickupServiceCode?: Maybe<DhlPickupServiceCode>;
};

export const DhlServiceCode = {
  B2C: 'B2C',
  BBX: 'BBX',
  BTC: 'BTC',
  CMX: 'CMX',
  DES: 'DES',
  DOK: 'DOK',
  DOL: 'DOL',
  DOM: 'DOM',
  DOT: 'DOT',
  DOX: 'DOX',
  ECX: 'ECX',
  EPA: 'EPA',
  EPP: 'EPP',
  ESI: 'ESI',
  ESU: 'ESU',
  FRT: 'FRT',
  GMB: 'GMB',
  JBX: 'JBX',
  NFO: 'NFO',
  SDX: 'SDX',
  SPL: 'SPL',
  TDE: 'TDE',
  TDK: 'TDK',
  TDL: 'TDL',
  TDM: 'TDM',
  TDT: 'TDT',
  TDY: 'TDY',
  WMX: 'WMX',
  WPX: 'WPX',
  XED: 'XED',
  XEP: 'XEP',
  XPD: 'XPD'
} as const;

export type DhlServiceCode = typeof DhlServiceCode[keyof typeof DhlServiceCode];
export type DhlServiceCodeWrapper = {
  readonly __typename?: 'DHLServiceCodeWrapper';
  readonly dhlServiceCode?: Maybe<DhlServiceCode>;
};

export type DhlUpdateCourierInput = {
  readonly accountNumber: Scalars['String']['input'];
  readonly apiKey: Scalars['String']['input'];
  readonly apiSecret: Scalars['String']['input'];
  readonly corporateNumber?: InputMaybe<Scalars['String']['input']>;
  readonly corporateNumberInJP: Scalars['Boolean']['input'];
  readonly courierId: Scalars['String']['input'];
  readonly shipperAddressId: Scalars['ID']['input'];
  readonly signatureImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly supportPackageTypes?: InputMaybe<ReadonlyArray<DhlPackageType>>;
};

export type DatasourceFilterInput = {
  readonly masterDataStoreId?: InputMaybe<Scalars['Long']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<DatasourceType>;
};

export type DatasourcePayload = {
  readonly __typename?: 'DatasourcePayload';
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly fivetranSchemaName?: Maybe<Scalars['String']['output']>;
  readonly fivetranTableName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Long']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly masterDataUserId?: Maybe<Scalars['Long']['output']>;
  readonly setting?: Maybe<Scalars['Map']['output']>;
  readonly status: DatasourceStatus;
  readonly statusDetail?: Maybe<Scalars['Map']['output']>;
  readonly store: MdStore;
  readonly tokenId?: Maybe<Scalars['Long']['output']>;
  readonly type: DatasourceType;
  readonly updatedAt: Scalars['ZonedDatetime']['output'];
  readonly user?: Maybe<MdUser>;
};

export type DatasourcePayloadList = {
  readonly __typename?: 'DatasourcePayloadList';
  readonly items: ReadonlyArray<DatasourcePayload>;
  readonly meta: ReportListMeta;
};

export type DatasourceSettingInput = {
  readonly account?: InputMaybe<Scalars['String']['input']>;
  readonly accountName?: InputMaybe<Scalars['String']['input']>;
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly customerName?: InputMaybe<Scalars['String']['input']>;
  readonly profile?: InputMaybe<Scalars['String']['input']>;
  readonly profileName?: InputMaybe<Scalars['String']['input']>;
  readonly property?: InputMaybe<Scalars['String']['input']>;
  readonly propertyName?: InputMaybe<Scalars['String']['input']>;
  readonly view?: InputMaybe<Scalars['String']['input']>;
  readonly viewName?: InputMaybe<Scalars['String']['input']>;
};

export const DatasourceSortByField = {
  CREATED_AT: 'CREATED_AT',
  ID: 'ID',
  TYPE: 'TYPE',
  UPDATED_AT: 'UPDATED_AT'
} as const;

export type DatasourceSortByField = typeof DatasourceSortByField[keyof typeof DatasourceSortByField];
export type DatasourceSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: DatasourceSortByField;
};

export const DatasourceStatus = {
  ACTIVE: 'ACTIVE',
  FAILED: 'FAILED',
  INITIALIZING: 'INITIALIZING'
} as const;

export type DatasourceStatus = typeof DatasourceStatus[keyof typeof DatasourceStatus];
export type DatasourceTokenPayload = {
  readonly __typename?: 'DatasourceTokenPayload';
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Long']['output'];
  readonly type: DatasourceType;
};

export const DatasourceType = {
  FacebookAds: 'FacebookAds',
  GoogleAds: 'GoogleAds',
  GoogleAnalytics4: 'GoogleAnalytics4',
  TikTokAds: 'TikTokAds',
  YahooDisplayAds: 'YahooDisplayAds',
  YahooSearchAds: 'YahooSearchAds'
} as const;

export type DatasourceType = typeof DatasourceType[keyof typeof DatasourceType];
export type DateRange = {
  readonly __typename?: 'DateRange';
  readonly from: Scalars['OffsetDateTime']['output'];
  readonly to: Scalars['OffsetDateTime']['output'];
};

export type DateRangeInput = {
  readonly from: Scalars['OffsetDateTime']['input'];
  readonly to: Scalars['OffsetDateTime']['input'];
};

export type DateRangeResponse = {
  readonly __typename?: 'DateRangeResponse';
  readonly from?: Maybe<Scalars['Date']['output']>;
  readonly to?: Maybe<Scalars['Date']['output']>;
};

export type DdiHomeReportChartPayload = {
  readonly __typename?: 'DdiHomeReportChartPayload';
  readonly currency: ReportSupportedCurrency;
  readonly grossSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly netSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly orders: ReadonlyArray<Scalars['Map']['output']>;
};

export type DdiHomeReportInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly saleChannelTypes: ReadonlyArray<SaleChannelType>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type DdiHomeReportPieChartPayload = {
  readonly __typename?: 'DdiHomeReportPieChartPayload';
  readonly currency: ReportSupportedCurrency;
  readonly grossSales: ReadonlyArray<DdiHomeReportPieChartSectionPayload>;
  readonly netSales: ReadonlyArray<DdiHomeReportPieChartSectionPayload>;
  readonly orders: ReadonlyArray<DdiHomeReportPieChartSectionPayload>;
};

export type DdiHomeReportPieChartSectionPayload = {
  readonly __typename?: 'DdiHomeReportPieChartSectionPayload';
  readonly saleChannelType: SaleChannelType;
  readonly value: Scalars['BigDecimal']['output'];
};

export type DdiHomeReportSummaryPayload = {
  readonly __typename?: 'DdiHomeReportSummaryPayload';
  readonly currency: ReportSupportedCurrency;
  readonly grossSales: NumericValueWithDiffPayload;
  readonly netSales: NumericValueWithDiffPayload;
  readonly orders: NumericValueWithDiffPayload;
};

export type DdiHomeReportTopProductPayload = {
  readonly __typename?: 'DdiHomeReportTopProductPayload';
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly productId?: Maybe<Scalars['Long']['output']>;
  readonly productImageUrl?: Maybe<Scalars['String']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
};

export type DdiHomeReportTopProductsPayload = {
  readonly __typename?: 'DdiHomeReportTopProductsPayload';
  readonly currency: ReportSupportedCurrency;
  readonly products: ReadonlyArray<DdiHomeReportTopProductPayload>;
};

export const DdiInventoryDailyReportSortBy = {
  AVAILABLE_TO_PURCHASE: 'AVAILABLE_TO_PURCHASE',
  DATE: 'DATE',
  DAYS_OF_INVENTORY: 'DAYS_OF_INVENTORY',
  STOCK_ON_HAND: 'STOCK_ON_HAND',
  STOCK_ON_HAND_VALUE: 'STOCK_ON_HAND_VALUE'
} as const;

export type DdiInventoryDailyReportSortBy = typeof DdiInventoryDailyReportSortBy[keyof typeof DdiInventoryDailyReportSortBy];
export const DdiInventoryProductReportSortBy = {
  DAYS_OF_INVENTORY: 'DAYS_OF_INVENTORY',
  PRODUCT_CODE: 'PRODUCT_CODE',
  PRODUCT_NAME: 'PRODUCT_NAME',
  STOCK_ON_HAND: 'STOCK_ON_HAND',
  STOCK_ON_HAND_VALUE: 'STOCK_ON_HAND_VALUE'
} as const;

export type DdiInventoryProductReportSortBy = typeof DdiInventoryProductReportSortBy[keyof typeof DdiInventoryProductReportSortBy];
export type DdiInventoryStockValueDailyReportExportInput = {
  readonly endDate: Scalars['LocalDate']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly sortBy?: InputMaybe<DdiInventoryDailyReportSortBy>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sortDirection?: InputMaybe<SortDirection>;
  readonly startDate: Scalars['LocalDate']['input'];
  readonly warehouse: DdiWarehouse;
};

export type DdiInventoryStockValueDailyReportInput = {
  readonly endDate: Scalars['LocalDate']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly sortBy?: InputMaybe<DdiInventoryDailyReportSortBy>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['LocalDate']['input'];
  readonly warehouse: DdiWarehouse;
};

export type DdiInventoryStockValueDailyReportPayload = {
  readonly __typename?: 'DdiInventoryStockValueDailyReportPayload';
  readonly availableToPurchase: Scalars['BigDecimal']['output'];
  readonly date: Scalars['LocalDate']['output'];
  readonly daysOfInventory: Scalars['Long']['output'];
  readonly stockOnHand: Scalars['Long']['output'];
  readonly stockOnHandValue: Scalars['BigDecimal']['output'];
};

export type DdiInventoryStockValueDailyReportPayloadList = {
  readonly __typename?: 'DdiInventoryStockValueDailyReportPayloadList';
  readonly days: ReadonlyArray<DdiInventoryStockValueDailyReportPayload>;
  readonly meta: ReportListMeta;
};

export type DdiInventoryStockValueProductReportExportInput = {
  readonly date: Scalars['LocalDate']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productKeyword?: InputMaybe<Scalars['String']['input']>;
  readonly sortBy?: InputMaybe<DdiInventoryProductReportSortBy>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sortDirection?: InputMaybe<SortDirection>;
  readonly warehouse: DdiWarehouse;
};

export type DdiInventoryStockValueProductReportInput = {
  readonly date: Scalars['LocalDate']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly productKeyword?: InputMaybe<Scalars['String']['input']>;
  readonly sortBy?: InputMaybe<DdiInventoryProductReportSortBy>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly warehouse: DdiWarehouse;
};

export type DdiInventoryStockValueProductReportPayload = {
  readonly __typename?: 'DdiInventoryStockValueProductReportPayload';
  readonly daysOfInventory: Scalars['Long']['output'];
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productId: Scalars['Long']['output'];
  readonly productImageUrl?: Maybe<Scalars['String']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly productVariantId: Scalars['Long']['output'];
  readonly productVariantName?: Maybe<Scalars['String']['output']>;
  readonly stockOnHand: Scalars['Long']['output'];
  readonly stockOnHandValue: Scalars['BigDecimal']['output'];
};

export type DdiInventoryStockValueProductReportPayloadList = {
  readonly __typename?: 'DdiInventoryStockValueProductReportPayloadList';
  readonly meta: ReportListMeta;
  readonly products: ReadonlyArray<DdiInventoryStockValueProductReportPayload>;
};

export type DdiInventoryTrackerReportInput = {
  readonly date: Scalars['LocalDate']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
};

export type DdiInventoryTrackerReportPayload = {
  readonly __typename?: 'DdiInventoryTrackerReportPayload';
  readonly warehouseData: ReadonlyArray<DdiInventoryTrackerReportWarehouseDataPayload>;
};

export type DdiInventoryTrackerReportSegmentDataPayload = {
  readonly __typename?: 'DdiInventoryTrackerReportSegmentDataPayload';
  readonly availableToPurchase: Scalars['BigDecimal']['output'];
  readonly daysOfInventory: Scalars['Long']['output'];
  readonly segment: DdiProductSegment;
  readonly stockOnHand: Scalars['Long']['output'];
  readonly stockOnHandValue: Scalars['BigDecimal']['output'];
};

export type DdiInventoryTrackerReportStore = {
  readonly __typename?: 'DdiInventoryTrackerReportStore';
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly storeName: Scalars['String']['output'];
};

export type DdiInventoryTrackerReportStores = {
  readonly __typename?: 'DdiInventoryTrackerReportStores';
  readonly stores: ReadonlyArray<DdiInventoryTrackerReportStore>;
};

export type DdiInventoryTrackerReportWarehouseDataPayload = {
  readonly __typename?: 'DdiInventoryTrackerReportWarehouseDataPayload';
  readonly segmentData: ReadonlyArray<DdiInventoryTrackerReportSegmentDataPayload>;
  readonly warehouse: DdiWarehouse;
};

export type DdiPersonInChargeAssignedStorePayload = {
  readonly __typename?: 'DdiPersonInChargeAssignedStorePayload';
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly storeName: Scalars['String']['output'];
};

export type DdiPersonInChargePayload = {
  readonly __typename?: 'DdiPersonInChargePayload';
  readonly assignedStores: ReadonlyArray<DdiPersonInChargeAssignedStorePayload>;
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

export type DdiPersonInChargePayloadList = {
  readonly __typename?: 'DdiPersonInChargePayloadList';
  readonly data: ReadonlyArray<DdiPersonInChargePayload>;
};

export type DdiProductReportData = {
  readonly __typename?: 'DdiProductReportData';
  readonly avgSalesValue?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discount?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate?: Maybe<Scalars['BigDecimal']['output']>;
  readonly grossSales?: Maybe<Scalars['BigDecimal']['output']>;
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly netSales?: Maybe<Scalars['BigDecimal']['output']>;
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productId?: Maybe<Scalars['Long']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly productVariantId?: Maybe<Scalars['Long']['output']>;
  readonly productVariantStatus?: Maybe<ProductVariantStatus>;
  readonly subsidy?: Maybe<Scalars['BigDecimal']['output']>;
  readonly unitSold?: Maybe<Scalars['Int']['output']>;
};

export type DdiProductReportExportFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly warehouse?: InputMaybe<ReadonlyArray<DdiWarehouse>>;
};

export type DdiProductReportPaginatedFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly sortBy?: InputMaybe<DdiProductReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly warehouse?: InputMaybe<ReadonlyArray<DdiWarehouse>>;
};

export type DdiProductReportPayload = {
  readonly __typename?: 'DdiProductReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly list: ReadonlyArray<DdiProductReportData>;
  readonly totalItems: Scalars['Int']['output'];
};

export const DdiProductReportSortByField = {
  AVG_ORDER_VALUES: 'AVG_ORDER_VALUES',
  DISCOUNTS: 'DISCOUNTS',
  DISCOUNTS_RATE: 'DISCOUNTS_RATE',
  GROSS_SALES: 'GROSS_SALES',
  NET_SALES: 'NET_SALES',
  PRODUCT: 'PRODUCT',
  PRODUCT_CODE: 'PRODUCT_CODE',
  SUBSIDY: 'SUBSIDY',
  UNIT_SOLD: 'UNIT_SOLD'
} as const;

export type DdiProductReportSortByField = typeof DdiProductReportSortByField[keyof typeof DdiProductReportSortByField];
export type DdiProductReportSummary = {
  readonly __typename?: 'DdiProductReportSummary';
  readonly grossSales: ReportCompareData;
  readonly netSales: ReportCompareData;
  readonly unitSold: ReportCompareData;
};

export type DdiProductReportSummaryFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly warehouse?: InputMaybe<ReadonlyArray<DdiWarehouse>>;
};

export type DdiProductReportSummaryPayload = {
  readonly __typename?: 'DdiProductReportSummaryPayload';
  readonly comparisonSummary: DdiProductReportSummary;
  readonly currency: ReportSupportedCurrency;
  readonly tableSummary: DdiProductReportTotalData;
};

export type DdiProductReportTotalData = {
  readonly __typename?: 'DdiProductReportTotalData';
  readonly avgSalesValue: Scalars['BigDecimal']['output'];
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly subsidy: Scalars['BigDecimal']['output'];
  readonly unitSold: Scalars['BigDecimal']['output'];
};

export const DdiProductSegment = {
  ESSENTIAL: 'ESSENTIAL',
  NORMAL: 'NORMAL',
  SUPER_ESSENTIAL: 'SUPER_ESSENTIAL',
  SUPER_LOW: 'SUPER_LOW',
  TOTAL: 'TOTAL',
  UNSEGMENTED: 'UNSEGMENTED'
} as const;

export type DdiProductSegment = typeof DdiProductSegment[keyof typeof DdiProductSegment];
export type DdiProductStatusInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productVariantIds: ReadonlyArray<Scalars['Long']['input']>;
};

export type DdiProductStatusPayload = {
  readonly __typename?: 'DdiProductStatusPayload';
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly isListed: Scalars['Boolean']['output'];
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly productVariantId: Scalars['Long']['output'];
  readonly productVariantName?: Maybe<Scalars['String']['output']>;
  readonly warehouseData: ReadonlyArray<DdiProductWarehouseStatusPayload>;
};

export type DdiProductStatusPayloadList = {
  readonly __typename?: 'DdiProductStatusPayloadList';
  readonly products: ReadonlyArray<DdiProductStatusPayload>;
};

export type DdiProductWarehouseStatusPayload = {
  readonly __typename?: 'DdiProductWarehouseStatusPayload';
  readonly isActive: Scalars['Boolean']['output'];
  readonly segment: DdiProductSegment;
  readonly warehouse: DdiWarehouse;
};

export type DdiReportCompareData = {
  readonly __typename?: 'DdiReportCompareData';
  readonly current?: Maybe<Scalars['BigDecimal']['output']>;
  readonly diff?: Maybe<Scalars['BigDecimal']['output']>;
  readonly isPercentage: Scalars['Boolean']['output'];
  readonly percentage?: Maybe<Scalars['BigDecimal']['output']>;
};

export type DdiReportSummaryComparisonData = {
  readonly __typename?: 'DdiReportSummaryComparisonData';
  readonly grossSales: DdiReportCompareData;
  readonly netSales: DdiReportCompareData;
  readonly orders: DdiReportCompareData;
};

export type DdiReportSummaryPayload = {
  readonly __typename?: 'DdiReportSummaryPayload';
  readonly comparisonSummary: DdiReportSummaryComparisonData;
  readonly currency: ReportSupportedCurrency;
  readonly tableSummary: DdiSaleChannelReportSummaryData;
};

export type DdiSaleChannelReportChartData = {
  readonly __typename?: 'DdiSaleChannelReportChartData';
  readonly cancelledOrders: Scalars['Long']['output'];
  readonly channel?: Maybe<SaleChannelType>;
  readonly day?: Maybe<Scalars['LocalDate']['output']>;
  readonly dow?: Maybe<Scalars['Int']['output']>;
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly marketplaceCalendarEvents?: Maybe<ReadonlyArray<MarketplaceCalendarEventPayload>>;
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly yearMonth?: Maybe<Scalars['YearMonth']['output']>;
  readonly yearWeek?: Maybe<Scalars['LocalDate']['output']>;
};

export type DdiSaleChannelReportChartPayload = {
  readonly __typename?: 'DdiSaleChannelReportChartPayload';
  readonly channels: ReadonlyArray<SaleChannelType>;
  readonly currency: ReportSupportedCurrency;
  readonly list: ReadonlyArray<DdiSaleChannelReportChartData>;
  readonly totalItems: Scalars['Int']['output'];
};

export type DdiSaleChannelReportExportFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly warehouse?: InputMaybe<ReadonlyArray<DdiWarehouse>>;
};

export type DdiSaleChannelReportPayload = {
  readonly __typename?: 'DdiSaleChannelReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly list: ReadonlyArray<DdiSaleChannelReportTableData>;
  readonly totalItems: Scalars['Int']['output'];
};

export const DdiSaleChannelReportSortByField = {
  AVG_ORDER_VALUES: 'AVG_ORDER_VALUES',
  CVR: 'CVR',
  DISCOUNTS: 'DISCOUNTS',
  DISCOUNTS_RATE: 'DISCOUNTS_RATE',
  GROSS_SALES: 'GROSS_SALES',
  METRIC: 'METRIC',
  NET_SALES: 'NET_SALES',
  ORDERS: 'ORDERS',
  SESSIONS: 'SESSIONS',
  SUBSIDY: 'SUBSIDY'
} as const;

export type DdiSaleChannelReportSortByField = typeof DdiSaleChannelReportSortByField[keyof typeof DdiSaleChannelReportSortByField];
export type DdiSaleChannelReportSummaryData = {
  readonly __typename?: 'DdiSaleChannelReportSummaryData';
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly cancelledOrders: Scalars['Long']['output'];
  readonly cvr?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly sessions?: Maybe<Scalars['Long']['output']>;
  readonly subsidy: Scalars['BigDecimal']['output'];
};

export type DdiSaleChannelReportSummaryFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly warehouse?: InputMaybe<ReadonlyArray<DdiWarehouse>>;
};

export type DdiSaleChannelReportTableData = {
  readonly __typename?: 'DdiSaleChannelReportTableData';
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly cancelledOrders: Scalars['Long']['output'];
  readonly cvr?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly metric: Scalars['String']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly sessions?: Maybe<Scalars['Long']['output']>;
  readonly subsidy: Scalars['BigDecimal']['output'];
};

export type DdiSaleChannelReportTableFilterInput = {
  readonly dimensions: ReportDimensions;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly sortBy?: InputMaybe<DdiSaleChannelReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly warehouse?: InputMaybe<ReadonlyArray<DdiWarehouse>>;
};

export const DdiWarehouse = {
  BANDUNG: 'BANDUNG',
  JAKARTA: 'JAKARTA',
  MEDAN: 'MEDAN',
  NON_GTL: 'NON_GTL',
  SURABAYA: 'SURABAYA'
} as const;

export type DdiWarehouse = typeof DdiWarehouse[keyof typeof DdiWarehouse];
export type DecodeDraftUnitMessageContentRequest = {
  readonly serializedContent: Scalars['String']['input'];
};

export type DelayAction = {
  readonly __typename?: 'DelayAction';
  readonly time: Scalars['Int']['output'];
  readonly unit: DelayUnit;
};

export type DelayActionInput = {
  readonly time: Scalars['Int']['input'];
  readonly unit: DelayUnit;
};

export const DelayUnit = {
  DAYS: 'DAYS',
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
  SECONDS: 'SECONDS',
  WEEKS: 'WEEKS'
} as const;

export type DelayUnit = typeof DelayUnit[keyof typeof DelayUnit];
export type DeleteCustomerTagsRequest = {
  readonly customerTagIds: ReadonlyArray<Scalars['String']['input']>;
};

export type DeleteManualCostInput = {
  readonly id: Scalars['Long']['input'];
};

export type DeleteMarketplaceCalendarEventInput = {
  readonly id: Scalars['Long']['input'];
};

export const DeliveryStatus = {
  ALLOCATING: 'ALLOCATING',
  CANCELLED: 'CANCELLED',
  PAYING: 'PAYING',
  PENDING: 'PENDING',
  PREPARING: 'PREPARING',
  SHIPPED: 'SHIPPED',
  WORKING: 'WORKING'
} as const;

export type DeliveryStatus = typeof DeliveryStatus[keyof typeof DeliveryStatus];
export const DeliveryTimeframe = {
  MORNING: 'MORNING',
  NOT_SPECIFIED: 'NOT_SPECIFIED',
  _12_14: '_12_14',
  _14_16: '_14_16',
  _16_18: '_16_18',
  _18_20: '_18_20',
  _18_21: '_18_21',
  _19_21: '_19_21',
  _20_21: '_20_21'
} as const;

export type DeliveryTimeframe = typeof DeliveryTimeframe[keyof typeof DeliveryTimeframe];
export type DeliveryTiming = Immediately | Schedule;

export type DeliveryTimingItemInput = {
  readonly schedule?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly type: DeliveryTimingType;
};

export const DeliveryTimingType = {
  IMMEDIATELY_SEND: 'IMMEDIATELY_SEND',
  SCHEDULE_SEND: 'SCHEDULE_SEND'
} as const;

export type DeliveryTimingType = typeof DeliveryTimingType[keyof typeof DeliveryTimingType];
export const DesiredDeliveryTimeframe = {
  MORNING: 'MORNING',
  NOT_SPECIFIED: 'NOT_SPECIFIED',
  _12_14: '_12_14',
  _14_16: '_14_16',
  _16_18: '_16_18',
  _18_20: '_18_20',
  _18_21: '_18_21',
  _19_21: '_19_21',
  _20_21: '_20_21'
} as const;

export type DesiredDeliveryTimeframe = typeof DesiredDeliveryTimeframe[keyof typeof DesiredDeliveryTimeframe];
export type Document = {
  readonly __typename?: 'Document';
  readonly filepath: Scalars['String']['output'];
};

export type DocumentPayload = {
  readonly __typename?: 'DocumentPayload';
  readonly fileName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Long']['output'];
  readonly status: DocumentStatus;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export const DocumentStatus = {
  FAIL: 'FAIL',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS'
} as const;

export type DocumentStatus = typeof DocumentStatus[keyof typeof DocumentStatus];
export type DraftUnitMessage = {
  readonly __typename?: 'DraftUnitMessage';
  readonly content: ReadonlyArray<LineMessage>;
  readonly name: Scalars['String']['output'];
};

export type Emoji = {
  readonly __typename?: 'Emoji';
  readonly code: Scalars['String']['output'];
};

export type EmojiInput = {
  readonly code: Scalars['String']['input'];
};

export type EmptySaleChannelData = {
  readonly __typename?: 'EmptySaleChannelData';
  readonly dummyData: Scalars['Boolean']['output'];
};

export type EstimateRecipient = {
  readonly __typename?: 'EstimateRecipient';
  readonly estimateCount: Scalars['Int']['output'];
};

export const FacebookAdAccountDisabledReasonResponse = {
  ADS_AFC_REVIEW: 'ADS_AFC_REVIEW',
  ADS_INTEGRITY_POLICY: 'ADS_INTEGRITY_POLICY',
  ADS_IP_REVIEW: 'ADS_IP_REVIEW',
  AOAB_DESHARE_LEGAL_ENTITY: 'AOAB_DESHARE_LEGAL_ENTITY',
  BUSINESS_INTEGRITY_RAR: 'BUSINESS_INTEGRITY_RAR',
  BUSINESS_MANAGER_INTEGRITY_POLICY: 'BUSINESS_MANAGER_INTEGRITY_POLICY',
  COMPROMISED_AD_ACCOUNT: 'COMPROMISED_AD_ACCOUNT',
  CTX_THREAD_REVIEW: 'CTX_THREAD_REVIEW',
  GRAY_ACCOUNT_SHUT_DOWN: 'GRAY_ACCOUNT_SHUT_DOWN',
  MISREPRESENTED_AD_ACCOUNT: 'MISREPRESENTED_AD_ACCOUNT',
  NONE: 'NONE',
  PERMANENT_CLOSE: 'PERMANENT_CLOSE',
  RISK_PAYMENT: 'RISK_PAYMENT',
  UMBRELLA_AD_ACCOUNT: 'UMBRELLA_AD_ACCOUNT',
  UNUSED_ACCOUNT: 'UNUSED_ACCOUNT',
  UNUSED_RESELLER_ACCOUNT: 'UNUSED_RESELLER_ACCOUNT'
} as const;

export type FacebookAdAccountDisabledReasonResponse = typeof FacebookAdAccountDisabledReasonResponse[keyof typeof FacebookAdAccountDisabledReasonResponse];
export const FacebookAdAccountStatusResponse = {
  ACTIVE: 'ACTIVE',
  ANY_ACTIVE: 'ANY_ACTIVE',
  ANY_CLOSED: 'ANY_CLOSED',
  CLOSED: 'CLOSED',
  DISABLED: 'DISABLED',
  IN_GRACE_PERIOD: 'IN_GRACE_PERIOD',
  PENDING_CLOSURE: 'PENDING_CLOSURE',
  PENDING_RISK_REVIEW: 'PENDING_RISK_REVIEW',
  PENDING_SETTLEMENT: 'PENDING_SETTLEMENT',
  UNSETTLED: 'UNSETTLED'
} as const;

export type FacebookAdAccountStatusResponse = typeof FacebookAdAccountStatusResponse[keyof typeof FacebookAdAccountStatusResponse];
export type FacebookAdsAdAccount = {
  readonly __typename?: 'FacebookAdsAdAccount';
  readonly accountStatus?: Maybe<FacebookAdAccountStatusResponse>;
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly amountSpent?: Maybe<Scalars['String']['output']>;
  readonly balance?: Maybe<Scalars['String']['output']>;
  readonly businessCity?: Maybe<Scalars['String']['output']>;
  readonly businessCountryCode?: Maybe<Scalars['String']['output']>;
  readonly businessName?: Maybe<Scalars['String']['output']>;
  readonly businessState?: Maybe<Scalars['String']['output']>;
  readonly createdTime?: Maybe<Scalars['ZonedDatetime']['output']>;
  readonly currency?: Maybe<Scalars['String']['output']>;
  readonly disabledReason?: Maybe<FacebookAdAccountDisabledReasonResponse>;
  readonly id: Scalars['String']['output'];
  readonly mediaAgency?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly owner?: Maybe<Scalars['String']['output']>;
  readonly partner?: Maybe<Scalars['String']['output']>;
  readonly timezoneName?: Maybe<Scalars['String']['output']>;
  readonly timezoneOffsetHoursUtc?: Maybe<Scalars['Float']['output']>;
};

export type FacebookAdsAdAccountPayloadList = {
  readonly __typename?: 'FacebookAdsAdAccountPayloadList';
  readonly items: ReadonlyArray<FacebookAdsAdAccount>;
  readonly meta: ReportListMeta;
};

export type FacebookExchangeCodePayload = {
  readonly __typename?: 'FacebookExchangeCodePayload';
  readonly datasourceTokenId: Scalars['Long']['output'];
  readonly email?: Maybe<Scalars['String']['output']>;
};

export type FacebookShopFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: FacebookShopFileUploadType;
};

export const FacebookShopFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type FacebookShopFileUploadType = typeof FacebookShopFileUploadType[keyof typeof FacebookShopFileUploadType];
export type FailToStartSendingMessengerBroadcastTooManyUnavailableCustomer = {
  readonly __typename?: 'FailToStartSendingMessengerBroadcastTooManyUnavailableCustomer';
  readonly availableRecipientsCount: Scalars['Int']['output'];
  readonly waitUntil: Scalars['OffsetDateTime']['output'];
};

export type FailureCancelOrder = {
  readonly __typename?: 'FailureCancelOrder';
  readonly orderId: Scalars['Long']['output'];
  readonly reason: Scalars['String']['output'];
};

export type FedExAccountDetails = {
  readonly __typename?: 'FedExAccountDetails';
  readonly fedExAccountNumber: Scalars['String']['output'];
  readonly fedExContactName: Scalars['String']['output'];
  readonly fedExLetterheadImageURL?: Maybe<Scalars['String']['output']>;
  readonly fedExShipperAddressId: Scalars['String']['output'];
  readonly fedExSignatureImageURL?: Maybe<Scalars['String']['output']>;
  readonly fedExSignatureOption: FedExSignatureOptionType;
  readonly fedExSupportPackageTypes?: Maybe<ReadonlyArray<FedExPackageType>>;
};

export type FedExAddressInput = {
  readonly addressFirst: Scalars['String']['input'];
  readonly addressSecond?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country: Country;
  readonly postalCode?: InputMaybe<Scalars['String']['input']>;
  readonly provinceCode?: InputMaybe<Scalars['String']['input']>;
};

export type FedExAddressValidationDetails = {
  readonly __typename?: 'FedExAddressValidationDetails';
  readonly accountAuthToken: Scalars['String']['output'];
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly supportedValidationOptions: ReadonlyArray<ValidationOption>;
};

export type FedExAddressValidationInput = {
  readonly accountNumber: Scalars['String']['input'];
  readonly address: FedExAddressInput;
  readonly contactName: Scalars['String']['input'];
  readonly masterDataAccountId: Scalars['ID']['input'];
};

export type FedExCreateCourier = {
  readonly accountAuthToken: Scalars['String']['input'];
  readonly accountId: Scalars['ID']['input'];
  readonly accountNumber: Scalars['String']['input'];
  readonly contactName: Scalars['String']['input'];
  readonly invoice?: InputMaybe<FedExInvoiceInput>;
  readonly letterheadImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly secureCodePin?: InputMaybe<Scalars['String']['input']>;
  readonly signatureImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly signatureOption: FedExSignatureOptionType;
  readonly supportPackageTypes?: InputMaybe<ReadonlyArray<FedExPackageType>>;
};

export type FedExGeneratePinCodeInput = {
  readonly accountAuthToken: Scalars['String']['input'];
  readonly masterDataAccountId: Scalars['ID']['input'];
  readonly option: ValidationOption;
};

export type FedExInvoiceInput = {
  readonly amount: AnylogiMoneyInput;
  readonly date: Scalars['Date']['input'];
  readonly number: Scalars['Int']['input'];
};

export const FedExPackageType = {
  FEDEX_10KG_BOX: 'FEDEX_10KG_BOX',
  FEDEX_25KG_BOX: 'FEDEX_25KG_BOX',
  FEDEX_ENVELOPE: 'FEDEX_ENVELOPE',
  FEDEX_EXTRA_LARGE_BOX: 'FEDEX_EXTRA_LARGE_BOX',
  FEDEX_LARGE_BOX: 'FEDEX_LARGE_BOX',
  FEDEX_MEDIUM_BOX: 'FEDEX_MEDIUM_BOX',
  FEDEX_PAK: 'FEDEX_PAK',
  FEDEX_SMALL_BOX: 'FEDEX_SMALL_BOX',
  FEDEX_TUBE: 'FEDEX_TUBE',
  FEDEX_YOUR_PACKAGING: 'FEDEX_YOUR_PACKAGING'
} as const;

export type FedExPackageType = typeof FedExPackageType[keyof typeof FedExPackageType];
export type FedExPackageWrapper = {
  readonly __typename?: 'FedExPackageWrapper';
  readonly FedExPackageType?: Maybe<FedExPackageType>;
};

export const FedExPickupServiceCode = {
  FEDEX_EXPRESS: 'FEDEX_EXPRESS'
} as const;

export type FedExPickupServiceCode = typeof FedExPickupServiceCode[keyof typeof FedExPickupServiceCode];
export type FedExPickupServiceCodeWrapper = {
  readonly __typename?: 'FedExPickupServiceCodeWrapper';
  readonly fedExPickupServiceCode?: Maybe<FedExPickupServiceCode>;
};

export const FedExServiceCode = {
  FEDEX_1_DAY_FREIGHT: 'FEDEX_1_DAY_FREIGHT',
  FEDEX_2_DAY: 'FEDEX_2_DAY',
  FEDEX_2_DAY_AM: 'FEDEX_2_DAY_AM',
  FEDEX_2_DAY_FREIGHT: 'FEDEX_2_DAY_FREIGHT',
  FEDEX_3_DAY_FREIGHT: 'FEDEX_3_DAY_FREIGHT',
  FEDEX_CARGO_AIRPORT_TO_AIRPORT: 'FEDEX_CARGO_AIRPORT_TO_AIRPORT',
  FEDEX_CARGO_INTERNATIONAL_PREMIUM: 'FEDEX_CARGO_INTERNATIONAL_PREMIUM',
  FEDEX_CARGO_MAIL: 'FEDEX_CARGO_MAIL',
  FEDEX_DISTANCE_DEFERRED: 'FEDEX_DISTANCE_DEFERRED',
  FEDEX_EXPRESS_SAVER: 'FEDEX_EXPRESS_SAVER',
  FEDEX_GROUND: 'FEDEX_GROUND',
  FEDEX_INTERNATIONAL_CONNECT_PLUS: 'FEDEX_INTERNATIONAL_CONNECT_PLUS',
  FEDEX_INTERNATIONAL_PRIORITY: 'FEDEX_INTERNATIONAL_PRIORITY',
  FEDEX_INTERNATIONAL_PRIORITY_EXPRESS: 'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
  FEDEX_IP_DIRECT_DISTRIBUTION_FREIGHT: 'FEDEX_IP_DIRECT_DISTRIBUTION_FREIGHT',
  FEDEX_NEXT_DAY: 'FEDEX_NEXT_DAY',
  FEDEX_NEXT_DAY_10AM: 'FEDEX_NEXT_DAY_10AM',
  FEDEX_NEXT_DAY_12PM: 'FEDEX_NEXT_DAY_12PM',
  FEDEX_NEXT_DAY_END_OF_DAY: 'FEDEX_NEXT_DAY_END_OF_DAY',
  FEDEX_NEXT_DAY_FREIGHT: 'FEDEX_NEXT_DAY_FREIGHT',
  FEDEX_ONE_DAY_FREIGHT: 'FEDEX_ONE_DAY_FREIGHT',
  FEDEX_REGIONAL_ECONOMY: 'FEDEX_REGIONAL_ECONOMY',
  FIRST_OVERNIGHT: 'FIRST_OVERNIGHT',
  FIRST_OVERNIGHT_FREIGHT: 'FIRST_OVERNIGHT_FREIGHT',
  GROUND_HOME_DELIVERY: 'GROUND_HOME_DELIVERY',
  INTERNATIONAL_DISTRIBUTION_FREIGHT: 'INTERNATIONAL_DISTRIBUTION_FREIGHT',
  INTERNATIONAL_ECONOMY: 'INTERNATIONAL_ECONOMY',
  INTERNATIONAL_ECONOMY_DISTRIBUTION: 'INTERNATIONAL_ECONOMY_DISTRIBUTION',
  INTERNATIONAL_ECONOMY_FREIGHT: 'INTERNATIONAL_ECONOMY_FREIGHT',
  INTERNATIONAL_FIRST: 'INTERNATIONAL_FIRST',
  INTERNATIONAL_PRIORITY_DISTRIBUTION: 'INTERNATIONAL_PRIORITY_DISTRIBUTION',
  INTERNATIONAL_PRIORITY_FREIGHT: 'INTERNATIONAL_PRIORITY_FREIGHT',
  INTL_GROUND_DISTRIBUTION: 'INTL_GROUND_DISTRIBUTION',
  PRIORITY_OVERNIGHT: 'PRIORITY_OVERNIGHT',
  SAME_DAY: 'SAME_DAY',
  SAME_DAY_CITY: 'SAME_DAY_CITY',
  SMART_POST: 'SMART_POST',
  STANDARD_OVERNIGHT: 'STANDARD_OVERNIGHT'
} as const;

export type FedExServiceCode = typeof FedExServiceCode[keyof typeof FedExServiceCode];
export type FedExServiceCodeWrapper = {
  readonly __typename?: 'FedExServiceCodeWrapper';
  readonly fedExServiceCode?: Maybe<FedExServiceCode>;
};

export const FedExSignatureOptionType = {
  ADULT: 'ADULT',
  DIRECT: 'DIRECT',
  INDIRECT: 'INDIRECT'
} as const;

export type FedExSignatureOptionType = typeof FedExSignatureOptionType[keyof typeof FedExSignatureOptionType];
export type FedExUpdateCourier = {
  readonly courierId: Scalars['String']['input'];
  readonly letterheadImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly signatureImageURL?: InputMaybe<Scalars['String']['input']>;
  readonly signatureOption: FedExSignatureOptionType;
  readonly supportPackageTypes?: InputMaybe<ReadonlyArray<FedExPackageType>>;
};

export type FetchUnitMessageRequest = {
  readonly messageId: Scalars['String']['input'];
};

export type FetchUnitMessagesRequest = {
  readonly messageIds: ReadonlyArray<Scalars['String']['input']>;
};

export const FileType = {
  CSV: 'CSV',
  IMAGE: 'IMAGE'
} as const;

export type FileType = typeof FileType[keyof typeof FileType];
export type FileUploadHistoriesInput = {
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
};

export type FileUploadHistoriesPayload = {
  readonly __typename?: 'FileUploadHistoriesPayload';
  readonly items: ReadonlyArray<FileUploadHistoryPayload>;
  readonly totalCount: Scalars['Int']['output'];
};

export type FileUploadHistoryItemPayload = {
  readonly __typename?: 'FileUploadHistoryItemPayload';
  readonly fileName: Scalars['String']['output'];
  readonly fileType: InventoryFileType;
  readonly fileUrl: Scalars['String']['output'];
  readonly saleChannelType?: Maybe<SaleChannelType>;
  readonly status: FileUploadHistoryStatusReporting;
  readonly uploadDate: Scalars['ZonedDatetime']['output'];
};

export type FileUploadHistoryPayload = {
  readonly __typename?: 'FileUploadHistoryPayload';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly fileName: Scalars['String']['output'];
  readonly fileUploadType: FileUploadType;
  readonly fileUrl: Scalars['URL']['output'];
  readonly id: Scalars['String']['output'];
  readonly saleChannelId: Scalars['Long']['output'];
  readonly status: FileUploadHistoryStatus;
};

export const FileUploadHistoryStatus = {
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  UPLOADED: 'UPLOADED'
} as const;

export type FileUploadHistoryStatus = typeof FileUploadHistoryStatus[keyof typeof FileUploadHistoryStatus];
export const FileUploadHistoryStatusReporting = {
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  UPLOADED: 'UPLOADED'
} as const;

export type FileUploadHistoryStatusReporting = typeof FileUploadHistoryStatusReporting[keyof typeof FileUploadHistoryStatusReporting];
export type FileUploadInput = {
  readonly amazonSellerCentral?: InputMaybe<AmazonSellerCentralFileUploadInput>;
  readonly auPayMarket?: InputMaybe<AuMarketFileUploadInput>;
  readonly facebookShop?: InputMaybe<FacebookShopFileUploadInput>;
  readonly lazada?: InputMaybe<LazadaFileUploadInput>;
  readonly magaseekFileUpload?: InputMaybe<MagaseekFileUploadInput>;
  readonly makeshop?: InputMaybe<MakeshopFileUploadInput>;
  readonly momo?: InputMaybe<MomoFileUploadInput>;
  readonly rakuten?: InputMaybe<RakutenFileUploadInput>;
  readonly rakutenFashion?: InputMaybe<RakutenFashionFileUploadInput>;
  readonly shopee?: InputMaybe<ShopeeFileUploadInput>;
  readonly shoplist?: InputMaybe<ShoplistFileUploadInput>;
  readonly stores?: InputMaybe<StoresFileUploadInput>;
  readonly superDelivery?: InputMaybe<SuperDeliveryFileUploadInput>;
  readonly tiktok?: InputMaybe<TikTokFileUploadInput>;
  readonly tokopedia?: InputMaybe<TokopediaFileUploadInput>;
  readonly zozotown?: InputMaybe<ZozotownFileUploadInput>;
};

export type FileUploadPayload = {
  readonly __typename?: 'FileUploadPayload';
  readonly fileName: Scalars['String']['output'];
  readonly fileUrl: Scalars['String']['output'];
  readonly signedUrl: Scalars['String']['output'];
};

export type FileUploadStatus = {
  readonly __typename?: 'FileUploadStatus';
  /** will be shown only when FileUploadWarning.NOT_UPLOADED_IN_LAST_7_DAYS. */
  readonly lastUploadedAt?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly warning?: Maybe<FileUploadWarning>;
};

export const FileUploadType = {
  Ads: 'Ads',
  CouponAdvanced: 'CouponAdvanced',
  Inventories: 'Inventories',
  Orders: 'Orders',
  Products: 'Products',
  RPP: 'RPP',
  Sales: 'Sales',
  Sessions: 'Sessions',
  SponsoredBrand: 'SponsoredBrand',
  SponsoredDiscovery: 'SponsoredDiscovery',
  SponsoredDisplay: 'SponsoredDisplay',
  SponsoredProduct: 'SponsoredProduct'
} as const;

export type FileUploadType = typeof FileUploadType[keyof typeof FileUploadType];
export const FileUploadWarning = {
  NOT_UPLOADED_IN_LAST_7_DAYS: 'NOT_UPLOADED_IN_LAST_7_DAYS',
  NO_FILE_UPLOADED: 'NO_FILE_UPLOADED'
} as const;

export type FileUploadWarning = typeof FileUploadWarning[keyof typeof FileUploadWarning];
export type FilterMerchants = {
  readonly accountId: Scalars['Long']['input'];
};

export type FlowBlock = {
  readonly __typename?: 'FlowBlock';
  readonly actionBlock?: Maybe<ActionSettingBlock>;
  readonly conditionalSplitBlock?: Maybe<ConditionalSplitBlock>;
  readonly id: Scalars['String']['output'];
};

export type FlowBlockInput = {
  readonly actionBlock?: InputMaybe<ActionSettingBlockInput>;
  readonly conditionalSplitBlock?: InputMaybe<ConditionalSplitBlockInput>;
  readonly id: Scalars['String']['input'];
};

export const FontSize = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xl: 'xl',
  xxl: 'xxl'
} as const;

export type FontSize = typeof FontSize[keyof typeof FontSize];
export type FullAddress = {
  readonly __typename?: 'FullAddress';
  readonly addressFirst: Scalars['String']['output'];
  readonly addressSecond?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Country>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly firstName: Scalars['String']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly phone: PhoneNumber;
  readonly postalCode?: Maybe<Scalars['String']['output']>;
  readonly provinceId?: Maybe<Scalars['ID']['output']>;
  readonly regionId?: Maybe<Scalars['ID']['output']>;
  readonly regionLocalName?: Maybe<Scalars['String']['output']>;
  readonly regionName?: Maybe<Scalars['String']['output']>;
};

export const Gender = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  OTHER: 'OTHER'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];
export type GenerateAssetUploadUrlRequest = {
  readonly fileName: Scalars['String']['input'];
  readonly instagramTarget?: InputMaybe<InstagramTargetInput>;
  readonly lazadaTarget?: InputMaybe<LazadaTargetInput>;
  readonly lineTarget?: InputMaybe<LineTargetInput>;
  readonly messengerTarget?: InputMaybe<MessengerTargetInput>;
  readonly mimeType: Scalars['String']['input'];
  readonly shopeeTarget?: InputMaybe<ShopeeTargetInput>;
  readonly whatsAppTarget?: InputMaybe<WhatsAppTargetInput>;
};

export type GenerateDraftUnitMessageContentRequest = {
  readonly messageItems: ReadonlyArray<LineMessageItemInput>;
  readonly name: Scalars['String']['input'];
};

export type GenerateShopeeChatAuthorizationLinkRequest = {
  readonly redirectUrl: Scalars['Url']['input'];
};

export type GenerateShopeeChatAuthorizationLinkResponse = {
  readonly __typename?: 'GenerateShopeeChatAuthorizationLinkResponse';
  readonly url: Scalars['Url']['output'];
};

export type GenerateShopifyOauthUrlRequest = {
  readonly shopifyStoreDomain: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type GenerateTrackingUrlRequest = {
  readonly shorten: Scalars['Boolean']['input'];
  readonly urlLinkGroupId: Scalars['String']['input'];
  readonly urls: ReadonlyArray<Scalars['Url']['input']>;
};

export type GetAutomationFlowRequest = {
  readonly id: Scalars['String']['input'];
};

export type GetAutomationFlowsRequest = {
  readonly eventTypes: ReadonlyArray<AutomationFlowEventType>;
  readonly limit: Scalars['Int']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly offset: Scalars['Int']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type GetChatCustomerDetailRequest = {
  readonly chatId: Scalars['String']['input'];
};

export type GetChatRequest = {
  readonly chatId: Scalars['String']['input'];
};

export type GetChatStateRequest = {
  readonly chatId: Scalars['String']['input'];
};

export type GetChatsRequest = {
  readonly chatTypeFilter: ChatTypeFilter;
  readonly daysSinceLastReceivedMessageWithIn?: InputMaybe<Scalars['Int']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly messagingChannel?: InputMaybe<ChatType>;
  readonly offset: Scalars['Int']['input'];
  readonly storeId: Scalars['String']['input'];
  readonly tagName?: InputMaybe<Scalars['String']['input']>;
};

export type GetCourierAccountListInput = {
  readonly accountId?: InputMaybe<Scalars['ID']['input']>;
  readonly crossBorderCourierProvider?: InputMaybe<CrossBorderCourierProvider>;
};

export type GetCustomerActivitiesRequest = {
  readonly customerId: Scalars['String']['input'];
  readonly interestedEvents?: InputMaybe<ReadonlyArray<CustomerActivityType>>;
  readonly limit: Scalars['Int']['input'];
  readonly newerThan?: InputMaybe<Scalars['String']['input']>;
  readonly offset?: InputMaybe<Scalars['Int']['input']>;
  readonly olderThan?: InputMaybe<Scalars['String']['input']>;
};

export type GetCustomerTagRequest = {
  readonly customerTagId: Scalars['String']['input'];
};

export type GetCustomerTagsRequest = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly offset?: InputMaybe<Scalars['Int']['input']>;
  readonly order?: InputMaybe<CustomerTagOrdering>;
  readonly storeId: Scalars['String']['input'];
};

export type GetCustomersRequest = {
  readonly chatType?: InputMaybe<ChatType>;
  readonly dateOfLastOrder?: InputMaybe<Scalars['Int']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly numberOfOrderFrom?: InputMaybe<Scalars['Int']['input']>;
  readonly numberOfOrderTo?: InputMaybe<Scalars['Int']['input']>;
  readonly offset: Scalars['Int']['input'];
  readonly shopifyConnected?: InputMaybe<Scalars['Boolean']['input']>;
  readonly storeId: Scalars['String']['input'];
};

export type GetCustomersViewRequest = {
  readonly chatPlatforms: ReadonlyArray<ChatType>;
  readonly countries: ReadonlyArray<Scalars['String']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly lastOrderMaxDays?: InputMaybe<Scalars['Int']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly orderBy?: InputMaybe<CustomersViewSortInput>;
  readonly salesChannels: ReadonlyArray<AnyChatSaleChannelType>;
  readonly storeId: Scalars['Long']['input'];
};

export type GetEstimateRecipientRequestInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly recipient: RecipientItemInput;
  readonly storeId: Scalars['String']['input'];
};

export type GetInfluencerCampaignByIdsRequest = {
  readonly campaigns: ReadonlyArray<InfluencerCampaignInput>;
};

export type GetInfluencerCampaignsRequest = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly status: InfluencerCampaignStatus;
  readonly storeId: Scalars['String']['input'];
};

export type GetInfluencerCategoriesByIdsRequest = {
  readonly categories: ReadonlyArray<Scalars['Long']['input']>;
};

export type GetInfluencersRequest = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type GetInstagramChatEventsRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly newerThan?: InputMaybe<Scalars['String']['input']>;
  readonly newerThanOrEqual?: InputMaybe<Scalars['String']['input']>;
  readonly olderThan?: InputMaybe<Scalars['String']['input']>;
  readonly olderThanOrEqual?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

export type GetLineChatEventsRequest = {
  readonly before?: InputMaybe<Scalars['String']['input']>;
  readonly chatId: Scalars['String']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

export type GetLazadaChatMessagesRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly newerThanMessageWithId?: InputMaybe<Scalars['String']['input']>;
  readonly newerThanOrEqualMessageWithId?: InputMaybe<Scalars['String']['input']>;
  readonly olderThanMessageWithId?: InputMaybe<Scalars['String']['input']>;
  readonly olderThanOrEqualMessageWithId?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

export type GetLineBroadcastMessageStatSummaryRequestInput = {
  readonly deliveryDateRange?: InputMaybe<DateRangeInput>;
  readonly deliveryDateRangeCompare?: InputMaybe<DateRangeInput>;
  readonly storeId: Scalars['String']['input'];
};

export type GetLineBroadcastMessagesRequestInput = {
  readonly deliveredDateFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly deliveredDateTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly sortBy?: InputMaybe<LineBroadcastSortByInput>;
  readonly status?: InputMaybe<BroadcastStatus>;
  readonly storeId: Scalars['String']['input'];
  readonly tagName?: InputMaybe<Scalars['String']['input']>;
};

export type GetLineChatEventSubRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly newerThanEventId?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

export type GetMessageQuotaInfoRequestInput = {
  readonly storeId: Scalars['String']['input'];
};

export type GetMessengerBroadcastMessageStatSummaryRequestInput = {
  readonly deliveryDateRange?: InputMaybe<DateRangeInput>;
  readonly deliveryDateRangeCompare?: InputMaybe<DateRangeInput>;
  readonly storeId: Scalars['String']['input'];
};

export type GetMessengerBroadcastMessagesRequestInput = {
  readonly customerTag?: InputMaybe<Scalars['String']['input']>;
  readonly deliveredDateFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly deliveredDateTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly status?: InputMaybe<BroadcastStatus>;
  readonly storeId: Scalars['String']['input'];
};

export type GetMessengerChatEventsRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly newerThan?: InputMaybe<Scalars['String']['input']>;
  readonly newerThanOrEqual?: InputMaybe<Scalars['String']['input']>;
  readonly olderThan?: InputMaybe<Scalars['String']['input']>;
  readonly olderThanOrEqual?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

export type GetMessengerEstimateRecipientRequestInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly recipient: MessengerRecipientItemInput;
  readonly storeId: Scalars['String']['input'];
};

export type GetShopeeChatMessagesRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly newerThanMessageWithId?: InputMaybe<Scalars['String']['input']>;
  readonly olderThanMessageWithId?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

export type GetSingleLineBroadcastMessageRequestInput = {
  readonly lineBroadcastMessageId: Scalars['String']['input'];
};

export type GetSingleMessengerBroadcastMessageRequestInput = {
  readonly messengerBroadcastId: Scalars['String']['input'];
};

export type GetSingleWhatsAppBroadcastMessageRequest = {
  readonly whatsappBroadcastId: Scalars['String']['input'];
};

export type GetStoreGreetingMessageRequest = {
  readonly storeId: Scalars['String']['input'];
};

export type GetStoreMessengerOptInMessageRequestInput = {
  readonly storeId: Scalars['String']['input'];
};

export type GetWhatsAppBroadcastMessageStatSummaryRequest = {
  readonly deliveryDateRange?: InputMaybe<DateRangeInput>;
  readonly deliveryDateRangeCompare?: InputMaybe<DateRangeInput>;
  readonly storeId: Scalars['String']['input'];
};

export type GetWhatsAppBroadcastMessagesRequest = {
  readonly customerTag?: InputMaybe<Scalars['String']['input']>;
  readonly deliveredDateFrom?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly deliveredDateTo?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly status?: InputMaybe<ReadonlyArray<WhatsAppBroadcastStatus>>;
  readonly storeId: Scalars['String']['input'];
};

export type GetWhatsAppChatMessagesRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly newerThan?: InputMaybe<Scalars['Long']['input']>;
  readonly newerThanOrEqual?: InputMaybe<Scalars['Long']['input']>;
  readonly olderThan?: InputMaybe<Scalars['Long']['input']>;
  readonly olderThanOrEqual?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

export type GetWhatsAppEstimateRecipientRequest = {
  readonly recipient: WhatsAppRecipientInput;
  readonly storeId: Scalars['String']['input'];
};

export type GoogleAdsAccountPayload = {
  readonly __typename?: 'GoogleAdsAccountPayload';
  readonly id: Scalars['String']['output'];
  readonly manager: Scalars['Boolean']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type GoogleAdsAccountPayloadList = {
  readonly __typename?: 'GoogleAdsAccountPayloadList';
  readonly items: ReadonlyArray<GoogleAdsAccountPayload>;
  readonly meta: ReportListMeta;
};

export type GoogleAdsCustomerPayload = {
  readonly __typename?: 'GoogleAdsCustomerPayload';
  readonly autoTaggingEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly callReportingSetting?: Maybe<Scalars['Map']['output']>;
  readonly conversionTrackingSetting?: Maybe<Scalars['Map']['output']>;
  readonly currencyCode?: Maybe<Scalars['String']['output']>;
  readonly descriptiveName?: Maybe<Scalars['String']['output']>;
  readonly finalUrlSuffix?: Maybe<Scalars['String']['output']>;
  readonly hasPartnersBadge?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['String']['output'];
  readonly manager?: Maybe<Scalars['Boolean']['output']>;
  readonly optimizationScore?: Maybe<Scalars['Int']['output']>;
  readonly optimizationScoreWeight?: Maybe<Scalars['Int']['output']>;
  readonly payPerConversionEligibilityFailureReasons?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly remarketingSetting?: Maybe<Scalars['Map']['output']>;
  readonly resourceName?: Maybe<Scalars['String']['output']>;
  readonly testAccount?: Maybe<Scalars['Boolean']['output']>;
  readonly timeZone?: Maybe<Scalars['String']['output']>;
  readonly trackingUrlTemplate?: Maybe<Scalars['String']['output']>;
};

export type GoogleAdsCustomerPayloadList = {
  readonly __typename?: 'GoogleAdsCustomerPayloadList';
  readonly items: ReadonlyArray<GoogleAdsCustomerPayload>;
  readonly meta: ReportListMeta;
};

export type GoogleAnalytics4AccountSummaries = {
  readonly __typename?: 'GoogleAnalytics4AccountSummaries';
  readonly accountSummaries: ReadonlyArray<GoogleAnalytics4AccountSummary>;
};

export type GoogleAnalytics4AccountSummary = {
  readonly __typename?: 'GoogleAnalytics4AccountSummary';
  readonly account: Scalars['String']['output'];
  readonly displayName: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly propertySummaries?: Maybe<ReadonlyArray<GoogleAnalytics4PropertySummary>>;
};

export type GoogleAnalytics4PropertySummary = {
  readonly __typename?: 'GoogleAnalytics4PropertySummary';
  readonly displayName: Scalars['String']['output'];
  readonly property: Scalars['String']['output'];
  readonly propertyType?: Maybe<Scalars['String']['output']>;
};

export type GoogleExchangeCodePayload = {
  readonly __typename?: 'GoogleExchangeCodePayload';
  readonly datasourceTokenId: Scalars['Long']['output'];
  readonly email?: Maybe<Scalars['String']['output']>;
};

export const GoogleLoginUrlType = {
  GoogleAds: 'GoogleAds',
  GoogleAnalytics4: 'GoogleAnalytics4'
} as const;

export type GoogleLoginUrlType = typeof GoogleLoginUrlType[keyof typeof GoogleLoginUrlType];
export type GraphQlMoney = {
  readonly __typename?: 'GraphQLMoney';
  readonly amount: Scalars['BigDecimal']['output'];
  readonly currency: CurrencyCode;
};

export type GraphQlMoneyInput = {
  readonly amount: Scalars['BigDecimal']['input'];
  readonly currency: CurrencyCode;
};

export type GreetingMessage = {
  readonly __typename?: 'GreetingMessage';
  readonly content: ReadonlyArray<LineMessage>;
  readonly createdAt: Scalars['Instant']['output'];
  readonly enabled: Scalars['Boolean']['output'];
  readonly storeId: Scalars['String']['output'];
  readonly updatedAt: Scalars['Instant']['output'];
};

export type Gtin = {
  readonly __typename?: 'Gtin';
  readonly gtin: Scalars['String']['output'];
};

export const HomeDailyReportMetric = {
  AVG_ORDER_VALUES: 'AVG_ORDER_VALUES',
  MARGINAL_PROFIT: 'MARGINAL_PROFIT',
  MARGINAL_PROFIT_RATE: 'MARGINAL_PROFIT_RATE',
  ORDERS: 'ORDERS',
  REVENUE: 'REVENUE'
} as const;

export type HomeDailyReportMetric = typeof HomeDailyReportMetric[keyof typeof HomeDailyReportMetric];
export type HomeDailyReportView = {
  readonly __typename?: 'HomeDailyReportView';
  readonly currency: ReportSupportedCurrency;
  readonly data: ReadonlyArray<ReportListKvData>;
  readonly totalItems: Scalars['Int']['output'];
};

export type HomeReportFilterInput = {
  readonly chartTab?: InputMaybe<ReportChartTab>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type HomeReportSaleChannelRevenuePayload = {
  readonly __typename?: 'HomeReportSaleChannelRevenuePayload';
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly saleChannelType?: Maybe<SaleChannelType>;
  readonly storeName?: Maybe<Scalars['String']['output']>;
};

export type HomeSaleChannelRevenueReportView = {
  readonly __typename?: 'HomeSaleChannelRevenueReportView';
  readonly currency: ReportSupportedCurrency;
  readonly data: ReadonlyArray<HomeReportSaleChannelRevenuePayload>;
};

export type HomeSummaryReportView = {
  readonly __typename?: 'HomeSummaryReportView';
  readonly avgOrderValues: ReportCompareData;
  readonly currency: ReportSupportedCurrency;
  readonly marginalProfit: ReportCompareData;
  readonly marginalProfitRate: ReportCompareData;
  readonly orders: ReportCompareData;
  readonly revenue: ReportCompareData;
};

export type HomeTop5ProductView = {
  readonly __typename?: 'HomeTop5ProductView';
  readonly currency: ReportSupportedCurrency;
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly orderCount: Scalars['Int']['output'];
  readonly productId?: Maybe<Scalars['Long']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly revenue: Scalars['BigDecimal']['output'];
};

export type HomeTop5ProductsReportView = {
  readonly __typename?: 'HomeTop5ProductsReportView';
  readonly data: ReadonlyArray<HomeTop5ProductView>;
};

export type Id = {
  readonly __typename?: 'Id';
  readonly id: Scalars['ID']['output'];
};

export type IdName = {
  readonly __typename?: 'IdName';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

export type IdNameWms = {
  readonly __typename?: 'IdNameWms';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly wms: Wms;
};

export type Image = Media & {
  readonly __typename?: 'Image';
  readonly fileName: Scalars['String']['output'];
  readonly mimeType: Scalars['String']['output'];
  readonly previewUrl: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
};

export type ImageInput = {
  readonly fileName: Scalars['String']['input'];
  readonly mimeType: Scalars['String']['input'];
  readonly previewUrl: Scalars['String']['input'];
  readonly url: Scalars['String']['input'];
};

export type ImageItem = {
  readonly __typename?: 'ImageItem';
  readonly imageUrl: Scalars['String']['output'];
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly url: Scalars['String']['output'];
};

export type ImageItemInput = {
  readonly imageUrl: Scalars['String']['input'];
  readonly label?: InputMaybe<Scalars['String']['input']>;
  readonly url: Scalars['String']['input'];
};

export type ImageMessage = {
  readonly __typename?: 'ImageMessage';
  readonly images: ReadonlyArray<ImageItem>;
};

export type ImageMessageContent = {
  readonly __typename?: 'ImageMessageContent';
  readonly originalUrl: Scalars['Url']['output'];
  readonly previewUrl: Scalars['Url']['output'];
};

export type ImageMessageInput = {
  readonly images: ReadonlyArray<ImageItemInput>;
};

export type ImageVideoMessage = {
  readonly __typename?: 'ImageVideoMessage';
  readonly media: Media;
};

export type ImageVideoMessageInputInput = {
  readonly media: MediaItemInput;
};

export type Immediately = {
  readonly __typename?: 'Immediately';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export const InUseSkuReason = {
  INUSE_INBOUND: 'INUSE_INBOUND',
  INUSE_INVENTORY: 'INUSE_INVENTORY',
  INUSE_ORDER: 'INUSE_ORDER',
  INUSE_SET_SKU: 'INUSE_SET_SKU'
} as const;

export type InUseSkuReason = typeof InUseSkuReason[keyof typeof InUseSkuReason];
export type Inbound = {
  readonly __typename?: 'Inbound';
  readonly brand?: Maybe<Brand>;
  readonly created: Scalars['Time']['output'];
  readonly deliveryDestination?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly image: Scalars['String']['output'];
  readonly inboundDate: Scalars['Time']['output'];
  readonly inboundId: Scalars['String']['output'];
  readonly inspectedQuantity?: Maybe<Scalars['Int']['output']>;
  readonly mdAccount?: Maybe<MdAccount>;
  readonly mdStore?: Maybe<MdStore>;
  readonly productCount: Scalars['Int']['output'];
  readonly quantity: Scalars['Int']['output'];
  readonly status: InboundStatus;
  readonly supplier: Supplier;
};

export type InboundDetailed = {
  readonly __typename?: 'InboundDetailed';
  readonly brand?: Maybe<Brand>;
  readonly comment: Scalars['String']['output'];
  readonly created: Scalars['Time']['output'];
  readonly deliveryAddress?: Maybe<Address>;
  readonly deliveryDestination?: Maybe<Scalars['String']['output']>;
  readonly deliveryPhoneNumber?: Maybe<PhoneNumber>;
  readonly id: Scalars['ID']['output'];
  readonly inboundDate: Scalars['Time']['output'];
  readonly inboundId: Scalars['String']['output'];
  readonly inspectedQuantity?: Maybe<Scalars['Int']['output']>;
  readonly mdAccount?: Maybe<MdAccount>;
  readonly mdStore?: Maybe<MdStore>;
  readonly mdUser?: Maybe<MdUser>;
  readonly products: ReadonlyArray<InboundProductDetailed>;
  readonly quantity: Scalars['Int']['output'];
  readonly status: InboundStatus;
  readonly supplier: Supplier;
  readonly user: User;
  readonly warehouseArrivalDate?: Maybe<Scalars['Time']['output']>;
};

export type InboundFilters = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<InboundStatus>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type InboundIds = {
  readonly __typename?: 'InboundIds';
  readonly id: Scalars['ID']['output'];
  readonly inboundId: Scalars['String']['output'];
};

export type InboundOverview = {
  readonly __typename?: 'InboundOverview';
  readonly all: Scalars['Int']['output'];
  readonly completed: Scalars['Int']['output'];
  readonly reviewing: Scalars['Int']['output'];
  readonly warehousing: Scalars['Int']['output'];
};

export type InboundProductDetailed = {
  readonly __typename?: 'InboundProductDetailed';
  readonly code: Scalars['String']['output'];
  readonly gtin: Scalars['String']['output'];
  readonly image: Scalars['String']['output'];
  readonly inspectedQuantity?: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly quantity: Scalars['Int']['output'];
};

export type InboundSku = {
  readonly gtin: Scalars['String']['input'];
  readonly quantity: Scalars['Int']['input'];
};

export const InboundStatus = {
  COMPLETED: 'COMPLETED',
  REVIEWING: 'REVIEWING',
  WAREHOUSING: 'WAREHOUSING'
} as const;

export type InboundStatus = typeof InboundStatus[keyof typeof InboundStatus];
export type InboundsPaginated = Paginated & {
  readonly __typename?: 'InboundsPaginated';
  readonly page: Scalars['Int']['output'];
  readonly records: ReadonlyArray<Inbound>;
  readonly total: Scalars['Int']['output'];
};

export type InfluencerCampaign = {
  readonly __typename?: 'InfluencerCampaign';
  readonly campaignType: InfluencerCampaignType;
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

export type InfluencerCampaignInput = {
  readonly campaignType: InfluencerCampaignType;
  readonly id: Scalars['Long']['input'];
};

export const InfluencerCampaignStatus = {
  Finished: 'Finished',
  Running: 'Running'
} as const;

export type InfluencerCampaignStatus = typeof InfluencerCampaignStatus[keyof typeof InfluencerCampaignStatus];
export const InfluencerCampaignType = {
  CAMPAIGN_TYPE_AUTO_MANAGED: 'CAMPAIGN_TYPE_AUTO_MANAGED',
  CAMPAIGN_TYPE_ENGAGEMENT: 'CAMPAIGN_TYPE_ENGAGEMENT',
  CAMPAIGN_TYPE_MARKETPLACE: 'CAMPAIGN_TYPE_MARKETPLACE'
} as const;

export type InfluencerCampaignType = typeof InfluencerCampaignType[keyof typeof InfluencerCampaignType];
export type InfluencerCategory = {
  readonly __typename?: 'InfluencerCategory';
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

export type InfluencerCondition = {
  readonly __typename?: 'InfluencerCondition';
  readonly influencerCondition: BroadcastInfluencerConditionWrapper;
};

export type InfluencerInfo = {
  readonly __typename?: 'InfluencerInfo';
  readonly engagementRate?: Maybe<Scalars['BigDecimal']['output']>;
  readonly followersCount?: Maybe<Scalars['Long']['output']>;
  readonly profileUrl: Scalars['String']['output'];
  readonly registeredAt: Scalars['OffsetDateTime']['output'];
};

export type InfluencerItem = {
  readonly __typename?: 'InfluencerItem';
  readonly avatar: Scalars['String']['output'];
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

export type InfluencerJoinedCampaignActivity = CustomerActivity & {
  readonly __typename?: 'InfluencerJoinedCampaignActivity';
  readonly campaignName: Scalars['String']['output'];
  readonly campaignUrl: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type InfluencerPublishedPostActivity = CustomerActivity & {
  readonly __typename?: 'InfluencerPublishedPostActivity';
  readonly campaignName: Scalars['String']['output'];
  readonly campaignUrl: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type InfluencerSubmittedPostActivity = CustomerActivity & {
  readonly __typename?: 'InfluencerSubmittedPostActivity';
  readonly campaignName: Scalars['String']['output'];
  readonly campaignUrl: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type InputCreateLogisticChannelRequestForm = {
  readonly accountId: Scalars['Long']['input'];
  readonly marketplaceName: Scalars['String']['input'];
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelId: Scalars['Long']['input'];
  readonly saleChannelName: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export type InputDateRange = {
  readonly from?: InputMaybe<Scalars['ZonedDatetime']['input']>;
  readonly to?: InputMaybe<Scalars['ZonedDatetime']['input']>;
};

export type InquiryReceivedActivity = CustomerActivity & {
  readonly __typename?: 'InquiryReceivedActivity';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type InquiryResolvedActivity = CustomerActivity & {
  readonly __typename?: 'InquiryResolvedActivity';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly user: BasicUser;
};

export type InquiryUnresolvedActivity = CustomerActivity & {
  readonly __typename?: 'InquiryUnresolvedActivity';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly user: BasicUser;
};

export type InstagramBotAutoReplyMessage = InstagramMessage & {
  readonly __typename?: 'InstagramBotAutoReplyMessage';
  readonly autoReplyInfo: AutoReplyInfo;
  readonly content: InstagramMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<InstagramReaction>;
};

export type InstagramBotAutoReplyMessageDelete = InstagramMessage & {
  readonly __typename?: 'InstagramBotAutoReplyMessageDelete';
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<InstagramReaction>;
};

export type InstagramBotMessage = InstagramMessage & {
  readonly __typename?: 'InstagramBotMessage';
  readonly author: BasicUser;
  readonly content: InstagramMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly messageState: MessageState;
  readonly reactions: ReadonlyArray<InstagramReaction>;
};

export type InstagramBotMessageDeleted = InstagramMessage & {
  readonly __typename?: 'InstagramBotMessageDeleted';
  readonly author: BasicUser;
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<InstagramReaction>;
};

export type InstagramChatEvent = CommonChatEvent | InstagramMessageEvent;

export type InstagramChatEvents = {
  readonly __typename?: 'InstagramChatEvents';
  readonly chatEvents: ReadonlyArray<InstagramChatEvent>;
  readonly chatId: Scalars['String']['output'];
  readonly lastCheckedAt?: Maybe<Scalars['UnixTimestamp']['output']>;
  readonly userName: Scalars['String']['output'];
};

export type InstagramChatPlatformDetail = {
  readonly __typename?: 'InstagramChatPlatformDetail';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type InstagramInfo = ChatInfo & {
  readonly __typename?: 'InstagramInfo';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type InstagramMessage = {
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<InstagramReaction>;
};

export type InstagramMessageContent = AutoReplyContent | ImageMessageContent | StoryMentionContent | TextLiveCommentContent | TextMessageContent | UnsupportedContent | VideoMessageContent;

export type InstagramMessageEvent = {
  readonly __typename?: 'InstagramMessageEvent';
  readonly id: Scalars['String']['output'];
  readonly message: InstagramMessage;
  readonly replyTo?: Maybe<ReplyTo>;
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type InstagramReaction = BotReaction | UserReaction;

export type InstagramSendImageMessageRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly originalUrl: Scalars['Url']['input'];
  readonly previewUrl: Scalars['Url']['input'];
  readonly replyTo?: InputMaybe<ReplyToInputInput>;
};

export type InstagramSendReactionRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly eventId: Scalars['String']['input'];
  readonly reaction: ReactionSignInputInput;
};

export type InstagramSendTextMessageRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly replyTo?: InputMaybe<ReplyToInputInput>;
  readonly text: Scalars['String']['input'];
};

export type InstagramSign = {
  readonly __typename?: 'InstagramSign';
  readonly name: Scalars['String']['output'];
};

export type InstagramSignInput = {
  readonly name: Scalars['String']['input'];
};

export type InstagramStoreChat = StoreChat & {
  readonly __typename?: 'InstagramStoreChat';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly isPrimaryReceiver: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly pageId: Scalars['String']['output'];
  readonly storeProfilePicture?: Maybe<Scalars['String']['output']>;
  readonly tokenStatus: MetaTokenStatus;
  readonly type: ChatType;
  readonly username: Scalars['String']['output'];
};

export type InstagramTargetInput = {
  readonly type: InstagramTargetType;
};

export const InstagramTargetType = {
  AUTOMATION_FLOW: 'AUTOMATION_FLOW',
  CHAT_MESSAGE: 'CHAT_MESSAGE'
} as const;

export type InstagramTargetType = typeof InstagramTargetType[keyof typeof InstagramTargetType];
export type InstagramUserMessage = InstagramMessage & {
  readonly __typename?: 'InstagramUserMessage';
  readonly content: InstagramMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<InstagramReaction>;
};

export type InstagramUserMessageDeleted = InstagramMessage & {
  readonly __typename?: 'InstagramUserMessageDeleted';
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<InstagramReaction>;
};

export type IntegrationSideNavOutput = {
  readonly __typename?: 'IntegrationSideNavOutput';
  readonly inactiveSaleChannelCount: Scalars['Int']['output'];
};

export type InvalidToken = {
  readonly __typename?: 'InvalidToken';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type Inventory = {
  readonly __typename?: 'Inventory';
  readonly allocating: Scalars['Int']['output'];
  readonly arriving: Scalars['Int']['output'];
  readonly brand?: Maybe<Brand>;
  readonly code: Scalars['String']['output'];
  readonly content: Scalars['String']['output'];
  readonly damaged: Scalars['Int']['output'];
  readonly free: Scalars['Int']['output'];
  readonly gtin: Scalars['String']['output'];
  readonly image: Scalars['String']['output'];
  readonly mdAccount?: Maybe<MdAccount>;
  readonly mdStore?: Maybe<MdStore>;
  readonly name: Scalars['String']['output'];
  readonly outOfStock: Scalars['Int']['output'];
  readonly preparing: Scalars['Int']['output'];
  readonly productId?: Maybe<Scalars['Long']['output']>;
  readonly productVariantId?: Maybe<Scalars['Long']['output']>;
  readonly type: SkuType;
  readonly warehousing: Scalars['Int']['output'];
};

export const InventoryFileType = {
  ANCHANTO_XLS: 'ANCHANTO_XLS',
  JUBELIO_CSV: 'JUBELIO_CSV'
} as const;

export type InventoryFileType = typeof InventoryFileType[keyof typeof InventoryFileType];
export type InventoryFileUploadHistoryInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly wms: WarehouseManagementSystem;
};

export type InventoryFileUploadHistoryPayload = {
  readonly __typename?: 'InventoryFileUploadHistoryPayload';
  readonly items: ReadonlyArray<FileUploadHistoryItemPayload>;
  readonly meta: ReportListMeta;
};

export type InventoryFilters = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly inventoryStatus?: InputMaybe<InventoryStatus>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly skuType?: InputMaybe<SkuType>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type InventoryListQueryInput = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  /** Start from 1. */
  readonly pageNumber: Scalars['Int']['input'];
  readonly productType?: InputMaybe<ProductType>;
  readonly stockType?: InputMaybe<StockType>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** Inventory list view. {start}-{end} / {total}. page {page} of {totalPage} */
export type InventoryListView = {
  readonly __typename?: 'InventoryListView';
  readonly end: Scalars['Int']['output'];
  readonly items: ReadonlyArray<InventoryRecord>;
  readonly page: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type InventoryOverview = {
  readonly __typename?: 'InventoryOverview';
  readonly all: Scalars['Int']['output'];
  readonly allocating: Scalars['Int']['output'];
  readonly arriving: Scalars['Int']['output'];
  readonly damaged: Scalars['Int']['output'];
  readonly free: Scalars['Int']['output'];
  readonly outOfStock: Scalars['Int']['output'];
  readonly preparing: Scalars['Int']['output'];
  readonly warehousing: Scalars['Int']['output'];
};

export type InventoryOverviewOutput = {
  readonly __typename?: 'InventoryOverviewOutput';
  readonly allCount: Scalars['Int']['output'];
  readonly allocatingCount: Scalars['Int']['output'];
  readonly arrivingCount: Scalars['Int']['output'];
  readonly damagedCount: Scalars['Int']['output'];
  readonly freeCount: Scalars['Int']['output'];
  readonly preparingCount: Scalars['Int']['output'];
  readonly warehousingCount: Scalars['Int']['output'];
};

export type InventoryOverviewQueryInput = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly productType?: InputMaybe<ProductType>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type InventoryPaginated = Paginated & {
  readonly __typename?: 'InventoryPaginated';
  readonly page: Scalars['Int']['output'];
  readonly records: ReadonlyArray<Inventory>;
  readonly total: Scalars['Int']['output'];
};

export type InventoryRecord = {
  readonly __typename?: 'InventoryRecord';
  readonly allocating?: Maybe<Scalars['Int']['output']>;
  readonly arriving?: Maybe<Scalars['Int']['output']>;
  readonly damaged?: Maybe<Scalars['Int']['output']>;
  readonly free?: Maybe<Scalars['Int']['output']>;
  readonly preparing?: Maybe<Scalars['Int']['output']>;
  readonly variant: ProductVariantV2;
  readonly warehousing?: Maybe<Scalars['Int']['output']>;
};

export const InventoryStatus = {
  ALLOCATING: 'ALLOCATING',
  ARRIVING: 'ARRIVING',
  DAMAGED: 'DAMAGED',
  FREE: 'FREE',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  PREPARING: 'PREPARING',
  WAREHOUSING: 'WAREHOUSING'
} as const;

export type InventoryStatus = typeof InventoryStatus[keyof typeof InventoryStatus];
export type InventoryUploadInput = {
  readonly fileName: Scalars['String']['input'];
  readonly fileType: InventoryFileType;
  readonly fileUrl: Scalars['String']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly saleChannelType?: InputMaybe<SaleChannelType>;
};

export type ItemSource = {
  readonly __typename?: 'ItemSource';
  readonly externalLink: Scalars['Url']['output'];
  readonly imageUrl?: Maybe<Scalars['Url']['output']>;
  readonly itemUrlPath?: Maybe<Scalars['String']['output']>;
  readonly shopeeItemId: Scalars['Long']['output'];
  readonly title: Scalars['String']['output'];
};

export type JapanPostAccountDetails = {
  readonly __typename?: 'JapanPostAccountDetails';
  readonly japanPostCardNumber?: Maybe<Scalars['String']['output']>;
  readonly japanPostSupportServiceCodes?: Maybe<ReadonlyArray<JapanPostServiceCode>>;
};

export type JapanPostCreateCourierInput = {
  readonly accountId: Scalars['String']['input'];
  readonly cardNumber?: InputMaybe<Scalars['String']['input']>;
  readonly supportServiceCodes?: InputMaybe<ReadonlyArray<JapanPostServiceCode>>;
};

export const JapanPostServiceCode = {
  EMS: 'EMS',
  INTERNATIONAL_PARCEL_AIR_MAIL: 'INTERNATIONAL_PARCEL_AIR_MAIL',
  INTERNATIONAL_PARCEL_SAL_MAIL: 'INTERNATIONAL_PARCEL_SAL_MAIL',
  INTERNATIONAL_PARCEL_SURFACE_MAIL: 'INTERNATIONAL_PARCEL_SURFACE_MAIL',
  REGISTERED_AIR_MAIL: 'REGISTERED_AIR_MAIL',
  REGISTERED_SAL_MAIL: 'REGISTERED_SAL_MAIL',
  REGISTERED_SURFACE_MAIL: 'REGISTERED_SURFACE_MAIL',
  SMALL_PACKAGE_AIR_MAIL: 'SMALL_PACKAGE_AIR_MAIL',
  SMALL_PACKAGE_SAL_MAIL: 'SMALL_PACKAGE_SAL_MAIL',
  SMALL_PACKAGE_SURFACE_MAIL: 'SMALL_PACKAGE_SURFACE_MAIL'
} as const;

export type JapanPostServiceCode = typeof JapanPostServiceCode[keyof typeof JapanPostServiceCode];
export type JapanPostServiceCodeWrapper = {
  readonly __typename?: 'JapanPostServiceCodeWrapper';
  readonly japanPostServiceCode?: Maybe<JapanPostServiceCode>;
};

export type JapanPostUpdateCourierInput = {
  readonly cardNumber?: InputMaybe<Scalars['String']['input']>;
  readonly courierId: Scalars['String']['input'];
  readonly supportServiceCodes?: InputMaybe<ReadonlyArray<JapanPostServiceCode>>;
};

export type Job = {
  readonly __typename?: 'Job';
  readonly jobId: Scalars['String']['output'];
};

export const JobState = {
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
  RECEIVED: 'RECEIVED',
  RETRY: 'RETRY',
  STARTED: 'STARTED',
  SUCCESS: 'SUCCESS'
} as const;

export type JobState = typeof JobState[keyof typeof JobState];
export const KerryInventorySyncStatus = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  NOT_SUPPORTED: 'NOT_SUPPORTED'
} as const;

export type KerryInventorySyncStatus = typeof KerryInventorySyncStatus[keyof typeof KerryInventorySyncStatus];
export type KeywordChartInput = {
  readonly campaignId: Scalars['Long']['input'];
  readonly datasourceId: Scalars['Long']['input'];
  readonly dimension: AdReportDimension;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly groupId: Scalars['Long']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type KeywordDetailedReportDimensionValuesPayload = {
  readonly __typename?: 'KeywordDetailedReportDimensionValuesPayload';
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly day?: Maybe<Scalars['LocalDate']['output']>;
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly month?: Maybe<Scalars['YearMonth']['output']>;
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly week?: Maybe<Scalars['LocalDate']['output']>;
};

export type KeywordDetailedReportInput = {
  readonly campaignId: Scalars['Long']['input'];
  readonly datasourceId: Scalars['Long']['input'];
  readonly dimension: AdReportDimension;
  readonly endDate: Scalars['LocalDate']['input'];
  readonly groupId: Scalars['Long']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type KeywordDetailedReportPayload = {
  readonly __typename?: 'KeywordDetailedReportPayload';
  readonly adGroupId: Scalars['Long']['output'];
  readonly clicks: Scalars['BigDecimal']['output'];
  readonly conversions: Scalars['BigDecimal']['output'];
  readonly cost: Scalars['BigDecimal']['output'];
  readonly cpa: Scalars['BigDecimal']['output'];
  readonly cpc: Scalars['BigDecimal']['output'];
  readonly cpm: Scalars['BigDecimal']['output'];
  readonly ctr: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly cvr: Scalars['BigDecimal']['output'];
  readonly datasourceId: Scalars['Long']['output'];
  readonly dimensionValues?: Maybe<ReadonlyArray<KeywordDetailedReportDimensionValuesPayload>>;
  readonly id: Scalars['Long']['output'];
  readonly impressions: Scalars['BigDecimal']['output'];
  readonly keyword: Scalars['String']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type KeywordDetailedReportPayloadList = {
  readonly __typename?: 'KeywordDetailedReportPayloadList';
  readonly items: ReadonlyArray<KeywordDetailedReportPayload>;
  readonly meta: ReportListMeta;
};

export type KeywordSummaryInput = {
  readonly campaignId: Scalars['Long']['input'];
  readonly datasourceId: Scalars['Long']['input'];
  readonly endDate: Scalars['LocalDate']['input'];
  readonly groupId: Scalars['Long']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pricesView: AdReportPricesView;
  readonly startDate: Scalars['LocalDate']['input'];
};

export type LineConnection = {
  readonly __typename?: 'LINEConnection';
  readonly botBasicId: Scalars['String']['output'];
  readonly botChannelId: Scalars['String']['output'];
  readonly botDisplayName: Scalars['String']['output'];
  readonly botUserId: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly loginChannelId?: Maybe<Scalars['String']['output']>;
};

export type LineLoginRequest = {
  readonly authorizationCode: Scalars['String']['input'];
  readonly loginChannelId: Scalars['String']['input'];
  readonly redirectUri: Scalars['String']['input'];
};

export type LineLoginResponse = {
  readonly __typename?: 'LINELoginResponse';
  readonly idToken: Scalars['String']['output'];
  readonly lineConnection: LineConnection;
  readonly lineUser: LineUser;
};

export type LineLoginShopifyLoginRequest = {
  readonly idToken: Scalars['String']['input'];
};

export type LineLoginShopifyLoginResponse = {
  readonly __typename?: 'LINELoginShopifyLoginResponse';
  readonly email: Scalars['String']['output'];
  readonly generatedPassword: Scalars['String']['output'];
};

export type LineLoginShopifySignUpRequest = {
  readonly email: Scalars['String']['input'];
  readonly idToken: Scalars['String']['input'];
};

export type LineLoginShopifySignUpResponse = {
  readonly __typename?: 'LINELoginShopifySignUpResponse';
  readonly email: Scalars['String']['output'];
  readonly generatedPassword: Scalars['String']['output'];
};

export type LineMessageAction = {
  readonly __typename?: 'LINEMessageAction';
  readonly templateId: Scalars['String']['output'];
};

export type LineMessageActionInput = {
  readonly templateContentJSON?: InputMaybe<Scalars['String']['input']>;
  readonly templateId?: InputMaybe<Scalars['String']['input']>;
};

export type LineMessageItemInput = {
  readonly buttonMessageV2?: InputMaybe<ButtonMessageInputV2>;
  readonly imageMessage?: InputMaybe<ImageMessageInput>;
  readonly mediaMessage?: InputMaybe<ImageVideoMessageInputInput>;
  readonly plainTextMessage?: InputMaybe<PlainTextMessageInput>;
  readonly richMessage?: InputMaybe<RichMessageInput>;
  readonly type: LineMessageType;
};

export type LineUser = {
  readonly __typename?: 'LINEUser';
  readonly chatId?: Maybe<Scalars['String']['output']>;
  readonly displayName: Scalars['String']['output'];
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly pictureUrl?: Maybe<Scalars['String']['output']>;
  readonly userId: Scalars['String']['output'];
};

export const Language = {
  EN_US: 'EN_US',
  JA_JP: 'JA_JP',
  KO_KR: 'KO_KR',
  ZH_CN: 'ZH_CN',
  ZH_TW: 'ZH_TW'
} as const;

export type Language = typeof Language[keyof typeof Language];
export type LazadaChatEvent = CommonChatEvent | LazadaMessageEvent;

export type LazadaChatEvents = {
  readonly __typename?: 'LazadaChatEvents';
  readonly chatEvents: ReadonlyArray<LazadaChatEvent>;
  readonly chatId: Scalars['String']['output'];
  readonly lastCheckedAt?: Maybe<Scalars['UnixTimestamp']['output']>;
  readonly userName: Scalars['String']['output'];
};

export type LazadaChatPlatformDetail = {
  readonly __typename?: 'LazadaChatPlatformDetail';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type LazadaEmojiMessageContent = {
  readonly __typename?: 'LazadaEmojiMessageContent';
  readonly iconUrl: Scalars['Url']['output'];
  readonly text: Scalars['String']['output'];
};

export type LazadaFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
  readonly type: LazadaFileUploadType;
};

export const LazadaFileUploadType = {
  AD_COST_SPONSOR_DISCOVERY: 'AD_COST_SPONSOR_DISCOVERY',
  SESSION: 'SESSION'
} as const;

export type LazadaFileUploadType = typeof LazadaFileUploadType[keyof typeof LazadaFileUploadType];
export type LazadaImageMessageContent = {
  readonly __typename?: 'LazadaImageMessageContent';
  readonly url: Scalars['Url']['output'];
};

export type LazadaInfo = ChatInfo & {
  readonly __typename?: 'LazadaInfo';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type LazadaItemMessageContent = {
  readonly __typename?: 'LazadaItemMessageContent';
  readonly actionUrl?: Maybe<Scalars['Url']['output']>;
  readonly iconUrl: Scalars['Url']['output'];
  readonly id: Scalars['String']['output'];
  readonly itemUrlPath?: Maybe<Scalars['String']['output']>;
  readonly title: Scalars['String']['output'];
};

export type LazadaLoginUrlV2Input = {
  readonly redirectUri: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export const LazadaMarketplace = {
  ID: 'ID',
  MY: 'MY',
  PH: 'PH',
  SG: 'SG',
  TH: 'TH',
  VN: 'VN'
} as const;

export type LazadaMarketplace = typeof LazadaMarketplace[keyof typeof LazadaMarketplace];
export type LazadaMessage = {
  readonly content: LazadaMessageContent;
  readonly messageId: Scalars['String']['output'];
};

export type LazadaMessageContent = LazadaEmojiMessageContent | LazadaImageMessageContent | LazadaItemMessageContent | LazadaOrderMessageContent | LazadaTextMessageContent | LazadaUnsupportedMessageContent | LazadaVideoMessageContent;

export type LazadaMessageEvent = {
  readonly __typename?: 'LazadaMessageEvent';
  readonly id: Scalars['String']['output'];
  readonly message: LazadaMessage;
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type LazadaOrderMessageContent = {
  readonly __typename?: 'LazadaOrderMessageContent';
  readonly anyxOrderId?: Maybe<Scalars['Long']['output']>;
  readonly iconUrl: Scalars['Url']['output'];
  readonly id: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type LazadaOrderProductDetail = {
  readonly __typename?: 'LazadaOrderProductDetail';
  readonly externalId: Scalars['String']['output'];
  readonly isFreeGift: Scalars['Boolean']['output'];
  readonly lazadaVoucher: Scalars['BigDecimal']['output'];
  readonly paidPrice: Scalars['BigDecimal']['output'];
  readonly sellerVoucher: Scalars['BigDecimal']['output'];
};

export type LazadaSaleChannelData = {
  readonly __typename?: 'LazadaSaleChannelData';
  readonly marketplace: LazadaMarketplace;
};

export type LazadaSalesChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type LazadaSalesChartPayload = {
  readonly __typename?: 'LazadaSalesChartPayload';
  readonly conversionRate: ReadonlyArray<Scalars['Map']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly orders: ReadonlyArray<Scalars['Map']['output']>;
  readonly sales: ReadonlyArray<Scalars['Map']['output']>;
  readonly unitsOrdered: ReadonlyArray<Scalars['Map']['output']>;
  readonly visitors: ReadonlyArray<Scalars['Map']['output']>;
};

export type LazadaSalesDetailedReportInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type LazadaSalesDetailedReportPayload = {
  readonly __typename?: 'LazadaSalesDetailedReportPayload';
  readonly conversionRate: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly unitsOrdered: Scalars['Long']['output'];
  readonly visitors: Scalars['Long']['output'];
};

export type LazadaSalesDetailedReportPayloadList = {
  readonly __typename?: 'LazadaSalesDetailedReportPayloadList';
  readonly items: ReadonlyArray<LazadaSalesDetailedReportPayload>;
  readonly meta: ReportListMeta;
  readonly total: LazadaSalesDetailedReportTotalPayload;
};

export type LazadaSalesDetailedReportTotalPayload = {
  readonly __typename?: 'LazadaSalesDetailedReportTotalPayload';
  readonly conversionRate: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly orders: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly unitsOrdered: Scalars['Long']['output'];
  readonly visitors: Scalars['Long']['output'];
};

export type LazadaSalesSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type LazadaSalesSummaryPayload = {
  readonly __typename?: 'LazadaSalesSummaryPayload';
  readonly conversionRate: NumericValueWithDiffPayload;
  readonly currency: ReportSupportedCurrency;
  readonly orders: NumericValueWithDiffPayload;
  readonly sales: NumericValueWithDiffPayload;
  readonly unitsOrdered: NumericValueWithDiffPayload;
  readonly visitors: NumericValueWithDiffPayload;
};

export type LazadaSendImageMessageRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly orderId?: InputMaybe<Scalars['String']['input']>;
  readonly url: Scalars['String']['input'];
};

export type LazadaSendTextMessageRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly orderId?: InputMaybe<Scalars['String']['input']>;
  readonly text: Scalars['String']['input'];
};

export type LazadaSpecificData = {
  readonly __typename?: 'LazadaSpecificData';
  readonly orderProductDetails: ReadonlyArray<LazadaOrderProductDetail>;
  readonly totalLazadaDiscount: Scalars['BigDecimal']['output'];
  readonly totalSellerDiscount: Scalars['BigDecimal']['output'];
};

export type LazadaStoreCsMessage = LazadaMessage & {
  readonly __typename?: 'LazadaStoreCSMessage';
  readonly author: BasicUser;
  readonly content: LazadaMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly messageState: MessageState;
};

export type LazadaStoreChat = StoreChat & {
  readonly __typename?: 'LazadaStoreChat';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly tokenUpdatedSuccessfully: Scalars['Boolean']['output'];
  readonly type: ChatType;
};

export type LazadaTargetInput = {
  readonly type: LazadaTargetType;
};

export const LazadaTargetType = {
  CHAT_MESSAGE: 'CHAT_MESSAGE'
} as const;

export type LazadaTargetType = typeof LazadaTargetType[keyof typeof LazadaTargetType];
export type LazadaTextMessageContent = {
  readonly __typename?: 'LazadaTextMessageContent';
  readonly text: Scalars['String']['output'];
};

export type LazadaUnsupportedMessageContent = {
  readonly __typename?: 'LazadaUnsupportedMessageContent';
  readonly messageType: Scalars['String']['output'];
};

export type LazadaUserMessage = LazadaMessage & {
  readonly __typename?: 'LazadaUserMessage';
  readonly content: LazadaMessageContent;
  readonly messageId: Scalars['String']['output'];
};

export type LazadaVideoMessageContent = {
  readonly __typename?: 'LazadaVideoMessageContent';
  readonly firstFrameUrl: Scalars['Url']['output'];
  readonly videoUrl?: Maybe<Scalars['Url']['output']>;
};

export type LiffIdTokenValidateRequest = {
  readonly idToken: Scalars['String']['input'];
};

export type LiffIdTokenValidateResponse = {
  readonly __typename?: 'LiffIdTokenValidateResponse';
  readonly lineConnection: LineConnection;
  readonly lineUser: LineUser;
};

export type LiffInfluencerConnectRequestInput = {
  readonly idToken: Scalars['String']['input'];
  readonly influencerId: Scalars['Long']['input'];
};

export type LiffInfluencerConnectResponse = {
  readonly __typename?: 'LiffInfluencerConnectResponse';
  readonly botBasicId: Scalars['String']['output'];
};

export type LiffInquirySendRequest = {
  readonly email: Scalars['String']['input'];
  readonly idToken: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly text: Scalars['String']['input'];
};

export type LiffShopifyConnectRequest = {
  readonly email: Scalars['String']['input'];
  readonly idToken: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
};

export type LiffShopifyCustomerCreateRequest = {
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly idToken: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
};

export type LineBotGreetingMessageEvent = {
  readonly __typename?: 'LineBotGreetingMessageEvent';
  readonly templateId: Scalars['String']['output'];
};

export type LineBotImageMessageEvent = {
  readonly __typename?: 'LineBotImageMessageEvent';
  readonly author: BasicUser;
  readonly originalUrl: Scalars['String']['output'];
  readonly previewUrl: Scalars['String']['output'];
};

export type LineBotTextMessageEvent = {
  readonly __typename?: 'LineBotTextMessageEvent';
  readonly author: MessageAuthor;
  readonly messageState: MessageState;
  readonly text: Scalars['String']['output'];
};

export type LineBotUnitMessageEvent = {
  readonly __typename?: 'LineBotUnitMessageEvent';
  readonly messages: ReadonlyArray<LineMessage>;
};

export type LineBroadcastMessage = {
  readonly __typename?: 'LineBroadcastMessage';
  readonly content: ReadonlyArray<LineMessage>;
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly deliveredAt?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly deliveryTiming: DeliveryTiming;
  readonly id: Scalars['String']['output'];
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly recipient: Recipient;
  readonly statistics?: Maybe<BroadcastStatistics>;
  readonly status: BroadcastStatus;
  readonly trackingEnabled: Scalars['Boolean']['output'];
  readonly trackingUrls?: Maybe<ReadonlyArray<TrackingUrl>>;
  readonly urlLinkGroupId: Scalars['String']['output'];
};

export type LineBroadcastMessageCreateRequestInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly lineContent: LineContentInputInput;
  readonly originalUrls: ReadonlyArray<Scalars['Url']['input']>;
  readonly recipient: RecipientItemInput;
  readonly storeId: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
  readonly trackingEnabled: Scalars['Boolean']['input'];
  readonly urlLinkGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type LineBroadcastMessageCreateResponse = {
  readonly __typename?: 'LineBroadcastMessageCreateResponse';
  readonly messageId: Scalars['String']['output'];
};

export type LineBroadcastMessageUpdateRequestInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly lineBroadcastMessageId: Scalars['String']['input'];
  readonly lineContent: LineContentInputInput;
  readonly originalUrls: ReadonlyArray<Scalars['Url']['input']>;
  readonly recipient: RecipientItemInput;
  readonly title: Scalars['String']['input'];
  readonly trackingEnabled: Scalars['Boolean']['input'];
};

export type LineBroadcastMessageUpdateResponse = {
  readonly __typename?: 'LineBroadcastMessageUpdateResponse';
  readonly messageId: Scalars['String']['output'];
};

export type LineBroadcastMessages = {
  readonly __typename?: 'LineBroadcastMessages';
  readonly messages: ReadonlyArray<LineBroadcastMessage>;
  readonly total: Scalars['Int']['output'];
};

export type LineBroadcastSortByInput = {
  readonly descending: Scalars['Boolean']['input'];
  readonly field: LineBroadcastSortField;
};

export const LineBroadcastSortField = {
  AverageOrderValue: 'AverageOrderValue',
  Click: 'Click',
  ClickRate: 'ClickRate',
  Conversion: 'Conversion',
  ConversionRate: 'ConversionRate',
  CreatedDate: 'CreatedDate',
  DeliveredCount: 'DeliveredCount',
  DeliveredDate: 'DeliveredDate',
  Open: 'Open',
  SaleAmount: 'SaleAmount'
} as const;

export type LineBroadcastSortField = typeof LineBroadcastSortField[keyof typeof LineBroadcastSortField];
export type LineBroadcastStatisticSummaries = {
  readonly __typename?: 'LineBroadcastStatisticSummaries';
  readonly compareStatistics?: Maybe<BroadcastStatistics>;
  readonly diffStatistics?: Maybe<BroadcastStatistics>;
  readonly sumStatistics: BroadcastStatistics;
};

export type LineCardButtonAction = LinkedPostbackAction | UrlAction;

export const LineCardButtonActionType = {
  LINKED_POSTBACK_BUTTON_ACTION: 'LINKED_POSTBACK_BUTTON_ACTION',
  URL_BUTTON_ACTION: 'URL_BUTTON_ACTION'
} as const;

export type LineCardButtonActionType = typeof LineCardButtonActionType[keyof typeof LineCardButtonActionType];
export type LineChatEvent = CommonChatEvent | LinePlatformEvent;

export type LineChatEvents = {
  readonly __typename?: 'LineChatEvents';
  readonly chatEvents: ReadonlyArray<LineChatEvent>;
  readonly chatId: Scalars['String']['output'];
  readonly lastCheckedAt?: Maybe<Scalars['UnixTimestamp']['output']>;
  readonly pageCursor?: Maybe<Scalars['String']['output']>;
  readonly userName: Scalars['String']['output'];
};

export type LineChatPlatformDetail = {
  readonly __typename?: 'LineChatPlatformDetail';
  readonly isFollow: Scalars['Boolean']['output'];
};

export type LineContentInputInput = {
  readonly messages: ReadonlyArray<LineMessageItemInput>;
};

export type LineInfluencerJoinedCampaignEvent = {
  readonly __typename?: 'LineInfluencerJoinedCampaignEvent';
  readonly campaignName: Scalars['String']['output'];
};

export type LineInfo = ChatInfo & {
  readonly __typename?: 'LineInfo';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type LineInquiryEvent = {
  readonly __typename?: 'LineInquiryEvent';
  readonly inquiry: Scalars['String']['output'];
};

export type LineInquiryFinishEvent = {
  readonly __typename?: 'LineInquiryFinishEvent';
  readonly author: BasicUser;
};

export type LineMessage = ButtonMessage | ImageMessage | ImageVideoMessage | PlainTextMessage | RichMessage;

export type LineMessageQuotaInfo = {
  readonly __typename?: 'LineMessageQuotaInfo';
  readonly consumption: Scalars['Int']['output'];
  readonly limit?: Maybe<Scalars['Int']['output']>;
};

export type LineMessageTemplateEvent = {
  readonly __typename?: 'LineMessageTemplateEvent';
  readonly templateId: Scalars['String']['output'];
  readonly templateName: Scalars['String']['output'];
};

export const LineMessageType = {
  BUTTON: 'BUTTON',
  IMAGES: 'IMAGES',
  IMAGE_VIDEO: 'IMAGE_VIDEO',
  PLAIN_TEXT: 'PLAIN_TEXT',
  RICH: 'RICH'
} as const;

export type LineMessageType = typeof LineMessageType[keyof typeof LineMessageType];
export type LinePlatformEvent = {
  readonly __typename?: 'LinePlatformEvent';
  readonly id: Scalars['String']['output'];
  readonly message: LinePlatformEventContent;
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type LinePlatformEventContent = LineBotGreetingMessageEvent | LineBotImageMessageEvent | LineBotTextMessageEvent | LineBotUnitMessageEvent | LineInfluencerJoinedCampaignEvent | LineInquiryEvent | LineInquiryFinishEvent | LineMessageTemplateEvent | LineStateUpdateEvent | LineSystemFoundFollowerEvent | LineUserAudioMessageEvent | LineUserFileMessageEvent | LineUserFollowedEvent | LineUserImageMessageEvent | LineUserLocationMessageEvent | LineUserPostbackEvent | LineUserStickerMessageEvent | LineUserTextMessageEvent | LineUserUnFollowedEvent | LineUserVideoMessageEvent;

export type LineRichMenu = {
  readonly __typename?: 'LineRichMenu';
  readonly chatBarText: Scalars['String']['output'];
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly displayPeriod?: Maybe<DateRange>;
  readonly id: Scalars['String']['output'];
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly showMenuByDefault: Scalars['Boolean']['output'];
  readonly status: LineRichMenuStatus;
  readonly template: LineRichMenuTemplate;
  readonly title: Scalars['String']['output'];
  readonly updatedAt: Scalars['OffsetDateTime']['output'];
};

export type LineRichMenuAction = LineRichMenuMessageAction | LineRichMenuPostbackAction | LineRichMenuUriAction;

export type LineRichMenuActionInput = {
  readonly actionInputType: LineRichMenuActionType;
  readonly postback?: InputMaybe<LineRichMenuPostbackActionInput>;
  readonly text?: InputMaybe<LineRichMenuMessageActionInput>;
  readonly uri?: InputMaybe<LineRichMenuUriActionInput>;
};

export const LineRichMenuActionType = {
  LINK: 'LINK',
  NO_ACTION: 'NO_ACTION',
  POSTBACK: 'POSTBACK',
  TEXT: 'TEXT'
} as const;

export type LineRichMenuActionType = typeof LineRichMenuActionType[keyof typeof LineRichMenuActionType];
export type LineRichMenuCreateDraftInput = {
  readonly richMenu: LineRichMenuInput;
  readonly storeId: Scalars['String']['input'];
};

export type LineRichMenuCreateDraftResponse = {
  readonly __typename?: 'LineRichMenuCreateDraftResponse';
  readonly id: Scalars['String']['output'];
};

export type LineRichMenuDisplayPeriodInput = {
  readonly dateRangeInput?: InputMaybe<DateRangeInput>;
};

export type LineRichMenuInput = {
  readonly chatBarText: Scalars['String']['input'];
  readonly displayPeriod: LineRichMenuDisplayPeriodInput;
  readonly imageUrl?: InputMaybe<Scalars['String']['input']>;
  readonly showMenuByDefault: Scalars['Boolean']['input'];
  readonly template: LineRichMenuTemplateInput;
  readonly title: Scalars['String']['input'];
};

export type LineRichMenuListItem = {
  readonly __typename?: 'LineRichMenuListItem';
  readonly displayPeriod?: Maybe<DateRange>;
  readonly id: Scalars['String']['output'];
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly status: LineRichMenuStatus;
  readonly title: Scalars['String']['output'];
};

export type LineRichMenuMessageAction = {
  readonly __typename?: 'LineRichMenuMessageAction';
  readonly text: Scalars['String']['output'];
};

export type LineRichMenuMessageActionInput = {
  readonly text: Scalars['String']['input'];
};

export type LineRichMenuPostbackAction = {
  readonly __typename?: 'LineRichMenuPostbackAction';
  readonly trigger: Scalars['String']['output'];
};

export type LineRichMenuPostbackActionInput = {
  readonly trigger: Scalars['String']['input'];
};

export const LineRichMenuStatus = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE',
  SCHEDULED: 'SCHEDULED'
} as const;

export type LineRichMenuStatus = typeof LineRichMenuStatus[keyof typeof LineRichMenuStatus];
export type LineRichMenuSubmitFailExistPermanent = {
  readonly __typename?: 'LineRichMenuSubmitFailExistPermanent';
  readonly existingId: Scalars['String']['output'];
};

export type LineRichMenuSubmitFailOverlap = {
  readonly __typename?: 'LineRichMenuSubmitFailOverlap';
  readonly overlapIds: ReadonlyArray<Scalars['String']['output']>;
};

export type LineRichMenuSubmitInput = {
  readonly forceReplace: Scalars['Boolean']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly richMenu: LineRichMenuInput;
  readonly storeId: Scalars['String']['input'];
};

export type LineRichMenuSubmitResponse = LineRichMenuSubmitFailExistPermanent | LineRichMenuSubmitFailOverlap | LineRichMenuSubmitSuccess;

export type LineRichMenuSubmitSuccess = {
  readonly __typename?: 'LineRichMenuSubmitSuccess';
  readonly id: Scalars['String']['output'];
};

export type LineRichMenuTemplate = {
  readonly __typename?: 'LineRichMenuTemplate';
  readonly actions: ReadonlyArray<Maybe<LineRichMenuAction>>;
  readonly id: LineRichMenuTemplateId;
};

export const LineRichMenuTemplateId = {
  T1: 'T1',
  T2: 'T2',
  T3: 'T3',
  T4: 'T4',
  T5: 'T5',
  T6: 'T6'
} as const;

export type LineRichMenuTemplateId = typeof LineRichMenuTemplateId[keyof typeof LineRichMenuTemplateId];
export type LineRichMenuTemplateInput = {
  readonly actions: ReadonlyArray<LineRichMenuActionInput>;
  readonly templateId: LineRichMenuTemplateId;
};

export type LineRichMenuUpdateFailExistPermanent = {
  readonly __typename?: 'LineRichMenuUpdateFailExistPermanent';
  readonly existingId: Scalars['String']['output'];
};

export type LineRichMenuUpdateFailOverlap = {
  readonly __typename?: 'LineRichMenuUpdateFailOverlap';
  readonly overlapIds: ReadonlyArray<Scalars['String']['output']>;
};

export type LineRichMenuUpdateInput = {
  readonly backToDraft: Scalars['Boolean']['input'];
  readonly forceReplace: Scalars['Boolean']['input'];
  readonly id: Scalars['String']['input'];
  readonly richMenu: LineRichMenuInput;
};

export type LineRichMenuUpdateResponse = LineRichMenuUpdateFailExistPermanent | LineRichMenuUpdateFailOverlap | LineRichMenuUpdateSuccess;

export type LineRichMenuUpdateSuccess = {
  readonly __typename?: 'LineRichMenuUpdateSuccess';
  readonly id: Scalars['String']['output'];
};

export type LineRichMenuUriAction = {
  readonly __typename?: 'LineRichMenuUriAction';
  readonly uri: Scalars['Url']['output'];
};

export type LineRichMenuUriActionInput = {
  readonly uri: Scalars['Url']['input'];
};

export type LineRichMenusInput = {
  readonly dateRange?: InputMaybe<DateRangeInput>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly status?: InputMaybe<LineRichMenuStatus>;
  readonly storeId: Scalars['String']['input'];
};

export type LineSendImageMessageRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly originalUrl: Scalars['String']['input'];
  readonly previewUrl: Scalars['String']['input'];
};

export type LineSendTextMessageRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly text: Scalars['String']['input'];
};

export type LineStateUpdateEvent = {
  readonly __typename?: 'LineStateUpdateEvent';
  readonly id: Scalars['String']['output'];
};

export type LineStickerData = AnimatedSticker | OtherSticker | StaticSticker;

export type LineStoreChat = StoreChat & {
  readonly __typename?: 'LineStoreChat';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly isAIReplyEnable: Scalars['Boolean']['output'];
  readonly isRefreshTokenSuccess: Scalars['Boolean']['output'];
  readonly messageQuota: LineMessageQuotaInfo;
  readonly name: Scalars['String']['output'];
  readonly type: ChatType;
};

export type LineSystemFoundFollowerEvent = {
  readonly __typename?: 'LineSystemFoundFollowerEvent';
  readonly username: Scalars['String']['output'];
};

export type LineTargetInput = {
  readonly messageType?: InputMaybe<LineMessageType>;
  readonly type: LineTargetType;
};

export const LineTargetType = {
  BROADCAST_MESSAGE: 'BROADCAST_MESSAGE',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  GREETING_MESSAGE: 'GREETING_MESSAGE',
  RICH_MENU: 'RICH_MENU',
  UNIT_MESSAGE: 'UNIT_MESSAGE'
} as const;

export type LineTargetType = typeof LineTargetType[keyof typeof LineTargetType];
export type LineUserAudioMessageEvent = {
  readonly __typename?: 'LineUserAudioMessageEvent';
  readonly contentUrl: Scalars['String']['output'];
  readonly duration: Scalars['Int']['output'];
};

export type LineUserFileMessageEvent = {
  readonly __typename?: 'LineUserFileMessageEvent';
  readonly contentUrl: Scalars['String']['output'];
  readonly fileName: Scalars['String']['output'];
};

export type LineUserFollowedEvent = {
  readonly __typename?: 'LineUserFollowedEvent';
  readonly username: Scalars['String']['output'];
};

export type LineUserImageMessageEvent = {
  readonly __typename?: 'LineUserImageMessageEvent';
  readonly imageUrl: Scalars['String']['output'];
};

export type LineUserLocationMessageEvent = {
  readonly __typename?: 'LineUserLocationMessageEvent';
  readonly address: Scalars['String']['output'];
  readonly latitude: Scalars['String']['output'];
  readonly longitude: Scalars['String']['output'];
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type LineUserPostbackEvent = {
  readonly __typename?: 'LineUserPostbackEvent';
  readonly displayText: Scalars['String']['output'];
};

export type LineUserRequest = {
  readonly idToken: Scalars['String']['input'];
};

export type LineUserStickerMessageEvent = {
  readonly __typename?: 'LineUserStickerMessageEvent';
  readonly stickerData: LineStickerData;
};

export type LineUserTextMessageEvent = {
  readonly __typename?: 'LineUserTextMessageEvent';
  readonly text: Scalars['String']['output'];
};

export type LineUserUnFollowedEvent = {
  readonly __typename?: 'LineUserUnFollowedEvent';
  readonly username: Scalars['String']['output'];
};

export type LineUserVideoMessageEvent = {
  readonly __typename?: 'LineUserVideoMessageEvent';
  readonly contentUrl: Scalars['String']['output'];
  readonly duration: Scalars['Int']['output'];
};

export type LinkedPostbackAction = {
  readonly __typename?: 'LinkedPostbackAction';
  readonly messageId: Scalars['String']['output'];
};

export type LinkedPostbackActionInput = {
  readonly messageId: Scalars['String']['input'];
};

export type ListMeta = {
  /** Length in this query */
  readonly length: Scalars['Int']['output'];
  /** Page size */
  readonly limit: Scalars['Int']['output'];
  /** Start position of this query */
  readonly start: Scalars['Int']['output'];
  /** Total length of items including other pages */
  readonly total: Scalars['Int']['output'];
};

export type Listing = {
  readonly __typename?: 'Listing';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly description: MarketplaceDescription;
  readonly id: Scalars['Long']['output'];
  readonly media: ReadonlyArray<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly saleChannel: SaleChannel;
  readonly saleChannelId: Scalars['Long']['output'];
  readonly status: ListingStatus;
  readonly updatedAt: Scalars['OffsetDateTime']['output'];
};

export type ListingDetail = {
  readonly __typename?: 'ListingDetail';
  readonly description: MarketplaceDescription;
  readonly id: Scalars['Long']['output'];
  readonly listingVariants: ReadonlyArray<ListingVariant>;
  readonly media: ReadonlyArray<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly productIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly saleChannel: SaleChannel;
  readonly saleChannelId: Scalars['Long']['output'];
  readonly status: ListingStatus;
  readonly variantIds: ReadonlyArray<Scalars['Long']['output']>;
};

export type ListingInfo = {
  readonly __typename?: 'ListingInfo';
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly saleChannelType: SaleChannelType;
};

export type ListingInfoPayload = {
  readonly __typename?: 'ListingInfoPayload';
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly saleChannelType: SaleChannelType;
};

export type ListingListFilterInput = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType?: InputMaybe<MarketplaceSaleChannelType>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
};

/** Listing list view. {start}-{end} / {total}. page {page} of {totalPage} */
export type ListingListView = {
  readonly __typename?: 'ListingListView';
  readonly end: Scalars['Int']['output'];
  readonly items: ReadonlyArray<ListingRecord>;
  readonly page: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type ListingName = {
  readonly __typename?: 'ListingName';
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

/** singleVariant will be appeared only when numOfVariant = 1, otherwise it will be null */
export type ListingRecord = {
  readonly __typename?: 'ListingRecord';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['Long']['output'];
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly numOfVariant: Scalars['Int']['output'];
  readonly saleChannel: SaleChannel;
  readonly saleChannelId: Scalars['Long']['output'];
  readonly singleVariant?: Maybe<SingleVariant>;
  readonly status: ListingStatus;
  readonly totalFreeStock: Scalars['Int']['output'];
};

export const ListingStatus = {
  ACTIVE: 'ACTIVE',
  UNLISTED: 'UNLISTED'
} as const;

export type ListingStatus = typeof ListingStatus[keyof typeof ListingStatus];
export type ListingTriggerSyncInput = {
  readonly saleChannelId: Scalars['Long']['input'];
};

export type ListingVariant = {
  readonly __typename?: 'ListingVariant';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly currency: Scalars['String']['output'];
  readonly freeStock: Scalars['Int']['output'];
  readonly id: Scalars['Long']['output'];
  readonly listing: Listing;
  readonly listingId: Scalars['Long']['output'];
  readonly media: ReadonlyArray<Scalars['URL']['output']>;
  readonly price: Scalars['BigDecimal']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly sellerSKUCode: Scalars['String']['output'];
  readonly updatedAt: Scalars['OffsetDateTime']['output'];
  readonly values: ReadonlyArray<Scalars['String']['output']>;
};

export type LiveCommentAutoReplyInfo = AutoReplyInfo & {
  readonly __typename?: 'LiveCommentAutoReplyInfo';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly trigger: AutomationFlowTrigger;
};

export const LiveCommentAutoReplyTiming = {
  ON_COMMENT_RECEIVED: 'ON_COMMENT_RECEIVED',
  ON_LIVE_ENDED: 'ON_LIVE_ENDED'
} as const;

export type LiveCommentAutoReplyTiming = typeof LiveCommentAutoReplyTiming[keyof typeof LiveCommentAutoReplyTiming];
export type LogilessMerchant = {
  readonly __typename?: 'LogilessMerchant';
  readonly id: Scalars['ID']['output'];
};

export type LogisticChannel = {
  readonly __typename?: 'LogisticChannel';
  readonly chargeCODFee: Scalars['Boolean']['output'];
  readonly created: Scalars['Time']['output'];
  readonly externalStoreId?: Maybe<Scalars['ID']['output']>;
  readonly externalStoreName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly merchantId?: Maybe<Scalars['String']['output']>;
  readonly preferredDeliveryDateTime: Scalars['Boolean']['output'];
  readonly provider: Provider;
  readonly saleChannelId?: Maybe<Scalars['Long']['output']>;
  readonly status?: Maybe<LogisticChannelStatus>;
  readonly validateAddress: Scalars['Boolean']['output'];
  readonly verified: Scalars['Boolean']['output'];
  readonly warehouseId?: Maybe<Scalars['ID']['output']>;
  readonly warehouseName: Scalars['String']['output'];
  readonly wmsType: WmsType;
};

export type LogisticChannelRequestForm = {
  readonly __typename?: 'LogisticChannelRequestForm';
  readonly accountId: Scalars['Long']['output'];
  readonly created: Scalars['Time']['output'];
  readonly id: Scalars['Long']['output'];
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly saleChannelId: Scalars['Long']['output'];
  readonly status: ChannelFormStatus;
  readonly updated: Scalars['Time']['output'];
};

export const LogisticChannelStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING'
} as const;

export type LogisticChannelStatus = typeof LogisticChannelStatus[keyof typeof LogisticChannelStatus];
export const LogisticFeatureFlag = {
  ADVANCED_LOGISTICS: 'ADVANCED_LOGISTICS',
  BASIC_LOGISTICS: 'BASIC_LOGISTICS',
  NO_LOGISTICS: 'NO_LOGISTICS'
} as const;

export type LogisticFeatureFlag = typeof LogisticFeatureFlag[keyof typeof LogisticFeatureFlag];
export const LogisticShippingRateConditionType = {
  BASED_ON_ORDER_ITEM_WEIGHT: 'BASED_ON_ORDER_ITEM_WEIGHT',
  BASED_ON_ORDER_PRICE: 'BASED_ON_ORDER_PRICE',
  NO_CONDITION: 'NO_CONDITION'
} as const;

export type LogisticShippingRateConditionType = typeof LogisticShippingRateConditionType[keyof typeof LogisticShippingRateConditionType];
export type LogisticsInput = {
  readonly courierService: CourierService;
  readonly deliveryNote: Scalars['String']['input'];
  /** yyyy-MM-dd */
  readonly preferredDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  readonly preferredDeliveryTime?: InputMaybe<DesiredDeliveryTimeframe>;
  readonly temperature: OrderTemperature;
};

export type MagaseekFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: MagaseekFileUploadType;
};

export const MagaseekFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type MagaseekFileUploadType = typeof MagaseekFileUploadType[keyof typeof MagaseekFileUploadType];
export type MakeshopFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: MakeshopFileUploadType;
};

export const MakeshopFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type MakeshopFileUploadType = typeof MakeshopFileUploadType[keyof typeof MakeshopFileUploadType];
export type ManualAssign = {
  readonly __typename?: 'ManualAssign';
  readonly assignee: ChatAssignee;
};

export type ManualCostFilterInput = {
  readonly endDate?: InputMaybe<Scalars['ZonedDatetime']['input']>;
  readonly masterDataStoreId?: InputMaybe<Scalars['Long']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly startDate?: InputMaybe<Scalars['ZonedDatetime']['input']>;
  readonly type?: InputMaybe<ManualCostType>;
};

export type ManualCostPayload = {
  readonly __typename?: 'ManualCostPayload';
  readonly amount?: Maybe<Scalars['BigDecimal']['output']>;
  readonly category: CostCategory;
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly currency?: Maybe<ReportSupportedCurrency>;
  readonly endDate: Scalars['ZonedDatetime']['output'];
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
  readonly percentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly startDate: Scalars['ZonedDatetime']['output'];
  readonly type: ManualCostType;
  readonly updatedAt: Scalars['ZonedDatetime']['output'];
};

export type ManualCostPayloadList = {
  readonly __typename?: 'ManualCostPayloadList';
  readonly items: ReadonlyArray<ManualCostPayload>;
  readonly meta: ReportListMeta;
};

export const ManualCostSortByField = {
  CREATED_AT: 'CREATED_AT',
  ID: 'ID',
  TYPE: 'TYPE',
  UPDATED_AT: 'UPDATED_AT'
} as const;

export type ManualCostSortByField = typeof ManualCostSortByField[keyof typeof ManualCostSortByField];
export type ManualCostSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: ManualCostSortByField;
};

export const ManualCostType = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE'
} as const;

export type ManualCostType = typeof ManualCostType[keyof typeof ManualCostType];
export const ManualOrderUploadType = {
  PER_ORDER: 'PER_ORDER',
  PER_PRODUCT: 'PER_PRODUCT'
} as const;

export type ManualOrderUploadType = typeof ManualOrderUploadType[keyof typeof ManualOrderUploadType];
export type ManualSetProductCsvUploadInput = {
  readonly fileUri: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export type ManualSetProductCsvUploadPayload = {
  readonly __typename?: 'ManualSetProductCsvUploadPayload';
  readonly ok: Scalars['Boolean']['output'];
};

export type ManualSingleProductCsvUploadInput = {
  readonly fileUri: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export type ManualSingleProductCsvUploadPayload = {
  readonly __typename?: 'ManualSingleProductCsvUploadPayload';
  readonly ok: Scalars['Boolean']['output'];
};

export type ManualUnassign = {
  readonly __typename?: 'ManualUnassign';
  readonly assignee: ChatAssignee;
};

export type ManualUpdateProductCsvUploadInput = {
  readonly fileUri: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export type ManualUpdateProductCsvUploadPayload = {
  readonly __typename?: 'ManualUpdateProductCsvUploadPayload';
  readonly ok: Scalars['Boolean']['output'];
};

/** Manual channel and other manual channels */
export type ManualsSpecificData = {
  readonly __typename?: 'ManualsSpecificData';
  readonly editable: Scalars['Boolean']['output'];
};

export type MarginSettingInput = {
  readonly adSetMappingCampaignId: Scalars['Long']['input'];
  readonly marginPercentage: Scalars['BigDecimal']['input'];
};

export type MarketplaceCalendarEventPayload = {
  readonly __typename?: 'MarketplaceCalendarEventPayload';
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly endDate: Scalars['ZonedDatetime']['output'];
  readonly id: Scalars['Long']['output'];
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly saleChannelCountry?: Maybe<Scalars['String']['output']>;
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['output'];
  readonly target?: Maybe<MarketplaceCalendarEventTargetPayload>;
  readonly title: Scalars['String']['output'];
};

export type MarketplaceCalendarEventPayloadList = {
  readonly __typename?: 'MarketplaceCalendarEventPayloadList';
  readonly items: ReadonlyArray<MarketplaceCalendarEventPayload>;
  readonly meta: ReportListMeta;
};

export type MarketplaceCalendarEventQueryInput = {
  readonly endDate?: InputMaybe<Scalars['ZonedDatetime']['input']>;
  readonly masterDataStoreId?: InputMaybe<Scalars['Long']['input']>;
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly saleChannelTypes?: InputMaybe<ReadonlyArray<SaleChannelType>>;
  readonly sortBy?: InputMaybe<MarketplaceCalendarEventSortByInput>;
  readonly startDate?: InputMaybe<Scalars['ZonedDatetime']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

export const MarketplaceCalendarEventSortByField = {
  CREATED_AT: 'CREATED_AT'
} as const;

export type MarketplaceCalendarEventSortByField = typeof MarketplaceCalendarEventSortByField[keyof typeof MarketplaceCalendarEventSortByField];
export type MarketplaceCalendarEventSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: MarketplaceCalendarEventSortByField;
};

export type MarketplaceCalendarEventTargetInput = {
  readonly currency: ReportSupportedCurrency;
  readonly revenue?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export type MarketplaceCalendarEventTargetPayload = {
  readonly __typename?: 'MarketplaceCalendarEventTargetPayload';
  readonly currency: ReportSupportedCurrency;
  readonly revenue?: Maybe<Scalars['BigDecimal']['output']>;
};

export type MarketplaceCalendarRevenueSummaryPayload = {
  readonly __typename?: 'MarketplaceCalendarRevenueSummaryPayload';
  readonly actual: MarketplaceCalendarSummaryActualRevenuePayload;
  readonly forecast: MarketplaceCalendarSummaryForecastRevenuePayload;
  readonly target: MarketplaceCalendarSummaryTargetRevenuePayload;
};

export type MarketplaceCalendarSummaryActualRevenuePayload = {
  readonly __typename?: 'MarketplaceCalendarSummaryActualRevenuePayload';
  readonly currency: ReportSupportedCurrency;
  readonly diffValue: Scalars['BigDecimal']['output'];
  readonly value: Scalars['BigDecimal']['output'];
};

export const MarketplaceCalendarSummaryDimension = {
  MONTH: 'MONTH',
  WEEK: 'WEEK'
} as const;

export type MarketplaceCalendarSummaryDimension = typeof MarketplaceCalendarSummaryDimension[keyof typeof MarketplaceCalendarSummaryDimension];
export type MarketplaceCalendarSummaryForecastRevenuePayload = {
  readonly __typename?: 'MarketplaceCalendarSummaryForecastRevenuePayload';
  readonly currency: ReportSupportedCurrency;
  readonly diffValue: Scalars['BigDecimal']['output'];
  readonly value: Scalars['BigDecimal']['output'];
};

export type MarketplaceCalendarSummaryQueryInput = {
  readonly dimension: MarketplaceCalendarSummaryDimension;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly saleChannelTypes?: InputMaybe<ReadonlyArray<SaleChannelType>>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type MarketplaceCalendarSummaryTargetRevenuePayload = {
  readonly __typename?: 'MarketplaceCalendarSummaryTargetRevenuePayload';
  readonly currency: ReportSupportedCurrency;
  readonly dailySalesEstimated: Scalars['BigDecimal']['output'];
  readonly value: Scalars['BigDecimal']['output'];
};

export const MarketplaceCountry = {
  AC: 'AC',
  AD: 'AD',
  AE: 'AE',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AO: 'AO',
  AR: 'AR',
  AT: 'AT',
  AU: 'AU',
  AW: 'AW',
  AX: 'AX',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BE: 'BE',
  BF: 'BF',
  BG: 'BG',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BL: 'BL',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BQ: 'BQ',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CC: 'CC',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CH: 'CH',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CN: 'CN',
  CO: 'CO',
  CR: 'CR',
  CV: 'CV',
  CW: 'CW',
  CX: 'CX',
  CY: 'CY',
  CZ: 'CZ',
  DE: 'DE',
  DJ: 'DJ',
  DK: 'DK',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EE: 'EE',
  EG: 'EG',
  EH: 'EH',
  ER: 'ER',
  ES: 'ES',
  ET: 'ET',
  FI: 'FI',
  FJ: 'FJ',
  FK: 'FK',
  FO: 'FO',
  FR: 'FR',
  GA: 'GA',
  GB: 'GB',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GR: 'GR',
  GS: 'GS',
  GT: 'GT',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HN: 'HN',
  HR: 'HR',
  HT: 'HT',
  HU: 'HU',
  ID: 'ID',
  IE: 'IE',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IO: 'IO',
  IQ: 'IQ',
  IR: 'IR',
  IS: 'IS',
  IT: 'IT',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  JP: 'JP',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KO: 'KO',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LI: 'LI',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LT: 'LT',
  LU: 'LU',
  LV: 'LV',
  LY: 'LY',
  MA: 'MA',
  MC: 'MC',
  MD: 'MD',
  ME: 'ME',
  MF: 'MF',
  MG: 'MG',
  MH: 'MH',
  MK: 'MK',
  ML: 'ML',
  MM: 'MM',
  MN: 'MN',
  MO: 'MO',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MT: 'MT',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NF: 'NF',
  NG: 'NG',
  NI: 'NI',
  NL: 'NL',
  NO: 'NO',
  NP: 'NP',
  NR: 'NR',
  NU: 'NU',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PL: 'PL',
  PM: 'PM',
  PN: 'PN',
  PS: 'PS',
  PT: 'PT',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RS: 'RS',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SD: 'SD',
  SE: 'SE',
  SG: 'SG',
  SH: 'SH',
  SI: 'SI',
  SJ: 'SJ',
  SK: 'SK',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  SS: 'SS',
  ST: 'ST',
  SV: 'SV',
  SX: 'SX',
  SZ: 'SZ',
  TC: 'TC',
  TD: 'TD',
  TF: 'TF',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TK: 'TK',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  UM: 'UM',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VN: 'VN',
  VU: 'VU',
  WF: 'WF',
  WS: 'WS',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW'
} as const;

export type MarketplaceCountry = typeof MarketplaceCountry[keyof typeof MarketplaceCountry];
export type MarketplaceDescription = {
  readonly __typename?: 'MarketplaceDescription';
  readonly default: Scalars['String']['output'];
  readonly rakutenSalesMethod?: Maybe<Scalars['String']['output']>;
  readonly rakutenSmartPhone?: Maybe<Scalars['String']['output']>;
  readonly yahooComment?: Maybe<Scalars['String']['output']>;
  readonly yahooProductDescription?: Maybe<Scalars['String']['output']>;
  readonly yahooProductInformation?: Maybe<Scalars['String']['output']>;
};

export const MarketplaceSaleChannelMarketplace = {
  AE: 'AE',
  CA: 'CA',
  CN: 'CN',
  DE: 'DE',
  ES: 'ES',
  FR: 'FR',
  HK: 'HK',
  ID: 'ID',
  IN: 'IN',
  IT: 'IT',
  JP: 'JP',
  KR: 'KR',
  MY: 'MY',
  NL: 'NL',
  PH: 'PH',
  SA: 'SA',
  SG: 'SG',
  TH: 'TH',
  TW: 'TW',
  UK: 'UK',
  US: 'US',
  VN: 'VN'
} as const;

export type MarketplaceSaleChannelMarketplace = typeof MarketplaceSaleChannelMarketplace[keyof typeof MarketplaceSaleChannelMarketplace];
export const MarketplaceSaleChannelType = {
  AmazonSellerCentral: 'AmazonSellerCentral',
  AuPayMarket: 'AuPayMarket',
  FacebookShop: 'FacebookShop',
  Lazada: 'Lazada',
  Magaseek: 'Magaseek',
  MakeshopByGmo: 'MakeshopByGmo',
  Manual: 'Manual',
  Momo: 'Momo',
  Qoo10: 'Qoo10',
  Rakuten: 'Rakuten',
  RakutenFashion: 'RakutenFashion',
  Shopee: 'Shopee',
  Shopify: 'Shopify',
  Shoplist: 'Shoplist',
  Stores: 'Stores',
  SuperDelivery: 'SuperDelivery',
  TikTok: 'TikTok',
  Tokopedia: 'Tokopedia',
  Yahoo: 'Yahoo',
  Zozotown: 'Zozotown'
} as const;

export type MarketplaceSaleChannelType = typeof MarketplaceSaleChannelType[keyof typeof MarketplaceSaleChannelType];
export type MdAccount = {
  readonly __typename?: 'MdAccount';
  readonly address1?: Maybe<Scalars['String']['output']>;
  readonly address2?: Maybe<Scalars['String']['output']>;
  readonly cityName?: Maybe<Scalars['String']['output']>;
  readonly countryId: Scalars['String']['output'];
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly hubspotCustomerId?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
  readonly netsuiteVendorId?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Scalars['String']['output'];
  readonly phoneNumberCountryId: Scalars['String']['output'];
  readonly product: MdAccountProduct;
  readonly provinceId?: Maybe<Scalars['String']['output']>;
  readonly status: MdAccountStatus;
  readonly updatedAt: Scalars['ZonedDatetime']['output'];
  readonly websiteUrl?: Maybe<Scalars['URL']['output']>;
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type MdAccountFilterInput = {
  readonly countryId?: InputMaybe<Scalars['String']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<MdAccountStatus>;
};

export const MdAccountProduct = {
  ANYCHAT: 'ANYCHAT',
  ANYLOGI: 'ANYLOGI',
  ANYX: 'ANYX'
} as const;

export type MdAccountProduct = typeof MdAccountProduct[keyof typeof MdAccountProduct];
export const MdAccountSortByFieldEnum = {
  CITY: 'CITY',
  COMPANY_NAME: 'COMPANY_NAME',
  COUNTRY_ID: 'COUNTRY_ID',
  CREATED_AT: 'CREATED_AT',
  HUBSPOT_CUSTOMER_ID: 'HUBSPOT_CUSTOMER_ID',
  ID: 'ID',
  NAME: 'NAME',
  NETSUITE_VENDOR_ID: 'NETSUITE_VENDOR_ID',
  PHONE_NUMBER: 'PHONE_NUMBER',
  PROVINCE_ID: 'PROVINCE_ID',
  UPDATED_AT: 'UPDATED_AT',
  WEBSITE_URL: 'WEBSITE_URL',
  ZIPCODE: 'ZIPCODE'
} as const;

export type MdAccountSortByFieldEnum = typeof MdAccountSortByFieldEnum[keyof typeof MdAccountSortByFieldEnum];
export type MdAccountSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: MdAccountSortByFieldEnum;
};

export const MdAccountStatus = {
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED'
} as const;

export type MdAccountStatus = typeof MdAccountStatus[keyof typeof MdAccountStatus];
export type MdAccounts = ListMeta & {
  readonly __typename?: 'MdAccounts';
  readonly items: ReadonlyArray<MdAccount>;
  readonly length: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export const MdAnyXModuleEnum = {
  ANALYTICS: 'ANALYTICS',
  CRM: 'CRM',
  DATASOURCES: 'DATASOURCES',
  INVENTORIES: 'INVENTORIES',
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS',
  PRODUCT_REVIEW_ANALYTICS: 'PRODUCT_REVIEW_ANALYTICS',
  SHIPPING_LABELS: 'SHIPPING_LABELS',
  WORKFLOW: 'WORKFLOW'
} as const;

export type MdAnyXModuleEnum = typeof MdAnyXModuleEnum[keyof typeof MdAnyXModuleEnum];
export type MdCountry = {
  readonly __typename?: 'MdCountry';
  readonly currency: MdCurrency;
  readonly currencyId: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly isdCode: Scalars['String']['output'];
  readonly language: MdLanguage;
  readonly languageId: Scalars['String']['output'];
  readonly lname: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly timezone: MdTimezone;
  readonly timezoneId: Scalars['String']['output'];
};

export const MdCountryEnum = {
  AD: 'AD',
  AE: 'AE',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AO: 'AO',
  AQ: 'AQ',
  AR: 'AR',
  AS: 'AS',
  AT: 'AT',
  AU: 'AU',
  AW: 'AW',
  AX: 'AX',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BE: 'BE',
  BF: 'BF',
  BG: 'BG',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BL: 'BL',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BQ: 'BQ',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BV: 'BV',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CC: 'CC',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CH: 'CH',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CN: 'CN',
  CO: 'CO',
  CR: 'CR',
  CU: 'CU',
  CV: 'CV',
  CW: 'CW',
  CX: 'CX',
  CY: 'CY',
  CZ: 'CZ',
  DE: 'DE',
  DJ: 'DJ',
  DK: 'DK',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EE: 'EE',
  EG: 'EG',
  EH: 'EH',
  ER: 'ER',
  ES: 'ES',
  ET: 'ET',
  FI: 'FI',
  FJ: 'FJ',
  FK: 'FK',
  FM: 'FM',
  FO: 'FO',
  FR: 'FR',
  GA: 'GA',
  GB: 'GB',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GR: 'GR',
  GS: 'GS',
  GT: 'GT',
  GU: 'GU',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HM: 'HM',
  HN: 'HN',
  HR: 'HR',
  HT: 'HT',
  HU: 'HU',
  ID: 'ID',
  IE: 'IE',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IO: 'IO',
  IQ: 'IQ',
  IR: 'IR',
  IS: 'IS',
  IT: 'IT',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  JP: 'JP',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KP: 'KP',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LI: 'LI',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LT: 'LT',
  LU: 'LU',
  LV: 'LV',
  LY: 'LY',
  MA: 'MA',
  MC: 'MC',
  MD: 'MD',
  ME: 'ME',
  MF: 'MF',
  MG: 'MG',
  MH: 'MH',
  MK: 'MK',
  ML: 'ML',
  MM: 'MM',
  MN: 'MN',
  MO: 'MO',
  MP: 'MP',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MT: 'MT',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NF: 'NF',
  NG: 'NG',
  NI: 'NI',
  NL: 'NL',
  NO: 'NO',
  NP: 'NP',
  NR: 'NR',
  NU: 'NU',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PL: 'PL',
  PM: 'PM',
  PN: 'PN',
  PR: 'PR',
  PS: 'PS',
  PT: 'PT',
  PW: 'PW',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RS: 'RS',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SD: 'SD',
  SE: 'SE',
  SG: 'SG',
  SH: 'SH',
  SI: 'SI',
  SJ: 'SJ',
  SK: 'SK',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  SS: 'SS',
  ST: 'ST',
  SV: 'SV',
  SX: 'SX',
  SY: 'SY',
  SZ: 'SZ',
  TC: 'TC',
  TD: 'TD',
  TF: 'TF',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TK: 'TK',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  UM: 'UM',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VI: 'VI',
  VN: 'VN',
  VU: 'VU',
  WF: 'WF',
  WS: 'WS',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW'
} as const;

export type MdCountryEnum = typeof MdCountryEnum[keyof typeof MdCountryEnum];
export type MdCreateAccountInput = {
  readonly address1: Scalars['String']['input'];
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  readonly cityName?: InputMaybe<Scalars['String']['input']>;
  readonly countryId: Scalars['String']['input'];
  readonly hubspotCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly netsuiteVendorId?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber: Scalars['String']['input'];
  readonly phoneNumberCountryId: Scalars['String']['input'];
  readonly product: MdAccountProduct;
  readonly provinceId?: InputMaybe<Scalars['String']['input']>;
  readonly status: MdAccountStatus;
  readonly websiteUrl: Scalars['URL']['input'];
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type MdCreateStoreInput = {
  readonly accountId: Scalars['Long']['input'];
  readonly name: Scalars['String']['input'];
};

export type MdCreateUserInput = {
  readonly accessAllStores: Scalars['Boolean']['input'];
  readonly accountIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly availableModules?: InputMaybe<ReadonlyArray<MdAnyXModuleEnum>>;
  readonly canManageUsers: Scalars['Boolean']['input'];
  /** ISO 4217 currency code */
  readonly currencyId: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly languageId: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly password?: InputMaybe<Scalars['String']['input']>;
  readonly role: MdUserRole;
  readonly sendPasswordResetEmail: Scalars['Boolean']['input'];
  readonly storeIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly timezoneId: Scalars['String']['input'];
};

export type MdCurrency = {
  readonly __typename?: 'MdCurrency';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type MdCurrencyFilterInput = {
  readonly currencyProduct?: InputMaybe<CurrencyProduct>;
};

export type MdLanguage = {
  readonly __typename?: 'MdLanguage';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type MdProvince = {
  readonly __typename?: 'MdProvince';
  readonly country: MdCountry;
  readonly countryId: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly lname: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type MdProvinceFilterInput = {
  readonly countryId?: InputMaybe<Scalars['String']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
};

export type MdSendVerificationEmailDetailPayload = {
  readonly __typename?: 'MdSendVerificationEmailDetailPayload';
  readonly status: SendVerificationEmailStatus;
  readonly user?: Maybe<MdUser>;
  readonly userId: Scalars['Long']['output'];
};

export type MdSendVerificationEmailInput = {
  readonly ids: ReadonlyArray<Scalars['Long']['input']>;
};

export type MdSendVerificationEmailPayload = {
  readonly __typename?: 'MdSendVerificationEmailPayload';
  readonly details: ReadonlyArray<MdSendVerificationEmailDetailPayload>;
  readonly ok: Scalars['Boolean']['output'];
};

export type MdStore = {
  readonly __typename?: 'MdStore';
  readonly account?: Maybe<MdAccount>;
  readonly accountId: Scalars['Long']['output'];
  readonly address1?: Maybe<Scalars['String']['output']>;
  readonly address2?: Maybe<Scalars['String']['output']>;
  readonly channels: ReadonlyArray<SaleChannel>;
  readonly cityName?: Maybe<Scalars['String']['output']>;
  readonly country: MdCountry;
  readonly countryId: Scalars['String']['output'];
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly createdBy?: Maybe<MdUser>;
  readonly createdById?: Maybe<Scalars['Long']['output']>;
  readonly crmAccountId?: Maybe<Scalars['String']['output']>;
  readonly crmStore?: Maybe<AnyChatStore>;
  readonly defaultCourierType?: Maybe<CourierType>;
  readonly id: Scalars['Long']['output'];
  readonly logisticFeatureFlag?: Maybe<LogisticFeatureFlag>;
  readonly name: Scalars['String']['output'];
  readonly phoneNumber: Scalars['String']['output'];
  readonly phoneNumberCountry: MdCountry;
  readonly phoneNumberCountryId: Scalars['String']['output'];
  readonly province: MdProvince;
  readonly provinceId?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['ZonedDatetime']['output'];
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type MdStoreFilterInput = {
  readonly accountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly storeIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
};

export const MdStoreSortByFieldEnum = {
  ACCOUNT_NAME: 'ACCOUNT_NAME',
  CREATED_AT: 'CREATED_AT',
  ID: 'ID',
  NAME: 'NAME',
  UPDATED_AT: 'UPDATED_AT'
} as const;

export type MdStoreSortByFieldEnum = typeof MdStoreSortByFieldEnum[keyof typeof MdStoreSortByFieldEnum];
export type MdStoreSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: MdStoreSortByFieldEnum;
};

export type MdStores = ListMeta & {
  readonly __typename?: 'MdStores';
  readonly items: ReadonlyArray<MdStore>;
  readonly length: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type MdTimezone = {
  readonly __typename?: 'MdTimezone';
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly offset: Scalars['String']['output'];
  readonly updatedAt: Scalars['ZonedDatetime']['output'];
};

export type MdUpdateAccountInput = {
  readonly address1: Scalars['String']['input'];
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  readonly cityName?: InputMaybe<Scalars['String']['input']>;
  readonly countryId: Scalars['String']['input'];
  readonly hubspotCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['Long']['input'];
  readonly name: Scalars['String']['input'];
  readonly netsuiteVendorId?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber: Scalars['String']['input'];
  readonly phoneNumberCountryId: Scalars['String']['input'];
  readonly product: MdAccountProduct;
  readonly provinceId?: InputMaybe<Scalars['String']['input']>;
  readonly status: MdAccountStatus;
  readonly websiteUrl: Scalars['URL']['input'];
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type MdUpdateStoreInput = {
  readonly address1: Scalars['String']['input'];
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  readonly cityName?: InputMaybe<Scalars['String']['input']>;
  readonly countryId: Scalars['String']['input'];
  readonly id: Scalars['Long']['input'];
  readonly name: Scalars['String']['input'];
  readonly phoneNumber: Scalars['String']['input'];
  readonly phoneNumberCountryId: Scalars['String']['input'];
  readonly provinceId?: InputMaybe<Scalars['String']['input']>;
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type MdUpdateUserInput = {
  readonly accessAllStores: Scalars['Boolean']['input'];
  readonly accountIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly availableModules?: InputMaybe<ReadonlyArray<MdAnyXModuleEnum>>;
  readonly canManageUsers: Scalars['Boolean']['input'];
  /** ISO 4217 currency code */
  readonly currencyId: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly id: Scalars['Long']['input'];
  readonly languageId: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly password?: InputMaybe<Scalars['String']['input']>;
  readonly storeIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly timezoneId: Scalars['String']['input'];
};

export type MdUser = {
  readonly __typename?: 'MdUser';
  readonly accessAllStores: Scalars['Boolean']['output'];
  readonly accountIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly accounts: ReadonlyArray<MdAccount>;
  readonly auth0UserId?: Maybe<Scalars['String']['output']>;
  readonly availableModules: ReadonlyArray<MdAnyXModuleEnum>;
  readonly canManageUsers: Scalars['Boolean']['output'];
  readonly country?: Maybe<MdCountry>;
  readonly countryId?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly currency?: Maybe<MdCurrency>;
  readonly currencyId: Scalars['String']['output'];
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['Long']['output'];
  readonly language?: Maybe<MdLanguage>;
  readonly languageId: Scalars['String']['output'];
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly role: MdUserRole;
  readonly status: MdUserStatus;
  readonly storeIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly stores: ReadonlyArray<MdStore>;
  readonly timezone?: Maybe<MdTimezone>;
  readonly timezoneId: Scalars['String']['output'];
  readonly updatedAt: Scalars['ZonedDatetime']['output'];
};

export type MdUserFilterInput = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly role?: InputMaybe<MdUserRole>;
  readonly status?: InputMaybe<MdUserStatus>;
  readonly storeIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
};

export const MdUserRole = {
  ADMIN: 'ADMIN',
  MERCHANT: 'MERCHANT',
  STAFF: 'STAFF'
} as const;

export type MdUserRole = typeof MdUserRole[keyof typeof MdUserRole];
export const MdUserSortByFieldEnum = {
  CREATED_AT: 'CREATED_AT',
  EMAIL: 'EMAIL',
  FIRST_NAME: 'FIRST_NAME',
  ID: 'ID',
  LAST_NAME: 'LAST_NAME',
  UPDATED_AT: 'UPDATED_AT'
} as const;

export type MdUserSortByFieldEnum = typeof MdUserSortByFieldEnum[keyof typeof MdUserSortByFieldEnum];
export type MdUserSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: MdUserSortByFieldEnum;
};

export const MdUserStatus = {
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
  PENDING: 'PENDING'
} as const;

export type MdUserStatus = typeof MdUserStatus[keyof typeof MdUserStatus];
export type MdUsers = ListMeta & {
  readonly __typename?: 'MdUsers';
  readonly items: ReadonlyArray<MdUser>;
  readonly length: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type Media = {
  readonly fileName: Scalars['String']['output'];
  readonly mimeType: Scalars['String']['output'];
  readonly previewUrl: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
};

export type MediaItemInput = {
  readonly image?: InputMaybe<ImageInput>;
  readonly type: MediaType;
  readonly video?: InputMaybe<VideoInput>;
};

export const MediaType = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
} as const;

export type MediaType = typeof MediaType[keyof typeof MediaType];
export type MergeProductVariantV3Input = {
  readonly sourceProductCodes: ReadonlyArray<Scalars['String']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly targetProductCode: Scalars['String']['input'];
};

export type MergeProductVariantV3Output = {
  readonly __typename?: 'MergeProductVariantV3Output';
  readonly ok: Scalars['Boolean']['output'];
};

export type MessageAuthor = AiAuthor | BasicUser;

export const MessageState = {
  FAIL: 'FAIL',
  SENDING: 'SENDING',
  SENT: 'SENT',
  WAIT_FOR_DISCARD: 'WAIT_FOR_DISCARD'
} as const;

export type MessageState = typeof MessageState[keyof typeof MessageState];
export type MessengerBotAutoMessage = MessengerMessage & {
  readonly __typename?: 'MessengerBotAutoMessage';
  readonly content: MessengerMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<MessengerReaction>;
};

export type MessengerBotAutoMessageDeleted = MessengerMessage & {
  readonly __typename?: 'MessengerBotAutoMessageDeleted';
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<MessengerReaction>;
};

export type MessengerBotMessage = MessengerMessage & {
  readonly __typename?: 'MessengerBotMessage';
  readonly author: BasicUser;
  readonly content: MessengerMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly messageState: MessageState;
  readonly reactions: ReadonlyArray<MessengerReaction>;
};

export type MessengerBotMessageDeleted = MessengerMessage & {
  readonly __typename?: 'MessengerBotMessageDeleted';
  readonly author: BasicUser;
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<MessengerReaction>;
};

export type MessengerBotReaction = {
  readonly __typename?: 'MessengerBotReaction';
  readonly sign: MessengerReactionSign;
};

export type MessengerBroadcastMessage = {
  readonly __typename?: 'MessengerBroadcastMessage';
  readonly content: MessengerContent;
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly deliveredAt?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly deliveryTiming: DeliveryTiming;
  readonly id: Scalars['String']['output'];
  readonly imageUrl?: Maybe<Scalars['Url']['output']>;
  readonly name: Scalars['String']['output'];
  readonly recipient: MessengerBroadcastRecipient;
  readonly statistics?: Maybe<MessengerBroadcastStatistics>;
  readonly status: BroadcastStatus;
};

export type MessengerBroadcastMessageCreateRequestInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly messengerContent: MessengerContentInput;
  readonly recipient: MessengerRecipientItemInput;
  readonly storeId: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
};

export type MessengerBroadcastMessageCreateResponse = {
  readonly __typename?: 'MessengerBroadcastMessageCreateResponse';
  readonly messageId: Scalars['String']['output'];
};

export type MessengerBroadcastMessageUpdateRequestInput = {
  readonly deliveryTiming: DeliveryTimingItemInput;
  readonly messengerBroadcastId: Scalars['String']['input'];
  readonly messengerContent: MessengerContentInput;
  readonly recipient: MessengerRecipientItemInput;
  readonly title: Scalars['String']['input'];
};

export type MessengerBroadcastMessageUpdateResponse = {
  readonly __typename?: 'MessengerBroadcastMessageUpdateResponse';
  readonly messageId: Scalars['String']['output'];
};

export type MessengerBroadcastMessages = {
  readonly __typename?: 'MessengerBroadcastMessages';
  readonly messages: ReadonlyArray<MessengerBroadcastMessage>;
  readonly total: Scalars['Int']['output'];
};

export type MessengerBroadcastRecipient = All | CustomerTagName;

export type MessengerBroadcastStatisticSummaries = {
  readonly __typename?: 'MessengerBroadcastStatisticSummaries';
  readonly compareStatistics?: Maybe<MessengerBroadcastStatistics>;
  readonly diffStatistics?: Maybe<MessengerBroadcastStatistics>;
  readonly sumStatistics: MessengerBroadcastStatistics;
};

export type MessengerBroadcastStatistics = {
  readonly __typename?: 'MessengerBroadcastStatistics';
  readonly deliveredCount: Scalars['Int']['output'];
  readonly openCount: Scalars['Int']['output'];
  readonly openRate: Scalars['Float']['output'];
  readonly reactionCount: Scalars['Int']['output'];
  readonly reactionRate: Scalars['Float']['output'];
};

export type MessengerChatEvent = CommonChatEvent | MessengerMessageEvent;

export type MessengerChatEvents = {
  readonly __typename?: 'MessengerChatEvents';
  readonly chatEvents: ReadonlyArray<MessengerChatEvent>;
  readonly chatId: Scalars['String']['output'];
  readonly lastCheckedAt?: Maybe<Scalars['UnixTimestamp']['output']>;
  readonly userName: Scalars['String']['output'];
};

export type MessengerChatPlatformDetail = {
  readonly __typename?: 'MessengerChatPlatformDetail';
  readonly isOptIn: Scalars['Boolean']['output'];
};

export type MessengerContent = MessengerTemplateContent | MessengerTextContent;

export type MessengerContentInput = {
  readonly contentType: MessengerMessageType;
  readonly template?: InputMaybe<MessengerTemplateContentInput>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

export type MessengerEmoji = {
  readonly __typename?: 'MessengerEmoji';
  readonly code: Scalars['String']['output'];
};

export type MessengerImage = {
  readonly __typename?: 'MessengerImage';
  readonly originalUrl: Scalars['Url']['output'];
  readonly previewUrl: Scalars['Url']['output'];
};

export type MessengerImageCommentContent = {
  readonly __typename?: 'MessengerImageCommentContent';
  readonly image: MessengerImage;
};

export type MessengerImageMessageContent = {
  readonly __typename?: 'MessengerImageMessageContent';
  readonly images: ReadonlyArray<MessengerImage>;
};

export type MessengerInfo = ChatInfo & {
  readonly __typename?: 'MessengerInfo';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type MessengerMessage = {
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<MessengerReaction>;
};

export type MessengerMessageContent = MessengerImageCommentContent | MessengerImageMessageContent | MessengerOptInContent | MessengerOptOutContent | MessengerPostOnPageContent | MessengerRequestOptInContent | MessengerResumeOptInContent | MessengerTextCommentContent | MessengerTextMessageContent | MessengerUnsupportedContent | MessengerVideoMessageContent;

export type MessengerMessageEvent = {
  readonly __typename?: 'MessengerMessageEvent';
  readonly id: Scalars['String']['output'];
  readonly message: MessengerMessage;
  readonly replyTo?: Maybe<MessengerReplyTo>;
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export const MessengerMessageType = {
  PLAIN_TEXT: 'PLAIN_TEXT',
  TEMPLATE_CARD: 'TEMPLATE_CARD'
} as const;

export type MessengerMessageType = typeof MessengerMessageType[keyof typeof MessengerMessageType];
export type MessengerNotSupportChatPlugInWithAnonymousUserFacebookError = {
  readonly __typename?: 'MessengerNotSupportChatPlugInWithAnonymousUserFacebookError';
  readonly message: Scalars['String']['output'];
};

export type MessengerOptInAcceptedActivity = CustomerActivity & {
  readonly __typename?: 'MessengerOptInAcceptedActivity';
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly title: Scalars['String']['output'];
};

export type MessengerOptInContent = {
  readonly __typename?: 'MessengerOptInContent';
  readonly buttonText: OptInCallToActionText;
  readonly title: Scalars['String']['output'];
};

export type MessengerOptInMessage = {
  readonly __typename?: 'MessengerOptInMessage';
  readonly buttonText: OptInCallToActionText;
  readonly enabled: Scalars['Boolean']['output'];
  readonly imageUrl?: Maybe<Scalars['Url']['output']>;
  readonly title: Scalars['String']['output'];
  readonly updatedAt: Scalars['OffsetDateTime']['output'];
};

export type MessengerOptInRequestSentActivity = CustomerActivity & {
  readonly __typename?: 'MessengerOptInRequestSentActivity';
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly title: Scalars['String']['output'];
};

export type MessengerOptOutContent = {
  readonly __typename?: 'MessengerOptOutContent';
  readonly title: Scalars['String']['output'];
};

export type MessengerOptedOutActivity = CustomerActivity & {
  readonly __typename?: 'MessengerOptedOutActivity';
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly title: Scalars['String']['output'];
};

export type MessengerPostOnPageContent = {
  readonly __typename?: 'MessengerPostOnPageContent';
  readonly images: ReadonlyArray<MessengerImage>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly video?: Maybe<MessengerVideo>;
};

export type MessengerReaction = MessengerBotReaction | MessengerUserReaction;

export type MessengerReactionSign = MessengerEmoji | MessengerSign;

export type MessengerRecipientItemInput = {
  readonly customerTagName?: InputMaybe<Scalars['String']['input']>;
  readonly type: MessengerRecipientType;
};

export const MessengerRecipientType = {
  ALL: 'ALL',
  WITH_CUSTOMER_TAGS: 'WITH_CUSTOMER_TAGS'
} as const;

export type MessengerRecipientType = typeof MessengerRecipientType[keyof typeof MessengerRecipientType];
export type MessengerReplyTo = MessengerReplyToMessage;

export type MessengerReplyToInputInput = {
  readonly messageId?: InputMaybe<Scalars['String']['input']>;
};

export type MessengerReplyToMessage = {
  readonly __typename?: 'MessengerReplyToMessage';
  readonly message: MessengerMessage;
};

export type MessengerRequestOptInContent = {
  readonly __typename?: 'MessengerRequestOptInContent';
  readonly buttonText: OptInCallToActionText;
  readonly imageUrl?: Maybe<Scalars['Url']['output']>;
  readonly title: Scalars['String']['output'];
};

export type MessengerResumeOptInContent = {
  readonly __typename?: 'MessengerResumeOptInContent';
  readonly title: Scalars['String']['output'];
};

export type MessengerSendImageMessageRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly originalUrl: Scalars['Url']['input'];
  readonly previewUrl: Scalars['Url']['input'];
  readonly replyTo?: InputMaybe<MessengerReplyToInputInput>;
};

export type MessengerSendTextMessageRequest = {
  readonly chatId: Scalars['String']['input'];
  readonly replyTo?: InputMaybe<MessengerReplyToInputInput>;
  readonly text: Scalars['String']['input'];
};

export type MessengerSign = {
  readonly __typename?: 'MessengerSign';
  readonly name: Scalars['String']['output'];
};

export type MessengerStoreChat = StoreChat & {
  readonly __typename?: 'MessengerStoreChat';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly facebookPagePicture?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly isPrimaryReceiver: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly pageId: Scalars['String']['output'];
  readonly tokenStatus: MetaTokenStatus;
  readonly type: ChatType;
};

export type MessengerTargetInput = {
  readonly type: MessengerTargetType;
};

export const MessengerTargetType = {
  BROADCAST_MESSAGE: 'BROADCAST_MESSAGE',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  OPT_IN_MESSAGE: 'OPT_IN_MESSAGE'
} as const;

export type MessengerTargetType = typeof MessengerTargetType[keyof typeof MessengerTargetType];
export type MessengerTemplateCard = {
  readonly __typename?: 'MessengerTemplateCard';
  readonly buttons: ReadonlyArray<MessengerTemplateCardButton>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly imageUrl: Scalars['Url']['output'];
  readonly title: Scalars['String']['output'];
};

export type MessengerTemplateCardButton = {
  readonly __typename?: 'MessengerTemplateCardButton';
  readonly buttonUrl: Scalars['Url']['output'];
  readonly title: Scalars['String']['output'];
};

export type MessengerTemplateCardButtonInput = {
  readonly buttonUrl: Scalars['Url']['input'];
  readonly title: Scalars['String']['input'];
};

export type MessengerTemplateCardInput = {
  readonly buttons: ReadonlyArray<MessengerTemplateCardButtonInput>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly imageUrl: Scalars['Url']['input'];
  readonly title: Scalars['String']['input'];
};

export type MessengerTemplateContent = {
  readonly __typename?: 'MessengerTemplateContent';
  readonly cards: ReadonlyArray<MessengerTemplateCard>;
};

export type MessengerTemplateContentInput = {
  readonly cards: ReadonlyArray<MessengerTemplateCardInput>;
};

export type MessengerTextCommentContent = {
  readonly __typename?: 'MessengerTextCommentContent';
  readonly text: Scalars['String']['output'];
};

export type MessengerTextContent = {
  readonly __typename?: 'MessengerTextContent';
  readonly text: Scalars['String']['output'];
};

export type MessengerTextMessageContent = {
  readonly __typename?: 'MessengerTextMessageContent';
  readonly text: Scalars['String']['output'];
};

export type MessengerUnsupportedContent = {
  readonly __typename?: 'MessengerUnsupportedContent';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type MessengerUserMessage = MessengerMessage & {
  readonly __typename?: 'MessengerUserMessage';
  readonly content: MessengerMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<MessengerReaction>;
};

export type MessengerUserMessageDeleted = MessengerMessage & {
  readonly __typename?: 'MessengerUserMessageDeleted';
  readonly messageId: Scalars['String']['output'];
  readonly reactions: ReadonlyArray<MessengerReaction>;
};

export type MessengerUserReaction = {
  readonly __typename?: 'MessengerUserReaction';
  readonly sign: MessengerReactionSign;
};

export type MessengerVideo = {
  readonly __typename?: 'MessengerVideo';
  readonly originalUrl: Scalars['Url']['output'];
  readonly previewUrl: Scalars['Url']['output'];
};

export type MessengerVideoMessageContent = {
  readonly __typename?: 'MessengerVideoMessageContent';
  readonly video: MessengerVideo;
};

export const MetaMessagingPlatform = {
  INSTAGRAM: 'INSTAGRAM',
  MESSENGER: 'MESSENGER'
} as const;

export type MetaMessagingPlatform = typeof MetaMessagingPlatform[keyof typeof MetaMessagingPlatform];
export type MetaTokenStatus = InvalidToken | TokenMissingScope | Working;

export type MomoFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: MomoFileUploadType;
};

export const MomoFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type MomoFileUploadType = typeof MomoFileUploadType[keyof typeof MomoFileUploadType];
export type Money = {
  readonly __typename?: 'Money';
  readonly amount: Scalars['BigDecimal']['output'];
  readonly currency: CurrencyCode;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  /**
   * [ADMIN, STAFF, MERCHANT]
   * add automation flow
   *
   * Code|Error
   * ---|---
   * 400| `INVALID_AUTOMATION_TRIGGER`.
   * 404| `USER_NOT_FOUND`.
   */
  readonly AddAutomationFlow?: Maybe<AutomationFlow>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * add customer tag.
   * returns a list of created tags
   */
  readonly AddCustomerTags?: Maybe<ReadonlyArray<CustomerTag>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * addWorkflow.
   * bodyJSON may have LINE_unit_message_content(serialized string).
   * after get content, pass to AnyChat Service.
   * Code|Error
   * ---|---
   * 400| `INITIAL_FLOW_BLOCK_NOT_FOUND`.
   * 400| `INVALID_ACTION`.
   * 400| `INVALID_LINE_SEND_MESSAGE_ACTION`.
   * 400| `INVALID_CONDITION_FOR_ORDER`.
   * 400| `INVALID_CONJUNCTION_FOR_NUMBER`.
   * 400| `INVALID_CONJUNCTION_FOR_ENUM`.
   * 400| `INVALID_FLOW_BLOCK`.
   * 400| `INVALID_CONDITION_FOR_LINE_MESSAGE`.
   * 400| `INVALID_CONJUNCTION_VALUE`.
   * 404| `STORE_NOT_FOUND`.
   */
  readonly AddWorkflow?: Maybe<Workflow>;
  /**
   * [ADMIN, STAFF]
   * get store.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly AnyChatStoreCrmConfigUpdate?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * assign me as pic.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   * @deprecated Please migrate to ChatAssignV2
   */
  readonly ChatAssign?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * assign me or AI as assignee.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ChatAssignV2?: Maybe<ChatAssignResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * resolve chat.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ChatResolve?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update status like email.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ChatStateUpdate?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * unassign pic.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ChatUnassign?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * unresolve chat.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ChatUnresolve?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * connect store to Lazada
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly ConnectLazada?: Maybe<ConnectLazadaResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * connect store to Shopee for ShopAccount and MainAccount
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   * 3005| `MISSING_PARAM_FOR_TYPE`
   * 2033| `SHOPEE_TEMP_CONNECTION_NOT_FOUND`
   * 3112| `SHOPEE_CONNECTION_ALREADY_EXIST_FOR_STORE`
   * 3113| `SHOPEE_SHOP_ALREADY_CONNECT_TO_OTHER_STORE`
   * 3115| `INVALID_SHOPEE_SHOP_ID`
   */
  readonly ConnectShopeeChat?: Maybe<ConnectShopeeChatResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * connect (re-connect) store to TikTokShop
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   * 3120| `TIKTOK_SHOP_ALREADY_CONNECTED`
   * 3121| `TIKTOK_SHOP_NOT_EXIST_IN_REGION`
   * 3122| `TIKTOK_SHOP_REGION_MISMATCH`
   */
  readonly ConnectTikTokShop?: Maybe<ConnectTikTokShopResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * connect to facebook with support multiple platform connection
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly ConnectToFacebookV2?: Maybe<ConnectToFacebookResponseV2>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * create whatsapp connection
   *
   * Code|SubCode|Error
   * ---|---|---
   * 401|1002| `NOT_ALLOW_ACTION`.
   * 404|2005| `STORE_NOT_FOUND`.
   */
  readonly ConnectToWhatsApp?: Maybe<ConnectToWhatsAppResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * resolve chat.
   *
   * Code|Error
   * ---|---
   * 404| `CUSTOMER_NOT_FOUND`.
   */
  readonly CustomerNoteAdd?: Maybe<NotedActivity>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update customer profile.
   *
   * birthday should be "YYYY-MM-DD"
   *
   * Code|Error
   * ---|---
   * 404| `CUSTOMER_NOT_FOUND`.
   */
  readonly CustomerProfileUpdate?: Maybe<CustomerProfile>;
  readonly DHLCreateCourier: CourierAccountDetails;
  readonly DHLUpdateCourier: CourierAccountDetails;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * delete customer tags.
   *
   * Errors
   * 3017 - INVALID_TAG - returns when try to delete Staff
   */
  readonly DeleteCustomerTags?: Maybe<CustomerTagDeleteResponse>;
  /** delete workflow */
  readonly DeleteWorkflow?: Maybe<WorkflowStatusOk>;
  /** duplicate workflow */
  readonly DuplicateWorkflow?: Maybe<Workflow>;
  readonly FedExAddressValidation: FedExAddressValidationDetails;
  readonly FedExCreateCourier: CourierAccountDetails;
  readonly FedExGeneratePinCode: StatusOk;
  readonly FedExUpdateCourier: CourierAccountDetails;
  readonly GenerateAssetUploadUrlV2?: Maybe<AssetUploadUrl>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * generate tracking url
   *
   * Code|Error
   * ---|---
   * 7011| `MARKETING_SERVICE_EXCEPTION` returns when cannot create offer
   * 7012| `URL_SHORTENING_SERVICE_EXCEPTION` returns when cannot shorten url
   */
  readonly GenerateTrackingUrl?: Maybe<ReadonlyArray<TrackingUrl>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * ChatEvent response may be empty when message was successfully sent but failed to get the event.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly InstagramChatImageMessageSend?: Maybe<InstagramChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send reaction for Instagram.
   * ChatEvent response may be empty when message was successfully sent but failed to get the event.
   *
   * Code|Error| Description
   * ---|---|---|
   * 404| `CHAT_NOT_FOUND`| The code return when chat is not found
   * 400| `CHANNEL_NOT_SUPPORT`| The code return when the messaging channel doesn't support reaction
   * 400| `REACTION_NOT_SUPPORT`| The code return when the reaction is not yet support by the service
   */
  readonly InstagramChatReactionSend?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * ChatEvent response may be empty when message was successfully sent but failed to get the event.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly InstagramChatTextMessageSend?: Maybe<InstagramChatEvent>;
  readonly JapanPostCreateCourier: CourierAccountDetails;
  readonly JapanPostUpdateCourier: CourierAccountDetails;
  /**
   * [All user can use this API]
   *
   * do LINE login, and fetch user data.
   *
   * Errors
   * AUTHORIZATION_REQUEST_INCORRECT
   */
  readonly LINELogin?: Maybe<LineLoginResponse>;
  /**
   * [All user can use this API]
   *
   * login to Shopify with using LINE login.
   * target customer should connect LINE <> Shopify before calling this API.
   *
   * Errors
   * IDTOKEN_INCORRECT
   */
  readonly LINELoginShopifyLogin?: Maybe<LineLoginShopifyLoginResponse>;
  /**
   * [All user can use this API]
   *
   * using idToken(for verify user), and email which user want to use for shopify,
   * we'll register new shopify customer.
   *
   * Errors
   * IDTOKEN_INCORRECT
   */
  readonly LINELoginShopifySignUp?: Maybe<LineLoginShopifySignUpResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send image message.
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly LazadaChatImageMessageSend?: Maybe<LazadaChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly LazadaChatTextMessageSend?: Maybe<LazadaChatEvent>;
  /**
   * [All user can use this API]
   *
   * Verify LINE user by idToken and connect to influencer by influencer id
   *
   * Errors
   * INCORRECT_ID_TOKEN
   */
  readonly LiffConnectInfluencer?: Maybe<LiffInfluencerConnectResponse>;
  /**
   * [ALL]
   * verify LIFF idToken, and return related info.
   *
   * Errors
   * INCORRECT_ID_TOKEN
   */
  readonly LiffIdTokenValidate?: Maybe<LiffIdTokenValidateResponse>;
  /**
   * [ALL]
   * inquiry.
   *
   * Errors
   * INCORRECT_ID_TOKEN
   */
  readonly LiffInquirySend?: Maybe<OkResponse>;
  /**
   * [ALL]
   * connect.
   *
   * SubCode|Error
   * ------|-----
   * 3117|SHOPIFY_CUSTOMER_UNIDENTIFIED
   * 3118|SHOPIFY_CUSTOMER_DISABLED
   * 3019|INCORRECT_ID_TOKEN
   * 7008|SHOPIFY_EXCEPTION
   * 2006|CUSTOMER_NOT_FOUND
   * 2002|ACCOUNT_NOT_FOUND
   * 2027|SHOPIFY_CHANNEL_NOT_FOUND
   * 2007|CHAT_NOT_FOUND
   */
  readonly LiffShopifyConnect?: Maybe<OkResponse>;
  /**
   * [ALL]
   * customer create.
   *
   * SubCode|Error
   * ------|-----
   * 3116|SHOPIFY_CUSTOMER_ALREADY_EXISTS
   * 3119|SHOPIFY_CUSTOMER_INVALID_INPUT
   * 3019|INCORRECT_ID_TOKEN
   * 7008|SHOPIFY_EXCEPTION
   * 2006|CUSTOMER_NOT_FOUND
   * 2007|CHAT_NOT_FOUND
   */
  readonly LiffShopifyCustomerCreate?: Maybe<OkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send broadcast message
   *
   * Code|Error
   * ---|---
   * 404| `BROADCAST_MESSAGE_NOT_FOUND`.
   */
  readonly LineBroadCastMessageSend?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * unschedule broadcast message will change status from schedule to draft
   *
   * Code|Error
   * ---|---
   * 404| `BROADCAST_MESSAGE_NOT_FOUND`.
   */
  readonly LineBroadCastMessageUnschedule?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * create broadcast message
   *
   *  originalUrls is ignored when trackingEnabled is false
   *
   * Code|Error
   * ---|---
   * 400| `BAD_REQUEST`.
   */
  readonly LineBroadcastMessageCreate?: Maybe<LineBroadcastMessageCreateResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update broadcast message
   *
   *  originalUrls is ignored when trackingEnabled is false
   *
   * Code|Error
   * ---|---
   * 404| `BROADCAST_MESSAGE_NOT_FOUND`.
   * 400| `BAD_REQUEST`.
   */
  readonly LineBroadcastMessageUpdate?: Maybe<LineBroadcastMessageUpdateResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send image message.
   * ChatEvent response may be empty when message was successfully sent but failed to get the event.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly LineChatImageMessageSend?: Maybe<LineChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * ChatEvent response may be empty when message was successfully sent but failed to get the event.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly LineChatTextMessageSend?: Maybe<LineChatEvent>;
  readonly LineRichMenuCreateDraft?: Maybe<LineRichMenuCreateDraftResponse>;
  readonly LineRichMenuSubmit?: Maybe<LineRichMenuSubmitResponse>;
  readonly LineRichMenuUpdate?: Maybe<LineRichMenuUpdateResponse>;
  /** Create an account */
  readonly MdCreateAccount: MdAccount;
  /** Create a store */
  readonly MdCreateStore: MdStore;
  /** Create a user */
  readonly MdCreateUser: MdUser;
  /** Delete a store */
  readonly MdDeleteStore: Scalars['Long']['output'];
  /** Delete a user */
  readonly MdDeleteUser: Scalars['Long']['output'];
  /** Delete users */
  readonly MdDeleteUsers: ReadonlyArray<Scalars['Long']['output']>;
  readonly MdSendVerificationEmail: MdSendVerificationEmailPayload;
  /** Update an account */
  readonly MdUpdateAccount: MdAccount;
  /** Update a store */
  readonly MdUpdateStore: MdStore;
  /** Update a user */
  readonly MdUpdateUser: MdUser;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send broadcast message
   *  `CompleteSendingMessengerBroadcastSuccess` indicates all process of sending is success.
   *  `StartSendingMessengerBroadcastSuccess` indicates passed validation and start sending.
   *  `FailToStartSendingMessengerBroadcastTooManyUnavailableCustomer` indicates fail validation
   *  regarding to available ratio consider last marketing message.
   *
   * Code|SubCode|Error
   * ---|----|-----
   * 404|2018| `BROADCAST_MESSAGE_NOT_FOUND`.
   * 400|3000| `BAD_REQUEST`.
   * 400|3110| `MESSENGER_BROADCAST_ZERO_RECIPIENT`.
   * 400|3111| `MESSENGER_BROADCAST_INVALID_OPERATION`
   */
  readonly MessengerBroadCastMessageSend?: Maybe<SendMessengerBroadcastResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * unschedule broadcast message will change status from schedule to draft
   *
   * Code|Error
   * ---|---
   * 404| `BROADCAST_MESSAGE_NOT_FOUND`.
   * 400| `BAD_REQUEST`.
   */
  readonly MessengerBroadCastMessageUnschedule?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * create broadcast message
   * Opt-in is required to perform this
   *
   * Code|SubCode|Error
   * ---|----|-----
   * 400|3108|`MESSENGER_BROADCAST_REQUIRED_OPT_IN`
   */
  readonly MessengerBroadcastMessageCreate?: Maybe<MessengerBroadcastMessageCreateResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update broadcast message
   *
   * Code|Error
   * ---|---
   * 404| `BROADCAST_MESSAGE_NOT_FOUND`.
   * 400| `BAD_REQUEST`.
   */
  readonly MessengerBroadcastMessageUpdate?: Maybe<MessengerBroadcastMessageUpdateResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * ChatEvent response may be empty when message was successfully sent but failed to get the event.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly MessengerChatImageMessageSend?: Maybe<MessengerChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * ChatEvent response may be empty when message was successfully sent but failed to get the event.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly MessengerChatTextMessageSend?: Maybe<MessengerChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update facebook connection (refresh token)
   *
   * Code|SubCode|Error
   * ---|---|---
   * 401|1002| `NOT_ALLOW_ACTION`.
   * 404|2005| `STORE_NOT_FOUND`.
   */
  readonly RefreshFacebookTokenConnection?: Maybe<RefreshFacebookTokenResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * When isRefreshTokenSuccess is false, use this Mutation to reauthorize the connection.
   *
   * Code|Error
   * ---|---
   * 2032| `SHOPEE_CONNECTION_NOT_FOUND`.
   * 3114| `NO_PERMISSION_GIVEN_FOR_SHOPEE_SHOP`.
   */
  readonly RefreshShopeeChatConnection?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * add greeting message for a store
   */
  readonly SaveGreetingMessage?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * create and update opt-in message of the store.
   *
   * after enable message will send when system received any message from customer and
   *  the customer still haven't got requested for opt-in we will send opt-in message.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly SaveMessengerOptInMessage?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * set customer tags to be
   *
   * Code|Error
   * ---|---
   * 400| `BAD_REQUEST`.
   */
  readonly SetChatCustomerTags?: Maybe<ReadonlyArray<CustomerTag>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send image message.
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ShopeeChatImageMessageSend?: Maybe<ShopeeChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ShopeeChatTextMessageSend?: Maybe<ShopeeChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * connect shopify channel.
   *
   * Code|Error
   * ---|---
   * 400| `INVALID_REDIRECTED_URL`
   */
  readonly StoreChannelShopifyConnect?: Maybe<StoreChannelShopifyConnectResponse>;
  readonly StoreChannelShopifyConnectAdmin?: Maybe<StoreChannelShopifyConnectResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * generate shopify connect url.
   *
   * Code|Error
   * ---|---
   * 400| `INVALID_SHOPIFY_DOMAIN`
   * 404| `STORE_NOT_FOUND`.
   */
  readonly StoreChannelShopifyOauthUrlGenerate?: Maybe<StoreChannelShopifyOauthUrlGenerateResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * register line chat.
   *
   * Code|Error
   * ---|---
   * 400| `INVALID_LINE_KEY_ID`
   */
  readonly StoreChatLineConnectV2?: Maybe<StoreChatLineConnectResponseV2>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * register line chat.
   *
   * Code|Error
   * ---|---
   */
  readonly StoreChatLinePublicKeyGenerateV2?: Maybe<StoreChatLinePublicKeyGenerateResponseV2>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * add template plain messages of the storeChat.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly StoreChatTemplatePlainMessageAdd?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * delete template plain messages of the storeChat.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly StoreChatTemplatePlainMessageDelete?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update template plain messages of the storeChat.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly StoreChatTemplatePlainMessageUpdate?: Maybe<AnyChatOkResponse>;
  /** ToggleEnableWorkflow */
  readonly ToggleEnableWorkflow?: Maybe<WorkflowStatusOk>;
  readonly UPSAddAccountNumber: CourierAccountDetails;
  readonly UPSInstallation: UpsPath;
  readonly UPSOpenAccount: CourierAccountDetails;
  readonly UPSRegisterCustomer: StatusOk;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update automation flow
   *
   * Code|Error
   * ---|---
   * 400| `USER_NOT_FOUND`.
   */
  readonly UpdateAutomationFlow?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update customer tag by id.
   * returns updated customer tag
   *
   * Erros
   * 3018 - ALREADY_EXISTS - returns when mergeIntoExistingTag is false
   */
  readonly UpdateCustomerTag?: Maybe<CustomerTag>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update whatsapp optIn Setting
   *
   * Code|SubCode|Error
   * ---|---|---
   * 401|1002| `NOT_ALLOW_ACTION`.
   * 404|2005| `STORE_NOT_FOUND`.
   * 404|2029| `WHATSAPP_CONNECTION_NOT_FOUND`.
   */
  readonly UpdateWhatsAppOptInSetting?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * updateWorkflow
   * bodyJSON may have LINE_unit_message_ID, LINE_unit_message_content(serialized string).
   * after get content, pass to AnyChat Service.
   * Code|Error
   * ---|---
   * 400| `INITIAL_FLOW_BLOCK_NOT_FOUND`.
   * 400| `INVALID_ACTION`.
   * 400| `INVALID_LINE_SEND_MESSAGE_ACTION`.
   * 400| `INVALID_CONDITION_FOR_ORDER`.
   * 400| `INVALID_CONJUNCTION_FOR_NUMBER`.
   * 400| `INVALID_CONJUNCTION_FOR_ENUM`.
   * 400| `INVALID_FLOW_BLOCK`.
   * 400| `INVALID_CONDITION_FOR_LINE_MESSAGE`.
   * 400| `INVALID_CONJUNCTION_VALUE`.
   * 404| `WORKFLOW_NOT_FOUND`.
   */
  readonly UpdateWorkflow?: Maybe<Workflow>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * create broadcast message
   * 400|3123|`WHATSAPP_BROADCAST_CONTENT_VALIDATION_FAIL`
   * 400|3124|`WHATSAPP_BROADCAST_REQUIRED_OPT_IN`
   * 400|3125|`WHATSAPP_BROADCAST_CARDS_SAME_AMOUNT_BUTTONS_FAIL`
   */
  readonly WhatsAppBroadcastCreate?: Maybe<Scalars['String']['output']>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update broadcast message
   * return error if broadcast status is not allowed to update
   * Code|SubCode|Error
   * ---|----|-----
   * 400|3022|`NOT_ALLOW_OPERATION_FOR_STATE`
   * 404|2018|`BROADCAST_MESSAGES_NOT_FOUND`
   */
  readonly WhatsAppBroadcastToDraft?: Maybe<AnyChatOkResponse>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * update broadcast message
   * return error if broadcast status is not allowed to update
   * Code|SubCode|Error
   * ---|----|-----
   * 400|3124|`WHATSAPP_BROADCAST_REQUIRED_OPT_IN`
   * 400|3123|`WHATSAPP_BROADCAST_CONTENT_VALIDATION_FAIL`
   * 400|3125|`WHATSAPP_BROADCAST_CARDS_SAME_AMOUNT_BUTTONS_FAIL`
   * 400|3022|`NOT_ALLOW_OPERATION_FOR_STATE`
   * 400|3005|`MISSING_PARAM_FOR_TYPE`
   * 404|2018|`BROADCAST_MESSAGES_NOT_FOUND`
   */
  readonly WhatsAppBroadcastUpdate?: Maybe<WhatsAppBroadcastUpdateResult>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send image message.
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly WhatsAppChatImageMessageSend?: Maybe<WhatsAppChatEvent>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * send text message.
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly WhatsAppChatTextMessageSend?: Maybe<WhatsAppChatEvent>;
  readonly addShipperAddress: ShipperAddress;
  readonly anyLogiUpsertOrder: Scalars['String']['output'];
  /**
   * Archive product variants.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `ALREADY_ARCHIVED(productCode: String)`, `CANNOT_ARCHIVE_VARIANT_WITH_ACTIVE_INBOUND(productCode: String)`, `CANNOT_ARCHIVE_VARIANT_WITH_ACTIVE_ORDER_LOGISTIC_FULFILLMENT(productCode: String)`, `CANNOT_ARCHIVE_VARIANT_WITH_SET_PRODUCT(productCode: String, setProductCodes: List)`, `CANNOT_ARCHIVE_VARIANT_WITH_WAREHOUSE_INVENTORY(productCode: String)`
   */
  readonly archiveProductVariants: ArchiveProductVariantsOutput;
  readonly cancelInbound: StatusOk;
  readonly cancelInbounds: BulkOperationResponse;
  readonly cancelPickups: BulkOperationResponse;
  readonly connectChannelToWarehouse: StatusOk;
  /**
   * Connect to Rakuten using licenseKey and serviceSecret.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_CREDENTIAL`, `STORE_ALREADY_CONNECTED`, `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly connectRakuten: RakutenConnectPayload;
  readonly createAnylogiSupplierUser: Id;
  readonly createCourierSettings: StatusOk;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Create a datasource.
   *         Please provide `setting` object with the following fields
   *         GA4:
   *         - `account`
   *         - `property`
   *         - `accountName`
   *         - `propertyName`
   *         Facebook ads:
   *         - `account`
   *         - `accountName`
   *         GoogleAds:
   *         - `customerId`
   *         - `account`
   *         - `customerName`
   *         - `accountName`
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly createDatasource: DatasourcePayload;
  readonly createInbound?: Maybe<InboundIds>;
  readonly createLogisticChannelRequestForm?: Maybe<StatusOk>;
  readonly createLogisticShopifyCarrierServiceCondition: ShopifyCarrierServiceCondition;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Create a manual cost.
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly createManualCost: ManualCostPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Create marketplace calendar event.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly createMarketplaceCalendarEvent: MarketplaceCalendarEventPayload;
  readonly createPickup: PickupDetails;
  readonly createShipping: Shipping;
  readonly createShopifyCarrierService: StatusOk;
  readonly createSupplier: Id;
  readonly createWarehouse: Id;
  readonly deleteAnylogiSupplierUsers: BulkOperationResponse;
  readonly deleteChannelFromWarehouse: StatusOk;
  readonly deleteCourierAccount: StatusOk;
  readonly deleteCrossBorderBasicAuth: StatusOk;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Delete a datasource.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly deleteDatasource: Scalars['Long']['output'];
  readonly deleteLogisticShopifyCarrierServiceCondition: StatusOk;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Delete a manual cost.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly deleteManualCost: Scalars['Long']['output'];
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Delete marketplace calendar event.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly deleteMarketplaceCalendarEvent: Scalars['Long']['output'];
  readonly deleteShopifyCarrierService: StatusOk;
  readonly deleteWarehouse: StatusOk;
  readonly deleteWarehouses: BulkOperationResponse;
  readonly deliverySlip: Path;
  readonly disconnectChannelFromWarehouse: StatusOk;
  readonly editShipperAddress: ShipperAddress;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Exchange Facebook OAuth code to long-lived token.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`       |Unauthorized request
   *         |`FORBIDDEN`           |This operation is prohibited
   *         |`FACEBOOK_OAUTH_ERROR`|Facebook OAuth has error
   */
  readonly facebookExchangeCode: FacebookExchangeCodePayload;
  /** Upload file */
  readonly fileUpload: FileUploadPayload;
  readonly generateCrossBorderBasicAuth: CrossBorderBasicAuth;
  readonly generateLogilessOAuthURL?: Maybe<Path>;
  readonly generateUploadUrl: Path;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Exchange Google OAuth code to token.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`     |Unauthorized request
   *         |`FORBIDDEN`         |This operation is prohibited
   *         |`GOOGLE_OAUTH_ERROR`|Google OAuth has error
   */
  readonly googleExchangeCode: GoogleExchangeCodePayload;
  readonly ignoreOrderValidation: StatusOk;
  readonly invoiceSlip: Path;
  /**
   * Trigger listing sync.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_SUPPORTED`, `SALE_CHANNEL_INACTIVE`, `SALE_CHANNEL_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly listingTriggerSync: Scalars['Long']['output'];
  readonly logilessOAuth: StatusOk;
  /**
   * Cancel a marketplace order.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_REQUEST`, `NON_CANCELABLE`, `UNAUTHORIZED`
   */
  readonly orderCancel: CancelOrderPayload;
  /**
   * Create a marketplace order.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_REQUEST`, `ORDER_ALREADY_EXIST`, `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly orderManualCreate: Scalars['Long']['output'];
  /**
   * Update a marketplace order.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_REQUEST`, `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly orderManualUpdate: Scalars['Long']['output'];
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `DUPLICATED_PRODUCT_CODE`, `INVALID_HS_CODE`, `INVALID_REQUEST`, `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `CANNOT_ASSIGN_SET_VARIANTS_TO_SET_CHILD(productVariantId: long)`, `CANNOT_BECOME_SET_VARIANT_SINCE_ITS_USED_AS_SET_CHILD(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INBOUND(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INVENTORY(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_ORDER(productVariantId: long)`, `PRODUCT_VARIANT_SET_CANNOT_CONTAIN_ITSELF(productVariantId: long)`
   */
  readonly productCreateV2: Scalars['Long']['output'];
  /**
   *     Update a marketplace product with variants
   *     ValidationError can have sub errors in "extensions.errors" field
   *     .
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   * ▸ SubErrorReasons: `CANNOT_ASSIGN_SET_VARIANTS_TO_SET_CHILD(productVariantId: long)`, `CANNOT_BECOME_SET_VARIANT_SINCE_ITS_USED_AS_SET_CHILD(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INBOUND(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INVENTORY(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_ORDER(productVariantId: long)`, `PRODUCT_VARIANT_SET_CANNOT_CONTAIN_ITSELF(productVariantId: long)`
   */
  readonly productUpdateWithMultiVariants: Scalars['Long']['output'];
  /**
   *     Update a marketplace product with single variant
   *     ValidationError can have sub errors in "extensions.errors" field
   *     .
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_HS_CODE`, `INVALID_REQUEST`, `NOT_FOUND`, `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `CANNOT_ASSIGN_SET_VARIANTS_TO_SET_CHILD(productVariantId: long)`, `CANNOT_BECOME_SET_VARIANT_SINCE_ITS_USED_AS_SET_CHILD(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INBOUND(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INVENTORY(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_ORDER(productVariantId: long)`, `PRODUCT_VARIANT_SET_CANNOT_CONTAIN_ITSELF(productVariantId: long)`
   */
  readonly productUpdateWithSingleVariant: Scalars['Long']['output'];
  /**
   *     Update a marketplace product variant.
   *     this will be used for variant detail page like this (https://app.any-x.com/product/210456/654998)
   *     ValidationError can have sub errors in "extensions.errors" field
   *     .
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_HS_CODE`, `INVALID_REQUEST`, `NOT_FOUND`, `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `CANNOT_ASSIGN_SET_VARIANTS_TO_SET_CHILD(productVariantId: long)`, `CANNOT_BECOME_SET_VARIANT_SINCE_ITS_USED_AS_SET_CHILD(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INBOUND(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_INVENTORY(productVariantId: long)`, `PRODUCT_VARIANT_IN_USE_ORDER(productVariantId: long)`, `PRODUCT_VARIANT_SET_CANNOT_CONTAIN_ITSELF(productVariantId: long)`
   */
  readonly productVariantUpdateV3: Scalars['Long']['output'];
  /**
   * Merge marketplace product variants into one.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `IN_USE`, `MIXED_PRODUCT_VARIANT_TYPE`, `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly productVariantsMergeV3: MergeProductVariantV3Output;
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_CREDENTIAL`, `SALE_CHANNEL_ALREADY_EXISTS`, `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly qoo10SaleChannelCreate: Scalars['Long']['output'];
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_CREDENTIAL`, `SALE_CHANNEL_NOT_FOUND`, `STORE_ALREADY_CONNECTED`, `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly qoo10SaleChannelReconnect: Scalars['Long']['output'];
  /**
   * Reconnect to Rakuten using licenseKey.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `INVALID_CREDENTIAL`, `SALE_CHANNEL_NOT_FOUND`, `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly reconnectRakuten: RakutenReconnectPayload;
  /**
   *             Sale channel file upload.
   *             VALIDATION_ERROR has SubErrorReasons in "extensions.errors" field.
   *         .
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `FILE_NOT_FOUND`, `SALE_CHANNEL_NOT_FOUND`, `STORE_NOT_FOUND`, `UNAUTHORIZED`, `UNKNOWN_IMPORT_TYPE`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `COLUMN_COUNT_MISS_MATCH(lineNumbers: List)`, `EMPTY_DATA()`, `EMPTY_REQUIRED_FIELD(lineNumbers: List, columnName: String)`, `HEADER_MISS_MATCH(columnNames: List)`, `INVALID_FORMAT(lineNumbers: List, columnName: String)`, `MISSING_COLUMNS(columnNames: List)`, `PHONE_NUMBER_AND_COUNTRY_NOT_MATCH(lineNumbers: List)`
   */
  readonly registerFileUploadV2: RegisterFileUploadPayload;
  /**
   * Creates a task to import MANUAL orders via CSV.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `COLUMN_COUNT_MISS_MATCH()`, `DIFFERENT_CURRENCY_CODES_WITHIN_SAME_ORDER(orderId: String)`, `DUPLICATE_ORDER_ID(orderId: String)`, `EMPTY_DATA_ROWS()`, `EMPTY_HEADER_ROW()`, `EMPTY_REQUIRED_FIELD(index: int, columnName: String)`, `HEADER_MISS_MATCH(columnName: String)`, `INVALID_COURIER_SERVICE_FOR_PAYMENT_METHOD(courierService: String, paymentMethod: String, index: int)`, `INVALID_FORMAT(index: int, columnName: String)`, `INVALID_LENGTH_FOR_PRODUCT_AND_PRICES(index: int)`, `INVALID_LENGTH_FOR_PRODUCT_AND_QUANTITIES(index: int)`, `PRODUCT_CODE_DOES_NOT_EXIST(productCode: String)`, `PRODUCT_CODE_DUPLICATED(orderId: String)`
   * @deprecated Use registerManualOrderCsvUploadV2 for new templates.
   */
  readonly registerManualOrderCsvUpload: RegisterManualOrderCsvUploadPayload;
  /**
   * Creates a task to import MANUAL orders via CSV.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `COLUMN_COUNT_MISS_MATCH()`, `DIFFERENT_CURRENCY_CODES_WITHIN_SAME_ORDER(orderId: String)`, `DUPLICATE_ORDER_ID(orderId: String)`, `EMPTY_DATA_ROWS()`, `EMPTY_HEADER_ROW()`, `EMPTY_REQUIRED_FIELD(index: int, columnName: String)`, `HEADER_MISS_MATCH(columnName: String)`, `INVALID_COURIER_SERVICE_FOR_PAYMENT_METHOD(courierService: String, paymentMethod: String, index: int)`, `INVALID_FORMAT(index: int, columnName: String)`, `INVALID_LENGTH_FOR_PRODUCT_AND_PRICES(index: int)`, `INVALID_LENGTH_FOR_PRODUCT_AND_QUANTITIES(index: int)`, `PRODUCT_CODE_DOES_NOT_EXIST(productCode: String)`, `PRODUCT_CODE_DUPLICATED(orderId: String)`
   */
  readonly registerManualOrderCsvUploadV2: RegisterManualOrderCsvUploadPayload;
  /**
   * Trigger a task for uploading set products by CSV.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `BODY_COLUMN_COUNT_MISS_MATCH(indexes: NonEmptyList)`, `CHILD_PRODUCTS_ALREADY_SET_PRODUCT(productCodes: NonEmptyList)`, `CHILD_PRODUCTS_NOT_FOUND(productCodes: NonEmptyList)`, `DOESNT_MATCH_WITHIN_SET(indexes: NonEmptyList, columnName: String)`, `DUPLICATE_PRODUCT_CODE(productCodes: NonEmptyList)`, `EMPTY_DATA()`, `EMPTY_REQUIRED_FIELD(indexes: NonEmptyList, columnName: String)`, `HEADER_COLUMN_SIZE_MISS_MATCH()`, `HEADER_MISS_MATCH(columnNames: NonEmptyList)`, `INVALID_CHILD_PRODUCT_QUANTITY(productCodes: NonEmptyList)`, `INVALID_FORMAT(indexes: NonEmptyList, columnName: String)`, `PRODUCT_CODE_EXISTS(productCodes: NonEmptyList)`
   */
  readonly registerManualSetProductCsvUpload: ManualSetProductCsvUploadPayload;
  /**
   * Trigger a task for uploading single products by CSV.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `BODY_COLUMN_COUNT_MISS_MATCH(indexes: NonEmptyList)`, `DUPLICATE_PRODUCT_CODE(productCodes: NonEmptyList)`, `EMPTY_DATA()`, `EMPTY_REQUIRED_FIELD(indexes: NonEmptyList, columnName: String)`, `HEADER_COLUMN_COUNT_MISS_MATCH()`, `HEADER_MISS_MATCH(columnNames: NonEmptyList)`, `INVALID_FORMAT(indexes: NonEmptyList, columnName: String)`, `INVALID_SUPPLIERS(supplierIds: NonEmptyList)`, `PRODUCT_CODE_EXISTS(productCodes: NonEmptyList)`
   */
  readonly registerManualSingleProductCsvUpload: ManualSingleProductCsvUploadPayload;
  /**
   * Trigger a task for uploading update products by CSV.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`, `VALIDATION_ERROR`
   * ▸ SubErrorReasons: `ADMIN_USER_ONLY_EDIT_PRODUCT_CODE(productCodes: NonEmptyList)`, `BODY_COLUMN_COUNT_MISS_MATCH(indexes: NonEmptyList)`, `DUPLICATE_PRODUCT_CODE(productCodes: NonEmptyList)`, `EMPTY_DATA()`, `EMPTY_REQUIRED_FIELD(indexes: NonEmptyList, columnName: String)`, `HEADER_COLUMN_SIZE_MISS_MATCH()`, `HEADER_MISS_MATCH(columnNames: NonEmptyList)`, `INVALID_FORMAT(indexes: NonEmptyList, columnName: String)`, `INVALID_SUPPLIERS(supplierIds: NonEmptyList)`, `PRODUCT_CODE_EXISTS(productCodes: NonEmptyList)`, `PRODUCT_ID_DOES_NOT_MATCH(productIds: NonEmptyList)`, `PRODUCT_VARIANT_NOT_FOUND(variantIds: NonEmptyList)`, `WAREHOUSE_CODE_ALREADY_EXISTS(warehouseCodes: NonEmptyList)`, `WAREHOUSE_CODE_CANNOT_CHANGE(warehouseCodes: NonEmptyList)`
   */
  readonly registerManualUpdateProductCsvUpload: ManualUpdateProductCsvUploadPayload;
  readonly reinviteAnylogiSupplierUsers: BulkOperationResponse;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Save ad cost campaign mapping
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly saveAdCostCampaignMapping: AdCostCampaignMappingSavePayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Save adset mapping
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly saveAdSetMapping: AdSetMappingSavePayload;
  /** Save DDI product status */
  readonly saveDdiProductStatus: OperationResultPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Create cost index for a sale channel.
   *
   *         Shopify:
   *         - creditCardFeePercentage (%)
   *
   *         Amazon:
   *         - referralFeeSoldPercentage (%)
   *
   *         Rakuten:
   *         - rakutenSystemFeePercentage (%)
   *         - rakutenPayFeePercentage (%)
   *
   *         Shopee, Lazada:
   *         - commissionFeePercentage (%)
   *         - transactionFeePercentage (%)
   *
   *         TikTok:
   *         - commissionFeePercentage (%)
   *         - flatFee: 100.1
   *
   *         Yahoo:
   *         - resourceBurdenRatioPercentage (%)
   *         - transactionFeePercentage (%)
   *
   *         ZOZOTOWN:
   *         - commissionFeePercentage (%)
   *
   *         Tokopedia:
   *         - commissionFeePercentage (%)
   *         - freeShippingFeeAdjustmentPercentage (%)
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly saveSaleChannelCostIndex: SaleChannelCostIndexPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Save adset mapping margin
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly setAdSetMappingMargin: AdSetMappingSetMarginPayload;
  readonly setLogisticShopifyCarrierServiceCondition: ShopifyCarrierServiceCondition;
  readonly shipOrder: StatusOk;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Start or refresh competitor analysis for a product.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly startProductReviewCompetitorAnalysis: ProductReviewCompetitorAnalysisStatusDto;
  readonly syncSkuInventory: StatusOk;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Exchange TikTok OAuth code to token.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`       |Unauthorized request
   *         |`FORBIDDEN`           |This operation is prohibited
   *         |`TIKTOK_OAUTH_ERROR`  |TikTok OAuth has error
   */
  readonly tikTokAdsExchangeCode: TikTokExchangeCodePayload;
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `SALE_CHANNEL_ALREADY_EXISTS`, `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly tokopediaSaleChannelCreate: Scalars['Long']['output'];
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `SALE_CHANNEL_NOT_FOUND`, `STORE_ALREADY_CONNECTED`, `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly tokopediaSaleChannelReconnect: Scalars['Long']['output'];
  /**
   * Unarchive product variants.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `ALREADY_UNARCHIVED`, `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly unarchiveProductVariants: UnarchiveProductVariantsOutput;
  readonly updateAnylogiCrossBorderShippingOrder: StatusOk;
  readonly updateAnylogiSupplierUser: StatusOk;
  readonly updateCourierSettings: StatusOk;
  readonly updateInbound: StatusOk;
  readonly updateInboundWarehouseArrivalDate: StatusOk;
  readonly updateLogisticChannel: StatusOk;
  readonly updateLogisticShopifyCarrierServiceCondition: ShopifyCarrierServiceCondition;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Update a manual cost.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly updateManualCost: ManualCostPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Update marketplace calendar event.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly updateMarketplaceCalendarEvent: MarketplaceCalendarEventPayload;
  readonly updateSupplier: StatusOk;
  readonly updateWarehouse: StatusOk;
  readonly uploadInboundsWithCsv: StatusOk;
  /** Upload inventory data */
  readonly uploadInventoryFile: UploadInventoryFilePayload;
  readonly voidShipping: Shipping;
  /** Exchange Yahoo Ads auth code to long-lived refresh token */
  readonly yahooAdsExchangeCode: YahooAdsDatasourceTokenPayload;
};


export type MutationAddAutomationFlowArgs = {
  input: AddAutomationFlowRequest;
};


export type MutationAddCustomerTagsArgs = {
  input: AddCustomerTagRequest;
};


export type MutationAddWorkflowArgs = {
  body: WorkflowBodyInput;
  schedule?: InputMaybe<WorkflowScheduleInput>;
  storeId: Scalars['Long']['input'];
  testMode?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};


export type MutationAnyChatStoreCrmConfigUpdateArgs = {
  input: AnyChatStoreCrmConfigUpdateRequest;
};


export type MutationChatAssignArgs = {
  input: ChatAssignRequest;
};


export type MutationChatAssignV2Args = {
  input: ChatAssignRequestV2;
};


export type MutationChatResolveArgs = {
  input: ChatResolveRequest;
};


export type MutationChatStateUpdateArgs = {
  input: ChatStateUpdateRequest;
};


export type MutationChatUnassignArgs = {
  input: ChatUnassignRequest;
};


export type MutationChatUnresolveArgs = {
  input: ChatUnresolveRequest;
};


export type MutationConnectLazadaArgs = {
  input: ConnectLazadaRequest;
};


export type MutationConnectShopeeChatArgs = {
  input: ConnectShopeeChatRequestInput;
};


export type MutationConnectTikTokShopArgs = {
  input: ConnectTikTokShopRequest;
};


export type MutationConnectToFacebookV2Args = {
  input: ConnectToFacebookRequestV2Input;
};


export type MutationConnectToWhatsAppArgs = {
  input: ConnectWhatsAppRequestInput;
};


export type MutationCustomerNoteAddArgs = {
  input: CustomerNoteAddRequest;
};


export type MutationCustomerProfileUpdateArgs = {
  input: CustomerProfileUpdateRequest;
};


export type MutationDhlCreateCourierArgs = {
  input: DhlCreateCourierInput;
};


export type MutationDhlUpdateCourierArgs = {
  input: DhlUpdateCourierInput;
};


export type MutationDeleteCustomerTagsArgs = {
  input: DeleteCustomerTagsRequest;
};


export type MutationDeleteWorkflowArgs = {
  id: Scalars['String']['input'];
};


export type MutationDuplicateWorkflowArgs = {
  id: Scalars['String']['input'];
};


export type MutationFedExAddressValidationArgs = {
  input: FedExAddressValidationInput;
};


export type MutationFedExCreateCourierArgs = {
  input: FedExCreateCourier;
};


export type MutationFedExGeneratePinCodeArgs = {
  input: FedExGeneratePinCodeInput;
};


export type MutationFedExUpdateCourierArgs = {
  input: FedExUpdateCourier;
};


export type MutationGenerateAssetUploadUrlV2Args = {
  input: GenerateAssetUploadUrlRequest;
};


export type MutationGenerateTrackingUrlArgs = {
  input: GenerateTrackingUrlRequest;
};


export type MutationInstagramChatImageMessageSendArgs = {
  input: InstagramSendImageMessageRequest;
};


export type MutationInstagramChatReactionSendArgs = {
  input: InstagramSendReactionRequest;
};


export type MutationInstagramChatTextMessageSendArgs = {
  input: InstagramSendTextMessageRequest;
};


export type MutationJapanPostCreateCourierArgs = {
  input: JapanPostCreateCourierInput;
};


export type MutationJapanPostUpdateCourierArgs = {
  input: JapanPostUpdateCourierInput;
};


export type MutationLineLoginArgs = {
  input: LineLoginRequest;
};


export type MutationLineLoginShopifyLoginArgs = {
  input: LineLoginShopifyLoginRequest;
};


export type MutationLineLoginShopifySignUpArgs = {
  input: LineLoginShopifySignUpRequest;
};


export type MutationLazadaChatImageMessageSendArgs = {
  input: LazadaSendImageMessageRequestInput;
};


export type MutationLazadaChatTextMessageSendArgs = {
  input: LazadaSendTextMessageRequestInput;
};


export type MutationLiffConnectInfluencerArgs = {
  input: LiffInfluencerConnectRequestInput;
};


export type MutationLiffIdTokenValidateArgs = {
  input: LiffIdTokenValidateRequest;
};


export type MutationLiffInquirySendArgs = {
  input: LiffInquirySendRequest;
};


export type MutationLiffShopifyConnectArgs = {
  input: LiffShopifyConnectRequest;
};


export type MutationLiffShopifyCustomerCreateArgs = {
  input: LiffShopifyCustomerCreateRequest;
};


export type MutationLineBroadCastMessageSendArgs = {
  input: SendBroadcastMessageRequestInput;
};


export type MutationLineBroadCastMessageUnscheduleArgs = {
  input: UnscheduleMessageRequestInput;
};


export type MutationLineBroadcastMessageCreateArgs = {
  input: LineBroadcastMessageCreateRequestInput;
};


export type MutationLineBroadcastMessageUpdateArgs = {
  input: LineBroadcastMessageUpdateRequestInput;
};


export type MutationLineChatImageMessageSendArgs = {
  input: LineSendImageMessageRequest;
};


export type MutationLineChatTextMessageSendArgs = {
  input: LineSendTextMessageRequest;
};


export type MutationLineRichMenuCreateDraftArgs = {
  input: LineRichMenuCreateDraftInput;
};


export type MutationLineRichMenuSubmitArgs = {
  input: LineRichMenuSubmitInput;
};


export type MutationLineRichMenuUpdateArgs = {
  input: LineRichMenuUpdateInput;
};


export type MutationMdCreateAccountArgs = {
  input: MdCreateAccountInput;
};


export type MutationMdCreateStoreArgs = {
  input: MdCreateStoreInput;
};


export type MutationMdCreateUserArgs = {
  input: MdCreateUserInput;
};


export type MutationMdDeleteStoreArgs = {
  id: Scalars['Long']['input'];
};


export type MutationMdDeleteUserArgs = {
  id: Scalars['Long']['input'];
};


export type MutationMdDeleteUsersArgs = {
  ids: ReadonlyArray<Scalars['Long']['input']>;
};


export type MutationMdSendVerificationEmailArgs = {
  input: MdSendVerificationEmailInput;
};


export type MutationMdUpdateAccountArgs = {
  input: MdUpdateAccountInput;
};


export type MutationMdUpdateStoreArgs = {
  input: MdUpdateStoreInput;
};


export type MutationMdUpdateUserArgs = {
  input: MdUpdateUserInput;
};


export type MutationMessengerBroadCastMessageSendArgs = {
  input: SendMessengerBroadcastMessageRequestInput;
};


export type MutationMessengerBroadCastMessageUnscheduleArgs = {
  input: UnscheduleMessengerMessageRequestInput;
};


export type MutationMessengerBroadcastMessageCreateArgs = {
  input: MessengerBroadcastMessageCreateRequestInput;
};


export type MutationMessengerBroadcastMessageUpdateArgs = {
  input: MessengerBroadcastMessageUpdateRequestInput;
};


export type MutationMessengerChatImageMessageSendArgs = {
  input: MessengerSendImageMessageRequest;
};


export type MutationMessengerChatTextMessageSendArgs = {
  input: MessengerSendTextMessageRequest;
};


export type MutationRefreshFacebookTokenConnectionArgs = {
  input: ConnectToFacebookRequestV2Input;
};


export type MutationRefreshShopeeChatConnectionArgs = {
  input: RefreshShopeeConnectionRequest;
};


export type MutationSaveGreetingMessageArgs = {
  input: SaveGreetingMessageRequest;
};


export type MutationSaveMessengerOptInMessageArgs = {
  input: SaveMessengerOptInMessageRequestInput;
};


export type MutationSetChatCustomerTagsArgs = {
  input: SetCustomerTagsRequest;
};


export type MutationShopeeChatImageMessageSendArgs = {
  input: ShopeeSendImageMessageRequestInput;
};


export type MutationShopeeChatTextMessageSendArgs = {
  input: ShopeeSendTextMessageRequestInput;
};


export type MutationStoreChannelShopifyConnectArgs = {
  input: StoreChannelShopifyConnectRequest;
};


export type MutationStoreChannelShopifyConnectAdminArgs = {
  input: StoreChannelShopifyConnectAdminRequest;
};


export type MutationStoreChannelShopifyOauthUrlGenerateArgs = {
  input: GenerateShopifyOauthUrlRequest;
};


export type MutationStoreChatLineConnectV2Args = {
  input: StoreChatLineConnectRequestV2;
};


export type MutationStoreChatLinePublicKeyGenerateV2Args = {
  input: StoreChatLinePublicKeyGenerateRequestV2;
};


export type MutationStoreChatTemplatePlainMessageAddArgs = {
  input: StoreChatTemplatePlainMessageAddRequest;
};


export type MutationStoreChatTemplatePlainMessageDeleteArgs = {
  input: StoreChatTemplatePlainMessagesDeleteRequest;
};


export type MutationStoreChatTemplatePlainMessageUpdateArgs = {
  input: StoreChatTemplatePlainMessageUpdateRequest;
};


export type MutationToggleEnableWorkflowArgs = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpsAddAccountNumberArgs = {
  accountNumber: Scalars['String']['input'];
  courierId: Scalars['ID']['input'];
};


export type MutationUpsInstallationArgs = {
  accountId: Scalars['String']['input'];
  callbackUrl: Scalars['String']['input'];
  merchantId: Scalars['String']['input'];
};


export type MutationUpsOpenAccountArgs = {
  input: UpsOpenAccountInput;
};


export type MutationUpsRegisterCustomerArgs = {
  input: UpsRegisterCustomerInput;
};


export type MutationUpdateAutomationFlowArgs = {
  input: UpdateAutomationFlowRequest;
};


export type MutationUpdateCustomerTagArgs = {
  input: UpdateCustomerTagRequest;
};


export type MutationUpdateWhatsAppOptInSettingArgs = {
  input: UpdateWhatsAppOptInSettingRequest;
};


export type MutationUpdateWorkflowArgs = {
  body: WorkflowBodyInput;
  id: Scalars['String']['input'];
  schedule?: InputMaybe<WorkflowScheduleInput>;
  testMode?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};


export type MutationWhatsAppBroadcastCreateArgs = {
  input: WhatsAppBroadcastCreateRequestInput;
};


export type MutationWhatsAppBroadcastToDraftArgs = {
  input: WhatsAppBroadcastToDraftRequest;
};


export type MutationWhatsAppBroadcastUpdateArgs = {
  input: WhatsAppBroadcastUpdateRequestInput;
};


export type MutationWhatsAppChatImageMessageSendArgs = {
  input: WhatsAppSendImageMessageRequestInput;
};


export type MutationWhatsAppChatTextMessageSendArgs = {
  input: WhatsAppSendTextMessageRequestInput;
};


export type MutationAddShipperAddressArgs = {
  input: ShipperAddressInput;
};


export type MutationAnyLogiUpsertOrderArgs = {
  input: AnyLogiUpsertOrderInput;
};


export type MutationArchiveProductVariantsArgs = {
  productVariantIds: ReadonlyArray<Scalars['Long']['input']>;
};


export type MutationCancelInboundArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelInboundsArgs = {
  filters: InboundFilters;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCancelPickupsArgs = {
  filters?: InputMaybe<PickupFilters>;
  ids: ReadonlyArray<Scalars['ID']['input']>;
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationConnectChannelToWarehouseArgs = {
  externalStoreId: Scalars['ID']['input'];
  merchantId: Scalars['String']['input'];
  saleChannelId: Scalars['Long']['input'];
  storeId: Scalars['Long']['input'];
  warehouseId: Scalars['ID']['input'];
};


export type MutationConnectRakutenArgs = {
  licenseKey: Scalars['String']['input'];
  serviceSecret: Scalars['String']['input'];
  storeId: Scalars['Long']['input'];
};


export type MutationCreateAnylogiSupplierUserArgs = {
  currency: AnylogiCurrencyInput;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: Language;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  role: AnylogiUserRoles;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  supplierId: Scalars['ID']['input'];
  timezone: AnylogiTimezone;
};


export type MutationCreateCourierSettingsArgs = {
  input: CourierSettingsInput;
};


export type MutationCreateDatasourceArgs = {
  input: CreateDatasourceInput;
};


export type MutationCreateInboundArgs = {
  comment: Scalars['String']['input'];
  inboundDate: Scalars['Time']['input'];
  products: ReadonlyArray<InboundSku>;
  storeId: Scalars['Long']['input'];
  supplierId: Scalars['ID']['input'];
  warehouseArrivalDate?: InputMaybe<Scalars['Time']['input']>;
};


export type MutationCreateLogisticChannelRequestFormArgs = {
  input: InputCreateLogisticChannelRequestForm;
};


export type MutationCreateLogisticShopifyCarrierServiceConditionArgs = {
  input: ShopifyCarrierServiceConditionInput;
};


export type MutationCreateManualCostArgs = {
  input: CreateManualCostInput;
};


export type MutationCreateMarketplaceCalendarEventArgs = {
  input: CreateMarketplaceCalendarEventInput;
};


export type MutationCreatePickupArgs = {
  input: PickupRequestInput;
};


export type MutationCreateShippingArgs = {
  crossBorderCourierProvider: CrossBorderCourierProvider;
  input: ShippingInput;
  serviceCode: ServiceCodeInput;
};


export type MutationCreateShopifyCarrierServiceArgs = {
  saleChannelId: Scalars['Long']['input'];
};


export type MutationCreateSupplierArgs = {
  accountId: Scalars['Long']['input'];
  addressFirst?: InputMaybe<Scalars['String']['input']>;
  addressSecond?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  provinceId?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateWarehouseArgs = {
  addressFirst: Scalars['String']['input'];
  addressSecond?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Country>;
  deliveryDestination: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: PhoneNumberInput;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  provinceId?: InputMaybe<Scalars['String']['input']>;
  vendor: Wms;
  wmsWarehouseId: Scalars['String']['input'];
};


export type MutationDeleteAnylogiSupplierUsersArgs = {
  filters?: InputMaybe<AnylogiUserFilters>;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeleteChannelFromWarehouseArgs = {
  saleChannelId: Scalars['Long']['input'];
};


export type MutationDeleteCourierAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCrossBorderBasicAuthArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDatasourceArgs = {
  id: Scalars['Long']['input'];
};


export type MutationDeleteLogisticShopifyCarrierServiceConditionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteManualCostArgs = {
  input: DeleteManualCostInput;
};


export type MutationDeleteMarketplaceCalendarEventArgs = {
  input: DeleteMarketplaceCalendarEventInput;
};


export type MutationDeleteShopifyCarrierServiceArgs = {
  saleChannelId: Scalars['Long']['input'];
};


export type MutationDeleteWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWarehousesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
};


export type MutationDeliverySlipArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationDisconnectChannelFromWarehouseArgs = {
  saleChannelId: Scalars['Long']['input'];
};


export type MutationEditShipperAddressArgs = {
  id: Scalars['ID']['input'];
  input: ShipperAddressInput;
};


export type MutationFacebookExchangeCodeArgs = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};


export type MutationFileUploadArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationGenerateCrossBorderBasicAuthArgs = {
  accountId: Scalars['ID']['input'];
};


export type MutationGenerateLogilessOAuthUrlArgs = {
  merchantId: Scalars['ID']['input'];
};


export type MutationGenerateUploadUrlArgs = {
  filename: Scalars['String']['input'];
  filetype: FileType;
  resource: Resource;
  storeId?: InputMaybe<Scalars['Long']['input']>;
};


export type MutationGoogleExchangeCodeArgs = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  type: GoogleLoginUrlType;
};


export type MutationIgnoreOrderValidationArgs = {
  anyXOrderId: Scalars['String']['input'];
};


export type MutationInvoiceSlipArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationListingTriggerSyncArgs = {
  input: ListingTriggerSyncInput;
};


export type MutationLogilessOAuthArgs = {
  code: Scalars['String']['input'];
  merchantId: Scalars['ID']['input'];
};


export type MutationOrderCancelArgs = {
  input: CancelOrderInput;
};


export type MutationOrderManualCreateArgs = {
  input: CreateManualOrderInput;
};


export type MutationOrderManualUpdateArgs = {
  input: UpdateManualOrderInput;
};


export type MutationProductCreateV2Args = {
  input: CreateProductV2Input;
};


export type MutationProductUpdateWithMultiVariantsArgs = {
  input: UpdateProductWithMultiVariantsInput;
};


export type MutationProductUpdateWithSingleVariantArgs = {
  input: UpdateProductWithSingleVariantInput;
};


export type MutationProductVariantUpdateV3Args = {
  input: UpdateProductVariantV3Input;
};


export type MutationProductVariantsMergeV3Args = {
  input: MergeProductVariantV3Input;
};


export type MutationQoo10SaleChannelCreateArgs = {
  input: Qoo10SaleChannelCreateInput;
};


export type MutationQoo10SaleChannelReconnectArgs = {
  input: Qoo10SaleChannelReconnectInput;
};


export type MutationReconnectRakutenArgs = {
  licenseKey: Scalars['String']['input'];
  saleChannelId: Scalars['Long']['input'];
};


export type MutationRegisterFileUploadV2Args = {
  input: FileUploadInput;
};


export type MutationRegisterManualOrderCsvUploadArgs = {
  fileUri: Scalars['String']['input'];
  manualOrderUploadType: ManualOrderUploadType;
  storeId: Scalars['Long']['input'];
};


export type MutationRegisterManualOrderCsvUploadV2Args = {
  fileUri: Scalars['String']['input'];
  manualOrderUploadType: ManualOrderUploadType;
  storeId: Scalars['Long']['input'];
};


export type MutationRegisterManualSetProductCsvUploadArgs = {
  input: ManualSetProductCsvUploadInput;
};


export type MutationRegisterManualSingleProductCsvUploadArgs = {
  input: ManualSingleProductCsvUploadInput;
};


export type MutationRegisterManualUpdateProductCsvUploadArgs = {
  input: ManualUpdateProductCsvUploadInput;
};


export type MutationReinviteAnylogiSupplierUsersArgs = {
  filters?: InputMaybe<AnylogiUserFilters>;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSaveAdCostCampaignMappingArgs = {
  input: AdCostCampaignMappingSaveInput;
};


export type MutationSaveAdSetMappingArgs = {
  input: AdSetMappingSaveInput;
};


export type MutationSaveDdiProductStatusArgs = {
  input: SaveDdiProductStatusListInput;
};


export type MutationSaveSaleChannelCostIndexArgs = {
  input: SaleChannelSaveInput;
};


export type MutationSetAdSetMappingMarginArgs = {
  input: AdSetMappingSetMarginInput;
};


export type MutationSetLogisticShopifyCarrierServiceConditionArgs = {
  active: Scalars['Boolean']['input'];
  input?: InputMaybe<ShopifyCarrierServiceConditionInput>;
  saleChannelId: Scalars['Long']['input'];
};


export type MutationShipOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationStartProductReviewCompetitorAnalysisArgs = {
  input: ProductReviewCompetitorAnalysisRequestInput;
};


export type MutationSyncSkuInventoryArgs = {
  gtin: Scalars['String']['input'];
};


export type MutationTikTokAdsExchangeCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationTokopediaSaleChannelCreateArgs = {
  input: TokopediaSaleChannelCreateInput;
};


export type MutationTokopediaSaleChannelReconnectArgs = {
  input: TokopediaSaleChannelReconnectInput;
};


export type MutationUnarchiveProductVariantsArgs = {
  productVariantIds: ReadonlyArray<Scalars['Long']['input']>;
};


export type MutationUpdateAnylogiCrossBorderShippingOrderArgs = {
  input?: InputMaybe<AnylogiUpdateCrossBorderShippingOrderInput>;
};


export type MutationUpdateAnylogiSupplierUserArgs = {
  currency: AnylogiCurrencyInput;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  language: Language;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  timezone: AnylogiTimezone;
};


export type MutationUpdateCourierSettingsArgs = {
  input: CourierSettingsInput;
};


export type MutationUpdateInboundArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  inboundDate: Scalars['Time']['input'];
  products: ReadonlyArray<InboundSku>;
  supplierId: Scalars['ID']['input'];
  warehouseArrivalDate?: InputMaybe<Scalars['Time']['input']>;
};


export type MutationUpdateInboundWarehouseArrivalDateArgs = {
  id: Scalars['ID']['input'];
  warehouseArrivalDate?: InputMaybe<Scalars['Time']['input']>;
};


export type MutationUpdateLogisticChannelArgs = {
  chargeCODFee: Scalars['Boolean']['input'];
  preferredDeliveryDateTime?: InputMaybe<Scalars['Boolean']['input']>;
  saleChannelId: Scalars['Long']['input'];
  validateAddress: Scalars['Boolean']['input'];
  verified: Scalars['Boolean']['input'];
};


export type MutationUpdateLogisticShopifyCarrierServiceConditionArgs = {
  id: Scalars['ID']['input'];
  input: ShopifyCarrierServiceConditionInput;
};


export type MutationUpdateManualCostArgs = {
  input: UpdateManualCostInput;
};


export type MutationUpdateMarketplaceCalendarEventArgs = {
  input: UpdateMarketplaceCalendarEventInput;
};


export type MutationUpdateSupplierArgs = {
  addressFirst?: InputMaybe<Scalars['String']['input']>;
  addressSecond?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  provinceId?: InputMaybe<Scalars['String']['input']>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateWarehouseArgs = {
  addressFirst: Scalars['String']['input'];
  addressSecond?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Country>;
  deliveryDestination: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phoneNumber: PhoneNumberInput;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  provinceId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUploadInboundsWithCsvArgs = {
  csvUrl: Scalars['String']['input'];
  storeId?: InputMaybe<Scalars['Long']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUploadInventoryFileArgs = {
  input: InventoryUploadInput;
};


export type MutationVoidShippingArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationYahooAdsExchangeCodeArgs = {
  input: YahooAdsExchangeCodeInput;
};

export type Name = {
  readonly __typename?: 'Name';
  readonly first: Scalars['String']['output'];
  readonly last: Scalars['String']['output'];
};

export type NameInput = {
  readonly first: Scalars['String']['input'];
  readonly last: Scalars['String']['input'];
};

export type NoInstagramAccountConnectToFacebookError = {
  readonly __typename?: 'NoInstagramAccountConnectToFacebookError';
  readonly message: Scalars['String']['output'];
};

export type NoInstagramAccountRefreshTokenError = {
  readonly __typename?: 'NoInstagramAccountRefreshTokenError';
  readonly message: Scalars['String']['output'];
};

export type NotedActivity = CustomerActivity & {
  readonly __typename?: 'NotedActivity';
  readonly id: Scalars['String']['output'];
  readonly text: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly user: BasicUser;
};

export type NumericValueWithDiffPayload = {
  readonly __typename?: 'NumericValueWithDiffPayload';
  readonly diff: Scalars['BigDecimal']['output'];
  readonly diffPercentage: Scalars['BigDecimal']['output'];
  readonly value: Scalars['BigDecimal']['output'];
};

export type OkResponse = {
  readonly __typename?: 'OkResponse';
  readonly ok: Scalars['Boolean']['output'];
};

export type Onboarding = {
  readonly __typename?: 'Onboarding';
  readonly hasConnectedSaleChannel: Scalars['Boolean']['output'];
  readonly hasInputUnitCost: Scalars['Boolean']['output'];
  readonly hasRegisteredOrder: Scalars['Boolean']['output'];
  readonly hasShopifyChannel: Scalars['Boolean']['output'];
  readonly inactiveSaleChannelCount: Scalars['Int']['output'];
  readonly storeId: Scalars['Long']['output'];
};

export type OnboardingInput = {
  readonly storeId: Scalars['Long']['input'];
};

export type OngoingCount = {
  readonly __typename?: 'OngoingCount';
  readonly ongoingCount: Scalars['Int']['output'];
  readonly ongoingInquiryCount: Scalars['Int']['output'];
  readonly ongoingMessageCount: Scalars['Int']['output'];
  readonly unreadCount: Scalars['Int']['output'];
};

export type OperationResultPayload = {
  readonly __typename?: 'OperationResultPayload';
  readonly success: Scalars['Boolean']['output'];
};

export const OptInCallToActionText = {
  ALLOW: 'ALLOW',
  GET: 'GET',
  GET_UPDATES: 'GET_UPDATES',
  OPT_IN: 'OPT_IN',
  SIGN_UP: 'SIGN_UP'
} as const;

export type OptInCallToActionText = typeof OptInCallToActionText[keyof typeof OptInCallToActionText];
export type OrderAddress = {
  readonly __typename?: 'OrderAddress';
  readonly address1: Scalars['String']['output'];
  readonly address2?: Maybe<Scalars['String']['output']>;
  readonly cityName: Scalars['String']['output'];
  readonly company?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly email: Scalars['String']['output'];
  readonly name: Name;
  readonly phoneNumber: Scalars['String']['output'];
  readonly phoneNumberCountry: Scalars['String']['output'];
  readonly province: Scalars['String']['output'];
  readonly zipcode: Scalars['String']['output'];
};

export type OrderAddressInput = {
  readonly address1: Scalars['String']['input'];
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  readonly cityName?: InputMaybe<Scalars['String']['input']>;
  readonly company?: InputMaybe<Scalars['String']['input']>;
  readonly country: Scalars['String']['input'];
  readonly name: NameInput;
  readonly phoneNumber: Scalars['String']['input'];
  readonly phoneNumberCountry: Scalars['String']['input'];
  readonly province?: InputMaybe<Scalars['String']['input']>;
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** if orderIds exist, fetch orders by orderIds. otherwise fetch all orders by filter */
export type OrderCsvExportV2Input = {
  readonly exportType: OrderExportType;
  readonly filter?: InputMaybe<OrderListFilterInput>;
  readonly orderIds: ReadonlyArray<Scalars['Long']['input']>;
};

export type OrderCustomerInput = {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly name: NameInput;
  readonly phoneNumber: Scalars['String']['input'];
  readonly phoneNumberCountry?: InputMaybe<MarketplaceCountry>;
};

export const OrderDateFilterTypeV2 = {
  CREATED: 'CREATED',
  SHIPPED: 'SHIPPED'
} as const;

export type OrderDateFilterTypeV2 = typeof OrderDateFilterTypeV2[keyof typeof OrderDateFilterTypeV2];
export type OrderDateRangeInput = {
  readonly endAt: Scalars['LocalDate']['input'];
  readonly startAt: Scalars['LocalDate']['input'];
  readonly type: OrderDateFilterTypeV2;
};

export type OrderDetail = {
  readonly __typename?: 'OrderDetail';
  readonly billingAddress?: Maybe<OrderAddress>;
  readonly cancelledDate?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly createdDate: Scalars['OffsetDateTime']['output'];
  readonly currency: Scalars['String']['output'];
  readonly customer?: Maybe<ReadOrderCustomer>;
  readonly deliveryStatus: DeliveryStatus;
  readonly discount?: Maybe<Scalars['BigDecimal']['output']>;
  /** we support only for specific store now. */
  readonly hasAwb: Scalars['Boolean']['output'];
  readonly id: Scalars['Long']['output'];
  readonly note: Scalars['String']['output'];
  readonly orderLogistics?: Maybe<OrderLogistics>;
  readonly orderProducts: ReadonlyArray<OrderProduct>;
  readonly paymentMethod: OrderPaymentMethod;
  readonly paymentStatus: PaymentStatus;
  readonly saleChannel: SaleChannel;
  readonly saleChannelSpecific?: Maybe<SaleChannelSpecificData>;
  readonly sellerChannelOrderId: Scalars['String']['output'];
  readonly shipping?: Maybe<Scalars['BigDecimal']['output']>;
  readonly shippingAddress?: Maybe<OrderAddress>;
  readonly shippingDate?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly subtotal?: Maybe<Scalars['BigDecimal']['output']>;
  readonly total?: Maybe<Scalars['BigDecimal']['output']>;
  readonly totalTax?: Maybe<Scalars['BigDecimal']['output']>;
};

export const OrderExportType = {
  PER_ORDER: 'PER_ORDER',
  PER_PRODUCT: 'PER_PRODUCT'
} as const;

export type OrderExportType = typeof OrderExportType[keyof typeof OrderExportType];
export type OrderFilters = {
  readonly accountId?: InputMaybe<Scalars['ID']['input']>;
  readonly channelProvider?: InputMaybe<ChannelProvider>;
  readonly courierType?: InputMaybe<CrossBorderCourierProvider>;
  readonly created?: InputMaybe<InputDateRange>;
  readonly crossBorderValidationErrors?: InputMaybe<ReadonlyArray<CrossBorderValidationError>>;
  readonly deliveryStatus?: InputMaybe<DeliveryStatus>;
  readonly deliveryStatusIn?: InputMaybe<ReadonlyArray<DeliveryStatus>>;
  readonly deliveryStatusNe?: InputMaybe<ReadonlyArray<DeliveryStatus>>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly labelCreated?: InputMaybe<InputDateRange>;
  readonly laterPay?: InputMaybe<Scalars['Boolean']['input']>;
  readonly paymentMethod?: InputMaybe<AnylogiPaymentMethod>;
  readonly paymentStatus?: InputMaybe<PaymentStatus>;
  readonly shipped?: InputMaybe<InputDateRange>;
  readonly shippingAddressCountryNe?: InputMaybe<Scalars['String']['input']>;
  readonly shippingStatus?: InputMaybe<ShippingStatus>;
  readonly storeId?: InputMaybe<Scalars['ID']['input']>;
  readonly trackingStatusIn?: InputMaybe<ReadonlyArray<AnylogiOrderTrackingStatus>>;
  readonly validationStatus?: InputMaybe<ReadonlyArray<ValidationStatus>>;
};

export type OrderItem = {
  readonly __typename?: 'OrderItem';
  readonly amount: Scalars['Int']['output'];
  /** @deprecated use costMoney.amount instead */
  readonly cost: Scalars['BigDecimal']['output'];
  readonly costMoney: AnyChatMoney;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  /** @deprecated use totalCostMoney.amount instead */
  readonly totalCost: Scalars['BigDecimal']['output'];
  readonly totalCostMoney: AnyChatMoney;
};

export type OrderKeywordFilterInput = {
  readonly keyword: Scalars['String']['input'];
  readonly type: OrderKeywordFilterType;
};

export const OrderKeywordFilterType = {
  ORDER: 'ORDER',
  PRODUCT: 'PRODUCT'
} as const;

export type OrderKeywordFilterType = typeof OrderKeywordFilterType[keyof typeof OrderKeywordFilterType];
export const OrderKeywordFilterTypeV2 = {
  ORDER_ID: 'ORDER_ID',
  PRODUCT: 'PRODUCT',
  TRACKING_NUMBER: 'TRACKING_NUMBER'
} as const;

export type OrderKeywordFilterTypeV2 = typeof OrderKeywordFilterTypeV2[keyof typeof OrderKeywordFilterTypeV2];
export type OrderKeywordFilterV2Input = {
  readonly keyword: Scalars['String']['input'];
  readonly type: OrderKeywordFilterTypeV2;
};

export type OrderListFilterInput = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly courierService?: InputMaybe<CourierService>;
  readonly deliveryStatus?: InputMaybe<DeliveryStatus>;
  readonly keywordFilterV2?: InputMaybe<OrderKeywordFilterV2Input>;
  readonly orderDateRange?: InputMaybe<OrderDateRangeInput>;
  readonly paymentMethod?: InputMaybe<PaymentMethodInput>;
  readonly saleChannelType?: InputMaybe<MarketplaceSaleChannelType>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
  readonly validationStatuses?: InputMaybe<ReadonlyArray<OrderValidationStatus>>;
};

/** Order list view. {start}-{end} / {total}. page {page} of {totalPage} */
export type OrderListView = {
  readonly __typename?: 'OrderListView';
  readonly end: Scalars['Int']['output'];
  readonly items: ReadonlyArray<OrderRecord>;
  readonly page: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type OrderLogistics = {
  readonly __typename?: 'OrderLogistics';
  readonly anylogiId: Scalars['String']['output'];
  readonly codFee?: Maybe<Scalars['BigDecimal']['output']>;
  readonly courierService: CourierService;
  readonly deliveryNote: Scalars['String']['output'];
  readonly desiredDeliveryDate?: Maybe<Scalars['LocalDate']['output']>;
  readonly desiredDeliveryTime?: Maybe<DesiredDeliveryTimeframe>;
  readonly temperature: OrderTemperature;
  readonly trackingNumber?: Maybe<Scalars['String']['output']>;
  readonly trackingURL?: Maybe<Scalars['URL']['output']>;
  readonly validationStatus: OrderValidationStatus;
};

export type OrderLogisticsForList = {
  readonly __typename?: 'OrderLogisticsForList';
  readonly anylogiId: Scalars['String']['output'];
  readonly courierService: CourierService;
  readonly orderValidationStatus: OrderValidationStatus;
  readonly warehouse?: Maybe<AnyXWarehouse>;
};

export const OrderOnboardingStatus = {
  NO_SALE_CHANNEL: 'NO_SALE_CHANNEL',
  ONGOING: 'ONGOING'
} as const;

export type OrderOnboardingStatus = typeof OrderOnboardingStatus[keyof typeof OrderOnboardingStatus];
export type OrderOverview = {
  readonly __typename?: 'OrderOverview';
  readonly all: Scalars['Int']['output'];
  readonly allocating: Scalars['Int']['output'];
  readonly cancelled: Scalars['Int']['output'];
  readonly deliveryStatuses: ReadonlyArray<DeliveryStatus>;
  readonly paying: Scalars['Int']['output'];
  readonly pending: Scalars['Int']['output'];
  readonly preparing: Scalars['Int']['output'];
  readonly shipped: Scalars['Int']['output'];
  readonly working: Scalars['Int']['output'];
};

export type OrderPaymentMethod = {
  readonly __typename?: 'OrderPaymentMethod';
  readonly name: Scalars['String']['output'];
  readonly paymentMethodType: PaymentMethodType;
};

export type OrderProduct = {
  readonly __typename?: 'OrderProduct';
  readonly amount: Scalars['BigDecimal']['output'];
  readonly externalId: Scalars['String']['output'];
  readonly fulfillable: Scalars['Boolean']['output'];
  readonly logistics?: Maybe<ReadOrderProductLogistics>;
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly price: Scalars['BigDecimal']['output'];
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly quantity: Scalars['Int']['output'];
  readonly sellerSku?: Maybe<Scalars['String']['output']>;
};

export const OrderProductDeliveryStatus = {
  ALLOCATED: 'ALLOCATED',
  ALLOCATING: 'ALLOCATING',
  CANCELLED: 'CANCELLED',
  UNKNOWN: 'UNKNOWN'
} as const;

export type OrderProductDeliveryStatus = typeof OrderProductDeliveryStatus[keyof typeof OrderProductDeliveryStatus];
export type OrderProductInputV2Input = {
  /** when this OrderProduct is existing, provide the same externalId, if it's new, use null */
  readonly externalId?: InputMaybe<Scalars['String']['input']>;
  readonly price: Scalars['BigDecimal']['input'];
  readonly productCode: Scalars['String']['input'];
  readonly quantity: Scalars['Int']['input'];
};

export type OrderRecord = {
  readonly __typename?: 'OrderRecord';
  readonly cancelable: Scalars['Boolean']['output'];
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly currency: CurrencyCode;
  readonly customerName?: Maybe<Name>;
  readonly deliveryStatus: DeliveryStatus;
  readonly externalOrderId: Scalars['String']['output'];
  readonly id: Scalars['Long']['output'];
  readonly itemCount: Scalars['Int']['output'];
  /** only for logistics-connected orders */
  readonly logistics?: Maybe<OrderLogisticsForList>;
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly paymentMethod: OrderPaymentMethod;
  readonly saleChannel: SaleChannel;
  readonly saleChannelId: Scalars['Long']['output'];
  readonly shippedAt?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly total: Scalars['BigDecimal']['output'];
};

export type OrderSource = {
  readonly __typename?: 'OrderSource';
  readonly anyXOrderId?: Maybe<Scalars['Long']['output']>;
  readonly imageUrl?: Maybe<Scalars['Url']['output']>;
  readonly itemCount: Scalars['Int']['output'];
  readonly shopeeOrderSN: Scalars['String']['output'];
};

export const OrderTemperature = {
  KEEP_FROZEN: 'KEEP_FROZEN',
  KEEP_REFRIGERATED: 'KEEP_REFRIGERATED',
  NORMAL: 'NORMAL'
} as const;

export type OrderTemperature = typeof OrderTemperature[keyof typeof OrderTemperature];
export const OrderValidationStatus = {
  IGNORED: 'IGNORED',
  INVALID_SHIPPING_ADDRESS_CITY: 'INVALID_SHIPPING_ADDRESS_CITY',
  INVALID_SHIPPING_ADDRESS_COUNTRY_CODE: 'INVALID_SHIPPING_ADDRESS_COUNTRY_CODE',
  INVALID_SHIPPING_ADDRESS_HOUSE_NUMBER: 'INVALID_SHIPPING_ADDRESS_HOUSE_NUMBER',
  INVALID_SHIPPING_ADDRESS_POSTAL_CODE: 'INVALID_SHIPPING_ADDRESS_POSTAL_CODE',
  INVALID_SHIPPING_ADDRESS_PROVINCE: 'INVALID_SHIPPING_ADDRESS_PROVINCE',
  UNKNOWN: 'UNKNOWN',
  VALID: 'VALID'
} as const;

export type OrderValidationStatus = typeof OrderValidationStatus[keyof typeof OrderValidationStatus];
export type OtherSticker = {
  readonly __typename?: 'OtherSticker';
  readonly stickerType: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
};

export type PackageType = DhlPackageWrapper | FedExPackageWrapper;

export type PackageTypeInput = {
  readonly dhlPackageType?: InputMaybe<DhlPackageType>;
  readonly fedexPackageType?: InputMaybe<FedExPackageType>;
};

export type PageHaveConnectedToOtherStore = {
  readonly __typename?: 'PageHaveConnectedToOtherStore';
  readonly message: Scalars['String']['output'];
  readonly storeId: Scalars['Long']['output'];
};

export type PageResultAutomationFlow = {
  readonly __typename?: 'PageResultAutomationFlow';
  readonly records: ReadonlyArray<AutomationFlow>;
  readonly total: Scalars['Int']['output'];
};

export type PageResultCustomerActivity = {
  readonly __typename?: 'PageResultCustomerActivity';
  readonly records: ReadonlyArray<CustomerActivity>;
  readonly total: Scalars['Int']['output'];
};

export type PageResultInfluencerCampaign = {
  readonly __typename?: 'PageResultInfluencerCampaign';
  readonly records: ReadonlyArray<InfluencerCampaign>;
  readonly total: Scalars['Int']['output'];
};

export type PageResultInfluencerItem = {
  readonly __typename?: 'PageResultInfluencerItem';
  readonly records: ReadonlyArray<InfluencerItem>;
  readonly total: Scalars['Int']['output'];
};

export type PageResultLineRichMenuListItem = {
  readonly __typename?: 'PageResultLineRichMenuListItem';
  readonly records: ReadonlyArray<LineRichMenuListItem>;
  readonly total: Scalars['Int']['output'];
};

export type Paginated = {
  readonly page: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type Path = {
  readonly __typename?: 'Path';
  readonly url: Scalars['String']['output'];
};

export type PaymentMethodInput = {
  readonly name: Scalars['String']['input'];
  readonly paymentMethodType: PaymentMethodType;
};

export const PaymentMethodType = {
  ATM: 'ATM',
  BANK_TRANSFER: 'BANK_TRANSFER',
  BNPL: 'BNPL',
  CARRIER_PAYMENT: 'CARRIER_PAYMENT',
  CASH: 'CASH',
  COD: 'COD',
  CREDIT_CARD: 'CREDIT_CARD',
  CVS: 'CVS',
  DEFERRED_PAYMENT: 'DEFERRED_PAYMENT',
  EMONEY: 'EMONEY',
  E_COMMERCE_EMONEY: 'E_COMMERCE_EMONEY',
  FREE: 'FREE',
  INSTALLMENT: 'INSTALLMENT',
  INVOICE: 'INVOICE',
  LEASE: 'LEASE',
  LOAN: 'LOAN',
  NO_PAYMENT: 'NO_PAYMENT',
  OTHER: 'OTHER',
  REGISTERED_MAIL: 'REGISTERED_MAIL',
  SHOPIFY_PAYMENTS: 'SHOPIFY_PAYMENTS',
  SMARTPHONE: 'SMARTPHONE',
  STORE: 'STORE',
  UNKNOWN_PAYMENT: 'UNKNOWN_PAYMENT',
  VIRTUAL_ACCOUNT: 'VIRTUAL_ACCOUNT'
} as const;

export type PaymentMethodType = typeof PaymentMethodType[keyof typeof PaymentMethodType];
export type PaymentMethodsPayload = {
  readonly __typename?: 'PaymentMethodsPayload';
  readonly paymentMethods: ReadonlyArray<PaymentMethodType>;
};

export const PaymentStatus = {
  CANCELLED: 'CANCELLED',
  PAID: 'PAID',
  PAYING: 'PAYING'
} as const;

export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];
export type PhoneNumber = {
  readonly __typename?: 'PhoneNumber';
  readonly countryCode: Country;
  readonly number: Scalars['String']['output'];
};

export type PhoneNumberInput = {
  readonly countryCode: Country;
  readonly number: Scalars['String']['input'];
};

export type PickupBoxSize = {
  readonly __typename?: 'PickupBoxSize';
  readonly depth: Scalars['Int']['output'];
  readonly height: Scalars['Int']['output'];
  readonly quantity: Scalars['Int']['output'];
  readonly weight: Scalars['Float']['output'];
  readonly width: Scalars['Int']['output'];
};

export type PickupBoxSizeInput = {
  readonly depth: Scalars['Int']['input'];
  readonly height: Scalars['Int']['input'];
  readonly quantity: Scalars['Int']['input'];
  readonly weight: Scalars['Float']['input'];
  readonly width: Scalars['Int']['input'];
};

export const PickupCourierProvider = {
  DHL: 'DHL',
  FED_EX: 'FED_EX',
  JAPAN_POST: 'JAPAN_POST',
  UPS: 'UPS'
} as const;

export type PickupCourierProvider = typeof PickupCourierProvider[keyof typeof PickupCourierProvider];
export type PickupDetails = {
  readonly __typename?: 'PickupDetails';
  readonly account: IdName;
  readonly anylogiPickupOrderIds?: Maybe<ReadonlyArray<AnylogiPickupOrder>>;
  readonly boxSizes?: Maybe<ReadonlyArray<PickupBoxSize>>;
  readonly contactName: Scalars['String']['output'];
  readonly created: Scalars['Date']['output'];
  readonly destinationCountryCode?: Maybe<Country>;
  readonly id: Scalars['ID']['output'];
  readonly overWeightIndicator?: Maybe<Scalars['Boolean']['output']>;
  readonly phone?: Maybe<PhoneNumber>;
  readonly pickupCourierProvider: PickupCourierProvider;
  readonly pickupNumber: Scalars['String']['output'];
  readonly pickupServiceCode?: Maybe<PickupServiceCode>;
  readonly pickupStatus: PickupStatus;
  readonly preferredClosePickupTime?: Maybe<Scalars['Date']['output']>;
  readonly preferredPickupDate?: Maybe<Scalars['Date']['output']>;
  readonly preferredPickupLocation?: Maybe<Scalars['String']['output']>;
  readonly preferredReadyPickupTime?: Maybe<Scalars['Date']['output']>;
  readonly residentialIndicator?: Maybe<Scalars['Boolean']['output']>;
  readonly shipperAddressId: Scalars['ID']['output'];
  readonly specialInstructions?: Maybe<Scalars['String']['output']>;
  readonly totalPackages?: Maybe<Scalars['Int']['output']>;
  readonly totalWeight?: Maybe<Scalars['Float']['output']>;
  readonly updated: Scalars['Date']['output'];
};

export type PickupDetailsListResponse = Paginated & {
  readonly __typename?: 'PickupDetailsListResponse';
  readonly page: Scalars['Int']['output'];
  readonly pickupDetailsList: ReadonlyArray<PickupDetails>;
  readonly total: Scalars['Int']['output'];
};

export type PickupFilters = {
  readonly accountId?: InputMaybe<Scalars['ID']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly pickupDate?: InputMaybe<Scalars['Date']['input']>;
  readonly pickupStatus?: InputMaybe<PickupStatus>;
};

export type PickupRequestInput = {
  readonly accountId: Scalars['ID']['input'];
  readonly anylogiPickupOrderIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly boxSizes?: InputMaybe<ReadonlyArray<PickupBoxSizeInput>>;
  readonly contactName?: InputMaybe<Scalars['String']['input']>;
  readonly destinationCountryCode?: InputMaybe<Country>;
  readonly overWeightIndicator?: InputMaybe<Scalars['Boolean']['input']>;
  readonly phone?: InputMaybe<PhoneNumberInput>;
  readonly pickupCourierProvider: PickupCourierProvider;
  readonly preferredClosePickupTime?: InputMaybe<TimeInput>;
  readonly preferredPickupDate?: InputMaybe<Scalars['Date']['input']>;
  readonly preferredPickupLocation?: InputMaybe<Scalars['String']['input']>;
  readonly preferredReadyPickupTime?: InputMaybe<TimeInput>;
  readonly residentialIndicator?: InputMaybe<Scalars['Boolean']['input']>;
  readonly serviceCode?: InputMaybe<PickupServiceCodeInput>;
  readonly shipperAddressId?: InputMaybe<Scalars['ID']['input']>;
  readonly specialInstructions?: InputMaybe<Scalars['String']['input']>;
  readonly totalPackages?: InputMaybe<Scalars['Int']['input']>;
  readonly totalWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type PickupServiceCode = DhlPickupServiceCodeWrapper | FedExPickupServiceCodeWrapper | UpsPickupServiceCodeWrapper;

export type PickupServiceCodeInput = {
  readonly dhlServiceCode?: InputMaybe<DhlPickupServiceCode>;
  readonly fedExServiceCode?: InputMaybe<FedExPickupServiceCode>;
  readonly upsServiceCode?: InputMaybe<UpsPickupServiceCode>;
};

export const PickupStatus = {
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  REQUESTED: 'REQUESTED'
} as const;

export type PickupStatus = typeof PickupStatus[keyof typeof PickupStatus];
export type PlainTextMessage = {
  readonly __typename?: 'PlainTextMessage';
  readonly richText?: Maybe<Scalars['String']['output']>;
  readonly text: Scalars['String']['output'];
};

export type PlainTextMessageInput = {
  readonly richText?: InputMaybe<Scalars['String']['input']>;
  readonly text: Scalars['String']['input'];
};

export type Product = {
  readonly __typename?: 'Product';
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['Long']['output'];
  readonly media: ReadonlyArray<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly store: MdStore;
};

export type ProductAdSetMappingInfoPayload = {
  readonly __typename?: 'ProductAdSetMappingInfoPayload';
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly productCode: Scalars['String']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly productName: Scalars['String']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly productVariantName: Scalars['String']['output'];
  readonly saleChannels?: Maybe<ReadonlyArray<ListingInfoPayload>>;
};

/** if productIds exist, fetch products by productIds. otherwise fetch all products by filter */
export type ProductCsvExportV2Input = {
  readonly filter?: InputMaybe<ProductListFilterInput>;
  readonly productIds: ReadonlyArray<Scalars['Long']['input']>;
};

export type ProductCategoryAndPricePayload = {
  readonly __typename?: 'ProductCategoryAndPricePayload';
  readonly category?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly currency?: Maybe<ReportSupportedCurrency>;
  readonly price?: Maybe<Scalars['BigDecimal']['output']>;
};

export type ProductChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly orderStatus: ReportOrderStatus;
  readonly productId: Scalars['Long']['input'];
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ProductChartPayload = {
  readonly __typename?: 'ProductChartPayload';
  readonly advertisingFee: ReadonlyArray<Scalars['Map']['output']>;
  readonly avgOrderValues: ReadonlyArray<Scalars['Map']['output']>;
  readonly cancelAndOrRefund: ReadonlyArray<Scalars['Map']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly discount: ReadonlyArray<Scalars['Map']['output']>;
  readonly discountRate: ReadonlyArray<Scalars['Map']['output']>;
  readonly grossSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly netSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly orders: ReadonlyArray<Scalars['Map']['output']>;
  readonly revenueViaAds: ReadonlyArray<Scalars['Map']['output']>;
  readonly roas: ReadonlyArray<Scalars['Map']['output']>;
  readonly shippingFee: ReadonlyArray<Scalars['Map']['output']>;
  readonly totalSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly unitSold: ReadonlyArray<Scalars['Map']['output']>;
};

export type ProductDailyReportData = {
  readonly __typename?: 'ProductDailyReportData';
  readonly advertisingFee: Scalars['BigDecimal']['output'];
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly commissionFee: Scalars['BigDecimal']['output'];
  readonly consumptionRatio?: Maybe<Scalars['BigDecimal']['output']>;
  readonly costRate: Scalars['BigDecimal']['output'];
  readonly date?: Maybe<Scalars['LocalDate']['output']>;
  readonly discount: Scalars['BigDecimal']['output'];
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate: Scalars['BigDecimal']['output'];
  readonly inventory?: Maybe<Scalars['Long']['output']>;
  readonly logisticsFee: Scalars['BigDecimal']['output'];
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate: Scalars['BigDecimal']['output'];
  readonly merchandiseCost: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly predictedInventoryDays?: Maybe<Scalars['Long']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly remaining?: Maybe<Scalars['Long']['output']>;
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly targetInventoryDays?: Maybe<Scalars['Long']['output']>;
};

export type ProductDailyReportPayload = {
  readonly __typename?: 'ProductDailyReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly data: ReadonlyArray<ProductDailyReportData>;
  readonly page: Scalars['Int']['output'];
  readonly summary: ProductReportSummary;
  readonly total: ProductDailyReportData;
  readonly totalItems: Scalars['Int']['output'];
};

export type ProductDetail = {
  readonly __typename?: 'ProductDetail';
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['Long']['output'];
  readonly media: ReadonlyArray<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  /** can exist when the product has only 1 variant */
  readonly singleVariant?: Maybe<ProductVariantSingle>;
  readonly status: ProductVariantStatus;
  readonly store: MdStore;
  readonly variantsV2: ReadonlyArray<ProductVariantV2>;
};

export type ProductDetailedReportInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly orderStatus: ReportOrderStatus;
  readonly productId: Scalars['Long']['input'];
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ProductDetailedReportPayload = {
  readonly __typename?: 'ProductDetailedReportPayload';
  readonly advertisingFee: Scalars['BigDecimal']['output'];
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly discount: Scalars['BigDecimal']['output'];
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly revenueViaAds: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly unitSold: Scalars['Long']['output'];
};

export type ProductDetailedReportPayloadList = {
  readonly __typename?: 'ProductDetailedReportPayloadList';
  readonly items: ReadonlyArray<ProductDetailedReportPayload>;
  readonly total: ProductDetailedReportTotalPayload;
};

export type ProductDetailedReportTotalPayload = {
  readonly __typename?: 'ProductDetailedReportTotalPayload';
  readonly advertisingFee: Scalars['BigDecimal']['output'];
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly discount: Scalars['BigDecimal']['output'];
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly revenueViaAds: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly unitSold: Scalars['Long']['output'];
};

export type ProductHsCodes = {
  readonly __typename?: 'ProductHsCodes';
  readonly hsCodes: ReadonlyArray<Scalars['String']['output']>;
};

export type ProductInformation = {
  readonly __typename?: 'ProductInformation';
  readonly id: Scalars['Long']['output'];
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly store: MdStore;
  readonly variantCount: Scalars['Int']['output'];
};

export type ProductListFilterInput = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType?: InputMaybe<MarketplaceSaleChannelType>;
  readonly status?: InputMaybe<ProductVariantStatus>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly type?: InputMaybe<ProductType>;
};

/** Product variant list view. {start}-{end} / {total}. page {page} of {totalPage} */
export type ProductListView = {
  readonly __typename?: 'ProductListView';
  readonly end: Scalars['Int']['output'];
  readonly items: ReadonlyArray<ProductRecord>;
  readonly page: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type ProductLogisticsInput = {
  readonly color?: InputMaybe<Scalars['String']['input']>;
  readonly content: Scalars['String']['input'];
  readonly courierType?: InputMaybe<CourierService>;
  readonly height_mm?: InputMaybe<Scalars['Int']['input']>;
  readonly inventorySync: Scalars['Boolean']['input'];
  readonly length_mm?: InputMaybe<Scalars['Int']['input']>;
  readonly note: Scalars['String']['input'];
  readonly size?: InputMaybe<Scalars['String']['input']>;
  readonly sizeCoefficient?: InputMaybe<Scalars['Int']['input']>;
  readonly supplierId?: InputMaybe<Scalars['String']['input']>;
  readonly width_mm?: InputMaybe<Scalars['Int']['input']>;
};

export const ProductOnboardingStatus = {
  NO_SALE_CHANNEL: 'NO_SALE_CHANNEL',
  ONGOING: 'ONGOING'
} as const;

export type ProductOnboardingStatus = typeof ProductOnboardingStatus[keyof typeof ProductOnboardingStatus];
export type ProductRatingOverviewPayload = {
  readonly __typename?: 'ProductRatingOverviewPayload';
  readonly averageRating: Scalars['BigDecimal']['output'];
  readonly fiveStarsCount: Scalars['Long']['output'];
  readonly fourStarsCount: Scalars['Long']['output'];
  readonly oneStarCount: Scalars['Long']['output'];
  readonly threeStarsCount: Scalars['Long']['output'];
  readonly totalRatings: Scalars['Long']['output'];
  readonly twoStarsCount: Scalars['Long']['output'];
};

export type ProductRecord = {
  readonly __typename?: 'ProductRecord';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['Long']['output'];
  readonly listingVariantSaleChannelIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly listingVariantSaleChannels: ReadonlyArray<SaleChannel>;
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly store: MdStore;
  readonly variantCount: Scalars['Int']['output'];
  /** can exist when the product has only 1 variant */
  readonly variantProductCode?: Maybe<Scalars['String']['output']>;
  readonly variantProductType: VariantProductType;
  /** can exist when the product has only 1 variant & has AnyLogi sku */
  readonly variantSkuDetail?: Maybe<SkuDetail>;
};

export type ProductReportData = {
  readonly __typename?: 'ProductReportData';
  readonly accountName?: Maybe<Scalars['String']['output']>;
  readonly advertisingCost: Scalars['BigDecimal']['output'];
  readonly avgSalesValue: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly commissionFees: Scalars['BigDecimal']['output'];
  readonly daysSalesOfInventory?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly logisticFees: Scalars['BigDecimal']['output'];
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate: Scalars['BigDecimal']['output'];
  readonly masterDataAccountId?: Maybe<Scalars['Long']['output']>;
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly merchandiseCost: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productId?: Maybe<Scalars['Long']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly revenueAdv: Scalars['BigDecimal']['output'];
  readonly revenueGross: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly storeName?: Maybe<Scalars['String']['output']>;
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly unitSold?: Maybe<Scalars['Int']['output']>;
  readonly warehouseInventory?: Maybe<Scalars['Int']['output']>;
};

export type ProductReportExportFilterInput = {
  readonly adsMetricsType?: InputMaybe<AdsMetricsType>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly sortBy?: InputMaybe<ProductReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly variants?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type ProductReportFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ProductReportPaginatedFilterInput = {
  readonly adsMetricsType?: InputMaybe<AdsMetricsType>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly sortBy?: InputMaybe<ProductReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly variants?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type ProductReportPayload = {
  readonly __typename?: 'ProductReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly items: ReadonlyArray<ProductReportData>;
  readonly meta: ReportListMeta;
};

export const ProductReportSortByField = {
  ADVERTISING_COST: 'ADVERTISING_COST',
  ADVERTISING_REVENUE: 'ADVERTISING_REVENUE',
  AVG_SALES_VALUE: 'AVG_SALES_VALUE',
  COMMISSION_FEE: 'COMMISSION_FEE',
  DAYS_SALES_INVENTORY: 'DAYS_SALES_INVENTORY',
  DISCOUNTS: 'DISCOUNTS',
  DISCOUNTS_RATE: 'DISCOUNTS_RATE',
  GROSS_PROFIT: 'GROSS_PROFIT',
  GROSS_PROFIT_RATE: 'GROSS_PROFIT_RATE',
  GROSS_REVENUE: 'GROSS_REVENUE',
  GROSS_SALES: 'GROSS_SALES',
  LOGISTICS_FEE: 'LOGISTICS_FEE',
  MARGINAL_PROFIT: 'MARGINAL_PROFIT',
  MARGINAL_PROFIT_RATE: 'MARGINAL_PROFIT_RATE',
  NET_SALES: 'NET_SALES',
  OTHER_COST: 'OTHER_COST',
  PRODUCT_NAME: 'PRODUCT_NAME',
  RETURN_CANCEL: 'RETURN_CANCEL',
  REVENUE: 'REVENUE',
  ROAS: 'ROAS',
  SHIPPING_FEE: 'SHIPPING_FEE',
  TOTAL_SALES: 'TOTAL_SALES',
  UNIT_COST: 'UNIT_COST',
  UNIT_SOLD: 'UNIT_SOLD',
  WAREHOUSE_INVENTORY: 'WAREHOUSE_INVENTORY'
} as const;

export type ProductReportSortByField = typeof ProductReportSortByField[keyof typeof ProductReportSortByField];
export type ProductReportSummary = {
  readonly __typename?: 'ProductReportSummary';
  readonly cancelAndOrRefund: ReportCompareData;
  readonly grossProfit: ReportCompareData;
  readonly grossProfitRate: ReportCompareData;
  readonly grossSales: ReportCompareData;
  readonly marginalProfit: ReportCompareData;
  readonly marginalProfitRate: ReportCompareData;
  readonly netSales: ReportCompareData;
  readonly revenue: ReportCompareData;
  readonly totalSales: ReportCompareData;
};

export type ProductReportSummaryPayload = {
  readonly __typename?: 'ProductReportSummaryPayload';
  readonly currency: ReportSupportedCurrency;
  readonly summary: ProductReportSummary;
  readonly total: ProductReportTotalData;
};

export type ProductReportTotalData = {
  readonly __typename?: 'ProductReportTotalData';
  readonly advertisingCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly avgSalesValue: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly commissionFees: Scalars['BigDecimal']['output'];
  readonly daysSalesOfInventory?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly logisticFees?: Maybe<Scalars['BigDecimal']['output']>;
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate: Scalars['BigDecimal']['output'];
  readonly merchandiseCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly revenueAdv: Scalars['BigDecimal']['output'];
  readonly revenueGross: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly unitSold?: Maybe<Scalars['Int']['output']>;
  readonly warehouseInventory?: Maybe<Scalars['Int']['output']>;
};

export type ProductReviewAnalysisFilterInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productId: Scalars['Long']['input'];
  readonly saleChannelType: SaleChannelType;
};

export type ProductReviewCompetitorAnalysisPayload = {
  readonly __typename?: 'ProductReviewCompetitorAnalysisPayload';
  readonly data?: Maybe<ProductReviewCompetitorResponsePayload>;
  readonly nextAvailableTime?: Maybe<Scalars['ZonedDatetime']['output']>;
  readonly status: ProductReviewCompetitorAnalysisStatus;
};

export type ProductReviewCompetitorAnalysisRequestInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productId: Scalars['Long']['input'];
  readonly saleChannelType: SaleChannelType;
};

export const ProductReviewCompetitorAnalysisStatus = {
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
  NOT_ENOUGH_COMPETITORS: 'NOT_ENOUGH_COMPETITORS',
  NOT_ENOUGH_REVIEWS_COMPETITORS: 'NOT_ENOUGH_REVIEWS_COMPETITORS',
  NOT_ENOUGH_REVIEWS_PRODUCT: 'NOT_ENOUGH_REVIEWS_PRODUCT',
  NOT_FOUND: 'NOT_FOUND',
  NOT_STARTED: 'NOT_STARTED',
  NO_DATA: 'NO_DATA',
  PROCESSING: 'PROCESSING'
} as const;

export type ProductReviewCompetitorAnalysisStatus = typeof ProductReviewCompetitorAnalysisStatus[keyof typeof ProductReviewCompetitorAnalysisStatus];
export type ProductReviewCompetitorAnalysisStatusDto = {
  readonly __typename?: 'ProductReviewCompetitorAnalysisStatusDto';
  readonly nextAvailableTime?: Maybe<Scalars['ZonedDatetime']['output']>;
  readonly status: ProductReviewCompetitorAnalysisStatus;
};

export type ProductReviewCompetitorResponsePayload = {
  readonly __typename?: 'ProductReviewCompetitorResponsePayload';
  readonly brand?: Maybe<Scalars['String']['output']>;
  readonly competitors: ReadonlyArray<ProductReviewCompetitorsResponse>;
  readonly criteria: ReadonlyArray<ProductReviewCriteriaResponseDto>;
  readonly currency?: Maybe<ReportSupportedCurrency>;
  readonly discountedPrice?: Maybe<Scalars['BigDecimal']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly itemId: Scalars['String']['output'];
  readonly masterDataStoreId: Scalars['Long']['output'];
  readonly originalPrice?: Maybe<Scalars['BigDecimal']['output']>;
  readonly productId: Scalars['Long']['output'];
  readonly productUrl?: Maybe<Scalars['String']['output']>;
  readonly rating?: Maybe<Scalars['BigDecimal']['output']>;
  readonly reviewCount?: Maybe<Scalars['Long']['output']>;
  readonly saleChannelType: SaleChannelType;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ProductReviewCompetitorsResponse = {
  readonly __typename?: 'ProductReviewCompetitorsResponse';
  readonly brand?: Maybe<Scalars['String']['output']>;
  readonly criteria: ReadonlyArray<ProductReviewCriteriaResponseDto>;
  readonly currency?: Maybe<ReportSupportedCurrency>;
  readonly discountedPrice?: Maybe<Scalars['BigDecimal']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly itemId: Scalars['String']['output'];
  readonly originalPrice?: Maybe<Scalars['BigDecimal']['output']>;
  readonly productUrl?: Maybe<Scalars['String']['output']>;
  readonly rating?: Maybe<Scalars['BigDecimal']['output']>;
  readonly reviewCount?: Maybe<Scalars['Long']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ProductReviewCriteriaResponseDto = {
  readonly __typename?: 'ProductReviewCriteriaResponseDto';
  readonly criteria: Scalars['String']['output'];
  readonly negative?: Maybe<Scalars['BigDecimal']['output']>;
  readonly positive?: Maybe<Scalars['BigDecimal']['output']>;
};

export type ProductReviewPayload = {
  readonly __typename?: 'ProductReviewPayload';
  readonly comment: Scalars['String']['output'];
  readonly date: Scalars['LocalDatetime']['output'];
  readonly rating: Scalars['Long']['output'];
};

export type ProductReviewSentimentAnalysisPayload = {
  readonly __typename?: 'ProductReviewSentimentAnalysisPayload';
  readonly data: ReadonlyArray<ProductReviewSentimentResponsePayload>;
};

export type ProductReviewSentimentResponsePayload = {
  readonly __typename?: 'ProductReviewSentimentResponsePayload';
  readonly key: Scalars['String']['output'];
  readonly percentage: Scalars['BigDecimal']['output'];
  readonly reviewIds: ReadonlyArray<Scalars['String']['output']>;
};

export type ProductReviewSummarizationPayload = {
  readonly __typename?: 'ProductReviewSummarizationPayload';
  readonly text: Scalars['String']['output'];
};

export type ProductReviewTopicAnalysisPayload = {
  readonly __typename?: 'ProductReviewTopicAnalysisPayload';
  readonly data: ReadonlyArray<ProductReviewTopicResponsePayload>;
};

export type ProductReviewTopicResponsePayload = {
  readonly __typename?: 'ProductReviewTopicResponsePayload';
  readonly percentage: Scalars['BigDecimal']['output'];
  readonly reviewIds: ReadonlyArray<Scalars['String']['output']>;
  readonly topic: Scalars['String']['output'];
  readonly topicSummary: Scalars['String']['output'];
};

export type ProductReviewsInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly productId: Scalars['Long']['input'];
  readonly rating?: InputMaybe<Scalars['Long']['input']>;
  readonly reviewIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly saleChannelType: SaleChannelType;
};

export type ProductReviewsPayloadList = {
  readonly __typename?: 'ProductReviewsPayloadList';
  readonly meta: ReportListMeta;
  readonly reviews: ReadonlyArray<ProductReviewPayload>;
};

export type ProductSaleChannelsInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly productId: Scalars['Long']['input'];
};

export type ProductSaleChannelsPayload = {
  readonly __typename?: 'ProductSaleChannelsPayload';
  readonly saleChannelTypes: ReadonlyArray<SaleChannelType>;
};

export type ProductSalesAnalyticsInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly orderStatus: ReportOrderStatus;
  readonly productId: Scalars['Long']['input'];
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ProductSalesAnalyticsPayload = {
  readonly __typename?: 'ProductSalesAnalyticsPayload';
  readonly chart: ReadonlyArray<Scalars['Map']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly summary: NumericValueWithDiffPayload;
};

export type ProductSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly orderStatus: ReportOrderStatus;
  readonly productId: Scalars['Long']['input'];
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ProductSummaryPayload = {
  readonly __typename?: 'ProductSummaryPayload';
  readonly advertisingFee: NumericValueWithDiffPayload;
  readonly avgOrderValues: NumericValueWithDiffPayload;
  readonly cancelAndOrRefund: NumericValueWithDiffPayload;
  readonly currency: ReportSupportedCurrency;
  readonly discount: NumericValueWithDiffPayload;
  readonly discountRate: NumericValueWithDiffPayload;
  readonly grossSales: NumericValueWithDiffPayload;
  readonly netSales: NumericValueWithDiffPayload;
  readonly orders: NumericValueWithDiffPayload;
  readonly revenueViaAds: NumericValueWithDiffPayload;
  readonly roas: NumericValueWithDiffPayload;
  readonly shippingFee: NumericValueWithDiffPayload;
  readonly totalSales: NumericValueWithDiffPayload;
  readonly unitSold: NumericValueWithDiffPayload;
};

export type ProductTagsFilterInput = {
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
};

export const ProductType = {
  SET: 'SET',
  SINGLE: 'SINGLE'
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];
/** if productVariantIds exist, fetch variants by productVariantIds. otherwise fetch all productVariants by filter */
export type ProductVariantCsvExportV2Input = {
  readonly filter?: InputMaybe<ProductVariantListFilterInput>;
  readonly productVariantIds: ReadonlyArray<Scalars['Long']['input']>;
};

export type ProductVariantDetail = {
  readonly __typename?: 'ProductVariantDetail';
  readonly anyLogiSkuDetail?: Maybe<SkuDetail>;
  readonly countryOfOrigin?: Maybe<ShopifySupportedCountryCode>;
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly hsCode?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Long']['output'];
  readonly inventorySyncStatus: KerryInventorySyncStatus;
  readonly listingVariantIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly listingVariants: ReadonlyArray<ListingVariant>;
  readonly media: ReadonlyArray<Scalars['URL']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly price: GraphQlMoney;
  readonly productCode: Scalars['String']['output'];
  readonly productInfo: ProductInformation;
  readonly setProductItems: ReadonlyArray<SetProductItem>;
  readonly status: ProductVariantStatus;
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
  readonly unitCost?: Maybe<GraphQlMoney>;
  readonly updatedAt: Scalars['OffsetDateTime']['output'];
  readonly weightInGrams?: Maybe<Scalars['BigDecimal']['output']>;
};

export type ProductVariantForAddInboundFilterInput = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type ProductVariantForAddInboundRecord = {
  readonly __typename?: 'ProductVariantForAddInboundRecord';
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly productCode: Scalars['String']['output'];
  readonly productName: Scalars['String']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly variantName: Scalars['String']['output'];
  readonly warehouseCode?: Maybe<Scalars['String']['output']>;
};

export type ProductVariantForAddInboundView = {
  readonly __typename?: 'ProductVariantForAddInboundView';
  readonly end: Scalars['Int']['output'];
  readonly page: Scalars['Int']['output'];
  readonly productVariants: ReadonlyArray<ProductVariantForAddInboundRecord>;
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type ProductVariantForMergeFilterInput = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType?: InputMaybe<MarketplaceSaleChannelType>;
  readonly storeId: Scalars['Long']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly type: ProductType;
};

export type ProductVariantForMergeRecord = {
  readonly __typename?: 'ProductVariantForMergeRecord';
  readonly inUseReasons: ReadonlyArray<ProductVariantInUseReason>;
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly productCode: Scalars['String']['output'];
  readonly productName: Scalars['String']['output'];
  readonly saleChannelIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly saleChannels: ReadonlyArray<SaleChannel>;
  readonly variantName: Scalars['String']['output'];
};

export type ProductVariantForMergeView = {
  readonly __typename?: 'ProductVariantForMergeView';
  readonly end: Scalars['Int']['output'];
  readonly page: Scalars['Int']['output'];
  readonly productVariants: ReadonlyArray<ProductVariantForMergeRecord>;
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type ProductVariantForOrderAddProductFilterInput = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly type?: InputMaybe<ProductType>;
};

export type ProductVariantForOrderAddProductRecord = {
  readonly __typename?: 'ProductVariantForOrderAddProductRecord';
  readonly anyLogiSkuDetail?: Maybe<SkuDetail>;
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly priceV2: Money;
  readonly productCode: Scalars['String']['output'];
  readonly productName: Scalars['String']['output'];
  readonly saleChannelIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly saleChannels: ReadonlyArray<SaleChannel>;
  readonly type: ProductType;
  readonly variantName: Scalars['String']['output'];
};

/** Product variant list view for order add/edit product modal. {start}-{end} / {total}. page {page} of {totalPage} */
export type ProductVariantForOrderAddProductView = {
  readonly __typename?: 'ProductVariantForOrderAddProductView';
  readonly end: Scalars['Int']['output'];
  readonly page: Scalars['Int']['output'];
  readonly productVariants: ReadonlyArray<ProductVariantForOrderAddProductRecord>;
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type ProductVariantForSetFilterInput = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType?: InputMaybe<MarketplaceSaleChannelType>;
  readonly storeId: Scalars['Long']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type ProductVariantForSetView = {
  readonly __typename?: 'ProductVariantForSetView';
  readonly end: Scalars['Int']['output'];
  readonly page: Scalars['Int']['output'];
  readonly productVariants: ReadonlyArray<ProductVariantForSetViewRecord>;
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type ProductVariantForSetViewRecord = {
  readonly __typename?: 'ProductVariantForSetViewRecord';
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly productCode: Scalars['String']['output'];
  readonly productName: Scalars['String']['output'];
  readonly productVariantId: Scalars['Long']['output'];
  readonly saleChannelIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly saleChannels: ReadonlyArray<SaleChannel>;
  readonly variantName: Scalars['String']['output'];
};

export const ProductVariantInUseReason = {
  ProductVariantHasLogisticsInbound: 'ProductVariantHasLogisticsInbound',
  ProductVariantHasLogisticsInventory: 'ProductVariantHasLogisticsInventory',
  ProductVariantHasLogisticsOrder: 'ProductVariantHasLogisticsOrder',
  ProductVariantIsPartOfASet: 'ProductVariantIsPartOfASet'
} as const;

export type ProductVariantInUseReason = typeof ProductVariantInUseReason[keyof typeof ProductVariantInUseReason];
export type ProductVariantListFilterInput = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType?: InputMaybe<MarketplaceSaleChannelType>;
  readonly status?: InputMaybe<ProductVariantStatus>;
  readonly storeId?: InputMaybe<Scalars['Long']['input']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly type?: InputMaybe<ProductType>;
};

/** Product variant list view. {start}-{end} / {total}. page {page} of {totalPage} */
export type ProductVariantListView = {
  readonly __typename?: 'ProductVariantListView';
  readonly end: Scalars['Int']['output'];
  readonly items: ReadonlyArray<ProductVariantRecord>;
  readonly page: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
  readonly totalPage: Scalars['Int']['output'];
};

export type ProductVariantRecord = {
  readonly __typename?: 'ProductVariantRecord';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly hasSiblings: Scalars['Boolean']['output'];
  readonly id: Scalars['Long']['output'];
  readonly listingVariantSaleChannelIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly listingVariantSaleChannels: ReadonlyArray<SaleChannel>;
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly name: Scalars['String']['output'];
  readonly product: Product;
  readonly productCode: Scalars['String']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly productType: ProductType;
  readonly skuDetail: SkuDetail;
  readonly status: ProductVariantStatus;
};

export type ProductVariantReportData = {
  readonly __typename?: 'ProductVariantReportData';
  readonly accountName?: Maybe<Scalars['String']['output']>;
  readonly advertisingCost: Scalars['BigDecimal']['output'];
  readonly avgSalesValue: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly commissionFees: Scalars['BigDecimal']['output'];
  readonly daysSalesOfInventory?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly logisticFees: Scalars['BigDecimal']['output'];
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate: Scalars['BigDecimal']['output'];
  readonly masterDataAccountId?: Maybe<Scalars['Long']['output']>;
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly merchandiseCost: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productId?: Maybe<Scalars['Long']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly productVariantId?: Maybe<Scalars['Long']['output']>;
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly revenueAdv: Scalars['BigDecimal']['output'];
  readonly revenueGross: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly storeName?: Maybe<Scalars['String']['output']>;
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly unitSold?: Maybe<Scalars['Int']['output']>;
  readonly variantName?: Maybe<Scalars['String']['output']>;
  readonly variantStatus?: Maybe<ProductVariantStatus>;
  readonly warehouseInventory?: Maybe<Scalars['Int']['output']>;
};

export type ProductVariantReportExportFilterInput = {
  readonly adsMetricsType?: InputMaybe<AdsMetricsType>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly productVariantIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly sortBy?: InputMaybe<VariantReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly variants?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type ProductVariantSetItemInput = {
  readonly productVariantId: Scalars['Long']['input'];
  readonly quantity: Scalars['Int']['input'];
};

export type ProductVariantSingle = {
  readonly __typename?: 'ProductVariantSingle';
  readonly anyLogiSkuDetail?: Maybe<SkuDetail>;
  readonly countryOfOrigin?: Maybe<ShopifySupportedCountryCode>;
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly hsCode?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['Long']['output'];
  readonly inventorySyncStatus: KerryInventorySyncStatus;
  readonly listingVariantIds: ReadonlyArray<Scalars['Long']['output']>;
  readonly listingVariants: ReadonlyArray<ListingVariant>;
  readonly media: ReadonlyArray<Scalars['URL']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly price: GraphQlMoney;
  readonly productCode: Scalars['String']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly setProductItems: ReadonlyArray<SetProductItem>;
  readonly status: ProductVariantStatus;
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
  readonly unitCost?: Maybe<GraphQlMoney>;
  readonly updatedAt: Scalars['OffsetDateTime']['output'];
  readonly weightInGrams?: Maybe<Scalars['BigDecimal']['output']>;
};

export const ProductVariantStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
} as const;

export type ProductVariantStatus = typeof ProductVariantStatus[keyof typeof ProductVariantStatus];
export type ProductVariantV2 = {
  readonly __typename?: 'ProductVariantV2';
  readonly id: Scalars['Long']['output'];
  readonly media?: Maybe<Scalars['URL']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly price: Money;
  readonly product: Product;
  readonly productCode: Scalars['String']['output'];
  readonly productId: Scalars['Long']['output'];
  readonly status: ProductVariantStatus;
  readonly unitCost?: Maybe<Money>;
};

export type ProfileUpdatedActivity = CustomerActivity & {
  readonly __typename?: 'ProfileUpdatedActivity';
  readonly fields: ReadonlyArray<UpdatedField>;
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly user: BasicUser;
};

export const Provider = {
  MANUAL: 'MANUAL',
  QOO10: 'QOO10',
  RAKUTEN: 'RAKUTEN',
  SHOPEE: 'SHOPEE',
  SHOPIFY: 'SHOPIFY'
} as const;

export type Provider = typeof Provider[keyof typeof Provider];
export type PublicUploadFilePayload = {
  readonly __typename?: 'PublicUploadFilePayload';
  readonly fileName: Scalars['String']['output'];
  /** this is public URL. it's same as signedUrl without query parameters. */
  readonly fileUrl: Scalars['String']['output'];
  readonly signedUrl: Scalars['String']['output'];
};

export const Qoo10Marketplace = {
  CN: 'CN',
  HK: 'HK',
  ID: 'ID',
  JP: 'JP',
  KR: 'KR',
  MY: 'MY',
  SG: 'SG'
} as const;

export type Qoo10Marketplace = typeof Qoo10Marketplace[keyof typeof Qoo10Marketplace];
export type Qoo10SaleChannelCreateInput = {
  readonly marketplace: Qoo10Marketplace;
  readonly password: Scalars['String']['input'];
  readonly sellerApiKey: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
  readonly userId: Scalars['String']['input'];
};

export type Qoo10SaleChannelData = {
  readonly __typename?: 'Qoo10SaleChannelData';
  readonly marketplace: Qoo10Marketplace;
  readonly sellerUserId: Scalars['String']['output'];
};

export type Qoo10SaleChannelReconnectInput = {
  readonly password: Scalars['String']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
};

export type Query = {
  readonly __typename?: 'Query';
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get All Campaigns.
   * Code|Error
   * ---|---
   */
  readonly AllInfluencerCampaigns?: Maybe<PageResultInfluencerCampaign>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get store.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly AnyChatStore?: Maybe<AnyChatStore>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get automation flow by id
   */
  readonly AutomationFlow?: Maybe<AutomationFlow>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * list all automation flow
   *
   * Code|Error
   * ---|---
   * 400| `USER_NOT_FOUND`.
   */
  readonly AutomationFlows?: Maybe<PageResultAutomationFlow>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat.
   *
   * Code|Error
   * ---|---
   */
  readonly Chat?: Maybe<Chat>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat user detail.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ChatCustomerDetail?: Maybe<ChatCustomerDetail>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat user detail.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ChatState?: Maybe<ChatState>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get all chats.
   *
   * Code|Error
   * ---|---
   */
  readonly Chats?: Maybe<Chats>;
  readonly CurrentLineRichMenu?: Maybe<LineRichMenu>;
  readonly Customer?: Maybe<Customer>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get Customer activities.
   * if customer is an influencer, pagination is ignored and all data is returned
   * interestedEvents are used for filter customer activities only
   * Code|Error
   * ---|---
   * 404| `Customer_NOT_FOUND`.
   */
  readonly CustomerActivitiesV2?: Maybe<PageResultCustomerActivity>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get customer orders.
   */
  readonly CustomerOrders?: Maybe<ReadonlyArray<CustomerOrder>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get customer.
   *
   * birthday will be "YYYY/MM/DD" format.
   * @deprecated use Customer
   */
  readonly CustomerProfile?: Maybe<CustomerProfile>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get customer tag by id.
   */
  readonly CustomerTag?: Maybe<CustomerTag>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get customer tags default order will be by frequently used.
   */
  readonly CustomerTags?: Maybe<CustomerTags>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get customers.
   */
  readonly Customers?: Maybe<Customers>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get customers.
   */
  readonly CustomersView?: Maybe<Customers>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * converts serialized content back to messages
   */
  readonly DecodeDraftUnitMessageContent?: Maybe<DraftUnitMessage>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * generates serialized content for unit message
   */
  readonly GenerateDraftUnitMessageContent?: Maybe<Scalars['String']['output']>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * generate redirect url which will open customer a page they can authorize shop access to our app.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly GenerateShopeeChatAuthorizationLink?: Maybe<GenerateShopeeChatAuthorizationLinkResponse>;
  readonly GetHighestLogisticConnectionType?: Maybe<LogisticFeatureFlag>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get Influencer Campaigns.
   * Code|Error
   * ---|---
   * 404| `Customer_NOT_FOUND`.
   * 400| `CUSTOMER_IS_NOT_AN_INFLUENCER`
   */
  readonly InfluencerCampaigns?: Maybe<ReadonlyArray<CampaignHistory>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get Campaigns by ids.
   * Code|Error
   * ---|---
   */
  readonly InfluencerCampaignsByIds?: Maybe<ReadonlyArray<InfluencerCampaign>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get Influencer Categories.
   * Code|Error
   * ---|---
   */
  readonly InfluencerCategories?: Maybe<ReadonlyArray<InfluencerCategory>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get Influencer Category by ids.
   * Code|Error
   * ---|---
   */
  readonly InfluencerCategoriesByIds?: Maybe<ReadonlyArray<InfluencerCategory>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get Influencers.
   * Code|Error
   * ---|---
   */
  readonly Influencers?: Maybe<PageResultInfluencerItem>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat history for instagram.
   *
   * olderThan/newerThan will be event id.
   * if both are null, system will return latest events.
   * if olderThan has value, system will return events which older than the message.
   * if newerThan has value, system will return events which newer than the message.
   * if both are exist, system will ignore newerThan value.
   *
   * system will return around 20 events, but it may change.
   *
   * TextMessageEvent.username will be null if sender is bot.
   * TextMessageEvent.username will be user's name if sender is chat user.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly InstagramChatEvents?: Maybe<InstagramChatEvents>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat history for lazada.
   *
   * olderThan/newerThan will be event id.
   * if both are null, system will return latest events.
   * if olderThan has value, system will return events which older than given datetime.
   * if newerThan has value, system will return events which newer given datetime.
   * if both are exist, system will ignore newerThan value.
   *
   * system will return around 20 events, but it may change.
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly LazadaChatEvents?: Maybe<LazadaChatEvents>;
  readonly LineBroadcastEstimateRecipients?: Maybe<EstimateRecipient>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get broadcast message by id
   *
   * Code|Error
   * ---|---
   * 404| `BROADCAST_MESSAGE_NOT_FOUND`.
   */
  readonly LineBroadcastMessage?: Maybe<LineBroadcastMessage>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get list of the broadcast message
   */
  readonly LineBroadcastMessages?: Maybe<LineBroadcastMessages>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get broadcast message summaries
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly LineBroadcastStatisticSummaries?: Maybe<LineBroadcastStatisticSummaries>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat history for line.
   *
   * olderThan/newerThan will be event id.
   * if both are null, system will return latest events.
   * if olderThan has value, system will return events which older than the message.
   * if newerThan has value, system will return events which newer than the message.
   * if both are exist, system will ignore newerThan value.
   *
   * system will return around 20 events, but it may change.
   *
   * TextMessageEvent.username will be null if sender is bot.
   * TextMessageEvent.username will be user's name if sender is chat user.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly LineChatEvents?: Maybe<LineChatEvents>;
  readonly LineRichMenu?: Maybe<LineRichMenu>;
  readonly LineRichMenus?: Maybe<PageResultLineRichMenuListItem>;
  /**
   * [ALL]
   * *Deprecated: please use LiffIdTokenValidate mutation*
   * get current line user info.
   */
  readonly LineUser?: Maybe<LineUser>;
  /** Get an account */
  readonly MdAccount: MdAccount;
  /** Get a list of accounts */
  readonly MdAccounts: MdAccounts;
  /** Get a list of countries */
  readonly MdCountries: ReadonlyArray<MdCountry>;
  /** Get a list of countries used by accounts */
  readonly MdCountriesUsedByAccounts: ReadonlyArray<MdCountry>;
  /** Get a country */
  readonly MdCountry: MdCountry;
  readonly MdCountryEnum: ReadonlyArray<MdCountryEnum>;
  /** Get a list of currencies */
  readonly MdCurrencies: ReadonlyArray<MdCurrency>;
  /** Get a currency */
  readonly MdCurrency: MdCurrency;
  /** Get a language */
  readonly MdLanguage: MdLanguage;
  /** Get a list of languages */
  readonly MdLanguages: ReadonlyArray<MdLanguage>;
  /** Get a list of provinces */
  readonly MdProvinces: ReadonlyArray<MdProvince>;
  readonly MdSelf: MdUser;
  /** Get a store */
  readonly MdStore: MdStore;
  /** Get a list of stores */
  readonly MdStores: MdStores;
  /** Get a timezone */
  readonly MdTimezone: MdTimezone;
  /** Get a list of timezones */
  readonly MdTimezones: ReadonlyArray<MdTimezone>;
  /** Get a user */
  readonly MdUser: MdUser;
  /** Get a list of users */
  readonly MdUsers: MdUsers;
  readonly MessengerBroadcastEstimateRecipients?: Maybe<EstimateRecipient>;
  readonly MessengerBroadcastMessage?: Maybe<MessengerBroadcastMessage>;
  readonly MessengerBroadcastMessages?: Maybe<MessengerBroadcastMessages>;
  readonly MessengerBroadcastStatisticSummaries?: Maybe<MessengerBroadcastStatisticSummaries>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat history for instagram.
   *
   * olderThan/newerThan will be event id.
   * if both are null, system will return latest events.
   * if olderThan has value, system will return events which older than the message.
   * if newerThan has value, system will return events which newer than the message.
   * if both are exist, system will ignore newerThan value.
   *
   * system will return around 20 events, but it may change.
   *
   * TextMessageEvent.username will be null if sender is bot.
   * TextMessageEvent.username will be user's name if sender is chat user.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly MessengerChatEvents?: Maybe<MessengerChatEvents>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get message quota info.
   */
  readonly QuotaInfos?: Maybe<QuotaInfos>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat history for shopee.
   *
   * olderThan/newerThan will be event id.
   * There won't be any updated of status in existing chat message so
   * fetching will consist of only two parts
   * 1. Fetch Scrolling by using olderThan
   * 2. Polling latest by using newerThan
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly ShopeeChatEvents?: Maybe<ShopeeChatEvents>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get template plain messages of the storeChat.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly StoreChatTemplatePlainMessages?: Maybe<TemplatePlainMessages>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get greeting message by storeId
   */
  readonly StoreGreetingMessage?: Maybe<GreetingMessage>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get optin message of the store.
   *
   * after enable message will send when system received any message from customer and
   *  the customer still haven't got requested for opt-in we will send opt-in message.
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly StoreMessengerOptInMessage?: Maybe<MessengerOptInMessage>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get unit message by id
   */
  readonly UnitMessage?: Maybe<UnitMessage>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get unit message by id
   */
  readonly UnitMessages?: Maybe<ReadonlyArray<UnitMessage>>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * estimate recipient count
   * Code|SubCode|Error
   *
   * ---|---|---
   * 400|3005|`MISSING_PARAM_FOR_TYPE`
   */
  readonly WhatsAppBroadcastEstimateRecipients?: Maybe<EstimateRecipient>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get broadcast message by id
   *
   * Code|SubCode|Error
   * ---|---|---
   * 404|2018| `BROADCAST_MESSAGE_NOT_FOUND`.
   */
  readonly WhatsAppBroadcastMessage?: Maybe<WhatsAppBroadcastMessage>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get broadcast message order by created time in decreasing order
   *
   * Code|SubCode|Error
   * ---|---|---
   * 404|2018| `BROADCAST_MESSAGE_NOT_FOUND`.
   */
  readonly WhatsAppBroadcastMessages?: Maybe<WhatsAppBroadcastMessages>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get whatsapp broadcast message statistic summary between input range
   * Code|SubCode|Error
   *
   * ---|---|---
   * 404|2018| `BROADCAST_MESSAGE_NOT_FOUND`.
   */
  readonly WhatsAppBroadcastStatisticSummaries?: Maybe<WhatsAppBroadcastStatisticSummaries>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * get chat history for whatsapp.
   *
   * olderThan/newerThan will be event id.
   * if both are null, system will return latest events.
   * if olderThan has value, system will return events which older than the message.
   * if newerThan has value, system will return events which newer than the message.
   * if both are exist, system will ignore newerThan value.
   *
   * system will return around 20 events, but it may change.
   *
   * TextMessageEvent.username will be null if sender is bot.
   * TextMessageEvent.username will be user's name if sender is chat user.
   *
   * Code|Error
   * ---|---
   * 404| `CHAT_NOT_FOUND`.
   */
  readonly WhatsAppChatEvents?: Maybe<WhatsAppChatEvents>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *
   * Code|Error
   * ---|---
   * 404| `WORKFLOW_NOT_FOUND`.
   */
  readonly Workflow?: Maybe<Workflow>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *
   * Code|Error
   * ---|---
   * 404| `WORKFLOW_NOT_FOUND`.
   */
  readonly WorkflowAuditLogs?: Maybe<WorkflowAuditLogs>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   * fetch Workflows
   *
   * Code|Error
   * ---|---
   * 404| `STORE_NOT_FOUND`.
   */
  readonly Workflows?: Maybe<Workflows>;
  /** Get detailed ad campaign report */
  readonly adCampaignDetailedReport: AdCampaignDetailedReportPayloadList;
  /** Get ads chart */
  readonly adChart: AdChartPayload;
  /** Get detailed ad report */
  readonly adDetailedReport: AdDetailedReportPayloadList;
  /** Get detailed ad group report */
  readonly adGroupDetailedReport: AdGroupDetailedReportPayloadList;
  /** Get ads overview */
  readonly adOverviewReport: AdOverviewReportPayloadList;
  /** Get ads summary */
  readonly adSummary: AdSummaryPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         List of ads cost by campaign preview data.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly adsCostByCampaignCSVPreview: AdsCostByCampaignCsvPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Ads cost range data by product variant distributed daily
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly adsCostRangeByProductDailyCSVPreview: AdsCostRangePerProductDailyCsvPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Ads cost range data by product distributed daily
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly adsCostRangeProductLevelDailyCSVPreview: AdsCostRangeProductLevelDailyCsvPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         List of ads cost by variant preview data.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly adsCostZozotownCSVPreview: AdsCostZozotownCsvPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         List of datasource.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly allDatasources: DatasourcePayloadList;
  readonly amazonSalesChart: AmazonSalesChartPayload;
  readonly amazonSalesDetailedReport: AmazonSalesDetailedReportPayloadList;
  readonly amazonSalesSummary: AmazonSalesSummaryPayload;
  /**
   * Get an Amazon Seller Central Login URL.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly amazonSellerCentralLoginUrlV2: Scalars['String']['output'];
  readonly anylogiOrder: AnylogiOrder;
  readonly anylogiOrders: AnylogiOrdersPaginated;
  /**
   * Get AWB file downloadable link. only for Sino.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly awbFile: Scalars['String']['output'];
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get a datasource.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly datasource?: Maybe<DatasourcePayload>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get a datasource token.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly datasourceToken?: Maybe<DatasourceTokenPayload>;
  /** data for the home page chart widget */
  readonly ddiHomeReportChart: DdiHomeReportChartPayload;
  /** data for the home page pie chart widget */
  readonly ddiHomeReportPieChart: DdiHomeReportPieChartPayload;
  /** data for the home page summary widget */
  readonly ddiHomeReportSummary: DdiHomeReportSummaryPayload;
  /** data for the home top products widget */
  readonly ddiHomeReportTopProducts: DdiHomeReportTopProductsPayload;
  readonly ddiInventoryReportPeopleInCharge: DdiPersonInChargePayloadList;
  /** DDI inventory stock value report, daily tab */
  readonly ddiInventoryStockValueDailyReport: DdiInventoryStockValueDailyReportPayloadList;
  /** DDI inventory stock value report, variants tab */
  readonly ddiInventoryStockValueProductReport: DdiInventoryStockValueProductReportPayloadList;
  /** DDI inventory tracker report, the all stores view one */
  readonly ddiInventoryTrackerReport: DdiInventoryTrackerReportPayload;
  readonly ddiInventoryTrackerReportStores: DdiInventoryTrackerReportStores;
  /**
   * [MERCHANT]
   *         get data in ddi product report card and summary data.
   *         only merchant in ddi account can access this api.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly ddiProductReportSummary: DdiProductReportSummaryPayload;
  /**
   * [MERCHANT]
   *         get data in ddi product report table.
   *         only merchant in ddi account can access this api.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly ddiProductReportTable: DdiProductReportPayload;
  /** get DDI product status per warehouse, along with other relevant information */
  readonly ddiProductStatus: DdiProductStatusPayloadList;
  /**
   * [MERCHANT]
   *         get data for chart.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly ddiSaleChannelReportChart: DdiSaleChannelReportChartPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get summary for top chart and table in sale channel report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly ddiSaleChannelReportSummary: DdiReportSummaryPayload;
  /**
   * [MERCHANT]
   *         get data in table and chart.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly ddiSaleChannelReportTable: DdiSaleChannelReportPayload;
  /**
   * Get export result from productCSVExport/productVariantCSVExport/orderCSVExport.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly document: DocumentPayload;
  /**
   * Get editor tools configuration for a given sale channel.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly editorConfigTools: ReadonlyArray<Scalars['String']['output']>;
  /** Export ad campaign report */
  readonly exportAdCampaignReport: ReportDocumentResult;
  /** Export ad group report */
  readonly exportAdGroupReport: ReportDocumentResult;
  /** Export ad overview report */
  readonly exportAdOverviewReport: ReportDocumentResult;
  /** Export ad report */
  readonly exportAdReport: ReportDocumentResult;
  /** export DDI inventory stock value report, daily tab */
  readonly exportDdiInventoryStockValueDailyReport: ReportDocumentResult;
  /** export DDI inventory stock value report, variants tab */
  readonly exportDdiInventoryStockValueProductReport: ReportDocumentResult;
  /** export DDI product report */
  readonly exportDdiProductReport: ReportDocumentResult;
  /** export DDI sale channel report */
  readonly exportDdiSaleChannelReport: ReportDocumentResult;
  /** Export keyword report */
  readonly exportKeywordReport: ReportDocumentResult;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         export product report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly exportProductReport: ReportDocumentResult;
  /** Export sale channel report. */
  readonly exportSaleChannelReport: ReportDocumentResult;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         export variant report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly exportVariantReport: ReportDocumentResult;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get Facebook Ads accounts.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`     |Unauthorized request
   *         |`FORBIDDEN`         |This operation is prohibited
   *         |`FACEBOOK_API_ERROR`|Facebook API has error
   */
  readonly facebookAdsAccounts: FacebookAdsAdAccountPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get a Facebook Login URL.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly facebookLoginUrl: Scalars['String']['output'];
  /**
   * Get file upload histories.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `PAGE_SIZE_LIMIT_ERROR`, `SALE_CHANNEL_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly fileUploadHistories: FileUploadHistoriesPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Query for for ad cost campaign mapping info for edit page
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getAdCostCampaignMapping: AdCostCampaignMappingInfoPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get ad cost campaign mappings list
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getAdCostCampaignMappings: AdCostCampaignMappingListPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get ad set mapping campaign detail
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getAdSetMappingCampaign: AdSetMappingCampaignPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get ad set mapping campaign list
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getAdSetMappingCampaigns: AdSetMappingCampaignsListPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Query for for ad set mapping group info for edit page
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getAdSetMappingGroup: AdSetMappingGroupInfoPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Query for for ad set mapping products
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getAdSetMappingGroupProducts: AdSetMappingGroupProductsListPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get ad set mapping group list
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getAdSetMappingGroups: AdSetMappingGroupListPayload;
  readonly getAnylogiUser: AnylogiUserDetailed;
  readonly getAnylogiUsers: AnylogiUsersPaginated;
  readonly getCourierAccount: CourierAccountDetails;
  readonly getCourierAccountList: CourierAccountDetailsListResponse;
  readonly getCourierAccountsSupported: ReadonlyArray<CourierAccountSupported>;
  readonly getCourierSettings?: Maybe<CourierSettings>;
  readonly getCourierSettingsList: GetCourierSettingsListResponse;
  readonly getCouriersList: ReadonlyArray<Courier>;
  readonly getCrossBorderBasicAuth?: Maybe<CrossBorderBasicAuth>;
  readonly getCsvExportJobResult: CsvExportJobResult;
  readonly getCsvUploadTemplate: CsvTemplate;
  readonly getInbound: InboundDetailed;
  readonly getInboundPdf: Document;
  readonly getInboundPdfs: Document;
  readonly getInbounds: InboundsPaginated;
  readonly getInboundsOverview: InboundOverview;
  readonly getInventories: InventoryPaginated;
  readonly getInventoriesCsv: GetInventoriesCsvResponse;
  readonly getInventoriesOverview: InventoryOverview;
  readonly getLogilessMerchants?: Maybe<ReadonlyArray<LogilessMerchant>>;
  readonly getLogisticChannelRequestForm?: Maybe<ReadonlyArray<LogisticChannelRequestForm>>;
  readonly getLogisticStoreStatus?: Maybe<LogisticFeatureFlag>;
  readonly getPaymentMethods?: Maybe<ReadonlyArray<PaymentMethodType>>;
  readonly getPickup: PickupDetails;
  readonly getPickups: PickupDetailsListResponse;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Products for ads mapping
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly getProductsForAdsMapping: AdsMappingProductsListPayload;
  readonly getShipperAddress: ShipperAddress;
  readonly getShipperAddresses: ShipperAddressesListResponse;
  readonly getShippingRate: ReadonlyArray<ShippingRateDetails>;
  readonly getShippingRates: GetShippingRateResponse;
  readonly getSku: SkuDetail;
  readonly getSkuTags: SkuTags;
  readonly getSkus: SkuPaginated;
  readonly getSupplier: SupplierDetailed;
  readonly getSuppliers: SuppliersPaginated;
  readonly getWarehouse: WarehouseDetailed;
  readonly getWarehouses: WarehousesPaginated;
  readonly getWarehousesList?: Maybe<ReadonlyArray<Warehouse>>;
  readonly getWmsStores: ReadonlyArray<WmsName>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get Google Ads client accounts. This query will not return manager accounts
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`     |Unauthorized request
   *         |`FORBIDDEN`         |This operation is prohibited
   *         |`GOOGLE_OAUTH_ERROR`|Google OAuth has error
   *         |`GOOGLE_API_ERROR`  |Google API has error
   */
  readonly googleAdsClientAccounts: GoogleAdsAccountPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get Google Ads Customers.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`     |Unauthorized request
   *         |`FORBIDDEN`         |This operation is prohibited
   *         |`GOOGLE_OAUTH_ERROR`|Google OAuth has error
   *         |`GOOGLE_API_ERROR`  |Google API has error
   */
  readonly googleAdsCustomers: GoogleAdsCustomerPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get Google Analytics 4 Accounts.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`     |Unauthorized request
   *         |`FORBIDDEN`         |This operation is prohibited
   *         |`GOOGLE_OAUTH_ERROR`|Google OAuth has error
   *         |`GOOGLE_API_ERROR`  |Google API has error
   */
  readonly googleAnalytics4Accounts: GoogleAnalytics4AccountSummaries;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get a Google Login URL.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly googleLoginUrl: Scalars['String']['output'];
  readonly hasAdvertisingConnection: Scalars['Boolean']['output'];
  readonly hasAnalyticsReport: Scalars['Boolean']['output'];
  readonly hasConnectedSaleChannel: Scalars['Boolean']['output'];
  /**
   * Upload imports file.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly importsFileUpload: UploadFilePayload;
  readonly inactiveIntegrationCount: Scalars['Int']['output'];
  /**
   * Onboarding information.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly integrationSideNav: IntegrationSideNavOutput;
  readonly inventoryFileUploadHistory: InventoryFileUploadHistoryPayload;
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly inventoryListView: InventoryListView;
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly inventoryOverview: InventoryOverviewOutput;
  /** Get keywords chart */
  readonly keywordChart: AdChartPayload;
  /** Get detailed keyword report */
  readonly keywordDetailedReport: KeywordDetailedReportPayloadList;
  /** Get keywords summary */
  readonly keywordSummary: AdSummaryPayload;
  /**
   * Check if provided product variants are the only remaining variants of a product.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly lastProductVariantIds: ReadonlyArray<Scalars['Long']['output']>;
  /**
   * Get a Lazada Login URL.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly lazadaLoginUrlV2: Scalars['String']['output'];
  readonly lazadaSalesChart: LazadaSalesChartPayload;
  readonly lazadaSalesDetailedReport: LazadaSalesDetailedReportPayloadList;
  readonly lazadaSalesSummary: LazadaSalesSummaryPayload;
  /**
   * A marketplace listing detail.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly listingDetail: ListingDetail;
  /**
   * List of marketplace Listings.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly listingListView: ListingListView;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get a manual cost.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly manualCost?: Maybe<ManualCostPayload>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         List of manual cost.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly manualCosts: ManualCostPayloadList;
  /**
   * Get sale channels for manual order creation.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly manualSaleChannelsByStoreId: ReadonlyArray<SaleChannelForManualOrder>;
  /** Get list of marketplace events */
  readonly marketplaceCalendarEvents: MarketplaceCalendarEventPayloadList;
  /** Get summary for the selected time period, about sales and targets */
  readonly marketplaceCalendarSummary: MarketplaceCalendarRevenueSummaryPayload;
  /**
   * Onboarding information.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly onboarding: Onboarding;
  /**
   * A marketplace order.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly order: OrderDetail;
  /**
   * Export Order CSV.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly orderCSVExportV2: Scalars['Long']['output'];
  /**
   * Upload CSV for order.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly orderCSVUpload: UploadFilePayload;
  /**
   * List of marketplace Orders.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly orderListView: OrderListView;
  /**
   * store order onboarding status for order list page.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly orderOnboarding: OrderOnboardingStatus;
  /**
   * Order overview.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly orderOverviewV2: OrderOverview;
  /**
   * AnyX Order payment methods.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly paymentMethods: PaymentMethodsPayload;
  readonly pickupDeferredPaymentForm: Path;
  /**
   * Export ProductVariant CSV by ProductFilter.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly productCSVExportV2: Scalars['Long']['output'];
  /**
   * Upload CSV for product.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly productCSVUpload: UploadFilePayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get product category and original price.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`INVALID_INPUT`|Invalid arguments
   *         |`NOT_FOUND`|Product not found
   */
  readonly productCategoryAndPrice: ProductCategoryAndPricePayload;
  /** Product chart */
  readonly productChart: ProductChartPayload;
  /**
   * A marketplace productDetail.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly productDetail: ProductDetail;
  /** Detailed product report */
  readonly productDetailedReport: ProductDetailedReportPayloadList;
  /**
   * Product HS Codes.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly productHsCodes: ProductHsCodes;
  /**
   * List of marketplace products.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `PAGE_SIZE_LIMIT_ERROR`, `UNAUTHORIZED`
   */
  readonly productListView: ProductListView;
  /**
   * Upload media for product.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly productMediaUpload: PublicUploadFilePayload;
  /**
   * store product onboarding status for product list page.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `STORE_NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly productOnboarding: ProductOnboardingStatus;
  /** Product rating summary */
  readonly productRatingOverview: ProductRatingOverviewPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get competitor analysis for a product.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`INVALID_INPUT`|Invalid arguments
   */
  readonly productReviewCompetitorAnalysis: ProductReviewCompetitorAnalysisPayload;
  /** Product review sentiment analysis */
  readonly productReviewSentimentAnalysis: ProductReviewSentimentAnalysisPayload;
  /** Product review summarization */
  readonly productReviewSummarization: ProductReviewSummarizationPayload;
  /** Product review topic analysis */
  readonly productReviewTopicAnalysis: ProductReviewTopicAnalysisPayload;
  /** Product review comments */
  readonly productReviews: ProductReviewsPayloadList;
  /** Sale channel types available for the product */
  readonly productSaleChannels: ProductSaleChannelsPayload;
  /** Product sales analytics */
  readonly productSalesAnalytics: ProductSalesAnalyticsPayload;
  /** Product summary */
  readonly productSummary: ProductSummaryPayload;
  /**
   * Get product variant by id.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly productVariant: ProductVariantDetail;
  /**
   * Export ProductVariant CSV by VariantFilter.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly productVariantCSVExportV2: Scalars['Long']['output'];
  /**
   * List of marketplace product variants; Order Add/Edit -> Add Products.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `PAGE_SIZE_LIMIT_ERROR`, `UNAUTHORIZED`
   */
  readonly productVariantForOrderAddProduct: ProductVariantForOrderAddProductView;
  /**
   * List of marketplace product variants; Products -> Variant View.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `PAGE_SIZE_LIMIT_ERROR`, `UNAUTHORIZED`
   */
  readonly productVariantListView: ProductVariantListView;
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly productVariantsForAddInbound: ProductVariantForAddInboundView;
  /**
   * List of marketplace product variants; Product Merge.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `PAGE_SIZE_LIMIT_ERROR`, `UNAUTHORIZED`
   */
  readonly productVariantsForProductMerge: ProductVariantForMergeView;
  /**
   * List of marketplace product variants; Product Set.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `PAGE_SIZE_LIMIT_ERROR`, `UNAUTHORIZED`
   */
  readonly productVariantsForProductSet: ProductVariantForSetView;
  readonly rakutenSalesReportChart: RakutenSalesReportChartPayload;
  readonly rakutenSalesReportSummary: RakutenSalesReportSummaryPayload;
  readonly rakutenSalesReportTable: RakutenSalesReportTablePayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get product report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportByProduct: ProductReportPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get product daily report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportByProductDaily: ProductDailyReportPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get product report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportByProductSummary: ProductReportSummaryPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get variant report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportByVariant: VariantReportPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get variant daily report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportByVariantDaily: VariantDailyReportPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get a document.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly reportDocument: ReportDocumentPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get home daily report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportHomeDaily: HomeDailyReportView;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get home pie chart report order by sale channel revenue
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportHomeSaleChannelRevenue: HomeSaleChannelRevenueReportView;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get home summary report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportHomeSummary: HomeSummaryReportView;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get home top 5 products report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly reportHomeTop5Products: HomeTop5ProductsReportView;
  /**
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly saleChannel: SaleChannel;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get sale channel cost index.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly saleChannelCostIndex?: Maybe<SaleChannelCostIndexQueryPayload>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get sale channel cost indexes.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly saleChannelCostIndexes: SaleChannelCostIndexPayloadList;
  /** get sale channel currencies */
  readonly saleChannelCurrency: ReportSupportedCurrency;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get sale channel plan.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly saleChannelPlan: ReadonlyArray<SaleChannelPlan>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get sale channel plan.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly saleChannelPlanV2: ReadonlyArray<SaleChannelPlanPayload>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get data for chart.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly saleChannelReportChart: SaleChannelReportChartPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get summary for top chart and table in sale channel report.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly saleChannelReportSummary: ReportSummaryPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         get data in table and chart.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly saleChannelReportTable: SaleChannelReportPayload;
  /**
   * List of sale channel sync summaries. Pending syncs will always be shown.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly saleChannelSyncs: SaleChannelSyncSummaryList;
  /**
   * search condition matched listings for AnyChat.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly searchListing: ReadonlyArray<ListingName>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         List of sessions preview data.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   *         |`FORBIDDEN`    |This operation is prohibited
   */
  readonly sessionsCSVPreview: SessionsCsvPayloadList;
  readonly settingGuide: SettingGuide;
  /**
   * Get a Shopee Login URL..
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly shopeeLoginUrlV2: Scalars['String']['output'];
  readonly shopeeSalesChart: ShopeeSalesChartPayload;
  readonly shopeeSalesDetailedReport: ShopeeSalesDetailedReportPayloadList;
  readonly shopeeSalesSummary: ShopeeSalesSummaryPayload;
  /**
   * Get a Shopify permission prompt URL.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly shopifyLoginUrlV2: Scalars['String']['output'];
  readonly shopifySalesReportChart: ShopifySalesReportChartPayload;
  readonly shopifySalesReportSummary: ShopifySalesReportSummaryPayload;
  readonly shopifySalesReportTable: ShopifySalesReportTablePayloadList;
  /**
   * Get list of stores with channel filter. pageNumber starts from 1.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `PAGE_SIZE_LIMIT_ERROR`, `UNAUTHORIZED`
   */
  readonly storesWithChannel: StoreList;
  /**
   * Product tags V2.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `NOT_FOUND`, `UNAUTHORIZED`
   */
  readonly tagsV2: ReadonlyArray<Scalars['String']['output']>;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get TikTok Ads accounts.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`     |Unauthorized request
   *         |`FORBIDDEN`         |This operation is prohibited
   *         |`TIKTOK_API_ERROR`  |TikTok API has error
   */
  readonly tikTokAdsAdAccounts: TikTokAdsAdAccountPayloadList;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get a TikTok Ads Login URL.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly tikTokAdsLoginUrl: Scalars['String']['output'];
  /**
   * Get TikTok Shop Login URL.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly tikTokLoginUrlV2: Scalars['String']['output'];
  readonly tikTokSalesReportChart: TikTokSalesReportChartPayload;
  readonly tikTokSalesReportDetailedReport: TikTokSalesReportDetailedReportPayloadList;
  readonly tikTokSalesReportSummary: TikTokSalesReportSummaryPayload;
  readonly tokopediaSalesChart: TokopediaSalesChartPayload;
  readonly tokopediaSalesDetailedReport: TokopediaSalesDetailedReportPayloadList;
  readonly tokopediaSalesSummary: TokopediaSalesSummaryPayload;
  /**
   * [ADMIN, STAFF, MERCHANT]
   *         Get list of variant names to use in report filters.
   *
   *         |Error|Description
   *         |-----|-----------
   *         |`UN_AUTHORIZED`|Unauthorized request
   */
  readonly variantValues: VariantValuesPayloadList;
  /** Get Yahoo available ad accounts */
  readonly yahooAdsAccounts: YahooAdsAccountsPayload;
  /** Get Yahoo Ads login URL */
  readonly yahooAdsLoginUrl: YahooAdsLoginUrlPayload;
  /**
   * Get a Yahoo Login URL.
   * ▸ Requires: `ADMIN`, `STAFF`, `MERCHANT`
   * ▸ Throws: `UNAUTHORIZED`
   */
  readonly yahooLoginUrl: Scalars['String']['output'];
  readonly yahooSalesReportChart: YahooSalesReportChartPayload;
  readonly yahooSalesReportSummary: YahooSalesReportSummaryPayload;
  readonly yahooSalesReportTable: YahooSalesReportTablePayloadList;
};


export type QueryAllInfluencerCampaignsArgs = {
  input: GetInfluencerCampaignsRequest;
};


export type QueryAnyChatStoreArgs = {
  input: AnyChatGetStoreRequest;
};


export type QueryAutomationFlowArgs = {
  input: GetAutomationFlowRequest;
};


export type QueryAutomationFlowsArgs = {
  input: GetAutomationFlowsRequest;
};


export type QueryChatArgs = {
  input: GetChatRequest;
};


export type QueryChatCustomerDetailArgs = {
  input: GetChatCustomerDetailRequest;
};


export type QueryChatStateArgs = {
  input: GetChatStateRequest;
};


export type QueryChatsArgs = {
  input: GetChatsRequest;
};


export type QueryCurrentLineRichMenuArgs = {
  input: CurrentLineRichMenuInput;
};


export type QueryCustomerArgs = {
  input: CustomerIdInput;
};


export type QueryCustomerActivitiesV2Args = {
  input: GetCustomerActivitiesRequest;
};


export type QueryCustomerOrdersArgs = {
  input: CustomerIdInput;
};


export type QueryCustomerProfileArgs = {
  input: CustomerIdInput;
};


export type QueryCustomerTagArgs = {
  input: GetCustomerTagRequest;
};


export type QueryCustomerTagsArgs = {
  input: GetCustomerTagsRequest;
};


export type QueryCustomersArgs = {
  input: GetCustomersRequest;
};


export type QueryCustomersViewArgs = {
  input: GetCustomersViewRequest;
};


export type QueryDecodeDraftUnitMessageContentArgs = {
  input: DecodeDraftUnitMessageContentRequest;
};


export type QueryGenerateDraftUnitMessageContentArgs = {
  input: GenerateDraftUnitMessageContentRequest;
};


export type QueryGenerateShopeeChatAuthorizationLinkArgs = {
  input: GenerateShopeeChatAuthorizationLinkRequest;
};


export type QueryGetHighestLogisticConnectionTypeArgs = {
  MdStoreIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
};


export type QueryInfluencerCampaignsArgs = {
  input: CustomerIdInput;
};


export type QueryInfluencerCampaignsByIdsArgs = {
  input: GetInfluencerCampaignByIdsRequest;
};


export type QueryInfluencerCategoriesByIdsArgs = {
  input: GetInfluencerCategoriesByIdsRequest;
};


export type QueryInfluencersArgs = {
  input: GetInfluencersRequest;
};


export type QueryInstagramChatEventsArgs = {
  input: GetInstagramChatEventsRequest;
};


export type QueryLazadaChatEventsArgs = {
  input: GetLazadaChatMessagesRequestInput;
};


export type QueryLineBroadcastEstimateRecipientsArgs = {
  input: GetEstimateRecipientRequestInput;
};


export type QueryLineBroadcastMessageArgs = {
  input: GetSingleLineBroadcastMessageRequestInput;
};


export type QueryLineBroadcastMessagesArgs = {
  input: GetLineBroadcastMessagesRequestInput;
};


export type QueryLineBroadcastStatisticSummariesArgs = {
  input: GetLineBroadcastMessageStatSummaryRequestInput;
};


export type QueryLineChatEventsArgs = {
  input: GetLineChatEventsRequest;
};


export type QueryLineRichMenuArgs = {
  input: Scalars['String']['input'];
};


export type QueryLineRichMenusArgs = {
  input: LineRichMenusInput;
};


export type QueryLineUserArgs = {
  input: LineUserRequest;
};


export type QueryMdAccountArgs = {
  id: Scalars['Long']['input'];
};


export type QueryMdAccountsArgs = {
  filter?: InputMaybe<MdAccountFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortBy?: InputMaybe<MdAccountSortByInput>;
};


export type QueryMdCountriesUsedByAccountsArgs = {
  filter?: InputMaybe<MdAccountFilterInput>;
};


export type QueryMdCountryArgs = {
  id: Scalars['String']['input'];
};


export type QueryMdCurrenciesArgs = {
  filter?: InputMaybe<MdCurrencyFilterInput>;
};


export type QueryMdCurrencyArgs = {
  id: Scalars['String']['input'];
};


export type QueryMdLanguageArgs = {
  id: Scalars['String']['input'];
};


export type QueryMdProvincesArgs = {
  filter?: InputMaybe<MdProvinceFilterInput>;
};


export type QueryMdStoreArgs = {
  id: Scalars['Long']['input'];
};


export type QueryMdStoresArgs = {
  filter?: InputMaybe<MdStoreFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortBy?: InputMaybe<MdStoreSortByInput>;
};


export type QueryMdTimezoneArgs = {
  id: Scalars['String']['input'];
};


export type QueryMdUserArgs = {
  id: Scalars['Long']['input'];
};


export type QueryMdUsersArgs = {
  filter?: InputMaybe<MdUserFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortBy?: InputMaybe<MdUserSortByInput>;
};


export type QueryMessengerBroadcastEstimateRecipientsArgs = {
  input: GetMessengerEstimateRecipientRequestInput;
};


export type QueryMessengerBroadcastMessageArgs = {
  input: GetSingleMessengerBroadcastMessageRequestInput;
};


export type QueryMessengerBroadcastMessagesArgs = {
  input: GetMessengerBroadcastMessagesRequestInput;
};


export type QueryMessengerBroadcastStatisticSummariesArgs = {
  input: GetMessengerBroadcastMessageStatSummaryRequestInput;
};


export type QueryMessengerChatEventsArgs = {
  input: GetMessengerChatEventsRequest;
};


export type QueryQuotaInfosArgs = {
  input: GetMessageQuotaInfoRequestInput;
};


export type QueryShopeeChatEventsArgs = {
  input: GetShopeeChatMessagesRequest;
};


export type QueryStoreChatTemplatePlainMessagesArgs = {
  input: StoreChatTemplatePlainMessagesRequest;
};


export type QueryStoreGreetingMessageArgs = {
  input: GetStoreGreetingMessageRequest;
};


export type QueryStoreMessengerOptInMessageArgs = {
  input: GetStoreMessengerOptInMessageRequestInput;
};


export type QueryUnitMessageArgs = {
  input: FetchUnitMessageRequest;
};


export type QueryUnitMessagesArgs = {
  input: FetchUnitMessagesRequest;
};


export type QueryWhatsAppBroadcastEstimateRecipientsArgs = {
  input: GetWhatsAppEstimateRecipientRequest;
};


export type QueryWhatsAppBroadcastMessageArgs = {
  input: GetSingleWhatsAppBroadcastMessageRequest;
};


export type QueryWhatsAppBroadcastMessagesArgs = {
  input: GetWhatsAppBroadcastMessagesRequest;
};


export type QueryWhatsAppBroadcastStatisticSummariesArgs = {
  input: GetWhatsAppBroadcastMessageStatSummaryRequest;
};


export type QueryWhatsAppChatEventsArgs = {
  input: GetWhatsAppChatMessagesRequestInput;
};


export type QueryWorkflowArgs = {
  id: Scalars['String']['input'];
};


export type QueryWorkflowAuditLogsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  workflowId: Scalars['String']['input'];
};


export type QueryWorkflowsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  storeId: Scalars['Long']['input'];
};


export type QueryAdCampaignDetailedReportArgs = {
  input: AdCampaignDetailedReportInput;
};


export type QueryAdChartArgs = {
  input: AdChartInput;
};


export type QueryAdDetailedReportArgs = {
  input: AdDetailedReportInput;
};


export type QueryAdGroupDetailedReportArgs = {
  input: AdGroupDetailedReportInput;
};


export type QueryAdOverviewReportArgs = {
  input: AdOverviewReportInput;
};


export type QueryAdSummaryArgs = {
  input: AdSummaryInput;
};


export type QueryAdsCostByCampaignCsvPreviewArgs = {
  ascending: Scalars['Boolean']['input'];
  filter: AdsCostCsvFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryAdsCostRangeByProductDailyCsvPreviewArgs = {
  ascending: Scalars['Boolean']['input'];
  filter: AdsCostRangePerProductDailyCsvFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryAdsCostRangeProductLevelDailyCsvPreviewArgs = {
  ascending: Scalars['Boolean']['input'];
  filter: AdsCostRangeProductLevelDailyCsvFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryAdsCostZozotownCsvPreviewArgs = {
  ascending: Scalars['Boolean']['input'];
  filter: AdsCostCsvFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryAllDatasourcesArgs = {
  filter?: InputMaybe<DatasourceFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortBy?: InputMaybe<DatasourceSortByInput>;
};


export type QueryAmazonSalesChartArgs = {
  input: AmazonSalesChartInput;
};


export type QueryAmazonSalesDetailedReportArgs = {
  input: AmazonSalesDetailedReportInput;
};


export type QueryAmazonSalesSummaryArgs = {
  input: AmazonSalesSummaryInput;
};


export type QueryAmazonSellerCentralLoginUrlV2Args = {
  input: AmazonSellerCentralLoginUrlV2Input;
};


export type QueryAnylogiOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnylogiOrdersArgs = {
  filters: OrderFilters;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAwbFileArgs = {
  orderId: Scalars['Long']['input'];
};


export type QueryDatasourceArgs = {
  id: Scalars['Long']['input'];
};


export type QueryDatasourceTokenArgs = {
  id: Scalars['Long']['input'];
};


export type QueryDdiHomeReportChartArgs = {
  input: DdiHomeReportInput;
};


export type QueryDdiHomeReportPieChartArgs = {
  input: DdiHomeReportInput;
};


export type QueryDdiHomeReportSummaryArgs = {
  input: DdiHomeReportInput;
};


export type QueryDdiHomeReportTopProductsArgs = {
  input: DdiHomeReportInput;
};


export type QueryDdiInventoryStockValueDailyReportArgs = {
  input: DdiInventoryStockValueDailyReportInput;
};


export type QueryDdiInventoryStockValueProductReportArgs = {
  input: DdiInventoryStockValueProductReportInput;
};


export type QueryDdiInventoryTrackerReportArgs = {
  input: DdiInventoryTrackerReportInput;
};


export type QueryDdiProductReportSummaryArgs = {
  filter: DdiProductReportSummaryFilterInput;
};


export type QueryDdiProductReportTableArgs = {
  filter: DdiProductReportPaginatedFilterInput;
};


export type QueryDdiProductStatusArgs = {
  input: DdiProductStatusInput;
};


export type QueryDdiSaleChannelReportChartArgs = {
  filter: DdiSaleChannelReportTableFilterInput;
};


export type QueryDdiSaleChannelReportSummaryArgs = {
  filter: DdiSaleChannelReportSummaryFilterInput;
};


export type QueryDdiSaleChannelReportTableArgs = {
  filter: DdiSaleChannelReportTableFilterInput;
};


export type QueryDocumentArgs = {
  documentId: Scalars['Long']['input'];
};


export type QueryEditorConfigToolsArgs = {
  saleChannelType: MarketplaceSaleChannelType;
};


export type QueryExportAdCampaignReportArgs = {
  input: AdCampaignDetailedReportInput;
};


export type QueryExportAdGroupReportArgs = {
  input: AdGroupDetailedReportInput;
};


export type QueryExportAdOverviewReportArgs = {
  input: AdOverviewReportInput;
};


export type QueryExportAdReportArgs = {
  input: AdDetailedReportInput;
};


export type QueryExportDdiInventoryStockValueDailyReportArgs = {
  input: DdiInventoryStockValueDailyReportExportInput;
};


export type QueryExportDdiInventoryStockValueProductReportArgs = {
  input: DdiInventoryStockValueProductReportExportInput;
};


export type QueryExportDdiProductReportArgs = {
  filter: DdiProductReportExportFilterInput;
};


export type QueryExportDdiSaleChannelReportArgs = {
  filter: DdiSaleChannelReportExportFilterInput;
};


export type QueryExportKeywordReportArgs = {
  input: KeywordDetailedReportInput;
};


export type QueryExportProductReportArgs = {
  filter: ProductReportExportFilterInput;
};


export type QueryExportSaleChannelReportArgs = {
  filter: SaleChannelReportTableFilterInput;
};


export type QueryExportVariantReportArgs = {
  filter: ProductVariantReportExportFilterInput;
};


export type QueryFacebookAdsAccountsArgs = {
  datasourceTokenId: Scalars['Long']['input'];
};


export type QueryFacebookLoginUrlArgs = {
  redirectUri: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFileUploadHistoriesArgs = {
  input: FileUploadHistoriesInput;
};


export type QueryGetAdCostCampaignMappingArgs = {
  adCostCampaignMappingId: Scalars['Long']['input'];
};


export type QueryGetAdCostCampaignMappingsArgs = {
  filter: AdCostCampaignMappingListFilterInput;
};


export type QueryGetAdSetMappingCampaignArgs = {
  adSetMappingCampaignId: Scalars['Long']['input'];
  masterDataStoreId: Scalars['Long']['input'];
};


export type QueryGetAdSetMappingCampaignsArgs = {
  filter: AdSetMappingCampaignListFilterInput;
};


export type QueryGetAdSetMappingGroupArgs = {
  adSetMappingGroupId: Scalars['Long']['input'];
};


export type QueryGetAdSetMappingGroupProductsArgs = {
  filter: AdSetMappingProductListFilterInput;
};


export type QueryGetAdSetMappingGroupsArgs = {
  filter: AdSetMappingGroupListFilterInput;
};


export type QueryGetAnylogiUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAnylogiUsersArgs = {
  filters: AnylogiUserFilters;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCourierAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCourierAccountListArgs = {
  filters: GetCourierAccountListInput;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};


export type QueryGetCourierSettingsArgs = {
  accountId: Scalars['ID']['input'];
};


export type QueryGetCourierSettingsListArgs = {
  filter?: InputMaybe<CourierSettingsFilterInput>;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};


export type QueryGetCouriersListArgs = {
  paymentMethod?: InputMaybe<PaymentMethodType>;
  storeID: Scalars['Long']['input'];
};


export type QueryGetCrossBorderBasicAuthArgs = {
  accountId: Scalars['ID']['input'];
};


export type QueryGetCsvExportJobResultArgs = {
  jobId: Scalars['String']['input'];
};


export type QueryGetCsvUploadTemplateArgs = {
  language: Language;
  operation?: InputMaybe<CsvImportOperation>;
  resource: Resource;
};


export type QueryGetInboundArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInboundPdfArgs = {
  id: Scalars['ID']['input'];
  language: Language;
};


export type QueryGetInboundPdfsArgs = {
  ids: ReadonlyArray<Scalars['ID']['input']>;
  language: Language;
};


export type QueryGetInboundsArgs = {
  filters: InboundFilters;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetInboundsOverviewArgs = {
  filters: InboundFilters;
};


export type QueryGetInventoriesArgs = {
  filters: InventoryFilters;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetInventoriesCsvArgs = {
  filters?: InputMaybe<InventoryFilters>;
  gtins?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  language: Language;
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetInventoriesOverviewArgs = {
  filters: InventoryFilters;
};


export type QueryGetLogilessMerchantsArgs = {
  filters: FilterMerchants;
};


export type QueryGetLogisticStoreStatusArgs = {
  userIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
};


export type QueryGetPaymentMethodsArgs = {
  filter: GetPaymentMethodFilter;
};


export type QueryGetPickupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPickupsArgs = {
  filters: GetPickupsInput;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};


export type QueryGetProductsForAdsMappingArgs = {
  filter: AdsMappingProductListFilterInput;
};


export type QueryGetShipperAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetShipperAddressesArgs = {
  accountId: Scalars['ID']['input'];
  filter: GetShipperAddressFilter;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
};


export type QueryGetShippingRateArgs = {
  input: ShippingInput;
};


export type QueryGetShippingRatesArgs = {
  input: ShippingInput;
};


export type QueryGetSkuArgs = {
  gtin: Scalars['String']['input'];
};


export type QueryGetSkuTagsArgs = {
  storeId?: InputMaybe<Scalars['Long']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetSkusArgs = {
  filters: SkuFilters;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetSupplierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSuppliersArgs = {
  filters: SupplierFilters;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWarehousesArgs = {
  filters: WarehouseFilters;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetWarehousesListArgs = {
  filters: WarehouseFilters;
};


export type QueryGetWmsStoresArgs = {
  associationKey: Scalars['String']['input'];
  storeId?: InputMaybe<Scalars['Long']['input']>;
  vendor: Wms;
};


export type QueryGoogleAdsClientAccountsArgs = {
  customerId: Scalars['String']['input'];
  datasourceTokenId: Scalars['Long']['input'];
};


export type QueryGoogleAdsCustomersArgs = {
  datasourceTokenId: Scalars['Long']['input'];
};


export type QueryGoogleAnalytics4AccountsArgs = {
  datasourceTokenId: Scalars['Long']['input'];
};


export type QueryGoogleLoginUrlArgs = {
  redirectUri: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  type: GoogleLoginUrlType;
};


export type QueryHasAdvertisingConnectionArgs = {
  input: OnboardingInput;
};


export type QueryHasAnalyticsReportArgs = {
  input: OnboardingInput;
};


export type QueryHasConnectedSaleChannelArgs = {
  input: OnboardingInput;
};


export type QueryImportsFileUploadArgs = {
  fileName: Scalars['String']['input'];
};


export type QueryInactiveIntegrationCountArgs = {
  input: OnboardingInput;
};


export type QueryIntegrationSideNavArgs = {
  storeId: Scalars['Long']['input'];
};


export type QueryInventoryFileUploadHistoryArgs = {
  input: InventoryFileUploadHistoryInput;
};


export type QueryInventoryListViewArgs = {
  input: InventoryListQueryInput;
};


export type QueryInventoryOverviewArgs = {
  input: InventoryOverviewQueryInput;
};


export type QueryKeywordChartArgs = {
  input: KeywordChartInput;
};


export type QueryKeywordDetailedReportArgs = {
  input: KeywordDetailedReportInput;
};


export type QueryKeywordSummaryArgs = {
  input: KeywordSummaryInput;
};


export type QueryLastProductVariantIdsArgs = {
  productVariantIds: ReadonlyArray<Scalars['Long']['input']>;
};


export type QueryLazadaLoginUrlV2Args = {
  input: LazadaLoginUrlV2Input;
};


export type QueryLazadaSalesChartArgs = {
  input: LazadaSalesChartInput;
};


export type QueryLazadaSalesDetailedReportArgs = {
  input: LazadaSalesDetailedReportInput;
};


export type QueryLazadaSalesSummaryArgs = {
  input: LazadaSalesSummaryInput;
};


export type QueryListingDetailArgs = {
  id: Scalars['Long']['input'];
};


export type QueryListingListViewArgs = {
  filter: ListingListFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryManualCostArgs = {
  id: Scalars['Long']['input'];
};


export type QueryManualCostsArgs = {
  filter?: InputMaybe<ManualCostFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortBy?: InputMaybe<ManualCostSortByInput>;
};


export type QueryManualSaleChannelsByStoreIdArgs = {
  storeId: Scalars['Long']['input'];
};


export type QueryMarketplaceCalendarEventsArgs = {
  input: MarketplaceCalendarEventQueryInput;
};


export type QueryMarketplaceCalendarSummaryArgs = {
  input: MarketplaceCalendarSummaryQueryInput;
};


export type QueryOnboardingArgs = {
  storeId: Scalars['Long']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['Long']['input'];
};


export type QueryOrderCsvExportV2Args = {
  input: OrderCsvExportV2Input;
};


export type QueryOrderCsvUploadArgs = {
  fileName: Scalars['String']['input'];
};


export type QueryOrderListViewArgs = {
  filter: OrderListFilterInput;
  pageNumber: Scalars['Int']['input'];
};


export type QueryOrderOnboardingArgs = {
  storeId: Scalars['Long']['input'];
};


export type QueryOrderOverviewV2Args = {
  filter: OrderListFilterInput;
};


export type QueryPickupDeferredPaymentFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductCsvExportV2Args = {
  input: ProductCsvExportV2Input;
};


export type QueryProductCsvUploadArgs = {
  fileName: Scalars['String']['input'];
};


export type QueryProductCategoryAndPriceArgs = {
  input: ProductReviewAnalysisFilterInput;
};


export type QueryProductChartArgs = {
  input: ProductChartInput;
};


export type QueryProductDetailArgs = {
  id: Scalars['Long']['input'];
};


export type QueryProductDetailedReportArgs = {
  input: ProductDetailedReportInput;
};


export type QueryProductListViewArgs = {
  filter: ProductListFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryProductMediaUploadArgs = {
  fileName: Scalars['String']['input'];
};


export type QueryProductOnboardingArgs = {
  storeId: Scalars['Long']['input'];
};


export type QueryProductRatingOverviewArgs = {
  input: ProductReviewAnalysisFilterInput;
};


export type QueryProductReviewCompetitorAnalysisArgs = {
  input: ProductReviewCompetitorAnalysisRequestInput;
};


export type QueryProductReviewSentimentAnalysisArgs = {
  input: ProductReviewAnalysisFilterInput;
};


export type QueryProductReviewSummarizationArgs = {
  input: ProductReviewAnalysisFilterInput;
};


export type QueryProductReviewTopicAnalysisArgs = {
  input: ProductReviewAnalysisFilterInput;
};


export type QueryProductReviewsArgs = {
  input: ProductReviewsInput;
};


export type QueryProductSaleChannelsArgs = {
  input: ProductSaleChannelsInput;
};


export type QueryProductSalesAnalyticsArgs = {
  input: ProductSalesAnalyticsInput;
};


export type QueryProductSummaryArgs = {
  input: ProductSummaryInput;
};


export type QueryProductVariantArgs = {
  id: Scalars['Long']['input'];
};


export type QueryProductVariantCsvExportV2Args = {
  input: ProductVariantCsvExportV2Input;
};


export type QueryProductVariantForOrderAddProductArgs = {
  filter: ProductVariantForOrderAddProductFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryProductVariantListViewArgs = {
  filter: ProductVariantListFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryProductVariantsForAddInboundArgs = {
  filter: ProductVariantForAddInboundFilterInput;
  pageNumber: Scalars['Int']['input'];
};


export type QueryProductVariantsForProductMergeArgs = {
  filter: ProductVariantForMergeFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryProductVariantsForProductSetArgs = {
  filter: ProductVariantForSetFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryRakutenSalesReportChartArgs = {
  input: RakutenSalesReportChartInput;
};


export type QueryRakutenSalesReportSummaryArgs = {
  input: RakutenSalesReportSummaryInput;
};


export type QueryRakutenSalesReportTableArgs = {
  input: RakutenSalesReportTableInput;
};


export type QueryReportByProductArgs = {
  filter: ProductReportPaginatedFilterInput;
};


export type QueryReportByProductDailyArgs = {
  filter: ProductReportFilterInput;
};


export type QueryReportByProductSummaryArgs = {
  filter: VariantReportFilterInput;
};


export type QueryReportByVariantArgs = {
  filter: VariantReportPaginatedFilterInput;
};


export type QueryReportByVariantDailyArgs = {
  filter: VariantReportFilterInput;
};


export type QueryReportDocumentArgs = {
  id: Scalars['Long']['input'];
};


export type QueryReportHomeDailyArgs = {
  filter: HomeReportFilterInput;
  metric: HomeDailyReportMetric;
};


export type QueryReportHomeSaleChannelRevenueArgs = {
  filter: HomeReportFilterInput;
};


export type QueryReportHomeSummaryArgs = {
  filter: HomeReportFilterInput;
};


export type QueryReportHomeTop5ProductsArgs = {
  filter: HomeReportFilterInput;
};


export type QuerySaleChannelArgs = {
  id: Scalars['Long']['input'];
};


export type QuerySaleChannelCostIndexArgs = {
  saleChannelId: Scalars['Long']['input'];
};


export type QuerySaleChannelCostIndexesArgs = {
  filter?: InputMaybe<SaleChannelCostIndexFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortBy?: InputMaybe<SaleChannelCostIndexSortByInput>;
};


export type QuerySaleChannelCurrencyArgs = {
  saleChannelType: SaleChannelType;
};


export type QuerySaleChannelPlanArgs = {
  type: SaleChannelType;
};


export type QuerySaleChannelPlanV2Args = {
  type: SaleChannelType;
};


export type QuerySaleChannelReportChartArgs = {
  filter: SaleChannelReportTableFilterInput;
};


export type QuerySaleChannelReportSummaryArgs = {
  filter: SaleChannelReportSummaryFilterInput;
};


export type QuerySaleChannelReportTableArgs = {
  filter: SaleChannelReportTableFilterInput;
};


export type QuerySaleChannelSyncsArgs = {
  after: Scalars['OffsetDateTime']['input'];
  masterDataStoreId: Scalars['Long']['input'];
};


export type QuerySearchListingArgs = {
  filter: SearchListingFilterInput;
  orListingId?: InputMaybe<Scalars['Long']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  storeId: Scalars['Long']['input'];
};


export type QuerySessionsCsvPreviewArgs = {
  ascending: Scalars['Boolean']['input'];
  filter: SessionsCsvFilterInput;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QuerySettingGuideArgs = {
  input: OnboardingInput;
};


export type QueryShopeeLoginUrlV2Args = {
  input: ShopeeLoginUrlV2Input;
};


export type QueryShopeeSalesChartArgs = {
  input: ShopeeSalesChartInput;
};


export type QueryShopeeSalesDetailedReportArgs = {
  input: ShopeeSalesDetailedReportInput;
};


export type QueryShopeeSalesSummaryArgs = {
  input: ShopeeSalesSummaryInput;
};


export type QueryShopifyLoginUrlV2Args = {
  input: ShopifyLoginUrlV2Input;
};


export type QueryShopifySalesReportChartArgs = {
  input: ShopifySalesReportChartInput;
};


export type QueryShopifySalesReportSummaryArgs = {
  input: ShopifySalesReportSummaryInput;
};


export type QueryShopifySalesReportTableArgs = {
  input: ShopifySalesReportTableInput;
};


export type QueryStoresWithChannelArgs = {
  filter?: InputMaybe<StoreWithChanelFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortBy?: InputMaybe<BrandSortByInput>;
};


export type QueryTagsV2Args = {
  filter?: InputMaybe<ProductTagsFilterInput>;
};


export type QueryTikTokAdsAdAccountsArgs = {
  datasourceTokenId: Scalars['Long']['input'];
};


export type QueryTikTokAdsLoginUrlArgs = {
  redirectUri: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTikTokLoginUrlV2Args = {
  input: TikTokLoginUrlV2Input;
};


export type QueryTikTokSalesReportChartArgs = {
  input: TikTokSalesReportChartInput;
};


export type QueryTikTokSalesReportDetailedReportArgs = {
  input: TikTokSalesReportDetailedReportInput;
};


export type QueryTikTokSalesReportSummaryArgs = {
  input: TikTokSalesReportSummaryInput;
};


export type QueryTokopediaSalesChartArgs = {
  input: TokopediaSalesChartInput;
};


export type QueryTokopediaSalesDetailedReportArgs = {
  input: TokopediaSalesDetailedReportInput;
};


export type QueryTokopediaSalesSummaryArgs = {
  input: TokopediaSalesSummaryInput;
};


export type QueryVariantValuesArgs = {
  input: VariantValuesInput;
};


export type QueryYahooAdsAccountsArgs = {
  input: YahooAdsAccountsInput;
};


export type QueryYahooAdsLoginUrlArgs = {
  input: YahooAdsLoginUrlInput;
};


export type QueryYahooLoginUrlArgs = {
  input: YahooLoginUrlInput;
};


export type QueryYahooSalesReportChartArgs = {
  input: YahooSalesReportChartInput;
};


export type QueryYahooSalesReportSummaryArgs = {
  input: YahooSalesReportSummaryInput;
};


export type QueryYahooSalesReportTableArgs = {
  input: YahooSalesReportTableInput;
};

export type QuotaInfos = {
  readonly __typename?: 'QuotaInfos';
  readonly line: LineMessageQuotaInfo;
};

export type RakutenConnectPayload = {
  readonly __typename?: 'RakutenConnectPayload';
  readonly ok: Scalars['Boolean']['output'];
  readonly unavailableAPI?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

export type RakutenFashionFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: RakutenFashionFileUploadType;
};

export const RakutenFashionFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type RakutenFashionFileUploadType = typeof RakutenFashionFileUploadType[keyof typeof RakutenFashionFileUploadType];
export type RakutenFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
  readonly type: RakutenFileUploadType;
};

export const RakutenFileUploadType = {
  AD_COST_COUPON_ADVANCED: 'AD_COST_COUPON_ADVANCED',
  AD_COST_RPP: 'AD_COST_RPP',
  SESSION: 'SESSION'
} as const;

export type RakutenFileUploadType = typeof RakutenFileUploadType[keyof typeof RakutenFileUploadType];
export type RakutenReconnectPayload = {
  readonly __typename?: 'RakutenReconnectPayload';
  readonly ok: Scalars['Boolean']['output'];
  readonly unavailableAPI?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

export type RakutenSaleChannelData = {
  readonly __typename?: 'RakutenSaleChannelData';
  readonly shopUrl: Scalars['String']['output'];
};

export type RakutenSalesReportChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type RakutenSalesReportChartPayload = {
  readonly __typename?: 'RakutenSalesReportChartPayload';
  readonly conversionRate: ReadonlyArray<Scalars['Map']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly orderCount: ReadonlyArray<Scalars['Map']['output']>;
  readonly sessions: ReadonlyArray<Scalars['Map']['output']>;
  readonly totalSales: ReadonlyArray<Scalars['Map']['output']>;
};

export type RakutenSalesReportSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type RakutenSalesReportSummaryPayload = {
  readonly __typename?: 'RakutenSalesReportSummaryPayload';
  readonly conversionRate: NumericValueWithDiffPayload;
  readonly currency: ReportSupportedCurrency;
  readonly orderCount: NumericValueWithDiffPayload;
  readonly sessions: NumericValueWithDiffPayload;
  readonly totalSales: NumericValueWithDiffPayload;
};

export type RakutenSalesReportTableInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type RakutenSalesReportTablePayload = {
  readonly __typename?: 'RakutenSalesReportTablePayload';
  readonly conversionRate: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly orderCount: Scalars['Int']['output'];
  readonly sessions: Scalars['Int']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
};

export type RakutenSalesReportTablePayloadList = {
  readonly __typename?: 'RakutenSalesReportTablePayloadList';
  readonly items: ReadonlyArray<RakutenSalesReportTablePayload>;
  readonly meta: ReportListMeta;
  readonly total: RakutenSalesReportTotalPayload;
};

export type RakutenSalesReportTotalPayload = {
  readonly __typename?: 'RakutenSalesReportTotalPayload';
  readonly conversionRate: Scalars['BigDecimal']['output'];
  readonly orderCount: Scalars['Int']['output'];
  readonly sessions: Scalars['Int']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
};

export type RatedShipmentAlert = {
  readonly __typename?: 'RatedShipmentAlert';
  readonly code: Scalars['String']['output'];
  readonly description: Scalars['String']['output'];
};

export type ReactionSign = Emoji | InstagramSign;

export type ReactionSignInputInput = {
  readonly emoji?: InputMaybe<EmojiInput>;
  readonly instagramSign?: InputMaybe<InstagramSignInput>;
};

export type ReadOrderCustomer = {
  readonly __typename?: 'ReadOrderCustomer';
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly name: Name;
  readonly phoneNumber: Scalars['String']['output'];
  readonly phoneNumberCountry?: Maybe<MarketplaceCountry>;
};

export type ReadOrderProductLogistics = {
  readonly __typename?: 'ReadOrderProductLogistics';
  readonly deliveryStatus?: Maybe<OrderProductDeliveryStatus>;
  readonly unknown: Scalars['Boolean']['output'];
};

export type Recipient = All | Condition | CustomerTagNames | InfluencerCondition;

export type RecipientItemInput = {
  readonly conditions?: InputMaybe<BroadcastConditionWrapperInput>;
  readonly customerTagNames?: InputMaybe<CustomerTagNamesInput>;
  readonly influencerConditions?: InputMaybe<BroadcastInfluencerConditionWrapperInput>;
  readonly type: RecipientType;
};

export const RecipientType = {
  ALL: 'ALL',
  CONDITIONS: 'CONDITIONS',
  INFLUENCER_CONDITIONS: 'INFLUENCER_CONDITIONS',
  WITH_CUSTOMER_TAGS: 'WITH_CUSTOMER_TAGS'
} as const;

export type RecipientType = typeof RecipientType[keyof typeof RecipientType];
export type RefreshFacebookTokenResponse = NoInstagramAccountRefreshTokenError | RefreshFacebookTokenResponseSuccess | UnknownErrorRefreshTokenError | WrongPageSelectedRefreshTokenError;

export type RefreshFacebookTokenResponseSuccess = {
  readonly __typename?: 'RefreshFacebookTokenResponseSuccess';
  readonly connectionId: Scalars['String']['output'];
};

export type RefreshShopeeConnectionRequest = {
  readonly code: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type Region = {
  readonly __typename?: 'Region';
  readonly id: Scalars['ID']['output'];
  readonly language: Language;
  readonly localName: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly provinceId: Scalars['String']['output'];
};

export type RegisterFileUploadPayload = {
  readonly __typename?: 'RegisterFileUploadPayload';
  readonly fileUploadId: Scalars['String']['output'];
};

export type RegisterManualOrderCsvUploadPayload = {
  readonly __typename?: 'RegisterManualOrderCsvUploadPayload';
  readonly ok: Scalars['Boolean']['output'];
};

export type RemoveCustomerTagsActivity = CustomerActivity & {
  readonly __typename?: 'RemoveCustomerTagsActivity';
  readonly customerTags: ReadonlyArray<CustomerTag>;
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
  readonly user: BasicUser;
};

export type ReplyTo = ReplyToMessage | ReplyToStory;

export type ReplyToInputInput = {
  readonly messageId?: InputMaybe<Scalars['String']['input']>;
};

export type ReplyToMessage = {
  readonly __typename?: 'ReplyToMessage';
  readonly message: InstagramMessage;
};

export type ReplyToStory = {
  readonly __typename?: 'ReplyToStory';
  readonly story: Story;
};

export const ReportChartTab = {
  CHANNEL: 'CHANNEL',
  STORE: 'STORE'
} as const;

export type ReportChartTab = typeof ReportChartTab[keyof typeof ReportChartTab];
export type ReportCompareData = {
  readonly __typename?: 'ReportCompareData';
  readonly current?: Maybe<Scalars['BigDecimal']['output']>;
  readonly diff?: Maybe<Scalars['BigDecimal']['output']>;
  readonly isPercentage: Scalars['Boolean']['output'];
  readonly percentage?: Maybe<Scalars['BigDecimal']['output']>;
};

export const ReportDimensions = {
  CHANNELS: 'CHANNELS',
  DAY: 'DAY',
  DOW: 'DOW',
  MONTH: 'MONTH',
  WEEK: 'WEEK'
} as const;

export type ReportDimensions = typeof ReportDimensions[keyof typeof ReportDimensions];
export type ReportDocumentPayload = {
  readonly __typename?: 'ReportDocumentPayload';
  readonly createdAt: Scalars['ZonedDatetime']['output'];
  readonly doneAt?: Maybe<Scalars['ZonedDatetime']['output']>;
  readonly id: Scalars['Long']['output'];
  readonly status: ReportDocumentStatus;
  readonly type: ReportDocumentType;
  readonly url?: Maybe<Scalars['URL']['output']>;
};

export type ReportDocumentResult = {
  readonly __typename?: 'ReportDocumentResult';
  readonly documentId: Scalars['Long']['output'];
  readonly ok: Scalars['Boolean']['output'];
};

export const ReportDocumentStatus = {
  FAIL: 'FAIL',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS'
} as const;

export type ReportDocumentStatus = typeof ReportDocumentStatus[keyof typeof ReportDocumentStatus];
export const ReportDocumentType = {
  AD_CAMPAIGN_CSV: 'AD_CAMPAIGN_CSV',
  AD_CSV: 'AD_CSV',
  AD_GROUP_CSV: 'AD_GROUP_CSV',
  AD_OVERVIEW_CSV: 'AD_OVERVIEW_CSV',
  DDI_INVENTORY_DAILY_CSV: 'DDI_INVENTORY_DAILY_CSV',
  DDI_INVENTORY_PRODUCT_CSV: 'DDI_INVENTORY_PRODUCT_CSV',
  DDI_PRODUCT_CSV: 'DDI_PRODUCT_CSV',
  DDI_SALES_CHANNEL_CSV: 'DDI_SALES_CHANNEL_CSV',
  KEYWORD_CSV: 'KEYWORD_CSV',
  PRODUCT_CSV: 'PRODUCT_CSV',
  SALES_CHANNEL_CSV: 'SALES_CHANNEL_CSV',
  VARIANT_CSV: 'VARIANT_CSV'
} as const;

export type ReportDocumentType = typeof ReportDocumentType[keyof typeof ReportDocumentType];
export type ReportListKvData = {
  readonly __typename?: 'ReportListKVData';
  readonly key: Scalars['String']['output'];
  readonly value: Scalars['BigDecimal']['output'];
};

export type ReportListMeta = {
  readonly __typename?: 'ReportListMeta';
  /** Length in this query */
  readonly length: Scalars['Int']['output'];
  /** Page size */
  readonly limit: Scalars['Int']['output'];
  /** Start position of this query */
  readonly start: Scalars['Int']['output'];
  /** Total length of items including other pages */
  readonly total: Scalars['Int']['output'];
};

export const ReportOrderStatus = {
  ORDERED: 'ORDERED',
  SHIPPED: 'SHIPPED'
} as const;

export type ReportOrderStatus = typeof ReportOrderStatus[keyof typeof ReportOrderStatus];
export type ReportSummaryComparisonData = {
  readonly __typename?: 'ReportSummaryComparisonData';
  readonly avgOrderValues: ReportCompareData;
  readonly cancelAndOrRefund: ReportCompareData;
  readonly cvr?: Maybe<ReportCompareData>;
  readonly grossProfit?: Maybe<ReportCompareData>;
  readonly grossProfitRate?: Maybe<ReportCompareData>;
  readonly grossSales: ReportCompareData;
  readonly marginalProfit?: Maybe<ReportCompareData>;
  readonly marginalProfitRate?: Maybe<ReportCompareData>;
  readonly netSales: ReportCompareData;
  readonly orders: ReportCompareData;
  readonly otherCost?: Maybe<ReportCompareData>;
  readonly revenue: ReportCompareData;
  readonly roas?: Maybe<ReportCompareData>;
  readonly sessions?: Maybe<ReportCompareData>;
  readonly totalSales: ReportCompareData;
};

export type ReportSummaryPayload = {
  readonly __typename?: 'ReportSummaryPayload';
  readonly comparisonSummary: ReportSummaryComparisonData;
  readonly currency: ReportSupportedCurrency;
  readonly tableSummary: SaleChannelReportSummaryData;
};

/** ISO 4217 currency code */
export const ReportSupportedCurrency = {
  /** United Arab Emirates dirham */
  AED: 'AED',
  /** Australian dollar */
  AUD: 'AUD',
  /** Bahraini Dinar */
  BHD: 'BHD',
  /** Brazilian real */
  BRL: 'BRL',
  /** Canadian dollar */
  CAD: 'CAD',
  /** Chinese yuan (Renminbi) */
  CNY: 'CNY',
  /** Egyptian Pound */
  EGP: 'EGP',
  /** Euro */
  EUR: 'EUR',
  /** United Kingdom Pound sterling */
  GBP: 'GBP',
  /** Hong Kong dollar */
  HKD: 'HKD',
  /** Indonesian rupiah */
  IDR: 'IDR',
  /** Indian rupee */
  INR: 'INR',
  /** Jordanian Dinar */
  JOD: 'JOD',
  /** Japanese yen */
  JPY: 'JPY',
  /** Cambodian riel */
  KHR: 'KHR',
  /** South Korean won */
  KRW: 'KRW',
  /** Kuwaiti dinar */
  KWD: 'KWD',
  /** Myanmar kyat */
  MMK: 'MMK',
  /** Mexican peso */
  MXN: 'MXN',
  /** Malaysian ringgit */
  MYR: 'MYR',
  /** Omani Rial */
  OMR: 'OMR',
  /** Philippine peso */
  PHP: 'PHP',
  /** Pakistani Rupee */
  PKR: 'PKR',
  /** Polish Zloty */
  PLN: 'PLN',
  /** Qatari Riyal */
  QAR: 'QAR',
  /** Saudi riyal */
  SAR: 'SAR',
  /** Swedish krona */
  SEK: 'SEK',
  /** Singapore dollar */
  SGD: 'SGD',
  /** Thai baht */
  THB: 'THB',
  /** Turkish lira */
  TRY: 'TRY',
  /** New Taiwan dollar */
  TWD: 'TWD',
  /** United States dollar */
  USD: 'USD',
  /** Vietnamese dong */
  VND: 'VND'
} as const;

export type ReportSupportedCurrency = typeof ReportSupportedCurrency[keyof typeof ReportSupportedCurrency];
export const ReportTab = {
  ALL: 'ALL',
  MARKETPLACE: 'MARKETPLACE',
  STORE: 'STORE'
} as const;

export type ReportTab = typeof ReportTab[keyof typeof ReportTab];
export const Resource = {
  COURIER: 'COURIER',
  INBOUND: 'INBOUND',
  ORDER: 'ORDER',
  SKU: 'SKU'
} as const;

export type Resource = typeof Resource[keyof typeof Resource];
export type RichMessage = {
  readonly __typename?: 'RichMessage';
  readonly imageUrl: Scalars['String']['output'];
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type RichMessageInput = {
  readonly imageUrl: Scalars['String']['input'];
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

export type SaleChannel = {
  readonly __typename?: 'SaleChannel';
  readonly costIndex?: Maybe<SaleChannelCostIndexQueryPayload>;
  readonly country?: Maybe<MarketplaceSaleChannelMarketplace>;
  readonly data: SaleChannelData;
  readonly fileUploadStatus: FileUploadStatus;
  readonly id: Scalars['Long']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly isManualChannel: Scalars['Boolean']['output'];
  readonly logisticChannel?: Maybe<LogisticChannel>;
  readonly logisticChannelFormStatus?: Maybe<ChannelFormStatus>;
  readonly logisticChannelShopifyCarrierService?: Maybe<ShopifyCarrierService>;
  readonly logisticChannelShopifyCarrierServiceCondition?: Maybe<ShopifyCarrierServiceCondition>;
  readonly status: SaleChannelStatus;
  readonly store: MdStore;
  readonly type: MarketplaceSaleChannelType;
};

export type SaleChannelCostIndexFilterInput = {
  readonly masterDataStoreId?: InputMaybe<Scalars['Long']['input']>;
};

export type SaleChannelCostIndexPayload = {
  readonly __typename?: 'SaleChannelCostIndexPayload';
  readonly ok: Scalars['Boolean']['output'];
};

export type SaleChannelCostIndexPayloadList = {
  readonly __typename?: 'SaleChannelCostIndexPayloadList';
  readonly items: ReadonlyArray<SaleChannelCostIndexQueryPayload>;
  readonly meta: ReportListMeta;
};

export type SaleChannelCostIndexQueryPayload = {
  readonly __typename?: 'SaleChannelCostIndexQueryPayload';
  readonly commissionFeePercentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly creditCardFeePercentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly flatFee?: Maybe<Scalars['BigDecimal']['output']>;
  readonly freeShippingFeeAdjustmentPercentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly logisticFeeMonthly?: Maybe<Scalars['BigDecimal']['output']>;
  readonly logisticsCurrency: ReportSupportedCurrency;
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly numberOfMonthlyOrders: Scalars['Int']['output'];
  readonly plan?: Maybe<SaleChannelPlanType>;
  readonly rakutenPayFeePercentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly rakutenSystemFeePercentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly referralFeeSoldPercentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly resourceBurdenRatioPercentage?: Maybe<Scalars['BigDecimal']['output']>;
  readonly saleChannelId: Scalars['Long']['output'];
  readonly taxRatePercentage: Scalars['BigDecimal']['output'];
  readonly transactionFeePercentage?: Maybe<Scalars['BigDecimal']['output']>;
};

export const SaleChannelCostIndexSortByField = {
  CREATED_AT: 'CREATED_AT',
  SALE_CHANNEL_ID: 'SALE_CHANNEL_ID',
  UPDATED_AT: 'UPDATED_AT'
} as const;

export type SaleChannelCostIndexSortByField = typeof SaleChannelCostIndexSortByField[keyof typeof SaleChannelCostIndexSortByField];
export type SaleChannelCostIndexSortByInput = {
  readonly descending?: InputMaybe<Scalars['Boolean']['input']>;
  readonly field: SaleChannelCostIndexSortByField;
};

export type SaleChannelData = AmazonSaleChannelData | EmptySaleChannelData | LazadaSaleChannelData | Qoo10SaleChannelData | RakutenSaleChannelData | ShopeeSaleChannelData | ShopifySaleChannelData | TikTokSaleChannelData | TokopediaSaleChannelData | YahooSaleChannelData;

export type SaleChannelForManualOrder = {
  readonly __typename?: 'SaleChannelForManualOrder';
  readonly hasAnyLogiConnection: Scalars['Boolean']['output'];
  /** null when Manual channel is not yet created */
  readonly saleChannelId?: Maybe<Scalars['Long']['output']>;
  readonly saleChannelType: MarketplaceSaleChannelType;
};

export type SaleChannelPlan = {
  readonly __typename?: 'SaleChannelPlan';
  readonly description: Scalars['String']['output'];
  readonly price: Scalars['String']['output'];
  readonly value: SaleChannelPlanType;
};

export type SaleChannelPlanPayload = {
  readonly __typename?: 'SaleChannelPlanPayload';
  readonly amount: Scalars['BigDecimal']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly price: Scalars['String']['output'];
  readonly unit: SaleChannelPlanUnit;
  readonly value: SaleChannelPlanType;
};

export const SaleChannelPlanType = {
  AmazonIndividual: 'AmazonIndividual',
  AmazonProfessional: 'AmazonProfessional',
  RakutenGanbare: 'RakutenGanbare',
  RakutenMegaShop: 'RakutenMegaShop',
  RakutenStandard: 'RakutenStandard',
  Shopify: 'Shopify',
  ShopifyAdvanced: 'ShopifyAdvanced',
  ShopifyBasic: 'ShopifyBasic',
  ShopifyPlus: 'ShopifyPlus'
} as const;

export type SaleChannelPlanType = typeof SaleChannelPlanType[keyof typeof SaleChannelPlanType];
export const SaleChannelPlanUnit = {
  MONTH: 'MONTH',
  UNIT_SOLD: 'UNIT_SOLD'
} as const;

export type SaleChannelPlanUnit = typeof SaleChannelPlanUnit[keyof typeof SaleChannelPlanUnit];
export type SaleChannelReportChartData = {
  readonly __typename?: 'SaleChannelReportChartData';
  readonly advertisingCost: Scalars['BigDecimal']['output'];
  readonly advertisingRevenue: Scalars['BigDecimal']['output'];
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly channel?: Maybe<SaleChannelType>;
  readonly commissionFee: Scalars['BigDecimal']['output'];
  readonly cvr?: Maybe<Scalars['BigDecimal']['output']>;
  readonly dailySales?: Maybe<Scalars['BigDecimal']['output']>;
  readonly day?: Maybe<Scalars['LocalDate']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly dow?: Maybe<Scalars['Int']['output']>;
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate: Scalars['BigDecimal']['output'];
  readonly grossRevenue: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly logisticsCost: Scalars['BigDecimal']['output'];
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate: Scalars['BigDecimal']['output'];
  readonly marketplaceCalendarEvents?: Maybe<ReadonlyArray<MarketplaceCalendarEventPayload>>;
  readonly masterDataStoreId?: Maybe<Scalars['Long']['output']>;
  readonly merchandiseCost: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly sessions: Scalars['Long']['output'];
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly storeName?: Maybe<Scalars['String']['output']>;
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly yearMonth?: Maybe<Scalars['YearMonth']['output']>;
  readonly yearWeek?: Maybe<Scalars['LocalDate']['output']>;
};

export type SaleChannelReportChartPayload = {
  readonly __typename?: 'SaleChannelReportChartPayload';
  readonly channels: ReadonlyArray<SaleChannelType>;
  readonly currency: ReportSupportedCurrency;
  readonly list: ReadonlyArray<SaleChannelReportChartData>;
  readonly stores: ReadonlyArray<SaleChannelReportChartStore>;
  readonly totalItems: Scalars['Int']['output'];
};

export type SaleChannelReportChartStore = {
  readonly __typename?: 'SaleChannelReportChartStore';
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

export type SaleChannelReportPayload = {
  readonly __typename?: 'SaleChannelReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly list: ReadonlyArray<SaleChannelReportTableData>;
  readonly totalItems: Scalars['Int']['output'];
};

export const SaleChannelReportSortByField = {
  ADVERTISING_COST: 'ADVERTISING_COST',
  ADVERTISING_REVENUE: 'ADVERTISING_REVENUE',
  AVG_ORDER_VALUES: 'AVG_ORDER_VALUES',
  COMMISSION_FEE: 'COMMISSION_FEE',
  CVR: 'CVR',
  DISCOUNTS: 'DISCOUNTS',
  DISCOUNTS_RATE: 'DISCOUNTS_RATE',
  GROSS_PROFIT: 'GROSS_PROFIT',
  GROSS_PROFIT_RATE: 'GROSS_PROFIT_RATE',
  GROSS_REVENUE: 'GROSS_REVENUE',
  GROSS_SALES: 'GROSS_SALES',
  LOGISTICS_FEE: 'LOGISTICS_FEE',
  MARGINAL_PROFIT: 'MARGINAL_PROFIT',
  MARGINAL_PROFIT_RATE: 'MARGINAL_PROFIT_RATE',
  METRIC: 'METRIC',
  NET_SALES: 'NET_SALES',
  ORDERS: 'ORDERS',
  OTHER_COST: 'OTHER_COST',
  RETURN_CANCEL: 'RETURN_CANCEL',
  REVENUE: 'REVENUE',
  ROAS: 'ROAS',
  SESSIONS: 'SESSIONS',
  SHIPPING_FEE: 'SHIPPING_FEE',
  TOTAL_SALES: 'TOTAL_SALES',
  UNIT_COST: 'UNIT_COST'
} as const;

export type SaleChannelReportSortByField = typeof SaleChannelReportSortByField[keyof typeof SaleChannelReportSortByField];
export type SaleChannelReportSummaryData = {
  readonly __typename?: 'SaleChannelReportSummaryData';
  readonly advertisingCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly advertisingRevenue?: Maybe<Scalars['BigDecimal']['output']>;
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly commissionFee?: Maybe<Scalars['BigDecimal']['output']>;
  readonly cvr?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate?: Maybe<Scalars['BigDecimal']['output']>;
  readonly grossRevenue: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly logisticsCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate?: Maybe<Scalars['BigDecimal']['output']>;
  readonly merchandiseCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly roas?: Maybe<Scalars['BigDecimal']['output']>;
  readonly sessions?: Maybe<Scalars['Long']['output']>;
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
};

export type SaleChannelReportSummaryFilterInput = {
  readonly adsMetricsType?: InputMaybe<AdsMetricsType>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly sortBy?: InputMaybe<SaleChannelReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly tab: ReportTab;
};

export type SaleChannelReportTableData = {
  readonly __typename?: 'SaleChannelReportTableData';
  readonly advertisingCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly advertisingRevenue?: Maybe<Scalars['BigDecimal']['output']>;
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly cancelAndOrRefund: Scalars['BigDecimal']['output'];
  readonly commissionFee?: Maybe<Scalars['BigDecimal']['output']>;
  readonly cvr?: Maybe<Scalars['BigDecimal']['output']>;
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate?: Maybe<Scalars['BigDecimal']['output']>;
  readonly grossRevenue: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly logisticsCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate?: Maybe<Scalars['BigDecimal']['output']>;
  readonly merchandiseCost?: Maybe<Scalars['BigDecimal']['output']>;
  readonly metric: Scalars['String']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly roas?: Maybe<Scalars['BigDecimal']['output']>;
  readonly sessions?: Maybe<Scalars['Long']['output']>;
  readonly shippingFee: Scalars['BigDecimal']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
};

export type SaleChannelReportTableFilterInput = {
  readonly adsMetricsType?: InputMaybe<AdsMetricsType>;
  readonly chartTab?: InputMaybe<ReportChartTab>;
  readonly dimensions: ReportDimensions;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly sortBy?: InputMaybe<SaleChannelReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly tab: ReportTab;
};

export type SaleChannelSaveInput = {
  readonly commissionFeePercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly creditCardFeePercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly flatFee?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly freeShippingFeeAdjustmentPercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly logisticFeeMonthly: Scalars['BigDecimal']['input'];
  readonly logisticsCurrency: ReportSupportedCurrency;
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly numberOfMonthlyOrders: Scalars['Int']['input'];
  readonly plan?: InputMaybe<SaleChannelPlanType>;
  readonly rakutenPayFeePercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly rakutenSystemFeePercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly referralFeeSoldPercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly resourceBurdenRatioPercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly saleChannelId: Scalars['Long']['input'];
  readonly saleChannelType: SaleChannelType;
  readonly taxRatePercentage: Scalars['BigDecimal']['input'];
  readonly transactionFeePercentage?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export type SaleChannelSpecificData = LazadaSpecificData | ManualsSpecificData | ShopifySpecificData;

export const SaleChannelStatus = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  PENDING: 'PENDING'
} as const;

export type SaleChannelStatus = typeof SaleChannelStatus[keyof typeof SaleChannelStatus];
export const SaleChannelSyncStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS'
} as const;

export type SaleChannelSyncStatus = typeof SaleChannelSyncStatus[keyof typeof SaleChannelSyncStatus];
export type SaleChannelSyncSummary = {
  readonly __typename?: 'SaleChannelSyncSummary';
  readonly action: Action;
  readonly errors?: Maybe<ReadonlyArray<Scalars['Map']['output']>>;
  readonly saleChannelTypes: ReadonlyArray<MarketplaceSaleChannelType>;
  readonly status: SaleChannelSyncStatus;
};

export type SaleChannelSyncSummaryList = {
  readonly __typename?: 'SaleChannelSyncSummaryList';
  readonly saleChannelSyncs: ReadonlyArray<SaleChannelSyncSummary>;
};

export const SaleChannelType = {
  AmazonSellerCentral: 'AmazonSellerCentral',
  AuPayMarket: 'AuPayMarket',
  FacebookShop: 'FacebookShop',
  Lazada: 'Lazada',
  Magaseek: 'Magaseek',
  MakeshopByGmo: 'MakeshopByGmo',
  Manual: 'Manual',
  Momo: 'Momo',
  Qoo10: 'Qoo10',
  Rakuten: 'Rakuten',
  RakutenFashion: 'RakutenFashion',
  Shopee: 'Shopee',
  Shopify: 'Shopify',
  Shoplist: 'Shoplist',
  Stores: 'Stores',
  SuperDelivery: 'SuperDelivery',
  TikTok: 'TikTok',
  Tokopedia: 'Tokopedia',
  Yahoo: 'Yahoo',
  Zozotown: 'Zozotown'
} as const;

export type SaleChannelType = typeof SaleChannelType[keyof typeof SaleChannelType];
export type SaveDdiProductStatusInput = {
  readonly isListed: Scalars['Boolean']['input'];
  readonly productVariantId: Scalars['Long']['input'];
  readonly warehouseData: ReadonlyArray<SaveDdiProductWarehouseStatusInput>;
};

export type SaveDdiProductStatusListInput = {
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly products: ReadonlyArray<SaveDdiProductStatusInput>;
};

export type SaveDdiProductWarehouseStatusInput = {
  readonly isActive: Scalars['Boolean']['input'];
  readonly warehouse: DdiWarehouse;
};

export type SaveGreetingMessageRequest = {
  readonly enable: Scalars['Boolean']['input'];
  readonly messageItems: ReadonlyArray<LineMessageItemInput>;
  readonly storeId: Scalars['String']['input'];
};

export type SaveMessengerOptInMessageRequestInput = {
  /**
   * Text that appears on call to action button
   * ALLOW – set optin message button text to “Allow messages”
   * GET – set optin message button text to “Get messages”
   * GET_UPDATES – set optin message button text to “Get updates”, this is also default if notification_messages_cta_text is not set
   * OPT_IN – set optin message button text to “Opt in to messages”
   * SIGN_UP – set optin message button text to “Sign up for messages”
   */
  readonly buttonText: OptInCallToActionText;
  readonly enabled: Scalars['Boolean']['input'];
  readonly imageUrl?: InputMaybe<Scalars['Url']['input']>;
  readonly storeId: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
};

export type Schedule = {
  readonly __typename?: 'Schedule';
  readonly datetime: Scalars['OffsetDateTime']['output'];
};

export type SearchListingFilterInput = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelTypes: ReadonlyArray<MarketplaceSaleChannelType>;
};

export type SendBroadcastMessageRequestInput = {
  readonly isTest: Scalars['Boolean']['input'];
  readonly lineBroadcastMessageId: Scalars['String']['input'];
};

export type SendMessengerBroadcastMessageRequestInput = {
  readonly isTest: Scalars['Boolean']['input'];
  readonly messengerBroadcastId: Scalars['String']['input'];
};

export type SendMessengerBroadcastResponse = CompleteSendingMessengerBroadcastSuccess | FailToStartSendingMessengerBroadcastTooManyUnavailableCustomer | StartSendingMessengerBroadcastSuccess;

export const SendVerificationEmailStatus = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
} as const;

export type SendVerificationEmailStatus = typeof SendVerificationEmailStatus[keyof typeof SendVerificationEmailStatus];
export type ServiceCodeInput = {
  readonly dhlServiceCode?: InputMaybe<DhlServiceCode>;
  readonly fedExServiceCode?: InputMaybe<FedExServiceCode>;
  readonly japanPostServiceCode?: InputMaybe<JapanPostServiceCode>;
  readonly upsServiceCode?: InputMaybe<UpsServiceCode>;
};

export type SessionsCsvFilterInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];  
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly saleChannelMarketplace?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type SessionsCsvPayload = {
  readonly __typename?: 'SessionsCSVPayload';
  readonly date: Scalars['LocalDate']['output'];
  readonly sessions: Scalars['Long']['output'];
};

export type SessionsCsvPayloadList = {
  readonly __typename?: 'SessionsCSVPayloadList';
  readonly items: ReadonlyArray<SessionsCsvPayload>;
  readonly meta: ReportListMeta;
};

export type SetCustomerTagsRequest = {
  readonly customerId: Scalars['String']['input'];
  readonly customerTagNames: ReadonlyArray<Scalars['String']['input']>;
};

export type SetProductItem = {
  readonly __typename?: 'SetProductItem';
  readonly productVariantId: Scalars['Long']['output'];
  readonly quantity: Scalars['Int']['output'];
  readonly variantV2: ProductVariantV2;
};

export type SettingGuide = {
  readonly __typename?: 'SettingGuide';
  readonly hasConnectedDatasourceChannel: Scalars['Boolean']['output'];
  readonly hasInputUnitCost: Scalars['Boolean']['output'];
  readonly hasSaleChannelCostIndex: Scalars['Boolean']['output'];
  readonly hasShopifyChannel: Scalars['Boolean']['output'];
};

export type ShipperAddress = {
  readonly __typename?: 'ShipperAddress';
  readonly accountId: Scalars['ID']['output'];
  readonly addressFirst: Scalars['String']['output'];
  readonly addressSecond?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly country: Country;
  readonly default: Scalars['Boolean']['output'];
  readonly email: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly personInCharge: Scalars['String']['output'];
  readonly phone: PhoneNumber;
  readonly postalCode?: Maybe<Scalars['String']['output']>;
  readonly region?: Maybe<Region>;
  readonly websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type ShipperAddressInput = {
  readonly accountId: Scalars['ID']['input'];
  readonly addressFirst: Scalars['String']['input'];
  readonly addressSecond?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly country: Country;
  readonly default?: InputMaybe<Scalars['Boolean']['input']>;
  readonly email: Scalars['String']['input'];
  readonly personInCharge: Scalars['String']['input'];
  readonly phone: PhoneNumberInput;
  readonly postalCode?: InputMaybe<Scalars['String']['input']>;
  readonly provinceId?: InputMaybe<Scalars['String']['input']>;
  readonly websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Shipping = {
  readonly __typename?: 'Shipping';
  readonly boxSizes?: Maybe<ReadonlyArray<ShippingBoxSize>>;
  readonly deliveryDutyPaid: Scalars['Boolean']['output'];
  readonly insurance?: Maybe<AnylogiMoney>;
  readonly orderID: Scalars['ID']['output'];
  readonly shipmentCategory: Category;
  readonly shipperAddressID: Scalars['String']['output'];
  readonly signatureOption: Scalars['Boolean']['output'];
  readonly status: ShippingStatus;
  readonly trackingURL: Scalars['String']['output'];
};

export type ShippingBoxSize = {
  readonly __typename?: 'ShippingBoxSize';
  readonly depth: Scalars['Int']['output'];
  readonly height: Scalars['Int']['output'];
  readonly packageType?: Maybe<PackageType>;
  readonly quantity: Scalars['Int']['output'];
  readonly weight?: Maybe<Scalars['Int']['output']>;
  readonly width: Scalars['Int']['output'];
};

export type ShippingBoxSizeInput = {
  readonly depth: Scalars['Int']['input'];
  readonly height: Scalars['Int']['input'];
  readonly packageType?: InputMaybe<PackageTypeInput>;
  readonly quantity: Scalars['Int']['input'];
  readonly weight?: InputMaybe<Scalars['Int']['input']>;
  readonly width: Scalars['Int']['input'];
};

export type ShippingInput = {
  readonly boxSizes: ReadonlyArray<ShippingBoxSizeInput>;
  readonly category: Category;
  readonly deliveryDutyPaid: Scalars['Boolean']['input'];
  readonly insurance?: InputMaybe<AnylogiMoneyInput>;
  readonly orderId: Scalars['ID']['input'];
  readonly shipperAddressId: Scalars['ID']['input'];
  readonly signatureOption: Scalars['Boolean']['input'];
};

export type ShippingRateDetail = {
  readonly __typename?: 'ShippingRateDetail';
  readonly crossBorderCourierProvider: CrossBorderCourierProvider;
  readonly estimateDeliveryDate?: Maybe<DateRangeResponse>;
  readonly ratedShipmentAlert?: Maybe<ReadonlyArray<RatedShipmentAlert>>;
  readonly serviceCode?: Maybe<CrossBorderServiceCode>;
  readonly totalCharge: AnylogiMoney;
};

export type ShippingRateDetails = {
  readonly __typename?: 'ShippingRateDetails';
  readonly crossBorderCourierProvider: CrossBorderCourierProvider;
  readonly estimateDeliveryDate?: Maybe<DateRangeResponse>;
  readonly ratedShipmentAlert?: Maybe<RatedShipmentAlert>;
  readonly serviceCode?: Maybe<CrossBorderServiceCode>;
  readonly totalCharge: AnylogiMoney;
};

export const ShippingStatus = {
  CANCELLED: 'CANCELLED',
  SHIPPING: 'SHIPPING',
  WAITING: 'WAITING'
} as const;

export type ShippingStatus = typeof ShippingStatus[keyof typeof ShippingStatus];
export type ShopeeChatEvent = CommonChatEvent | ShopeeMessageEvent;

export type ShopeeChatEvents = {
  readonly __typename?: 'ShopeeChatEvents';
  readonly chatEvents: ReadonlyArray<ShopeeChatEvent>;
  readonly chatId: Scalars['String']['output'];
  readonly userName: Scalars['String']['output'];
};

export type ShopeeChatPlatformDetail = {
  readonly __typename?: 'ShopeeChatPlatformDetail';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type ShopeeContinueShopSelectionItem = {
  readonly __typename?: 'ShopeeContinueShopSelectionItem';
  readonly displayName: Scalars['String']['output'];
  readonly shopId: Scalars['Long']['output'];
};

export type ShopeeFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
  readonly type: ShopeeFileUploadType;
};

export const ShopeeFileUploadType = {
  AD_COST_ADS: 'AD_COST_ADS',
  SESSION: 'SESSION'
} as const;

export type ShopeeFileUploadType = typeof ShopeeFileUploadType[keyof typeof ShopeeFileUploadType];
export type ShopeeImageMessageContent = {
  readonly __typename?: 'ShopeeImageMessageContent';
  readonly previewUrl: Scalars['Url']['output'];
  readonly url: Scalars['Url']['output'];
};

export type ShopeeInfo = ChatInfo & {
  readonly __typename?: 'ShopeeInfo';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type ShopeeItemMessageContent = {
  readonly __typename?: 'ShopeeItemMessageContent';
  readonly externalLink: Scalars['Url']['output'];
  readonly isShopItem: Scalars['Boolean']['output'];
  readonly itemImageUrl?: Maybe<Scalars['Url']['output']>;
  readonly itemUrlPath?: Maybe<Scalars['String']['output']>;
  readonly title: Scalars['String']['output'];
};

export type ShopeeLoginUrlV2Input = {
  readonly redirectUri: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export const ShopeeMarketplace = {
  ID: 'ID',
  MY: 'MY',
  PH: 'PH',
  SG: 'SG',
  TH: 'TH',
  TW: 'TW',
  VN: 'VN'
} as const;

export type ShopeeMarketplace = typeof ShopeeMarketplace[keyof typeof ShopeeMarketplace];
export type ShopeeMessage = {
  readonly content: ShopeeMessageContent;
  readonly messageId: Scalars['String']['output'];
};

export type ShopeeMessageContent = ShopeeImageMessageContent | ShopeeItemMessageContent | ShopeeOrderMessageContent | ShopeeStickerMessageContent | ShopeeTextMessageContent | ShopeeVideoMessageContent;

export type ShopeeMessageEvent = {
  readonly __typename?: 'ShopeeMessageEvent';
  readonly id: Scalars['String']['output'];
  readonly message: ShopeeMessage;
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type ShopeeMessageSource = ItemSource | OrderSource;

export type ShopeeOrderMessageContent = {
  readonly __typename?: 'ShopeeOrderMessageContent';
  readonly anyXOrderId?: Maybe<Scalars['Long']['output']>;
  readonly isShopOrder: Scalars['Boolean']['output'];
  readonly itemCount: Scalars['Int']['output'];
  readonly orderImageUrl?: Maybe<Scalars['Url']['output']>;
  readonly shopeeOrderSN: Scalars['String']['output'];
};

export const ShopeeOrderType = {
  ORDERED: 'ORDERED',
  PAID: 'PAID'
} as const;

export type ShopeeOrderType = typeof ShopeeOrderType[keyof typeof ShopeeOrderType];
export type ShopeeOrderedActivity = CustomerActivity & {
  readonly __typename?: 'ShopeeOrderedActivity';
  readonly id: Scalars['String']['output'];
  readonly shopeeOrderId: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type ShopeeSaleChannelData = {
  readonly __typename?: 'ShopeeSaleChannelData';
  readonly expiresAt: Scalars['OffsetDateTime']['output'];
  readonly marketplace: ShopeeMarketplace;
  readonly shopId: Scalars['Long']['output'];
  readonly showReconnectButton: Scalars['Boolean']['output'];
};

export type ShopeeSalesChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly orderType?: InputMaybe<ShopeeOrderType>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ShopeeSalesChartPayload = {
  readonly __typename?: 'ShopeeSalesChartPayload';
  readonly currency: ReportSupportedCurrency;
  readonly orders: ReadonlyArray<Scalars['Map']['output']>;
  readonly sales: ReadonlyArray<Scalars['Map']['output']>;
  readonly salesPerOrder: ReadonlyArray<Scalars['Map']['output']>;
  readonly visitors: ReadonlyArray<Scalars['Map']['output']>;
};

export type ShopeeSalesDetailedReportInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly orderType?: InputMaybe<ShopeeOrderType>;
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ShopeeSalesDetailedReportPayload = {
  readonly __typename?: 'ShopeeSalesDetailedReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly salesPerOrder: Scalars['BigDecimal']['output'];
  readonly visitors: Scalars['Long']['output'];
};

export type ShopeeSalesDetailedReportPayloadList = {
  readonly __typename?: 'ShopeeSalesDetailedReportPayloadList';
  readonly items: ReadonlyArray<ShopeeSalesDetailedReportPayload>;
  readonly meta: ReportListMeta;
  readonly total: ShopeeSalesDetailedReportTotalPayload;
};

export type ShopeeSalesDetailedReportTotalPayload = {
  readonly __typename?: 'ShopeeSalesDetailedReportTotalPayload';
  readonly currency: ReportSupportedCurrency;
  readonly orders: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly salesPerOrder: Scalars['BigDecimal']['output'];
  readonly visitors: Scalars['Long']['output'];
};

export type ShopeeSalesSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly orderType?: InputMaybe<ShopeeOrderType>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ShopeeSalesSummaryPayload = {
  readonly __typename?: 'ShopeeSalesSummaryPayload';
  readonly currency: ReportSupportedCurrency;
  readonly orders: NumericValueWithDiffPayload;
  readonly sales: NumericValueWithDiffPayload;
  readonly salesPerOrder: NumericValueWithDiffPayload;
  readonly visitors: NumericValueWithDiffPayload;
};

export type ShopeeSendImageMessageRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly url: Scalars['Url']['input'];
};

export type ShopeeSendTextMessageRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly text: Scalars['String']['input'];
};

export type ShopeeStickerMessageContent = {
  readonly __typename?: 'ShopeeStickerMessageContent';
  readonly imageUrl: Scalars['Url']['output'];
};

export type ShopeeStoreCsMessage = ShopeeMessage & {
  readonly __typename?: 'ShopeeStoreCSMessage';
  readonly author: BasicUser;
  readonly content: ShopeeMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly messageState: MessageState;
};

export type ShopeeStoreChat = StoreChat & {
  readonly __typename?: 'ShopeeStoreChat';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly shopId: Scalars['Long']['output'];
  readonly tokenUpdatedSuccessfully: Scalars['Boolean']['output'];
  readonly type: ChatType;
};

export type ShopeeTargetInput = {
  readonly type: ShopeeTargetType;
};

export const ShopeeTargetType = {
  CHAT_MESSAGE: 'CHAT_MESSAGE'
} as const;

export type ShopeeTargetType = typeof ShopeeTargetType[keyof typeof ShopeeTargetType];
export type ShopeeTextMessageContent = {
  readonly __typename?: 'ShopeeTextMessageContent';
  readonly text: Scalars['String']['output'];
};

export type ShopeeUserMessage = ShopeeMessage & {
  readonly __typename?: 'ShopeeUserMessage';
  readonly content: ShopeeMessageContent;
  readonly messageId: Scalars['String']['output'];
  readonly source?: Maybe<ShopeeMessageSource>;
};

export type ShopeeVideoMessageContent = {
  readonly __typename?: 'ShopeeVideoMessageContent';
  readonly previewUrl: Scalars['Url']['output'];
  readonly videoUrl: Scalars['Url']['output'];
};

export type ShopifyCarrierService = {
  readonly __typename?: 'ShopifyCarrierService';
  readonly active: Scalars['Boolean']['output'];
  readonly adminGraphqlApiId: Scalars['String']['output'];
  readonly callbackUrl: Scalars['String']['output'];
  readonly carrierServiceType: Scalars['String']['output'];
  readonly channelId: Scalars['ID']['output'];
  readonly format: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly serviceDiscovery: Scalars['Boolean']['output'];
};

export type ShopifyCarrierServiceCondition = {
  readonly __typename?: 'ShopifyCarrierServiceCondition';
  readonly condition: LogisticShippingRateConditionType;
  readonly created: Scalars['Time']['output'];
  readonly id: Scalars['ID']['output'];
  readonly maximumPrice?: Maybe<AnylogiMoney>;
  readonly maximumWeight?: Maybe<Scalars['Float']['output']>;
  readonly minimumPrice?: Maybe<AnylogiMoney>;
  readonly minimumWeight?: Maybe<Scalars['Float']['output']>;
  readonly updated: Scalars['Time']['output'];
};

export type ShopifyCarrierServiceConditionInput = {
  readonly condition: LogisticShippingRateConditionType;
  readonly maximumPrice?: InputMaybe<AnylogiMoneyInput>;
  readonly maximumWeight?: InputMaybe<Scalars['Float']['input']>;
  readonly minimumPrice?: InputMaybe<AnylogiMoneyInput>;
  readonly minimumWeight?: InputMaybe<Scalars['Float']['input']>;
  readonly saleChannelId: Scalars['Long']['input'];
};

export type ShopifyLoginUrlV2Input = {
  readonly redirectUri: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
  readonly storeName: Scalars['String']['input'];
};

export type ShopifyOrderedActivity = CustomerActivity & {
  readonly __typename?: 'ShopifyOrderedActivity';
  readonly id: Scalars['String']['output'];
  readonly shopifyOrderId: Scalars['String']['output'];
  readonly shopifyOrderNumber: Scalars['Long']['output'];
  readonly shopifyOrderUrl: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type ShopifySaleChannelData = {
  readonly __typename?: 'ShopifySaleChannelData';
  readonly domain: Scalars['String']['output'];
};

export type ShopifySalesReportChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ShopifySalesReportChartPayload = {
  readonly __typename?: 'ShopifySalesReportChartPayload';
  readonly currency: ReportSupportedCurrency;
  readonly discounts: ReadonlyArray<Scalars['Map']['output']>;
  readonly grossSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly netSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly orderCount: ReadonlyArray<Scalars['Map']['output']>;
  readonly returns: ReadonlyArray<Scalars['Map']['output']>;
  readonly shipping: ReadonlyArray<Scalars['Map']['output']>;
  readonly taxes: ReadonlyArray<Scalars['Map']['output']>;
  readonly totalSales: ReadonlyArray<Scalars['Map']['output']>;
};

export type ShopifySalesReportSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ShopifySalesReportSummaryPayload = {
  readonly __typename?: 'ShopifySalesReportSummaryPayload';
  readonly currency: ReportSupportedCurrency;
  readonly discounts: NumericValueWithDiffPayload;
  readonly grossSales: NumericValueWithDiffPayload;
  readonly netSales: NumericValueWithDiffPayload;
  readonly orderCount: NumericValueWithDiffPayload;
  readonly returns: NumericValueWithDiffPayload;
  readonly shipping: NumericValueWithDiffPayload;
  readonly taxes: NumericValueWithDiffPayload;
  readonly totalSales: NumericValueWithDiffPayload;
};

export type ShopifySalesReportTableInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type ShopifySalesReportTablePayload = {
  readonly __typename?: 'ShopifySalesReportTablePayload';
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orderCount: Scalars['Long']['output'];
  readonly returns: Scalars['BigDecimal']['output'];
  readonly shipping: Scalars['BigDecimal']['output'];
  readonly taxes: Scalars['BigDecimal']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
};

export type ShopifySalesReportTablePayloadList = {
  readonly __typename?: 'ShopifySalesReportTablePayloadList';
  readonly items: ReadonlyArray<ShopifySalesReportTablePayload>;
  readonly meta: ReportListMeta;
  readonly total: ShopifySalesReportTotalPayload;
};

export type ShopifySalesReportTotalPayload = {
  readonly __typename?: 'ShopifySalesReportTotalPayload';
  readonly currency: ReportSupportedCurrency;
  readonly discounts: Scalars['BigDecimal']['output'];
  readonly grossSales: Scalars['BigDecimal']['output'];
  readonly netSales: Scalars['BigDecimal']['output'];
  readonly orderCount: Scalars['Long']['output'];
  readonly returns: Scalars['BigDecimal']['output'];
  readonly shipping: Scalars['BigDecimal']['output'];
  readonly taxes: Scalars['BigDecimal']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
};

export type ShopifySpecificData = {
  readonly __typename?: 'ShopifySpecificData';
  readonly orderUrl: Scalars['URL']['output'];
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
};

export const ShopifySupportedCountryCode = {
  AC: 'AC',
  AD: 'AD',
  AE: 'AE',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AO: 'AO',
  AR: 'AR',
  AT: 'AT',
  AU: 'AU',
  AW: 'AW',
  AX: 'AX',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BE: 'BE',
  BF: 'BF',
  BG: 'BG',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BL: 'BL',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BQ: 'BQ',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CC: 'CC',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CH: 'CH',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CN: 'CN',
  CO: 'CO',
  CR: 'CR',
  CV: 'CV',
  CW: 'CW',
  CX: 'CX',
  CY: 'CY',
  CZ: 'CZ',
  DE: 'DE',
  DJ: 'DJ',
  DK: 'DK',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EE: 'EE',
  EG: 'EG',
  EH: 'EH',
  ER: 'ER',
  ES: 'ES',
  ET: 'ET',
  FI: 'FI',
  FJ: 'FJ',
  FK: 'FK',
  FO: 'FO',
  FR: 'FR',
  GA: 'GA',
  GB: 'GB',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GR: 'GR',
  GS: 'GS',
  GT: 'GT',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HN: 'HN',
  HR: 'HR',
  HT: 'HT',
  HU: 'HU',
  ID: 'ID',
  IE: 'IE',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IO: 'IO',
  IQ: 'IQ',
  IR: 'IR',
  IS: 'IS',
  IT: 'IT',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  JP: 'JP',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KO: 'KO',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LI: 'LI',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LT: 'LT',
  LU: 'LU',
  LV: 'LV',
  LY: 'LY',
  MA: 'MA',
  MC: 'MC',
  MD: 'MD',
  ME: 'ME',
  MF: 'MF',
  MG: 'MG',
  MH: 'MH',
  MK: 'MK',
  ML: 'ML',
  MM: 'MM',
  MN: 'MN',
  MO: 'MO',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MT: 'MT',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NF: 'NF',
  NG: 'NG',
  NI: 'NI',
  NL: 'NL',
  NO: 'NO',
  NP: 'NP',
  NR: 'NR',
  NU: 'NU',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PL: 'PL',
  PM: 'PM',
  PN: 'PN',
  PS: 'PS',
  PT: 'PT',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RS: 'RS',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SD: 'SD',
  SE: 'SE',
  SG: 'SG',
  SH: 'SH',
  SI: 'SI',
  SJ: 'SJ',
  SK: 'SK',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  SS: 'SS',
  ST: 'ST',
  SV: 'SV',
  SX: 'SX',
  SZ: 'SZ',
  TC: 'TC',
  TD: 'TD',
  TF: 'TF',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TK: 'TK',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  UM: 'UM',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VN: 'VN',
  VU: 'VU',
  WF: 'WF',
  WS: 'WS',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW'
} as const;

export type ShopifySupportedCountryCode = typeof ShopifySupportedCountryCode[keyof typeof ShopifySupportedCountryCode];
export type ShoplistFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: ShoplistFileUploadType;
};

export const ShoplistFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type ShoplistFileUploadType = typeof ShoplistFileUploadType[keyof typeof ShoplistFileUploadType];
export type SingleVariant = {
  readonly __typename?: 'SingleVariant';
  readonly currency: CurrencyCode;
  readonly id: Scalars['Long']['output'];
  readonly price: Scalars['BigDecimal']['output'];
  readonly sellerSKUCode?: Maybe<Scalars['String']['output']>;
};

export type Sku = {
  readonly __typename?: 'Sku';
  readonly brand?: Maybe<Brand>;
  readonly code: Scalars['String']['output'];
  readonly content: Scalars['String']['output'];
  readonly created: Scalars['Time']['output'];
  readonly gtin: Scalars['String']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly inventoryFree?: Maybe<Scalars['Int']['output']>;
  readonly mdAccount?: Maybe<MdAccount>;
  readonly mdStore?: Maybe<MdStore>;
  readonly name: Scalars['String']['output'];
  readonly price?: Maybe<AnylogiMoney>;
  readonly productVariantId?: Maybe<Scalars['Long']['output']>;
  readonly skuExternalId?: Maybe<Scalars['Long']['output']>;
  readonly supplier?: Maybe<SupplierName>;
  readonly type: SkuType;
};

export type SkuDetail = {
  readonly __typename?: 'SkuDetail';
  readonly brand?: Maybe<Brand>;
  readonly code: Scalars['String']['output'];
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly content: Scalars['String']['output'];
  readonly costPerItem?: Maybe<AnylogiMoney>;
  readonly countryOfOrigin?: Maybe<Country>;
  readonly courierType?: Maybe<CourierType>;
  readonly created: Scalars['Time']['output'];
  readonly freeStock?: Maybe<Scalars['Int']['output']>;
  readonly gtin: Scalars['String']['output'];
  readonly height?: Maybe<Scalars['Float']['output']>;
  readonly hsCode?: Maybe<Scalars['String']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly inUse: Scalars['Boolean']['output'];
  readonly inUseReasons?: Maybe<ReadonlyArray<InUseSkuReason>>;
  readonly length?: Maybe<Scalars['Float']['output']>;
  readonly mdAccount?: Maybe<MdAccount>;
  readonly mdStore?: Maybe<MdStore>;
  readonly name: Scalars['String']['output'];
  readonly price?: Maybe<AnylogiMoney>;
  readonly productVariantId?: Maybe<Scalars['Long']['output']>;
  readonly products?: Maybe<ReadonlyArray<SkuForSetDetailed>>;
  readonly size?: Maybe<Scalars['String']['output']>;
  readonly sizeCoefficient?: Maybe<Scalars['Int']['output']>;
  readonly skuExternalId?: Maybe<Scalars['Long']['output']>;
  readonly supplier?: Maybe<SupplierName>;
  readonly sync?: Maybe<Scalars['Boolean']['output']>;
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly tax?: Maybe<AnylogiMoney>;
  readonly type: SkuType;
  readonly weight?: Maybe<Scalars['Float']['output']>;
  readonly width?: Maybe<Scalars['Float']['output']>;
};

export type SkuFilters = {
  readonly accountId?: InputMaybe<Scalars['ID']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['ID']['input']>;
  readonly supplierId?: InputMaybe<Scalars['ID']['input']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly type?: InputMaybe<SkuType>;
};

export type SkuForSet = {
  readonly productVariantId: Scalars['Long']['input'];
  readonly quantity: Scalars['Int']['input'];
};

export type SkuForSetDetailed = {
  readonly __typename?: 'SkuForSetDetailed';
  readonly code: Scalars['String']['output'];
  readonly gtin: Scalars['String']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly productVariantId?: Maybe<Scalars['Long']['output']>;
  readonly quantity: Scalars['Int']['output'];
  readonly skuExternalId?: Maybe<Scalars['Long']['output']>;
};

export type SkuPaginated = Paginated & {
  readonly __typename?: 'SkuPaginated';
  readonly page: Scalars['Int']['output'];
  readonly records: ReadonlyArray<Sku>;
  readonly total: Scalars['Int']['output'];
};

export type SkuTags = {
  readonly __typename?: 'SkuTags';
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

export const SkuType = {
  SET: 'SET',
  SINGLE: 'SINGLE'
} as const;

export type SkuType = typeof SkuType[keyof typeof SkuType];
export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
export const SpecialServiceType = {
  ETD_SIGNATURE_IMAGE_IS_NOT_SUPPORTED: 'ETD_SIGNATURE_IMAGE_IS_NOT_SUPPORTED',
  ETD_SIGNATURE_IMAGE_IS_NOT_UPLOADED: 'ETD_SIGNATURE_IMAGE_IS_NOT_UPLOADED'
} as const;

export type SpecialServiceType = typeof SpecialServiceType[keyof typeof SpecialServiceType];
export type SpecificChatPlatformDetail = InstagramChatPlatformDetail | LazadaChatPlatformDetail | LineChatPlatformDetail | MessengerChatPlatformDetail | ShopeeChatPlatformDetail | TikTokShopChatPlatformDetail | WhatsAppChatPlatformDetail;

export type StartSendingMessengerBroadcastSuccess = {
  readonly __typename?: 'StartSendingMessengerBroadcastSuccess';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type StaticSticker = {
  readonly __typename?: 'StaticSticker';
  readonly url: Scalars['String']['output'];
};

export type StatusOk = {
  readonly __typename?: 'StatusOk';
  readonly ok: Scalars['Boolean']['output'];
};

export const StockType = {
  ALLOCATING: 'ALLOCATING',
  ARRIVING: 'ARRIVING',
  DAMAGED: 'DAMAGED',
  FREE: 'FREE',
  PREPARING: 'PREPARING',
  WAREHOUSING: 'WAREHOUSING'
} as const;

export type StockType = typeof StockType[keyof typeof StockType];
export type StoreChannelShopifyConnectAdminRequest = {
  readonly accessToken: Scalars['String']['input'];
  readonly storeDomain: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type StoreChannelShopifyConnectRequest = {
  readonly redirectedUrl: Scalars['String']['input'];
};

export type StoreChannelShopifyConnectResponse = {
  readonly __typename?: 'StoreChannelShopifyConnectResponse';
  readonly storeChannelId: Scalars['String']['output'];
  readonly storeId: Scalars['String']['output'];
};

export type StoreChannelShopifyOauthUrlGenerateResponse = {
  readonly __typename?: 'StoreChannelShopifyOauthUrlGenerateResponse';
  readonly oauthUrl: Scalars['String']['output'];
};

export type StoreChat = {
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly type: ChatType;
};

export type StoreChatLineConnectRequestV2 = {
  readonly channelId: Scalars['String']['input'];
  readonly channelSecret: Scalars['String']['input'];
  readonly keyId: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type StoreChatLineConnectResponseV2 = {
  readonly __typename?: 'StoreChatLineConnectResponseV2';
  readonly storeId: Scalars['String']['output'];
};

export type StoreChatLinePublicKeyGenerateRequestV2 = {
  readonly storeId: Scalars['String']['input'];
};

export type StoreChatLinePublicKeyGenerateResponseV2 = {
  readonly __typename?: 'StoreChatLinePublicKeyGenerateResponseV2';
  readonly publicKeyJson: Scalars['String']['output'];
};

export type StoreChatTemplatePlainMessageAddRequest = {
  readonly plainText: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
};

export type StoreChatTemplatePlainMessageUpdateRequest = {
  readonly id: Scalars['String']['input'];
  readonly plainText: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
};

export type StoreChatTemplatePlainMessagesDeleteRequest = {
  readonly ids: ReadonlyArray<Scalars['String']['input']>;
  readonly storeId: Scalars['String']['input'];
};

export type StoreChatTemplatePlainMessagesRequest = {
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly limit: Scalars['Int']['input'];
  readonly offset: Scalars['Int']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type StoreList = {
  readonly __typename?: 'StoreList';
  readonly items: ReadonlyArray<MdStore>;
  readonly length: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export const StoreSortByField = {
  CREATED_AT: 'CREATED_AT',
  ID: 'ID',
  NAME: 'NAME',
  UPDATED_AT: 'UPDATED_AT'
} as const;

export type StoreSortByField = typeof StoreSortByField[keyof typeof StoreSortByField];
export type StoreWithChanelFilterInput = {
  readonly channelType?: InputMaybe<MarketplaceSaleChannelType>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
};

export type StoresFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: StoresFileUploadType;
};

export const StoresFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type StoresFileUploadType = typeof StoresFileUploadType[keyof typeof StoresFileUploadType];
export type Story = {
  readonly __typename?: 'Story';
  readonly contentType?: Maybe<Scalars['String']['output']>;
  readonly originalUrl: Scalars['Url']['output'];
};

export type StoryMentionContent = {
  readonly __typename?: 'StoryMentionContent';
  readonly story: Story;
};

export type Subscription = {
  readonly __typename?: 'Subscription';
  /** [ADMIN, STAFF, MERCHANT] */
  readonly LineChatEventSub?: Maybe<LineChatEvent>;
};


export type SubscriptionLineChatEventSubArgs = {
  input: GetLineChatEventSubRequest;
};

export type SuperDeliveryFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: SuperDeliveryFileUploadType;
};

export const SuperDeliveryFileUploadType = {
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type SuperDeliveryFileUploadType = typeof SuperDeliveryFileUploadType[keyof typeof SuperDeliveryFileUploadType];
export type Supplier = {
  readonly __typename?: 'Supplier';
  readonly country?: Maybe<Country>;
  readonly created: Scalars['Time']['output'];
  readonly id: Scalars['ID']['output'];
  readonly mdAccount?: Maybe<MdAccount>;
  readonly name: Scalars['String']['output'];
  readonly users: Scalars['Int']['output'];
};

export type SupplierDetailed = {
  readonly __typename?: 'SupplierDetailed';
  readonly addressFirst?: Maybe<Scalars['String']['output']>;
  readonly addressSecond?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Country>;
  readonly id: Scalars['ID']['output'];
  readonly mdAccount?: Maybe<MdAccount>;
  readonly name: Scalars['String']['output'];
  readonly phoneNumber?: Maybe<PhoneNumber>;
  readonly postalCode?: Maybe<Scalars['String']['output']>;
  readonly provinceId?: Maybe<Scalars['String']['output']>;
  readonly supportEmail?: Maybe<Scalars['String']['output']>;
};

export type SupplierFilters = {
  readonly accountId?: InputMaybe<Scalars['Long']['input']>;
  readonly country?: InputMaybe<Country>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierName = {
  readonly __typename?: 'SupplierName';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

export type SuppliersPaginated = Paginated & {
  readonly __typename?: 'SuppliersPaginated';
  readonly page: Scalars['Int']['output'];
  readonly records: ReadonlyArray<Supplier>;
  readonly total: Scalars['Int']['output'];
};

export type SystemAutoAssignAi = {
  readonly __typename?: 'SystemAutoAssignAI';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type SystemAutoUnassignAi = {
  readonly __typename?: 'SystemAutoUnassignAI';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export const Temperature = {
  KEEP_FROZEN: 'KEEP_FROZEN',
  KEEP_REFRIGERATED: 'KEEP_REFRIGERATED',
  NORMAL: 'NORMAL'
} as const;

export type Temperature = typeof Temperature[keyof typeof Temperature];
export type TemplatePlainMessage = {
  readonly __typename?: 'TemplatePlainMessage';
  readonly createdAt: Scalars['UnixTimestamp']['output'];
  readonly id: Scalars['String']['output'];
  readonly plainText: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type TemplatePlainMessages = {
  readonly __typename?: 'TemplatePlainMessages';
  readonly messages: ReadonlyArray<TemplatePlainMessage>;
  readonly total: Scalars['Int']['output'];
};

export type TextComponent = {
  readonly __typename?: 'TextComponent';
  readonly fontSize: FontSize;
  readonly text: Scalars['String']['output'];
  readonly weighted: Scalars['Boolean']['output'];
};

export type TextComponentInput = {
  readonly fontSize: FontSize;
  readonly text: Scalars['String']['input'];
  readonly weighted: Scalars['Boolean']['input'];
};

export type TextLiveCommentContent = {
  readonly __typename?: 'TextLiveCommentContent';
  readonly text: Scalars['String']['output'];
};

export type TextMessageContent = {
  readonly __typename?: 'TextMessageContent';
  readonly text: Scalars['String']['output'];
};

export type TikTokAdsAdAccountPayload = {
  readonly __typename?: 'TikTokAdsAdAccountPayload';
  readonly advertiserId: Scalars['String']['output'];
  readonly advertiserName: Scalars['String']['output'];
};

export type TikTokAdsAdAccountPayloadList = {
  readonly __typename?: 'TikTokAdsAdAccountPayloadList';
  readonly items: ReadonlyArray<TikTokAdsAdAccountPayload>;
  readonly meta: ReportListMeta;
};

export type TikTokExchangeCodePayload = {
  readonly __typename?: 'TikTokExchangeCodePayload';
  readonly datasourceTokenId: Scalars['Long']['output'];
};

export type TikTokFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
  readonly type: TikTokFileUploadType;
};

export const TikTokFileUploadType = {
  SESSION: 'SESSION'
} as const;

export type TikTokFileUploadType = typeof TikTokFileUploadType[keyof typeof TikTokFileUploadType];
export type TikTokLoginUrlV2Input = {
  readonly redirectUri: Scalars['String']['input'];
  readonly region: TikTokRegion;
  readonly storeId: Scalars['Long']['input'];
};

export const TikTokRegion = {
  GB: 'GB',
  ID: 'ID',
  MY: 'MY',
  PH: 'PH',
  SG: 'SG',
  TH: 'TH',
  VN: 'VN'
} as const;

export type TikTokRegion = typeof TikTokRegion[keyof typeof TikTokRegion];
export type TikTokSaleChannelData = {
  readonly __typename?: 'TikTokSaleChannelData';
  readonly marketplace: TikTokRegion;
};

export type TikTokSalesReportChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type TikTokSalesReportChartPayload = {
  readonly __typename?: 'TikTokSalesReportChartPayload';
  readonly currency: ReportSupportedCurrency;
  readonly itemsOrdered: ReadonlyArray<Scalars['Map']['output']>;
  readonly sales: ReadonlyArray<Scalars['Map']['output']>;
  readonly unitsOrdered: ReadonlyArray<Scalars['Map']['output']>;
};

export type TikTokSalesReportDetailedReportInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type TikTokSalesReportDetailedReportPayload = {
  readonly __typename?: 'TikTokSalesReportDetailedReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly itemsOrdered: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly unitsOrdered: Scalars['Long']['output'];
};

export type TikTokSalesReportDetailedReportPayloadList = {
  readonly __typename?: 'TikTokSalesReportDetailedReportPayloadList';
  readonly items: ReadonlyArray<TikTokSalesReportDetailedReportPayload>;
  readonly meta: ReportListMeta;
  readonly total: TikTokSalesReportDetailedReportTotalPayload;
};

export type TikTokSalesReportDetailedReportTotalPayload = {
  readonly __typename?: 'TikTokSalesReportDetailedReportTotalPayload';
  readonly currency: ReportSupportedCurrency;
  readonly itemsOrdered: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
  readonly unitsOrdered: Scalars['Long']['output'];
};

export type TikTokSalesReportSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type TikTokSalesReportSummaryPayload = {
  readonly __typename?: 'TikTokSalesReportSummaryPayload';
  readonly currency: ReportSupportedCurrency;
  readonly itemsOrdered: NumericValueWithDiffPayload;
  readonly sales: NumericValueWithDiffPayload;
  readonly unitsOrdered: NumericValueWithDiffPayload;
};

export type TikTokShopChatPlatformDetail = {
  readonly __typename?: 'TikTokShopChatPlatformDetail';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type TikTokShopInfo = ChatInfo & {
  readonly __typename?: 'TikTokShopInfo';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type TikTokShopStoreChat = StoreChat & {
  readonly __typename?: 'TikTokShopStoreChat';
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly tokenUpdatedSuccessfully: Scalars['Boolean']['output'];
  readonly type: ChatType;
};

export type TimeInput = {
  readonly hour: Scalars['Int']['input'];
  readonly minute: Scalars['Int']['input'];
};

export type TokenMissingScope = {
  readonly __typename?: 'TokenMissingScope';
  readonly scopes: ReadonlyArray<Scalars['String']['output']>;
};

export type TokopediaFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId: Scalars['Long']['input'];
  readonly type: TokopediaFileUploadType;
};

export const TokopediaFileUploadType = {
  AD_COST_ADS: 'AD_COST_ADS',
  INVENTORY_REPORT: 'INVENTORY_REPORT',
  SALES_REPORT: 'SALES_REPORT',
  SESSION: 'SESSION'
} as const;

export type TokopediaFileUploadType = typeof TokopediaFileUploadType[keyof typeof TokopediaFileUploadType];
export type TokopediaSaleChannelCreateInput = {
  readonly domain: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export type TokopediaSaleChannelData = {
  readonly __typename?: 'TokopediaSaleChannelData';
  readonly domain: Scalars['String']['output'];
};

export type TokopediaSaleChannelReconnectInput = {
  readonly saleChannelId: Scalars['Long']['input'];
};

export type TokopediaSalesChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type TokopediaSalesChartPayload = {
  readonly __typename?: 'TokopediaSalesChartPayload';
  readonly currency: ReportSupportedCurrency;
  readonly orders: ReadonlyArray<Scalars['Map']['output']>;
  readonly sales: ReadonlyArray<Scalars['Map']['output']>;
};

export type TokopediaSalesDetailedReportInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type TokopediaSalesDetailedReportPayload = {
  readonly __typename?: 'TokopediaSalesDetailedReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
};

export type TokopediaSalesDetailedReportPayloadList = {
  readonly __typename?: 'TokopediaSalesDetailedReportPayloadList';
  readonly items: ReadonlyArray<TokopediaSalesDetailedReportPayload>;
  readonly meta: ReportListMeta;
  readonly total: TokopediaSalesDetailedReportTotalPayload;
};

export type TokopediaSalesDetailedReportTotalPayload = {
  readonly __typename?: 'TokopediaSalesDetailedReportTotalPayload';
  readonly currency: ReportSupportedCurrency;
  readonly orders: Scalars['Long']['output'];
  readonly sales: Scalars['BigDecimal']['output'];
};

export type TokopediaSalesSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type TokopediaSalesSummaryPayload = {
  readonly __typename?: 'TokopediaSalesSummaryPayload';
  readonly currency: ReportSupportedCurrency;
  readonly orders: NumericValueWithDiffPayload;
  readonly sales: NumericValueWithDiffPayload;
};

export type TrackingUrl = {
  readonly __typename?: 'TrackingUrl';
  readonly originalUrl: Scalars['Url']['output'];
  readonly shortUrl?: Maybe<Scalars['Url']['output']>;
  readonly trackingUrl: Scalars['Url']['output'];
};

export type TrendChart = {
  readonly __typename?: 'TrendChart';
  readonly data: ReadonlyArray<TrendChartData>;
  readonly total: Scalars['Int']['output'];
};

export type TrendChartData = {
  readonly __typename?: 'TrendChartData';
  readonly date: Scalars['Time']['output'];
  readonly value: Scalars['Int']['output'];
};

export type UpsAccountCharacteristicsInput = {
  readonly customerClassification: CustomerClassificationType;
  readonly customerName: CustomerName;
  readonly taxId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsAccountDetails = {
  readonly __typename?: 'UPSAccountDetails';
  readonly upsAccountNumber?: Maybe<Scalars['String']['output']>;
};

export type UpsAddress = {
  readonly addressFirst: Scalars['String']['input'];
  readonly addressSecond?: InputMaybe<Scalars['String']['input']>;
  readonly addressType: UpsAddressType;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country: Country;
  readonly postalCode?: InputMaybe<Scalars['String']['input']>;
  readonly province?: InputMaybe<Scalars['String']['input']>;
};

export const UpsAddressType = {
  BUSINESS: 'BUSINESS'
} as const;

export type UpsAddressType = typeof UpsAddressType[keyof typeof UpsAddressType];
export type UpsBillingInformation = {
  readonly billingAddress: UpsAddress;
  readonly billingContactName: CustomerName;
  readonly billingEmail: Scalars['String']['input'];
  readonly billingPhone: UpsPhone;
};

export const UpsContactType = {
  BUSINESS: 'BUSINESS'
} as const;

export type UpsContactType = typeof UpsContactType[keyof typeof UpsContactType];
export type UpsOpenAccountInput = {
  readonly accountCharacteristics: UpsAccountCharacteristicsInput;
  readonly address: UpsAddress;
  readonly billingInformation: UpsBillingInformation;
  readonly businessName: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly merchantId: Scalars['String']['input'];
  readonly phone: UpsPhone;
  readonly pickupInformation: UpsPickupInformation;
};

export type UpsPath = {
  readonly __typename?: 'UPSPath';
  readonly courierID: Scalars['ID']['output'];
  readonly url: Scalars['String']['output'];
};

export type UpsPhone = {
  readonly countryCode: Country;
  readonly number: Scalars['String']['input'];
  readonly phoneType?: InputMaybe<UpsPhoneType>;
};

export const UpsPhoneType = {
  BUSINESS: 'BUSINESS'
} as const;

export type UpsPhoneType = typeof UpsPhoneType[keyof typeof UpsPhoneType];
export type UpsPickupInformation = {
  readonly pickupAddress: UpsAddress;
  readonly pickupContactName: CustomerName;
  readonly pickupEmail: Scalars['String']['input'];
  readonly pickupPhone: UpsPhone;
};

export const UpsPickupServiceCode = {
  ECONOMY_MAIL_INNOVATIONS: 'ECONOMY_MAIL_INNOVATIONS',
  EXPEDITED: 'EXPEDITED',
  EXPEDITED_MAIL_INNOVATIONS: 'EXPEDITED_MAIL_INNOVATIONS',
  EXPRESS: 'EXPRESS',
  EXPRESS_PLUS: 'EXPRESS_PLUS',
  FIRST_CLASS_MAIL: 'FIRST_CLASS_MAIL',
  GROUND: 'GROUND',
  MAIL_INNOVATIONS_MI_RETURNS: 'MAIL_INNOVATIONS_MI_RETURNS',
  NEXT_DAY_AIR: 'NEXT_DAY_AIR',
  NEXT_DAY_AIR_SAVER: 'NEXT_DAY_AIR_SAVER',
  PRIORITY_MAIL: 'PRIORITY_MAIL',
  PRIORITY_MAIL_INNOVATIONS: 'PRIORITY_MAIL_INNOVATIONS',
  UPS_ACCESS_POINT_ECONOMY: 'UPS_ACCESS_POINT_ECONOMY',
  UPS_EXPRESS_12_00: 'UPS_EXPRESS_12_00',
  UPS_HEAVY_GOODS: 'UPS_HEAVY_GOODS',
  UPS_NEXT_DAY_AIR_EARLY: 'UPS_NEXT_DAY_AIR_EARLY',
  UPS_SAVER: 'UPS_SAVER',
  UPS_STANDARD: 'UPS_STANDARD',
  UPS_TODAY_DEDICATED_COURIER: 'UPS_TODAY_DEDICATED_COURIER',
  UPS_TODAY_EXPRESS: 'UPS_TODAY_EXPRESS',
  UPS_TODAY_EXPRESS_SAVER: 'UPS_TODAY_EXPRESS_SAVER',
  UPS_TODAY_INTERCITY: 'UPS_TODAY_INTERCITY',
  UPS_TODAY_STANDARD: 'UPS_TODAY_STANDARD',
  UPS_WORLDWIDE_ECONOMY_DDP: 'UPS_WORLDWIDE_ECONOMY_DDP',
  UPS_WORLDWIDE_ECONOMY_DDU: 'UPS_WORLDWIDE_ECONOMY_DDU',
  UPS_WORLDWIDE_EXPRESS_FREIGHT: 'UPS_WORLDWIDE_EXPRESS_FREIGHT',
  UPS_WORLDWIDE_EXPRESS_FREIGHT_MIDDAY: 'UPS_WORLDWIDE_EXPRESS_FREIGHT_MIDDAY',
  _2ND_DAY_AIR: '_2ND_DAY_AIR',
  _2ND_DAY_AIR_AM: '_2ND_DAY_AIR_AM',
  _3_DAY_SELECT: '_3_DAY_SELECT'
} as const;

export type UpsPickupServiceCode = typeof UpsPickupServiceCode[keyof typeof UpsPickupServiceCode];
export type UpsPickupServiceCodeWrapper = {
  readonly __typename?: 'UPSPickupServiceCodeWrapper';
  readonly upsPickupServiceCode?: Maybe<UpsPickupServiceCode>;
};

export type UpsRegisterCustomerInput = {
  readonly accountId: Scalars['String']['input'];
  readonly address: UpsAddress;
  readonly contact: Contact;
  readonly userIdentity: UserIdentity;
};

export const UpsServiceCode = {
  ECONOMY_MAIL_INNOVATIONS: 'ECONOMY_MAIL_INNOVATIONS',
  EXPEDITED: 'EXPEDITED',
  EXPEDITED_MAIL_INNOVATIONS: 'EXPEDITED_MAIL_INNOVATIONS',
  EXPRESS: 'EXPRESS',
  EXPRESS_PLUS: 'EXPRESS_PLUS',
  FIRST_CLASS_MAIL: 'FIRST_CLASS_MAIL',
  GROUND: 'GROUND',
  MAIL_INNOVATIONS_MI_RETURNS: 'MAIL_INNOVATIONS_MI_RETURNS',
  NEXT_DAY_AIR: 'NEXT_DAY_AIR',
  NEXT_DAY_AIR_SAVER: 'NEXT_DAY_AIR_SAVER',
  PRIORITY_MAIL: 'PRIORITY_MAIL',
  PRIORITY_MAIL_INNOVATIONS: 'PRIORITY_MAIL_INNOVATIONS',
  UPS_ACCESS_POINT_ECONOMY: 'UPS_ACCESS_POINT_ECONOMY',
  UPS_EXPRESS_12_00: 'UPS_EXPRESS_12_00',
  UPS_HEAVY_GOODS: 'UPS_HEAVY_GOODS',
  UPS_NEXT_DAY_AIR_EARLY: 'UPS_NEXT_DAY_AIR_EARLY',
  UPS_SAVER: 'UPS_SAVER',
  UPS_STANDARD: 'UPS_STANDARD',
  UPS_TODAY_DEDICATED_COURIER: 'UPS_TODAY_DEDICATED_COURIER',
  UPS_TODAY_EXPRESS: 'UPS_TODAY_EXPRESS',
  UPS_TODAY_EXPRESS_SAVER: 'UPS_TODAY_EXPRESS_SAVER',
  UPS_TODAY_INTERCITY: 'UPS_TODAY_INTERCITY',
  UPS_TODAY_STANDARD: 'UPS_TODAY_STANDARD',
  UPS_WORLDWIDE_ECONOMY_DDP: 'UPS_WORLDWIDE_ECONOMY_DDP',
  UPS_WORLDWIDE_ECONOMY_DDU: 'UPS_WORLDWIDE_ECONOMY_DDU',
  UPS_WORLDWIDE_EXPRESS_FREIGHT: 'UPS_WORLDWIDE_EXPRESS_FREIGHT',
  UPS_WORLDWIDE_EXPRESS_FREIGHT_MIDDAY: 'UPS_WORLDWIDE_EXPRESS_FREIGHT_MIDDAY',
  _2ND_DAY_AIR: '_2ND_DAY_AIR',
  _2ND_DAY_AIR_AM: '_2ND_DAY_AIR_AM',
  _3_DAY_SELECT: '_3_DAY_SELECT'
} as const;

export type UpsServiceCode = typeof UpsServiceCode[keyof typeof UpsServiceCode];
export type UpsServiceCodeWrapper = {
  readonly __typename?: 'UPSServiceCodeWrapper';
  readonly upsServiceCode?: Maybe<UpsServiceCode>;
};

export type UnarchiveProductVariantsOutput = {
  readonly __typename?: 'UnarchiveProductVariantsOutput';
  readonly ok: Scalars['Boolean']['output'];
};

export type UnitMessage = {
  readonly __typename?: 'UnitMessage';
  readonly content: ReadonlyArray<LineMessage>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type UnknownErrorConnectLazadaError = {
  readonly __typename?: 'UnknownErrorConnectLazadaError';
  readonly message: Scalars['String']['output'];
};

export type UnknownErrorConnectToFacebookError = {
  readonly __typename?: 'UnknownErrorConnectToFacebookError';
  readonly message: Scalars['String']['output'];
};

export type UnknownErrorConnectToWhatsAppError = {
  readonly __typename?: 'UnknownErrorConnectToWhatsAppError';
  readonly message: Scalars['String']['output'];
};

export type UnknownErrorRefreshTokenError = {
  readonly __typename?: 'UnknownErrorRefreshTokenError';
  readonly message: Scalars['String']['output'];
};

export type UnscheduleMessageRequestInput = {
  readonly lineBroadcastMessageId: Scalars['String']['input'];
};

export type UnscheduleMessengerMessageRequestInput = {
  readonly messengerBroadcastId: Scalars['String']['input'];
};

export type UnsupportedContent = {
  readonly __typename?: 'UnsupportedContent';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateAutomationFlowRequest = {
  readonly enabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['String']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly replyContents?: InputMaybe<ReadonlyArray<AutomationFlowContentInput>>;
  readonly sendWhen?: InputMaybe<LiveCommentAutoReplyTiming>;
  readonly trigger?: InputMaybe<AutomationFlowTriggerInput>;
};

export type UpdateCustomerTagRequest = {
  readonly customerTagId: Scalars['String']['input'];
  readonly customerTagName: Scalars['String']['input'];
  readonly mergeWithExistingTag: Scalars['Boolean']['input'];
};

export type UpdateManualCostInput = {
  readonly amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly category: CostCategory;
  readonly currency?: InputMaybe<ReportSupportedCurrency>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly id: Scalars['Long']['input'];
  readonly name: Scalars['String']['input'];
  readonly percentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly type: ManualCostType;
};

export type UpdateManualOrderInput = {
  readonly billingAddress: OrderAddressInput;
  readonly customer: OrderCustomerInput;
  readonly customerNote: Scalars['String']['input'];
  readonly deliveryStatus?: InputMaybe<AnyXDeliveryStatus>;
  readonly discount: Scalars['BigDecimal']['input'];
  readonly id: Scalars['Long']['input'];
  /** only for logistics connected store */
  readonly logisticsInput?: InputMaybe<LogisticsInput>;
  readonly orderProductsV2: ReadonlyArray<OrderProductInputV2Input>;
  readonly paymentStatus: PaymentStatus;
  readonly shipping: Scalars['BigDecimal']['input'];
  readonly shippingAddress: OrderAddressInput;
  readonly totalTax: Scalars['BigDecimal']['input'];
};

export type UpdateMarketplaceCalendarEventInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly id: Scalars['Long']['input'];
  readonly masterDataStoreId?: InputMaybe<Scalars['Long']['input']>;
  readonly saleChannelCountry?: InputMaybe<Scalars['String']['input']>;
  readonly saleChannelType: SaleChannelType;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly target?: InputMaybe<MarketplaceCalendarEventTargetInput>;
  readonly title: Scalars['String']['input'];
};

export type UpdateMultipleProductVariantInput = {
  readonly price?: InputMaybe<GraphQlMoneyInput>;
  /** use it as unique identifier */
  readonly productCode: Scalars['String']['input'];
  readonly unitCost?: InputMaybe<GraphQlMoneyInput>;
};

export type UpdateProductVariantV3Input = {
  readonly countryOfOrigin?: InputMaybe<Scalars['String']['input']>;
  readonly hsCode?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['Long']['input'];
  readonly kerryInventorySync?: InputMaybe<Scalars['Boolean']['input']>;
  /** only for logistics connected store */
  readonly logistics?: InputMaybe<ProductLogisticsInput>;
  readonly media: ReadonlyArray<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly price?: InputMaybe<GraphQlMoneyInput>;
  readonly setItems: ReadonlyArray<ProductVariantSetItemInput>;
  readonly tags: ReadonlyArray<Scalars['String']['input']>;
  readonly unitCost?: InputMaybe<GraphQlMoneyInput>;
  readonly weightInGrams?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export type UpdateProductWithMultiVariantsInput = {
  readonly description: Scalars['String']['input'];
  readonly id: Scalars['Long']['input'];
  readonly media: ReadonlyArray<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly variants: ReadonlyArray<UpdateMultipleProductVariantInput>;
};

export type UpdateProductWithSingleVariantInput = {
  readonly description: Scalars['String']['input'];
  readonly id: Scalars['Long']['input'];
  readonly media: ReadonlyArray<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly variant: UpdateSingleProductVariantInput;
};

export type UpdateSingleProductVariantInput = {
  readonly countryOfOrigin?: InputMaybe<Scalars['String']['input']>;
  readonly hsCode?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['Long']['input'];
  readonly kerryInventorySync?: InputMaybe<Scalars['Boolean']['input']>;
  /** only for logistics connected store */
  readonly logistics?: InputMaybe<ProductLogisticsInput>;
  readonly price?: InputMaybe<GraphQlMoneyInput>;
  readonly setItems: ReadonlyArray<ProductVariantSetItemInput>;
  readonly tags: ReadonlyArray<Scalars['String']['input']>;
  readonly unitCost?: InputMaybe<GraphQlMoneyInput>;
  readonly weightInGrams?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export type UpdateWhatsAppOptInSettingRequest = {
  readonly optInEnabled: Scalars['Boolean']['input'];
  readonly storeId: Scalars['String']['input'];
};

export type UpdatedField = {
  readonly __typename?: 'UpdatedField';
  readonly fieldName: Scalars['String']['output'];
  readonly from: Scalars['String']['output'];
  readonly to: Scalars['String']['output'];
};

export type UploadFilePayload = {
  readonly __typename?: 'UploadFilePayload';
  readonly fileName: Scalars['String']['output'];
  /** URI for identify the file. current format is like gs://anyx-private-storage/XXX/Order Import Template (7).csv */
  readonly fileUri: Scalars['String']['output'];
  /** Use fileURI instead. this is invalid URL format like https://storage.googleapis.com/anyx-private-storage/XXX/Order Import Template (7).csv */
  readonly fileUrl: Scalars['String']['output'];
  readonly signedUrl: Scalars['String']['output'];
};

export type UploadInventoryFilePayload = {
  readonly __typename?: 'UploadInventoryFilePayload';
  readonly uploadId: Scalars['Long']['output'];
};

export type UploadUrl = {
  readonly __typename?: 'UploadURL';
  readonly fileSizeLimitByte: Scalars['Int']['output'];
  readonly mimeType?: Maybe<Scalars['String']['output']>;
  readonly url: Scalars['String']['output'];
};

export type UrlAction = {
  readonly __typename?: 'UrlAction';
  readonly url: Scalars['String']['output'];
};

export type UrlActionInput = {
  readonly url: Scalars['String']['input'];
};

export type User = {
  readonly __typename?: 'User';
  readonly name: Scalars['String']['output'];
  readonly userId?: Maybe<Scalars['Long']['output']>;
};

export type UserIdentity = {
  readonly password: Scalars['String']['input'];
  readonly username: Scalars['String']['input'];
};

export type UserReaction = {
  readonly __typename?: 'UserReaction';
  readonly sign: ReactionSign;
};

export const ValidationOption = {
  CALL: 'CALL',
  EMAIL: 'EMAIL',
  INVOICE: 'INVOICE',
  SMS: 'SMS'
} as const;

export type ValidationOption = typeof ValidationOption[keyof typeof ValidationOption];
export const ValidationStatus = {
  IGNORED: 'IGNORED',
  INVALID_SHIPPING_ADDRESS_CITY: 'INVALID_SHIPPING_ADDRESS_CITY',
  INVALID_SHIPPING_ADDRESS_COUNTRY_CODE: 'INVALID_SHIPPING_ADDRESS_COUNTRY_CODE',
  INVALID_SHIPPING_ADDRESS_HOUSE_NUMBER: 'INVALID_SHIPPING_ADDRESS_HOUSE_NUMBER',
  INVALID_SHIPPING_ADDRESS_POSTAL_CODE: 'INVALID_SHIPPING_ADDRESS_POSTAL_CODE',
  INVALID_SHIPPING_ADDRESS_PROVINCE: 'INVALID_SHIPPING_ADDRESS_PROVINCE',
  VALID: 'VALID'
} as const;

export type ValidationStatus = typeof ValidationStatus[keyof typeof ValidationStatus];
export type VariantDailyReportData = {
  readonly __typename?: 'VariantDailyReportData';
  readonly advertisingFee: Scalars['BigDecimal']['output'];
  readonly avgOrderValues: Scalars['BigDecimal']['output'];
  readonly commissionFee: Scalars['BigDecimal']['output'];
  readonly consumptionRatio?: Maybe<Scalars['BigDecimal']['output']>;
  readonly costRate: Scalars['BigDecimal']['output'];
  readonly date?: Maybe<Scalars['LocalDate']['output']>;
  readonly discount: Scalars['BigDecimal']['output'];
  readonly discountRate: Scalars['BigDecimal']['output'];
  readonly grossProfit: Scalars['BigDecimal']['output'];
  readonly grossProfitRate: Scalars['BigDecimal']['output'];
  readonly inventory?: Maybe<Scalars['Long']['output']>;
  readonly logisticsFee: Scalars['BigDecimal']['output'];
  readonly marginalProfit: Scalars['BigDecimal']['output'];
  readonly marginalProfitRate: Scalars['BigDecimal']['output'];
  readonly merchandiseCost: Scalars['BigDecimal']['output'];
  readonly orders: Scalars['Long']['output'];
  readonly otherCost: Scalars['BigDecimal']['output'];
  readonly predictedInventoryDays?: Maybe<Scalars['Long']['output']>;
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly remaining?: Maybe<Scalars['Long']['output']>;
  readonly revenue: Scalars['BigDecimal']['output'];
  readonly roas: Scalars['BigDecimal']['output'];
  readonly targetInventoryDays?: Maybe<Scalars['Long']['output']>;
  readonly variantName?: Maybe<Scalars['String']['output']>;
};

export type VariantDailyReportPayload = {
  readonly __typename?: 'VariantDailyReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly data: ReadonlyArray<VariantDailyReportData>;
  readonly page: Scalars['Int']['output'];
  readonly summary: VariantReportSummary;
  readonly total: VariantDailyReportData;
  readonly totalItems: Scalars['Int']['output'];
};

export const VariantProductType = {
  MIXED: 'MIXED',
  SET: 'SET',
  SINGLE: 'SINGLE'
} as const;

export type VariantProductType = typeof VariantProductType[keyof typeof VariantProductType];
export type VariantReportFilterInput = {
  readonly adsMetricsType?: InputMaybe<AdsMetricsType>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly productVariantIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly variants?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type VariantReportPaginatedFilterInput = {
  readonly adsMetricsType?: InputMaybe<AdsMetricsType>;
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaces: ReadonlyArray<SaleChannelType>;
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataAccountIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly masterDataStoreIds: ReadonlyArray<Scalars['Long']['input']>;
  readonly orderStatus: ReportOrderStatus;
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
  readonly productIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly productVariantIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly sortBy?: InputMaybe<VariantReportSortByField>;
  readonly sortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate: Scalars['ZonedDatetime']['input'];
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly variants?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type VariantReportPayload = {
  readonly __typename?: 'VariantReportPayload';
  readonly currency: ReportSupportedCurrency;
  readonly items: ReadonlyArray<ProductVariantReportData>;
  readonly meta: ReportListMeta;
};

export const VariantReportSortByField = {
  ADVERTISING_COST: 'ADVERTISING_COST',
  ADVERTISING_REVENUE: 'ADVERTISING_REVENUE',
  AVG_SALES_VALUE: 'AVG_SALES_VALUE',
  COMMISSION_FEE: 'COMMISSION_FEE',
  DAYS_SALES_INVENTORY: 'DAYS_SALES_INVENTORY',
  DISCOUNTS: 'DISCOUNTS',
  DISCOUNTS_RATE: 'DISCOUNTS_RATE',
  GROSS_PROFIT: 'GROSS_PROFIT',
  GROSS_PROFIT_RATE: 'GROSS_PROFIT_RATE',
  GROSS_REVENUE: 'GROSS_REVENUE',
  GROSS_SALES: 'GROSS_SALES',
  LOGISTICS_FEE: 'LOGISTICS_FEE',
  MARGINAL_PROFIT: 'MARGINAL_PROFIT',
  MARGINAL_PROFIT_RATE: 'MARGINAL_PROFIT_RATE',
  NET_SALES: 'NET_SALES',
  OTHER_COST: 'OTHER_COST',
  PRODUCT: 'PRODUCT',
  PRODUCT_CODE: 'PRODUCT_CODE',
  RETURN_CANCEL: 'RETURN_CANCEL',
  REVENUE: 'REVENUE',
  ROAS: 'ROAS',
  SHIPPING_FEE: 'SHIPPING_FEE',
  TOTAL_SALES: 'TOTAL_SALES',
  UNIT_COST: 'UNIT_COST',
  UNIT_SOLD: 'UNIT_SOLD',
  VARIANT_NAME: 'VARIANT_NAME',
  WAREHOUSE_INVENTORY: 'WAREHOUSE_INVENTORY'
} as const;

export type VariantReportSortByField = typeof VariantReportSortByField[keyof typeof VariantReportSortByField];
export type VariantReportSummary = {
  readonly __typename?: 'VariantReportSummary';
  readonly grossProfit: ReportCompareData;
  readonly grossProfitRate: ReportCompareData;
  readonly marginalProfit: ReportCompareData;
  readonly marginalProfitRate: ReportCompareData;
  readonly otherCost: ReportCompareData;
  readonly revenue: ReportCompareData;
};

export type VariantValuesInput = {
  readonly masterDataAccountId?: InputMaybe<Scalars['Long']['input']>;
  readonly masterDataStoreIds?: InputMaybe<ReadonlyArray<Scalars['Long']['input']>>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly pageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type VariantValuesPayload = {
  readonly __typename?: 'VariantValuesPayload';
  readonly name: Scalars['String']['output'];
};

export type VariantValuesPayloadList = {
  readonly __typename?: 'VariantValuesPayloadList';
  readonly items: ReadonlyArray<VariantValuesPayload>;
  readonly meta: ReportListMeta;
};

export type Video = Media & {
  readonly __typename?: 'Video';
  readonly fileName: Scalars['String']['output'];
  readonly mimeType: Scalars['String']['output'];
  readonly previewUrl: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
};

export type VideoInput = {
  readonly fileName: Scalars['String']['input'];
  readonly mimeType: Scalars['String']['input'];
  readonly previewUrl: Scalars['String']['input'];
  readonly url: Scalars['String']['input'];
};

export type VideoMessageContent = {
  readonly __typename?: 'VideoMessageContent';
  readonly originalUrl: Scalars['Url']['output'];
  readonly previewUrl: Scalars['Url']['output'];
};

export const Wms = {
  LOGILESS: 'LOGILESS',
  LOGILESS_READ_ONLY: 'LOGILESS_READ_ONLY'
} as const;

export type Wms = typeof Wms[keyof typeof Wms];
export type WmsName = {
  readonly __typename?: 'WMSName';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

export const WmsType = {
  LOGILESS: 'LOGILESS',
  LOGILESS_READ_ONLY: 'LOGILESS_READ_ONLY'
} as const;

export type WmsType = typeof WmsType[keyof typeof WmsType];
export type Warehouse = {
  readonly __typename?: 'Warehouse';
  readonly country: Country;
  readonly created: Scalars['Time']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly wms: Wms;
  readonly wmsWarehouseId: Scalars['String']['output'];
};

export type WarehouseDetailed = {
  readonly __typename?: 'WarehouseDetailed';
  readonly addressFirst: Scalars['String']['output'];
  readonly addressSecond?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country: Country;
  readonly deliveryDestination: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly phoneNumber: PhoneNumber;
  readonly postalCode?: Maybe<Scalars['String']['output']>;
  readonly provinceId?: Maybe<Scalars['String']['output']>;
  readonly wms: Wms;
  readonly wmsWarehouseId: Scalars['String']['output'];
};

export type WarehouseFilters = {
  readonly country?: InputMaybe<Country>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly wms?: InputMaybe<Wms>;
};

export const WarehouseManagementSystem = {
  ANCHANTO: 'ANCHANTO',
  JUBELIO: 'JUBELIO'
} as const;

export type WarehouseManagementSystem = typeof WarehouseManagementSystem[keyof typeof WarehouseManagementSystem];
export type WarehousesPaginated = Paginated & {
  readonly __typename?: 'WarehousesPaginated';
  readonly page: Scalars['Int']['output'];
  readonly records: ReadonlyArray<Warehouse>;
  readonly total: Scalars['Int']['output'];
};

export type WhatsAppAutoReplyMessage = WhatsAppMessage & {
  readonly __typename?: 'WhatsAppAutoReplyMessage';
  readonly content: WhatsAppMessageAutoContent;
  readonly messageId: Scalars['String']['output'];
};

export type WhatsAppBroadcastActionButton = WhatsAppBroadcastUrlActionButton;

export type WhatsAppBroadcastActionButtonInput = {
  readonly type: WhatsAppBroadcastActionButtonType;
  readonly urlButton?: InputMaybe<WhatsAppBroadcastUrlActionButtonInput>;
};

export const WhatsAppBroadcastActionButtonType = {
  URL_BUTTON: 'URL_BUTTON'
} as const;

export type WhatsAppBroadcastActionButtonType = typeof WhatsAppBroadcastActionButtonType[keyof typeof WhatsAppBroadcastActionButtonType];
export type WhatsAppBroadcastCard = {
  readonly __typename?: 'WhatsAppBroadcastCard';
  readonly buttons: ReadonlyArray<WhatsAppBroadcastActionButton>;
  readonly descriptionText: Scalars['String']['output'];
  readonly image: Scalars['Url']['output'];
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type WhatsAppBroadcastCardContent = {
  readonly __typename?: 'WhatsAppBroadcastCardContent';
  readonly cards: ReadonlyArray<WhatsAppBroadcastCard>;
  readonly initialMessage: Scalars['String']['output'];
};

export type WhatsAppBroadcastCardContentInput = {
  readonly cards: ReadonlyArray<WhatsAppBroadcastCardInput>;
  readonly initialMessage: Scalars['String']['input'];
};

export type WhatsAppBroadcastCardInput = {
  readonly buttons: ReadonlyArray<WhatsAppBroadcastActionButtonInput>;
  readonly descriptionText: Scalars['String']['input'];
  readonly image: Scalars['Url']['input'];
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

export type WhatsAppBroadcastContent = WhatsAppBroadcastCardContent | WhatsAppBroadcastTextContent;

export type WhatsAppBroadcastContentInput = {
  readonly carousel?: InputMaybe<WhatsAppBroadcastCardContentInput>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly type: WhatsAppBroadcastMessageType;
};

export type WhatsAppBroadcastCreateRequestInput = {
  readonly recipient: WhatsAppRecipientInput;
  readonly schedule: Scalars['OffsetDateTime']['input'];
  readonly storeId: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
  readonly whatsappContent: WhatsAppBroadcastContentInput;
};

export type WhatsAppBroadcastMessage = {
  readonly __typename?: 'WhatsAppBroadcastMessage';
  readonly content: WhatsAppBroadcastContent;
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly deliveredAt?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly deliveryTiming: DeliveryTiming;
  readonly id: Scalars['String']['output'];
  readonly imageUrl?: Maybe<Scalars['Url']['output']>;
  readonly name: Scalars['String']['output'];
  readonly recipient: WhatsAppBroadcastRecipient;
  readonly statistics?: Maybe<WhatsAppBroadcastStatistics>;
  readonly status: WhatsAppBroadcastStatus;
};

export const WhatsAppBroadcastMessageType = {
  PLAIN_TEXT: 'PLAIN_TEXT',
  TEMPLATE_CARD: 'TEMPLATE_CARD'
} as const;

export type WhatsAppBroadcastMessageType = typeof WhatsAppBroadcastMessageType[keyof typeof WhatsAppBroadcastMessageType];
export type WhatsAppBroadcastMessages = {
  readonly __typename?: 'WhatsAppBroadcastMessages';
  readonly messages: ReadonlyArray<WhatsAppBroadcastMessage>;
  readonly total: Scalars['Int']['output'];
};

export type WhatsAppBroadcastRecipient = All | WhatsAppRecipientCustomerTagName;

export type WhatsAppBroadcastStatisticSummaries = {
  readonly __typename?: 'WhatsAppBroadcastStatisticSummaries';
  readonly compareStatistics?: Maybe<WhatsAppBroadcastStatistics>;
  readonly diffStatistics?: Maybe<WhatsAppBroadcastStatistics>;
  readonly sumStatistics: WhatsAppBroadcastStatistics;
};

export type WhatsAppBroadcastStatistics = {
  readonly __typename?: 'WhatsAppBroadcastStatistics';
  readonly clickCount: Scalars['Int']['output'];
  readonly clickRate: Scalars['Float']['output'];
  readonly deliveredCount: Scalars['Int']['output'];
  readonly openCount: Scalars['Int']['output'];
  readonly openRate: Scalars['Float']['output'];
};

export const WhatsAppBroadcastStatus = {
  APPROVED_DRAFT: 'APPROVED_DRAFT',
  DELIVERED: 'DELIVERED',
  DRAFT: 'DRAFT',
  ERROR: 'ERROR',
  REJECTED: 'REJECTED',
  RESCHEDULE_REQUIRED: 'RESCHEDULE_REQUIRED',
  REVIEWING: 'REVIEWING',
  SCHEDULED: 'SCHEDULED',
  SENDING: 'SENDING'
} as const;

export type WhatsAppBroadcastStatus = typeof WhatsAppBroadcastStatus[keyof typeof WhatsAppBroadcastStatus];
export type WhatsAppBroadcastTextContent = {
  readonly __typename?: 'WhatsAppBroadcastTextContent';
  readonly text: Scalars['String']['output'];
};

export type WhatsAppBroadcastToDraftRequest = {
  readonly broadcastId: Scalars['String']['input'];
};

export const WhatsAppBroadcastUpdateMode = {
  DRY_SUBMIT: 'DRY_SUBMIT',
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE'
} as const;

export type WhatsAppBroadcastUpdateMode = typeof WhatsAppBroadcastUpdateMode[keyof typeof WhatsAppBroadcastUpdateMode];
export type WhatsAppBroadcastUpdateRequestInput = {
  readonly broadcastId: Scalars['String']['input'];
  readonly mode: WhatsAppBroadcastUpdateMode;
  readonly recipient: WhatsAppRecipientInput;
  readonly schedule?: InputMaybe<Scalars['OffsetDateTime']['input']>;
  readonly title: Scalars['String']['input'];
  readonly whatsappContent: WhatsAppBroadcastContentInput;
};

export type WhatsAppBroadcastUpdateResult = {
  readonly __typename?: 'WhatsAppBroadcastUpdateResult';
  readonly broadcast: WhatsAppBroadcastMessage;
  readonly hasChanged: Scalars['Boolean']['output'];
};

export type WhatsAppBroadcastUrlActionButton = {
  readonly __typename?: 'WhatsAppBroadcastUrlActionButton';
  readonly buttonText: Scalars['String']['output'];
  readonly url: Scalars['Url']['output'];
};

export type WhatsAppBroadcastUrlActionButtonInput = {
  readonly buttonText: Scalars['String']['input'];
  readonly url: Scalars['Url']['input'];
};

export type WhatsAppChatEvent = CommonChatEvent | WhatsAppMessageEvent;

export type WhatsAppChatEvents = {
  readonly __typename?: 'WhatsAppChatEvents';
  readonly chatEvents: ReadonlyArray<WhatsAppChatEvent>;
  readonly chatId: Scalars['String']['output'];
  readonly lastCheckedAt?: Maybe<Scalars['UnixTimestamp']['output']>;
  readonly userName: Scalars['String']['output'];
};

export type WhatsAppChatPlatformDetail = {
  readonly __typename?: 'WhatsAppChatPlatformDetail';
  readonly isOptIn: Scalars['Boolean']['output'];
};

export type WhatsAppImageMessageContent = {
  readonly __typename?: 'WhatsAppImageMessageContent';
  readonly caption?: Maybe<Scalars['String']['output']>;
  readonly url: Scalars['Url']['output'];
};

export type WhatsAppInfo = ChatInfo & {
  readonly __typename?: 'WhatsAppInfo';
  readonly chatId: Scalars['String']['output'];
  readonly chatType: ChatType;
  readonly storeId: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type WhatsAppMessage = {
  readonly messageId: Scalars['String']['output'];
};

export type WhatsAppMessageAutoContent = {
  readonly version: Scalars['Int']['output'];
};

export const WhatsAppMessageDeliveryStatus = {
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
  READ: 'READ',
  RECEIVED: 'RECEIVED',
  SENT: 'SENT'
} as const;

export type WhatsAppMessageDeliveryStatus = typeof WhatsAppMessageDeliveryStatus[keyof typeof WhatsAppMessageDeliveryStatus];
export type WhatsAppMessageEvent = {
  readonly __typename?: 'WhatsAppMessageEvent';
  readonly id: Scalars['String']['output'];
  readonly message: WhatsAppMessage;
  readonly replyTo?: Maybe<WhatsAppReplyTo>;
  readonly seqNumber: Scalars['Long']['output'];
  readonly status: WhatsAppMessageDeliveryStatus;
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type WhatsAppMessageReceivedContent = WhatsAppImageMessageContent | WhatsAppOptInMessageContent | WhatsAppOptOutMessageContent | WhatsAppTextMessageContent | WhatsAppUnsupportedContent | WhatsAppVideoMessageContent;

export type WhatsAppMessageSentContent = WhatsAppImageMessageContent | WhatsAppTextMessageContent | WhatsAppVideoMessageContent;

export type WhatsAppOptInAcceptedActivity = CustomerActivity & {
  readonly __typename?: 'WhatsAppOptInAcceptedActivity';
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type WhatsAppOptInMessageContent = {
  readonly __typename?: 'WhatsAppOptInMessageContent';
  readonly text: Scalars['String']['output'];
};

export type WhatsAppOptInRequestMessageContent = WhatsAppMessageAutoContent & {
  readonly __typename?: 'WhatsAppOptInRequestMessageContent';
  readonly version: Scalars['Int']['output'];
};

export type WhatsAppOptOutMessageContent = {
  readonly __typename?: 'WhatsAppOptOutMessageContent';
  readonly text: Scalars['String']['output'];
};

export type WhatsAppOptedInRequestActivity = CustomerActivity & {
  readonly __typename?: 'WhatsAppOptedInRequestActivity';
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type WhatsAppOptedOutActivity = CustomerActivity & {
  readonly __typename?: 'WhatsAppOptedOutActivity';
  readonly id: Scalars['String']['output'];
  readonly timestamp: Scalars['UnixTimestamp']['output'];
};

export type WhatsAppPhoneNumber = {
  readonly __typename?: 'WhatsAppPhoneNumber';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly number: Scalars['String']['output'];
  readonly profilePicUrl?: Maybe<Scalars['String']['output']>;
};

export type WhatsAppRecipientCustomerTagName = {
  readonly __typename?: 'WhatsAppRecipientCustomerTagName';
  readonly name: Scalars['String']['output'];
};

export type WhatsAppRecipientInput = {
  readonly customerTagName?: InputMaybe<Scalars['String']['input']>;
  readonly type: WhatsAppRecipientType;
};

export const WhatsAppRecipientType = {
  ALL: 'ALL',
  WITH_CUSTOMER_TAGS: 'WITH_CUSTOMER_TAGS'
} as const;

export type WhatsAppRecipientType = typeof WhatsAppRecipientType[keyof typeof WhatsAppRecipientType];
export type WhatsAppReplyTo = {
  readonly __typename?: 'WhatsAppReplyTo';
  readonly message: WhatsAppMessage;
};

export type WhatsAppReplyToInput = {
  readonly messageId?: InputMaybe<Scalars['String']['input']>;
};

export type WhatsAppSendImageMessageRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly replyTo?: InputMaybe<WhatsAppReplyToInput>;
  readonly url: Scalars['String']['input'];
};

export type WhatsAppSendTextMessageRequestInput = {
  readonly chatId: Scalars['String']['input'];
  readonly replyTo?: InputMaybe<WhatsAppReplyToInput>;
  readonly text: Scalars['String']['input'];
};

export type WhatsAppStoreCsMessage = WhatsAppMessage & {
  readonly __typename?: 'WhatsAppStoreCSMessage';
  readonly author: BasicUser;
  readonly content: WhatsAppMessageSentContent;
  readonly messageId: Scalars['String']['output'];
  readonly messageState: MessageState;
};

export type WhatsAppStoreChat = StoreChat & {
  readonly __typename?: 'WhatsAppStoreChat';
  readonly businessAccountId: Scalars['String']['output'];
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly optInEnabled: Scalars['Boolean']['output'];
  readonly phones: ReadonlyArray<WhatsAppPhoneNumber>;
  readonly type: ChatType;
};

export type WhatsAppTargetInput = {
  readonly type: WhatsAppTargetType;
};

export const WhatsAppTargetType = {
  BROADCAST_MESSAGE: 'BROADCAST_MESSAGE',
  CHAT_MESSAGE: 'CHAT_MESSAGE'
} as const;

export type WhatsAppTargetType = typeof WhatsAppTargetType[keyof typeof WhatsAppTargetType];
export type WhatsAppTextMessageContent = {
  readonly __typename?: 'WhatsAppTextMessageContent';
  readonly text: Scalars['String']['output'];
};

export type WhatsAppUnsupportedContent = {
  readonly __typename?: 'WhatsAppUnsupportedContent';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type WhatsAppUserMessage = WhatsAppMessage & {
  readonly __typename?: 'WhatsAppUserMessage';
  readonly content: WhatsAppMessageReceivedContent;
  readonly messageId: Scalars['String']['output'];
};

export type WhatsAppVideoMessageContent = {
  readonly __typename?: 'WhatsAppVideoMessageContent';
  readonly caption?: Maybe<Scalars['String']['output']>;
  readonly url: Scalars['Url']['output'];
};

export type Workflow = {
  readonly __typename?: 'Workflow';
  readonly body: WorkflowBody;
  readonly createdAt: Scalars['OffsetDateTime']['output'];
  readonly enabled: Scalars['Boolean']['output'];
  readonly id: Scalars['String']['output'];
  readonly lastActivatedAt?: Maybe<Scalars['OffsetDateTime']['output']>;
  readonly reports: ReadonlyArray<WorkflowReport>;
  readonly schedule?: Maybe<WorkflowSchedule>;
  readonly testMode: Scalars['Boolean']['output'];
  readonly title: Scalars['String']['output'];
  readonly version: Scalars['Int']['output'];
};

export type WorkflowAction = {
  readonly __typename?: 'WorkflowAction';
  readonly delay?: Maybe<DelayAction>;
  readonly id: Scalars['String']['output'];
  readonly lineMessage?: Maybe<LineMessageAction>;
};

export type WorkflowActionInput = {
  readonly delay?: InputMaybe<DelayActionInput>;
  readonly id: Scalars['String']['input'];
  readonly lineMessage?: InputMaybe<LineMessageActionInput>;
};

export type WorkflowAuditLog = {
  readonly __typename?: 'WorkflowAuditLog';
  readonly durationMillis: Scalars['Long']['output'];
  readonly errors: ReadonlyArray<WorkflowAuditLogError>;
  readonly id: Scalars['String']['output'];
  readonly lastExecutedAt: Scalars['OffsetDateTime']['output'];
  readonly startedAt: Scalars['OffsetDateTime']['output'];
  readonly status: WorkflowAuditLogStatus;
};

export type WorkflowAuditLogError = {
  readonly __typename?: 'WorkflowAuditLogError';
  readonly errorLocation: WorkflowAuditLogErrorLocation;
  readonly errorType: WorkflowAuditLogErrorType;
};

export const WorkflowAuditLogErrorLocation = {
  ACTION: 'ACTION',
  CONDITION: 'CONDITION',
  DELAY_ACTION: 'DELAY_ACTION',
  SEND_LINE_MESSAGE_ACTION: 'SEND_LINE_MESSAGE_ACTION'
} as const;

export type WorkflowAuditLogErrorLocation = typeof WorkflowAuditLogErrorLocation[keyof typeof WorkflowAuditLogErrorLocation];
export const WorkflowAuditLogErrorType = {
  ANYCHAT_INTERNAL: 'ANYCHAT_INTERNAL',
  LINE_CONNECTION_ERROR: 'LINE_CONNECTION_ERROR',
  LINE_CUSTOMER_NOT_FOUND: 'LINE_CUSTOMER_NOT_FOUND',
  LINE_MESSAGE_QUOTA_EXCEEDED: 'LINE_MESSAGE_QUOTA_EXCEEDED',
  SHOPIFY_CONNECTION_ERROR: 'SHOPIFY_CONNECTION_ERROR',
  SHOPIFY_CUSTOMER_NOT_FOUND: 'SHOPIFY_CUSTOMER_NOT_FOUND',
  UNEXPECTED: 'UNEXPECTED'
} as const;

export type WorkflowAuditLogErrorType = typeof WorkflowAuditLogErrorType[keyof typeof WorkflowAuditLogErrorType];
export const WorkflowAuditLogStatus = {
  CONFIG_CHANGE: 'CONFIG_CHANGE',
  CONFIG_ERROR: 'CONFIG_ERROR',
  FAILURE: 'FAILURE',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEED: 'SUCCEED'
} as const;

export type WorkflowAuditLogStatus = typeof WorkflowAuditLogStatus[keyof typeof WorkflowAuditLogStatus];
export type WorkflowAuditLogs = {
  readonly __typename?: 'WorkflowAuditLogs';
  readonly auditLogs: ReadonlyArray<WorkflowAuditLog>;
  readonly total: Scalars['Int']['output'];
};

export type WorkflowBody = {
  readonly __typename?: 'WorkflowBody';
  readonly blocks: ReadonlyArray<FlowBlock>;
  readonly initialFlowBlockId: Scalars['String']['output'];
  readonly type: WorkflowType;
};

export type WorkflowBodyInput = {
  readonly blocks: ReadonlyArray<FlowBlockInput>;
  readonly initialFlowBlockId: Scalars['String']['input'];
  readonly type: WorkflowType;
};

export type WorkflowCondition = {
  readonly __typename?: 'WorkflowCondition';
  readonly id: Scalars['String']['output'];
  readonly operator: ConditionOperator;
  readonly type: ConditionType;
  readonly values: ReadonlyArray<Scalars['String']['output']>;
};

export type WorkflowConditionInput = {
  readonly id: Scalars['String']['input'];
  readonly operator: ConditionOperator;
  readonly type: ConditionType;
  readonly values: ReadonlyArray<Scalars['String']['input']>;
};

export type WorkflowReport = {
  readonly __typename?: 'WorkflowReport';
  readonly clickCount?: Maybe<Scalars['Int']['output']>;
  readonly clickRate?: Maybe<Scalars['Float']['output']>;
  readonly deliveredCount: Scalars['Int']['output'];
  readonly lineUnitMessageID: Scalars['String']['output'];
  readonly lineUnitMessageName: Scalars['String']['output'];
  readonly openRate?: Maybe<Scalars['Float']['output']>;
  readonly openedCount?: Maybe<Scalars['Int']['output']>;
};

export type WorkflowSchedule = {
  readonly __typename?: 'WorkflowSchedule';
  readonly endAt: Scalars['OffsetDateTime']['output'];
  readonly startAt: Scalars['OffsetDateTime']['output'];
};

export type WorkflowScheduleInput = {
  readonly endAt: Scalars['OffsetDateTime']['input'];
  readonly startAt: Scalars['OffsetDateTime']['input'];
};

export type WorkflowStatusOk = {
  readonly __typename?: 'WorkflowStatusOk';
  readonly ok: Scalars['Boolean']['output'];
};

export const WorkflowType = {
  LINE_NEW_FRIEND: 'LINE_NEW_FRIEND',
  LINE_NEW_MESSAGE: 'LINE_NEW_MESSAGE',
  LINE_RICH_MENU_POSTBACK: 'LINE_RICH_MENU_POSTBACK',
  LINE_SHOPIFY_ID_SYNCED: 'LINE_SHOPIFY_ID_SYNCED',
  SHOPIFY_NEW_ORDER: 'SHOPIFY_NEW_ORDER',
  SHOPIFY_ORDER_FULFILLMENT_STATUS_UPDATED: 'SHOPIFY_ORDER_FULFILLMENT_STATUS_UPDATED',
  SHOPIFY_ORDER_PAYMENT_STATUS_UPDATED: 'SHOPIFY_ORDER_PAYMENT_STATUS_UPDATED'
} as const;

export type WorkflowType = typeof WorkflowType[keyof typeof WorkflowType];
export type Workflows = {
  readonly __typename?: 'Workflows';
  readonly total: Scalars['Int']['output'];
  readonly workflows: ReadonlyArray<Workflow>;
};

export type Working = {
  readonly __typename?: 'Working';
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  readonly _?: Maybe<Scalars['Boolean']['output']>;
};

export type WrongPageSelectedRefreshTokenError = {
  readonly __typename?: 'WrongPageSelectedRefreshTokenError';
  readonly message: Scalars['String']['output'];
  readonly pageId: Scalars['String']['output'];
};

export type YahooAdsAccountPayload = {
  readonly __typename?: 'YahooAdsAccountPayload';
  readonly id: Scalars['Long']['output'];
  readonly name: Scalars['String']['output'];
};

export type YahooAdsAccountsInput = {
  readonly datasourceTokenId: Scalars['Long']['input'];
  readonly datasourceType: DatasourceType;
};

export type YahooAdsAccountsPayload = {
  readonly __typename?: 'YahooAdsAccountsPayload';
  readonly accounts: ReadonlyArray<YahooAdsAccountPayload>;
};

export type YahooAdsDatasourceTokenPayload = {
  readonly __typename?: 'YahooAdsDatasourceTokenPayload';
  readonly datasourceTokenId: Scalars['Long']['output'];
};

export type YahooAdsExchangeCodeInput = {
  readonly code: Scalars['String']['input'];
  readonly redirectUrl: Scalars['String']['input'];
};

export type YahooAdsLoginUrlInput = {
  readonly redirectUrl: Scalars['String']['input'];
  readonly state: Scalars['String']['input'];
};

export type YahooAdsLoginUrlPayload = {
  readonly __typename?: 'YahooAdsLoginUrlPayload';
  readonly loginUrl: Scalars['String']['output'];
};

export type YahooLoginUrlInput = {
  readonly publicKey: Scalars['String']['input'];
  readonly publicKeyExpirationDate: Scalars['OffsetDateTime']['input'];
  readonly publicKeyVersion: Scalars['Int']['input'];
  readonly redirectUri: Scalars['String']['input'];
  readonly sellerId: Scalars['String']['input'];
  readonly storeId: Scalars['Long']['input'];
};

export type YahooSaleChannelData = {
  readonly __typename?: 'YahooSaleChannelData';
  readonly sellerId: Scalars['String']['output'];
};

export type YahooSalesReportChartInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type YahooSalesReportChartPayload = {
  readonly __typename?: 'YahooSalesReportChartPayload';
  readonly avgSpendPerCustomer: ReadonlyArray<Scalars['Map']['output']>;
  readonly currency: ReportSupportedCurrency;
  readonly orderCount: ReadonlyArray<Scalars['Map']['output']>;
  readonly totalSales: ReadonlyArray<Scalars['Map']['output']>;
  readonly unitSoldCount: ReadonlyArray<Scalars['Map']['output']>;
};

export type YahooSalesReportSummaryInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type YahooSalesReportSummaryPayload = {
  readonly __typename?: 'YahooSalesReportSummaryPayload';
  readonly avgSpendPerCustomer: NumericValueWithDiffPayload;
  readonly currency: ReportSupportedCurrency;
  readonly orderCount: NumericValueWithDiffPayload;
  readonly totalSales: NumericValueWithDiffPayload;
  readonly unitSoldCount: NumericValueWithDiffPayload;
};

export type YahooSalesReportTableInput = {
  readonly endDate: Scalars['ZonedDatetime']['input'];
  readonly masterDataStoreId: Scalars['Long']['input'];
  readonly pageNumber: Scalars['Int']['input'];
  readonly pageSize: Scalars['Int']['input'];
  readonly startDate: Scalars['ZonedDatetime']['input'];
};

export type YahooSalesReportTablePayload = {
  readonly __typename?: 'YahooSalesReportTablePayload';
  readonly avgSpendPerCustomer: Scalars['Int']['output'];
  readonly currency: ReportSupportedCurrency;
  readonly date: Scalars['LocalDate']['output'];
  readonly orderCount: Scalars['Int']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly unitSoldCount: Scalars['Int']['output'];
};

export type YahooSalesReportTablePayloadList = {
  readonly __typename?: 'YahooSalesReportTablePayloadList';
  readonly items: ReadonlyArray<YahooSalesReportTablePayload>;
  readonly meta: ReportListMeta;
  readonly total: YahooSalesReportTotalPayload;
};

export type YahooSalesReportTotalPayload = {
  readonly __typename?: 'YahooSalesReportTotalPayload';
  readonly avgSpendPerCustomer: Scalars['Int']['output'];
  readonly orderCount: Scalars['Int']['output'];
  readonly totalSales: Scalars['BigDecimal']['output'];
  readonly unitSoldCount: Scalars['Int']['output'];
};

export type ZozotownFileUploadInput = {
  readonly fileUrl: Scalars['String']['input'];
  readonly saleChannelId?: InputMaybe<Scalars['Long']['input']>;
  readonly storeId: Scalars['Long']['input'];
  readonly type: ZozotownFileUploadType;
};

export const ZozotownFileUploadType = {
  AD_COST_ADS: 'AD_COST_ADS',
  ORDERS: 'ORDERS',
  PRODUCTS: 'PRODUCTS'
} as const;

export type ZozotownFileUploadType = typeof ZozotownFileUploadType[keyof typeof ZozotownFileUploadType];
export type GetCourierSettingsListResponse = Paginated & {
  readonly __typename?: 'getCourierSettingsListResponse';
  readonly accountCourierSettings: ReadonlyArray<AccountCourierSettings>;
  readonly page: Scalars['Int']['output'];
  readonly total: Scalars['Int']['output'];
};

export type GetInventoriesCsvResponse = Document | Job;

export type GetPaymentMethodFilter = {
  readonly courier?: InputMaybe<CourierType>;
};

export type GetPickupsInput = {
  readonly accountId?: InputMaybe<Scalars['ID']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
  readonly pickupDate?: InputMaybe<Scalars['Date']['input']>;
  readonly pickupStatus?: InputMaybe<PickupStatus>;
};

export type GetShipperAddressFilter = {
  readonly default?: InputMaybe<Scalars['Boolean']['input']>;
  readonly keyword?: InputMaybe<Scalars['String']['input']>;
};

export type GetShippingRateResponse = {
  readonly __typename?: 'getShippingRateResponse';
  readonly errors?: Maybe<ReadonlyArray<ShippingRateError>>;
  readonly shippingRates?: Maybe<ReadonlyArray<ShippingRateDetail>>;
};

export type ShipperAddressesListResponse = Paginated & {
  readonly __typename?: 'shipperAddressesListResponse';
  readonly page: Scalars['Int']['output'];
  readonly perPage: Scalars['Int']['output'];
  readonly shipperAddresses: ReadonlyArray<ShipperAddress>;
  readonly total: Scalars['Int']['output'];
};

export type ShippingRateError = {
  readonly __typename?: 'shippingRateError';
  readonly crossBorderCourierProvider: CrossBorderCourierProvider;
  readonly messages?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};
