import { isBefore, isAfter, isDate, parseISO, isValid, format } from 'date-fns';
import { enUS, ja, zhCN, ko } from 'date-fns/locale';
import { formatInTimeZone, FormatOptionsWithTZ, toZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { EMPTY_DEFAULT } from '../config';
import { RangePeriod } from '../models/dates.model';

import { AnyXLanguage, LanguageUtils } from './language.utils';
import { TimezoneUtils } from './timezone.utils';

dayjs.extend(weekOfYear);
dayjs.extend(timezone);
dayjs.extend(utc);

export { dayjs };

export class DateUtils {
  static readonly LOCALES = {
    [AnyXLanguage.EN_US]: enUS,
    [AnyXLanguage.JA_JP]: ja,
    [AnyXLanguage.ZH_CN]: zhCN,
    [AnyXLanguage.KO_KR]: ko,
  };

  // Details
  // https://adasiaholdings.atlassian.net/wiki/spaces/ANYX/pages/3102605334/Date+and+Times
  static readonly FORMATS = {
    // Days of week
    EEEE: 'EEEE',
    // March 1st, 2017
    PPP: 'PPP',
    // March 1st, 2017 13:00
    PPPHHmm: 'PPP HH:mm',
    // 2017/03/01 13:00
    PHHmm: 'P HH:mm',
    // 2017/03/01
    P: 'P',
  };

  static getDateFnsLocale(language?: AnyXLanguage) {
    return DateUtils.LOCALES[language || AnyXLanguage.EN_US];
  }

  static getClosestDate(date1: Date | null, date2: Date | null, period: RangePeriod = 'past') {
    if (!isDate(date1) || !isDate(date2)) {
      return isDate(date1) ? date1 : isDate(date2) ? date2 : null;
    }
    return period === 'future'
      ? isBefore(date1 as Date, date2 as Date)
        ? date2
        : date1
      : isAfter(date1 as Date, date2 as Date)
      ? date2
      : date1;
  }

  static formatDate(
    date: number | Date | string | null,
    formatting?: string,
    language = LanguageUtils.getCurrentLanguage()
  ): string {
    date = typeof date === 'string' ? parseISO(date) : date;
    return date && isValid(date)
      ? format(date, formatting || DateUtils.FORMATS.PHHmm, {
          locale: DateUtils.getDateFnsLocale(language),
        })
      : EMPTY_DEFAULT;
  }

  static formatDateInTimeZone(
    date: Date | string | number,
    extras?: {
      timeZone?: string;
      language?: AnyXLanguage;
      formatStr?: string;
      options?: FormatOptionsWithTZ;
    }
  ) {
    const {
      timeZone = TimezoneUtils.getCurrentTimezone(),
      language = LanguageUtils.getCurrentLanguage(),
      formatStr = DateUtils.FORMATS.PHHmm,
      options,
    } = extras || {};

    try {
      return formatInTimeZone(date, timeZone, formatStr, {
        locale: DateUtils.getDateFnsLocale(language),
        ...options,
      });
    } catch (error) {
      return EMPTY_DEFAULT;
    }
  }

  static toISOStringWithTimezone = (
    date: Date,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    const zonedTime = toZonedTime(date, timezone);
    const utc = DateUtils.formatDateInTimeZone(zonedTime, {
      timeZone: timezone,
      formatStr: 'XXX',
    });
    try {
      const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
      return (
        zonedTime.getFullYear() +
        '-' +
        pad(zonedTime.getMonth() + 1) +
        '-' +
        pad(zonedTime.getDate()) +
        'T' +
        pad(zonedTime.getHours()) +
        ':' +
        pad(zonedTime.getMinutes()) +
        ':' +
        pad(zonedTime.getSeconds()) +
        utc
      );
    } catch (e) {
      throw new Error('toISOStringWithTimezone could not parse date');
    }
  };

  static toISOStringWithoutTimezone = (
    date: Date,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    const zonedTime = toZonedTime(date, timezone);
    try {
      const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
      return (
        zonedTime.getFullYear() +
        '-' +
        pad(zonedTime.getMonth() + 1) +
        '-' +
        pad(zonedTime.getDate())
      );
    } catch (e) {
      throw new Error('toISOStringWithoutTimezone could not parse date');
    }
  };

  static getLocalTimeZone() {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      console.error('Cannot get local timezone:', error);
      return 'UTC';
    }
  }

  static isoEndOfDay(
    isoString: string,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ): string {
    try {
      const date = parseISO(isoString);
      const zonedDate = toZonedTime(date, timezone);
      zonedDate.setHours(23, 59, 59, 999);

      return DateUtils.toISOStringWithTimezone(zonedDate, timezone);
    } catch (error) {
      console.error('Error converting to end of day:', error);
      throw new Error('toEndOfDay could not parse date');
    }
  }

  static isoStartOfDay(
    isoString: string,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ): string {
    try {
      const date = parseISO(isoString);
      const zonedDate = toZonedTime(date, timezone);
      zonedDate.setHours(0, 0, 0, 0);

      return DateUtils.toISOStringWithTimezone(zonedDate, timezone);
    } catch (error) {
      console.error('Error converting to start of day:', error);
      throw new Error('toStartOfDay could not parse date');
    }
  }
}
