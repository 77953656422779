import { DatesProvider } from '@any-ui-react/dates';

import { useSelfCtx } from '~anyx/common/self';

export const AnyxDatesProvider = ({ children }: { children: React.ReactNode }) => {
  const { self } = useSelfCtx();

  return (
    <DatesProvider settings={{ locale: self.languageId, timezone: self.timezoneId }}>
      {children}
    </DatesProvider>
  );
};
