import { useEffect, useMemo } from 'react';

import { DefaultRangeValue, useRangeSelectDefaultOptions } from '@any-ui-react/dates';

import { HomeMetricOption, useReportSyncCalendar } from '~anyx/feature/report';
import {
  AdsMetricsType,
  ReportChartTab,
  ReportDimensions,
  ReportOrderStatus,
  ReportTab,
} from '~anyx/shared/graphql';
import { DateUtils, dayjs, TimezoneUtils, useFilters } from '~anyx/shared/utils';

import { HomeFilterInputFilterType, HomePageFiltersParser } from './homePage.filters';

export const useHomeFilter = ({
  accountIds,
  storeId,
}: {
  accountIds: string[];
  storeId?: string;
}) => {
  const timezone = TimezoneUtils.getCurrentTimezone();
  const rangeList = useRangeSelectDefaultOptions({
    only: [DefaultRangeValue.LAST_7_DAYS],
  });
  const startDate = rangeList[0]?.value[0] || dayjs().tz(timezone).startOf('day').format();
  const endDate = rangeList[0]?.value[1] || dayjs().tz(timezone).endOf('day').format();
  const { range } = useReportSyncCalendar();

  const filtersDefault = useMemo(
    () =>
      HomePageFiltersParser.parse({
        startDate: range.startDate ?? startDate,
        endDate: range.endDate ?? endDate,
        orderStatus: ReportOrderStatus.ORDERED,
        masterDataAccountIds: accountIds,
        masterDataStoreIds: storeId ? [storeId] : [],
        marketplaces: [],
        dimensions: ReportDimensions.DAY,
        metric: HomeMetricOption.GROSS_SALES,
        adsMetricsType: AdsMetricsType.GROSS,
        chartTab: ReportChartTab.CHANNEL,
        tab: ReportTab.ALL,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, storeId]
  );

  const filters = useFilters<HomeFilterInputFilterType>(filtersDefault, {
    withPagination: false,
    withLocalStorage: false,
    scope: 'home',
    parser: HomePageFiltersParser.parse,
  });

  const dateVariables = {
    endDate: filters.current?.endDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.endDate))
      : '',
    startDate: filters.current?.startDate
      ? DateUtils.toISOStringWithTimezone(new Date(filters.current?.startDate))
      : '',
  };

  const filterInputs = {
    ...filters.current,
    ...dateVariables,
  };

  // Reset the filter when default values are changed
  useEffect(() => {
    filters.resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDefault]);

  return {
    filters,
    filterInputs,
  };
};
